import React, { Fragment } from "react";

import Multistep from "react-multistep";

import PageTitle from "./../../layouts/PageTitle";
import { useTranslation } from "react-i18next";

const AddConsultation = () => {
   const { t } = useTranslation();
   const prevStyle = {
      background: "#F7FAFC",
      borderWidth: "0px",
      color: "#333333",
      borderRadius: "4px",
      fontSize: "14px",
      fontWeight: "600",
      padding: "0.55em 2em",
      border: "1px solid #EEEEEE",
      marginRight: "1rem",
   };
   const nextStyle = {
      background: "#24439D",
      borderWidth: "0px",
      color: "#fff",
      borderRadius: "4px",
      fontSize: "14px",
      fontWeight: "600",
      padding: "0.55em 2em",
   };
   return (
      <Fragment>
         <PageTitle activeMenu="Consultation" motherMenu="Home" />

         <div className="row">
            <div className="col-xl-12 col-xxl-12">
               <div className="card">
                  <div className="card-header">
                     <h4 className="card-title">{t('common.consultation-form')}</h4>
                  </div>
                  <div className="card-body">
                     <form
                        onSubmit={(e) => e.preventDefault()}
                        id="step-form-horizontal"
                        className="step-form-horizontal"
                     >

                     </form>
                  </div>
               </div>
            </div>
         </div>
      </Fragment>
   );
};

export default AddConsultation;
