import React, {useState,useEffect } from 'react'
import { connect, useDispatch } from 'react-redux';
import { Modal, Button, Spinner } from 'react-bootstrap';
import BasicDatatable from '../DataTable';
import { Link } from "react-router-dom";
import {
    getTypeActesAction,
    getFamilleActesAction,
    startLoader,
    createTypeActeAction,
    deleteDataAction,
    updateTypeActesAction,
    importTypeActeFileUploadAction
} from '../../../../store/actions/ProviderActions';
import Select from 'react-select';
import swal from 'sweetalert';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { InputText } from 'primereact/inputtext';
import { Button as PButton } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { DataTableBase } from '../../DataTables/DataTableBase';
import { currentUser } from "../../../../store/selectors/CurrentUserSelector";
import { useTranslation } from "react-i18next";

const TypeActe = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    let errorsObj = { name: '', code: '' };
    const [nameFr, setNameFr] = useState('');
    const [nameEn, setNameEn] = useState('');
    const [file, setFile] = useState(null);
    const [code, setCode] = useState('');
    const [famille, setFamille] = useState('');
    const [typeActesId, setTypeActesId] = useState('');
    const [errors, setErrors] = useState(errorsObj);
    //Modal box
    const [addCard, setAddCard] = useState(false);
    const [updateMode, setUpdateMode] = useState(false);
    const header =["Code", "Grande famille", "Nom","Date de création","Action"];

    const [filters, setFilters] = useState(null);
    const [globalFilterValue, setGlobalFilterValue] = useState('');

    const initFilters = () => {
        setFilters({
            global: { value: null, matchMode: FilterMatchMode.CONTAINS },
            code: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            'familleTypeActe.nameFr': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            nameFr: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            createdAt: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
        });
        setGlobalFilterValue("");
    };

    const renderHeader = () => {
        return (
            <div className="flex justify-content-between">
                <PButton type="button" icon="pi pi-filter-slash"  label={t('common.reinit')} outlined onClick={clearFilter} />
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText value={globalFilterValue} onChange={(e) => onGlobalFilterChange(e)}  placeholder={t('common.search')} />
                </span>
            </div>
        );
    };

    const clearFilter = () => {
        initFilters();
    };

    const setColumns = () => {
        return [
            {name: 'code', headerName: "Code", filterPlaceholder: 'Filtrer par code', style: { minWidth: '12rem' }, filter: true, bodyTemplate: null},
            {name: 'familleTypeActe.nameFr', headerName: "Grande Famille", filterPlaceholder: 'Filtrer par grande famille', style: { minWidth: '12rem' }, filter: true, bodyTemplate: null},
            {name: 'nameFr', headerName: t('common.name'), filterPlaceholder: t('common.filter-name'), style: { minWidth: '12rem' }, filter: true, bodyTemplate: null},
            {name: 'createdAt', filterField: 'createdAt', dataType: 'date', headerName: 'Créé le', filterPlaceholder: t('facturation.create-date-filter'), style: { minWidth: '6rem' }, filter: true, bodyTemplate: datePaiementBodyTemplate, filterElement: dateFilterTemplate},
            {name: 'actions', headerName: 'Actions', filterPlaceholder: '', style: { minWidth: '6rem'}, filter: false, bodyTemplate: getActionsTemplate},
        ]
    };

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };

        _filters['global'].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
    };

    const getActionsTemplate = (rowData) => {
        return <div style={{ width: '100%', display: 'flex', justifyContent: 'center'}}>
            {hasMenu('MENPARAMACTES') && canDo('MENPARAMACTES', 'UPDATEFAMILLEACTE') && <Link href="#" to="#" data-testid={'update-button-'+rowData.id} onClick={e => updateData(rowData)} className="mr-4" title="Edit">
                <i className="fa fa-pencil color-muted"></i>{" "}
            </Link>}
            {hasMenu('MENPARAMACTES') && canDo('MENPARAMACTES', 'DELETEFAMILLEACTE') && <Link href="#" to="#" data-testid={'delete-button-'+rowData.id} title="Close"
                onClick={() =>
                    swal({
                    title: "Supprimer la famille d'acte "+rowData.nameFr+" ?",
                    text:
                        "Une fois la famille d'acte, elle ne pourra plus être accessible",
                    icon: "warning",
                    buttons: true,
                    dangerMode: true,
                    }).then((willDelete) => {
                    if (willDelete) {
                        let data = {
                            type: "TYPEACTE",
                            id: rowData.id
                        }
                        dispatch(startLoader());
                        dispatch(deleteDataAction(data));
                    }
                    })
                }>
                <i className="fa fa-close color-danger"></i>
            </Link>}
        </div>
    }

    const updateData = (data) => {
        setNameEn(data.nameEn);
        setNameFr(data.nameFr);
        setCode(data.code);
        setFamille(data.familleTypeActe.id)
        setTypeActesId(data.id);
        setAddCard(true);
        setUpdateMode(true);
    }

    const dateFilterTemplate = (options) => {
        return <Calendar value={options.value} onChange={(e) => options.filterCallback(e.value, options.index)} dateFormat="dd/mm/yy" placeholder="dd/mm/yyyy" mask="99/99/9999" />;
    };

    const datePaiementBodyTemplate = (rowData) => {
        return formatDate(rowData.createdAt);
    };

    const formatDate = (value) => {
        return value.toLocaleDateString('fr-FR', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric'
        });
    };

    const canDo = (menuCode, actionCode) => {
        let theMenu = props.currentUser.menus?.find(menu => menu.code === menuCode);
        if(!theMenu){
            return false;
        }else{
            return theMenu.actionMenus?.filter(action => action.code === actionCode).length > 0;
        }
    }
    
    const hasMenu = (menuCode) => {
        return props.currentUser.menus?.filter(menu => menu.code === menuCode).length > 0;
    }


    useEffect(() => {
        initFilters();
        dispatch(startLoader());
        dispatch(getTypeActesAction()); 
        dispatch(getFamilleActesAction());    
    }, []);


    useEffect(() => {
        setUpdateMode(false);
        setNameEn("");
        setNameFr("");
        setCode("");
        setFamille("");
        setTypeActesId("");
        setAddCard(false);
        //dispatch(getTypeActesAction()); 
        //dispatch(getFamilleActesAction());   
    }, [props.typeActes]);
   
    function onTypeActesSave(e) {
        e.preventDefault();
        let error = false;
        const errorObj = { ...errorsObj };
        if(file){
            dispatch(startLoader());
            console.log(file)
            dispatch(importTypeActeFileUploadAction(file));
            return ;
        }
        if (code === '') {
            errorObj.code = 'Code est requis';
            error = true;
        }
        if (nameFr === '') {
            errorObj.name = 'le nom est requis';
            error = true;
        }
        /*if (nameEn === '') {
            errorObj.name = 'le nom est requis';
            error = true;
        }*/
        setErrors(errorObj);
        if (error) {
            swal('Oops', t('common.check-form-fields'), "infos");
			return ;
		}
        dispatch(startLoader());

        if(updateMode) {
            let obj = { nameFr: nameFr,nameEn: nameEn, code: code, familleTypeActeId: famille };
            dispatch(updateTypeActesAction(typeActesId, obj));
        }else{
            let obj = { nameFr: nameFr,nameEn: nameEn, code: code, famille: famille };
            dispatch(createTypeActeAction(obj));
        }
    }
    function selectFile(event) {
        setFile(event.target.files[0])
    }

    const createMode = () => {
        setNameEn("");
        setNameFr("");
        setCode("");
        setFamille("");
        setTypeActesId("");
        setUpdateMode(false);
        setAddCard(true)
    };

    return (
        <>
            <div className="form-head d-flex mb-3 mb-md-4 align-items-start">
                <div className="mr-auto d-none d-lg-block">
                    {hasMenu('MENPARAMACTES') && canDo('MENPARAMACTES', 'CREATEFAMILLEACTE') && <Link
                        to="#"
                        className="btn btn-primary btn-rounded"
                        onClick={() => createMode()}
                    >
                        + Nouvelle Famille d'actes
                    </Link>}
                </div>
            </div>
            <Modal className="modal fade" show={addCard} onHide={setAddCard} >
            <div className="">
                <div className="card">
                    <div className="card-header">
                        <h4 className="card-title">Créer une nouvelle famille d'actes</h4>
                    </div>
                    <div className='card-body'>
                        <div className='basic-form'>
                            <form onSubmit={onTypeActesSave}>
                                <div className='form-group row'>
                                    <label className='col-sm-3 col-form-label'>{t('common.code')}</label>
                                    <div className='col-sm-9'>
                                        <input
                                            value={code}
                                            type='text'
                                            name='code'
                                            className='form-control'
                                            placeholder='0000'
                                            disabled={props.showLoading || file}
                                            required={props.showLoading || file}
                                            onChange={(e) => setCode(e.target.value)}
                                        />
                                    </div>
                                </div>
                                
                                <div className='form-group row'>
                                    <label className='col-sm-3 col-form-label'>Grande famille</label>
                                    <div className='col-sm-9'>
                                        <Select
                                            className="select-search"
                                            value={props.familleActes?.filter((opt)=>{return opt.id === famille}).map((opti)=>{return {label:opti.nameFr,value:opti.id}})}
                                            placeholder="Choisir une grande famille"
                                            onChange={(val) => setFamille(val.value)}
                                            isDisabled={props.showLoading || file}
                                            required={props.showLoading || file}
                                            options={props.familleActes?.map((opt)=>{return {label:opt.nameFr,value:opt.id}})}
                                            style={{
                                            lineHeight: '40px',
                                            color: '#7e7e7e',
                                            paddingLeft: ' 15px',
                                            }}
                                        />
                                    </div>
                                </div>

                                <div className='form-group row'>
                                    <label className='col-sm-3 col-form-label'>{t('common.label')} Fr</label>
                                    <div className='col-sm-9'>
                                        <input
                                            value={nameFr}
                                            type='text'
                                            name='nameFr'
                                            className='form-control'
                                            placeholder='Famille Acte'
                                            disabled={props.showLoading || file}
                                            required={props.showLoading || file}
                                            onChange={(e) => setNameFr(e.target.value)}
                                        />
                                    </div>
                                </div>
                                {/*<div className='form-group row'>
                                    <label className='col-sm-3 col-form-label'>{t('common.label')} En</label>
                                    <div className='col-sm-9'>
                                        <input
                                            type='text'
                                            name='nameEn'
                                            className='form-control'
                                            placeholder='Famille Acte'
                                            onChange={(e) => setNameEn(e.target.value)}
                                        />
                                    </div>
                                </div>*/}
                                <hr />
                                <h4 className='text-center'>Ou Importer un fichier csv des familles d'actes</h4>
                                <div className='input-group mb-3'>
                                    <div className='input-group-prepend'>
                                        <span className='input-group-text'>CSV</span>
                                    </div>
                                    <div className='custom-file'>
                                        <input type='file' className='custom-file-input' onChange={selectFile} />
                                        <label className='custom-file-label'>{file ? file.name : t('common.choose-file')}</label>
                                    </div>
                                </div>
                                <div className='form-group align-items-center justify-content-center'>
                                    <div className=''>
                                        { props.showLoading && 
                                            <Button variant="primary" disabled>
                                                <Spinner
                                                    as="span"
                                                    animation="grow"
                                                    size="sm"
                                                    role="status"
                                                    aria-hidden="true"
                                                />{' '}
                                                <span className="visually-hidden">{t('common.await-text')}</span>
                                            </Button> }
                                        { !props.showLoading &&
                                            <button type='submit' className='btn btn-primary'>
                                              {t('common.save')}
                                            </button> }
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            </Modal>
            
            <DataTableBase 
                data={props.typeActes.length > 0 ? props.typeActes.map(acte => {
                        acte.createdAt = new Date(acte.createdAt);
                        acte.familleActeName = acte.familleTypeActe?.nameFr;
                        return acte;
                }) : []}
                emptyMessage="Aucune famille trouvée" 
                filters={filters}
                globalFiltersFields={['code', 'familleTypeActe.nameFr', 'nameFr',  'createdAt']}
                header={renderHeader()}
                columns={setColumns()}
                exportColumns={[
                    { title: 'Code', dataKey: 'code' },
                    { title: 'Grande Famille', dataKey: 'familleActeName' },
                    { title: t('common.name'), dataKey: 'nameFr' },
                    { title: t('common.create-date'), dataKey: 'createdAt' },
                ]}
                rows={10} 
                showExport={true}
                exportFilename={"familles-actes"}
                loading={props.showLoading}
                lazy={false}
            />

        </>
    );
};

const mapStateToProps = (state) => {
    return {
        typeActes: state.provider.typeActes,
        familleActes: state.provider.familleActes,
        errorMessage: state.clientAuth.errorMessage,
        successMessage: state.clientAuth.successMessage,
        showLoading: state.provider.showLoading,
        currentUser: currentUser(state)
    };
};

export default connect(mapStateToProps)(TypeActe);