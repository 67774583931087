import {
    getBeneficiaires,
    getAssuresBeneficiaires,
    getSouscripteurs,
    getGaranties,
    getActes,
    searchContrat,
    getBeneficiaire,
    getAssuresPrincipaux,
    getAllAssuresPrincipaux,
    getAllBeneficiaires,
    getContrats,
    getAllSouscripteurs,
    mapActeProdGarantie,
    getHistoSouscripteurs,
    getHistoBeneficiaires,
    getHistoContrats,
    getContractPerClient,
    getCollegePerContract,
    getFamillePerCollege,
    getRequestIncorporation,
    getRequestRetrait,
    getRequestSuspension,
    getRequestAnnulation,
    getRequestRenouvellement,
    getRequestCollegeSwitch 
} from '../../services/ContratService';
import { errorToastr, successToastr } from '../../Utils/ToastrMessage';


export const GET_BENEF_CONFIRMED_ACTION = '[getBeneficiaires action] confirmed getBeneficiaires';
export const GET_BENEF_FAILED_ACTION = '[getBeneficiaires action] failed getBeneficiaires';

export const GET_ASSUR_BENEF_CONFIRMED_ACTION = '[getAssuresBeneficiaires action] confirmed getAssuresBeneficiaires';
export const GET_ASSUR_BENEF_FAILED_ACTION = '[getAssuresBeneficiaires action] failed getAssuresBeneficiaires';

export const GET_SOUSCRPTS_CONFIRMED_ACTION = '[getSouscripteurs action] confirmed getSouscripteurs';
export const GET_SOUSCRIPTS_FAILED_ACTION = '[getSouscripteurs action] failed getSouscripteurs';

export const GET_GARANTIES_CONFIRMED_ACTION = '[getGaranties action] confirmed getGaranties';
export const GET_GARANTIES_FAILED_ACTION = '[getGaranties action] failed getGaranties';

export const GET_ACTES_CONFIRMED_ACTION = '[getActesProd action] confirmed getActesProd';
export const GET_ACTES_FAILED_ACTION = '[getActesProd action] failed getActesProd';

export const SEARCH_CONTRAT_CONFIRMED_ACTION = '[searchContrat action] confirmed searchContrat';
export const SEARCH_CONTRAT_FAILED_ACTION = '[searchContrat action] failed searchContrat';

export const GET_BENEF_DATA_CONFIRMED_ACTION = '[getBeneficiaire action] confirmed getBeneficiaire';
export const GET_BENEF_DATA_FAILED_ACTION = '[getBeneficiaire action] failed getBeneficiaire';

export const GET_ALL_BENEF_CONFIRMED_ACTION = '[getAllBeneficiaire action] confirmed getAllBeneficiaire';
export const GET_ALL_BENEF_FAILED_ACTION = '[getAllBeneficiaire action] failed getAllBeneficiaire';

export const GET_ALL_ASS_PRINC_CONFIRMED_ACTION = '[getAllAssPrinc action] confirmed getAllAssPrinc';
export const GET_ALL_ASS_PRINC_FAILED_ACTION = '[getAssAllPrinc action] failed getAllAssPrinc';

export const GET_CONTRATS_CONFIRMED_ACTION = '[getContrats action] confirmed getContrats';
export const GET_CONTRATS_FAILED_ACTION = '[getContrats action] failed getContrats';

export const GET_ALL_SOUSCRPTS_CONFIRMED_ACTION = '[getAllSouscripteurs action] confirmed getAllSouscripteurs';
export const GET_ALL_SOUSCRIPTS_FAILED_ACTION = '[getAllSouscripteurs action] failed getAllSouscripteurs';

export const GET_ASS_PRINC_CONFIRMED_ACTION = '[getAssurePrincipaux action] confirmed getAssurePrincipaux';
export const GET_ASS_PRINC_FAILED_ACTION = '[getAssurePrincipaux action] failed getAssurePrincipaux';

export const MAP_ACTE_PROD_GARAN_CONFIRMED_ACTION = '[mapActProdGarantie action] confirmed mapActProdGarantie';
export const MAP_ACTE_PROD_GARAN__FAILED_ACTION = '[mapActProdGarantie action] failed mapActProdGarantie';

export const GET_BENEF_HISTO_CONFIRMED_ACTION = '[getBeneficiaireHisto action] confirmed getBeneficiaireHisto';
export const GET_BENEF_HISTO_FAILED_ACTION = '[getBeneficiaireHisto action] failed getBeneficiaireHisto';

export const GET_CONTRATS_HISTO_CONFIRMED_ACTION = '[getContratHisto action] confirmed getContratHisto';
export const GET_CONTRATS_HISTO_FAILED_ACTION = '[getContratHisto action] failed getContratHisto';

export const GET_SOUSCRPTS_HISTO_CONFIRMED_ACTION = '[getSouscripteurHisto action] confirmed getSouscripteurHisto';
export const GET_SOUSCRIPTS_HISTO_FAILED_ACTION = '[getSouscripteurHisto action] failed getSouscripteurHisto';

export const GET_REQUEST_AVENANT_CONFIRMED_ACTION = '[getRequestAvenant action] confirmed getRequestAvenant';
export const GET_REQUEST_AVENANT_FAILED_ACTION = '[getRequestAvenant action] failed getRequestAvenant';

export const GET_CONTRACT_PER_CLIENT_CONFIRMED_ACTION = '[getContractPerClient action] confirmed getContractPerClient';

export const GET_COLLEGE_PER_CONTRACT_CONFIRMED_ACTION = '[getCollegePerContract action] confirmed getCollegePerContract';

export const GET_FAMILLE_PER_COLLEGE_CONFIRMED_ACTION = '[getFamillePerCollege action] confirmed getFamillePerCollege';

export const LOADING_TOGGLE_ACTION = '[Loading action] toggle loading';

var I18n = require('react-redux-i18n').I18n;

export function getBeneficiaireAction(id, history) {
    return (dispatch) => {
        getBeneficiaire(id)
        .then((response) => {
            let data = response.data;
            dispatch(confirmedGetBenefDataAction(data));
        })
        .catch((error) => {
            errorToastr(I18n.t('contrat.getBenef.error')+error.response?.data?.message);
            dispatch(getBenefDataFailedAction(error.response?.data?.message));
            history.push('/client-search/');
        });
    };
}

export function getBeneficiairesAction(numPolice) {
    return (dispatch) => {
        getBeneficiaires(numPolice)
        .then((response) => {
            let data = response.data;

            let res = data.map(dat => {
                let ans = {};
                if(dat.numBeneficiairePrincipal === dat.numBeneficiaire) {
                    ans.assurePrinc = dat;
                    ans.beneficiaires = data.filter(elt => {return elt.numBeneficiairePrincipal && elt.numBeneficiairePrincipal === dat.numBeneficiaire});
                    ans.beneficiaires = [...ans.beneficiaires, dat];
                }
                return ans;
            }).filter(elt => {return Object.keys(elt).length > 0});

            console.log(res);
            dispatch(confirmedGetBenefAction(res));
        })
        .catch((error) => {
            errorToastr(I18n.t('contrat.getBenef.error')+error.response?.data?.message);
            dispatch(getBenefFailedAction(error.response?.data?.message));
        });
    };
}

export function getAssuresBeneficiairesAction(numContrat) {
    return (dispatch) => {
        getAssuresBeneficiaires(numContrat)
        .then((response) => {
            let data = response.data;
            dispatch(confirmedGetAssuresBenefAction(data));
        })
        .catch((error) => {
            errorToastr(I18n.t('contrat.getBenef.by_assu_error')+error.response?.data?.message);
            dispatch(getAssuresBenefFailedAction(error.response?.data?.message));
        });
    };
}

export function getSouscripteursAction() {
    return (dispatch) => {
        getSouscripteurs()
        .then((response) => {
            let data = response.data;
            dispatch(confirmedGetSouscripteursAction(data));
        })
        .catch((error) => {
            errorToastr(I18n.t('contrat.getSouscript.error')+error.response?.data?.message);
            dispatch(getSouscripteursFailedAction(error.response?.data?.message));
        });
    };
}

export function getGarantiesAction() {
    return (dispatch) => {
        getGaranties()
        .then((response) => {
            let data = response.data;
            dispatch(confirmedGetGarantiesAction(data));
        })
        .catch((error) => {
            errorToastr(I18n.t('contrat.getGaranties.error')+error.response?.data?.message);
            dispatch(getGarantiesFailedAction(error.response?.data?.message));
        });
    };
}

export function getActesProdAction() {
    return (dispatch) => {
        getActes()
        .then((response) => {
            let data = response.data;
            dispatch(confirmedGetActesAction(data));
        })
        .catch((error) => {
            errorToastr(I18n.t('contrat.getActes.error')+error.response?.data?.message);
            dispatch(getActesFailedAction(error.response?.data?.message));
        });
    };
}

export function searchContratAction(data) {
    return (dispatch) => {
        searchContrat(data)
        .then((response) => {
            let data = response.data;
            dispatch(confirmedSearchContratAction(data));
        })
        .catch((error) => {
            errorToastr(I18n.t('contrat.contratSearch.error')+error.response?.data?.message);
            dispatch(searchContratFailedAction(error.response?.data?.message));
        });
    };
}

export function getContratsAction(page, max, keyword) {
    return (dispatch) => {
        getContrats(page, max, keyword)
        .then((response) => {
            let data = response.data;
            dispatch(confirmedGetContratsAction(data));
        })
        .catch((error) => {
            errorToastr(I18n.t('contrat.contratSearch.error_get_all')+error.response?.data?.message);
            dispatch(getContratsFailedAction(error.response?.data?.message));
        });
    };
}

export function getAssurePrincipauxAction(data) {
    return (dispatch) => {
        getAssuresPrincipaux(data)
        .then((response) => {
            let data = response.data;
            dispatch(confirmedAssurePrincipauxAction(data));
        })
        .catch((error) => {
            errorToastr(I18n.t('contrat.contratSearch.error_get_ass_princ')+error.response?.data?.message);
            dispatch(assurePrincipauxFailedAction(error.response?.data?.message));
        });
    };
}

export function getAllBeneficiairesAction(page, max, keyword) {
    return (dispatch) => {
        getAllBeneficiaires(page, max, keyword)
        .then((response) => {
            let data = response.data;
            dispatch(confirmedGetBeneficiairesAction(data));
        })
        .catch((error) => {
            errorToastr(I18n.t('contrat.contratSearch.error_get_all_benef')+error.response?.data?.message);
            dispatch(getBeneficiairesFailedAction(error.response?.data?.message));
        });
    };
}

export function getAllAssuresPrincipauxAction(page, max, keyword) {
    return (dispatch) => {
        getAllAssuresPrincipaux(page, max, keyword)
        .then((response) => {
            let data = response.data;
            dispatch(confirmedGetAllAssuresPrincipauxAction(data));
        })
        .catch((error) => {
            errorToastr(I18n.t('contrat.contratSearch.error_get_ass_princ')+error.response?.data?.message);
            //dispatch(getAllAssuresPrincipauxFailedAction(error.response?.data?.message));
        });
    };
}

export function getAllSouscripteursAction(page, max, keyword) {
    return (dispatch) => {
        getAllSouscripteurs(page, max, keyword)
        .then((response) => {
            let data = response.data;
            dispatch(confirmedGetAllSouscripteursAction(data));
        })
        .catch((error) => {
            errorToastr(I18n.t('contrat.getSouscript.error')+error.response?.data?.message);
            dispatch(getAllSouscripteursFailedAction(error.response?.data?.message));
        });
    };
}

export function mapActeProdGarantieAction(data) {
    return (dispatch) => {
        mapActeProdGarantie(data)
        .then((response) => {
            successToastr(I18n.t('contrat.mappingActs.success'));
            dispatch(confirmedMapActeProdGarantieAction(response.data));
        })
        .catch((error) => {
            errorToastr(I18n.t('contrat.mappingActs.error')+error.response?.data?.message);
            dispatch(mapActeProdGarantieFailedAction(error.response?.data?.message));
        });
    };
}

export function getSouscripteurHistoAction(page, max, keyword) {
    return (dispatch) => {
        getHistoSouscripteurs(page, max, keyword)
        .then((response) => {
            let data = response.data;
            dispatch(confirmedGetSouscripteurHistoAction(data));
        })
        .catch((error) => {
            errorToastr(I18n.t('contrat.getHistory.error_souscript')+error.response?.data?.message);
            dispatch(getSouscripteurHistoFailedAction(error.response?.data?.message));
        });
    };
}

export function getContratHistoAction(page, max, keyword) {
    return (dispatch) => {
        getHistoContrats(page, max, keyword)
        .then((response) => {
            let data = response.data;
            dispatch(confirmedGetContratHistoAction(data));
        })
        .catch((error) => {
            errorToastr(I18n.t('contrat.getHistory.error_contract')+error.response?.data?.message);
            dispatch(getContratHistoFailedAction(error.response?.data?.message));
        });
    };
}

export function getBeneficiaireHistoAction(page, max, keyword) {
    return (dispatch) => {
        getHistoBeneficiaires(page, max, keyword)
        .then((response) => {
            let data = response.data;
            dispatch(confirmedGetBenefHistoAction(data));
        })
        .catch((error) => {
            errorToastr(I18n.t('contrat.getHistory.error_benef')+error.response?.data?.message);
            dispatch(getBenefHistoFailedAction(error.response?.data?.message));
        });
    };
}

export function getCollegePerContractAction(numeroContrat) {
    return (dispatch) => {
        getCollegePerContract(numeroContrat)
        .then((response) => {
            let data = response.data;
            dispatch(confirmedGetCollegePerContractAction(data));
        })
        .catch((error) => {
            errorToastr("Erreur lors de la récupération des collèges pour ce contract: "+error.response?.data?.message);
        });
    };
}

export function getFamillePerCollegeAction(numeroCollege, numeroContrat) {
    return (dispatch) => {
        getFamillePerCollege(numeroCollege, numeroContrat)
        .then((response) => {
            let data = response.data;
            dispatch(confirmedGetFamillePerCollegeAction(data));
        })
        .catch((error) => {
            errorToastr("Erreur lors de la récupération des familles pour ce collège: "+error.response?.data?.message);
        });
    };
}

export function getContractPerClientAction(numeroClient) {
    return (dispatch) => {
        getContractPerClient(numeroClient)
        .then((response) => {
            let data = response.data;
            dispatch(confirmedGetContractPerClientAction(data));
        })
        .catch((error) => {
            errorToastr("Erreur lors de la récupération des contrats pour ce clienb: "+error.response?.data?.message);
        });
    };
}

export function getRequestAvenantAction(type) {
    return (dispatch) => {
        if(type === 'incorporation') {
            getRequestIncorporation()
            .then((response) => {
                let data = response.data;
                dispatch(confirmedGetRequestAvenantAction(data, 'incorporation'));
            })
            .catch((error) => {
                errorToastr("Erreur lors de la demande d'incorporation: "+error.response?.data?.message);
            });
        }else if(type === 'retrait') {
            getRequestRetrait()
            .then((response) => {
                let data = response.data;
                dispatch(confirmedGetRequestAvenantAction(data, 'retrait'));
            })
            .catch((error) => {
                errorToastr("Erreur lors de la demande de retrait: "+error.response?.data?.message);
            });
        }else if(type === 'suspension') {
            getRequestSuspension()
            .then((response) => {
                let data = response.data;
                dispatch(confirmedGetRequestAvenantAction(data, 'suspension'));
            })
            .catch((error) => {
                errorToastr("Erreur lors de la demande de suspension: "+error.response?.data?.message);
            });
        }else if(type === 'annulation') {
            getRequestAnnulation()
            .then((response) => {
                let data = response.data;
                dispatch(confirmedGetRequestAvenantAction(data, 'annulation'));
            })
            .catch((error) => {
                errorToastr("Erreur lors de la demande d'annulation: "+error.response?.data?.message);
            });
        }else if(type === 'renouvellement') {
            getRequestRenouvellement()
            .then((response) => {
                let data = response.data;
                dispatch(confirmedGetRequestAvenantAction(data, 'renouvellement'));
            })
            .catch((error) => {
                errorToastr("Erreur lors de la récupération des contrats pour ce clienb: "+error.response?.data?.message);
            });
        }else {
            getRequestCollegeSwitch()
            .then((response) => {
                let data = response.data;
                dispatch(confirmedGetRequestAvenantAction(data, 'changementCollege'));
            })
            .catch((error) => {
                errorToastr("Erreur lors de la récupération des contrats pour ce clienb: "+error.response?.data?.message);
            });
        }
    };
}

export function confirmedGetRequestAvenantAction(data, typeData) {
    return {
        type: GET_REQUEST_AVENANT_CONFIRMED_ACTION,
        payload: {data, typeData},
    };
}

export function getRequestAvenantFailedAction(data) {
    return {
        type: GET_REQUEST_AVENANT_FAILED_ACTION,
        payload: data,
    };
}

export function confirmedGetCollegePerContractAction(data) {
    return {
        type: GET_COLLEGE_PER_CONTRACT_CONFIRMED_ACTION,
        payload: data,
    };
}

export function confirmedGetFamillePerCollegeAction(data) {
    return {
        type: GET_FAMILLE_PER_COLLEGE_CONFIRMED_ACTION,
        payload: data,
    };
}

export function confirmedGetContractPerClientAction(data) {
    return {
        type: GET_CONTRACT_PER_CLIENT_CONFIRMED_ACTION,
        payload: data,
    };
}

export function confirmedGetBenefHistoAction(data) {
    return {
        type: GET_BENEF_HISTO_CONFIRMED_ACTION,
        payload: data,
    };
}

export function getBenefHistoFailedAction(data) {
    return {
        type: GET_BENEF_HISTO_FAILED_ACTION,
        payload: data,
    };
}

export function confirmedGetContratHistoAction(data) {
    return {
        type: GET_CONTRATS_HISTO_CONFIRMED_ACTION,
        payload: data,
    };
}

export function getContratHistoFailedAction(data) {
    return {
        type: GET_CONTRATS_HISTO_FAILED_ACTION,
        payload: data,
    };
}

export function confirmedGetSouscripteurHistoAction(data) {
    return {
        type: GET_SOUSCRPTS_HISTO_CONFIRMED_ACTION,
        payload: data,
    };
}

export function getSouscripteurHistoFailedAction(data) {
    return {
        type: GET_SOUSCRIPTS_HISTO_FAILED_ACTION,
        payload: data,
    };
}

export function confirmedGetAllSouscripteursAction(data) {
    return {
        type: GET_ALL_SOUSCRPTS_CONFIRMED_ACTION,
        payload: data,
    };
}

export function getAllSouscripteursFailedAction(data) {
    return {
        type: GET_ALL_SOUSCRIPTS_FAILED_ACTION,
        payload: data,
    };
}

export function confirmedGetAssuresPrincipauxAction(data) {
    return {
        type: GET_ASS_PRINC_CONFIRMED_ACTION,
        payload: data,
    };
}

export function getAssuresPrincipauxFailedAction(data) {
    return {
        type: GET_ASSUR_BENEF_FAILED_ACTION,
        payload: data,
    };
}

export function confirmedAssurePrincipauxAction(data) {
    return {
        type: GET_ASS_PRINC_CONFIRMED_ACTION,
        payload: data,
    };
}

export function assurePrincipauxFailedAction(data) {
    return {
        type: GET_ASS_PRINC_FAILED_ACTION,
        payload: data,
    };
}

export function confirmedGetAllAssuresPrincipauxAction(data) {
    return {
        type: GET_ALL_ASS_PRINC_CONFIRMED_ACTION,
        payload: data,
    };
}

export function getAllAssurePrincipauxFailedAction(data) {
    return {
        type: GET_ALL_ASS_PRINC_FAILED_ACTION,
        payload: data,
    };
}

export function confirmedGetBeneficiairesAction(data) {
    return {
        type: GET_ALL_BENEF_CONFIRMED_ACTION,
        payload: data,
    };
}

export function confirmedMapActeProdGarantieAction(data) {
    return {
        type: MAP_ACTE_PROD_GARAN_CONFIRMED_ACTION,
        payload: data,
    };
}

export function getBeneficiairesFailedAction(data) {
    return {
        type: GET_ALL_BENEF_FAILED_ACTION,
        payload: data,
    };
}

export function confirmedGetContratsAction(data) {
    return {
        type: GET_CONTRATS_CONFIRMED_ACTION,
        payload: data,
    };
}

export function getContratsFailedAction(data) {
    return {
        type: GET_CONTRATS_FAILED_ACTION,
        payload: data,
    };
}

export function mapActeProdGarantieFailedAction(data) {
    return {
        type: MAP_ACTE_PROD_GARAN__FAILED_ACTION,
        payload: data,
    };
}

export function confirmedGetBenefDataAction(data) {
    return {
        type: GET_BENEF_DATA_CONFIRMED_ACTION,
        payload: data,
    };
}

export function getBenefDataFailedAction(data) {
    return {
        type: GET_BENEF_DATA_FAILED_ACTION,
        payload: data,
    };
}

export function confirmedSearchContratAction(data) {
    return {
        type: SEARCH_CONTRAT_CONFIRMED_ACTION,
        payload: data,
    };
}

export function searchContratFailedAction(data) {
    return {
        type: SEARCH_CONTRAT_FAILED_ACTION,
        payload: data,
    };
}

export function confirmedGetActesAction(data) {
    return {
        type: GET_ACTES_CONFIRMED_ACTION,
        payload: data,
    };
}

export function getActesFailedAction(data) {
    return {
        type: GET_ACTES_FAILED_ACTION,
        payload: data,
    };
}

export function confirmedGetGarantiesAction(data) {
    return {
        type: GET_GARANTIES_CONFIRMED_ACTION,
        payload: data,
    };
}

export function getGarantiesFailedAction(data) {
    return {
        type: GET_GARANTIES_FAILED_ACTION,
        payload: data,
    };
}

export function confirmedGetBenefAction(data) {
    return {
        type: GET_BENEF_CONFIRMED_ACTION,
        payload: data,
    };
}

export function getBenefFailedAction(data) {
    return {
        type: GET_BENEF_FAILED_ACTION,
        payload: data,
    };
}

export function confirmedGetAssuresBenefAction(data) {
    return {
        type: GET_ASSUR_BENEF_CONFIRMED_ACTION,
        payload: data,
    };
}

export function getAssuresBenefFailedAction(data) {
    return {
        type: GET_ASSUR_BENEF_FAILED_ACTION,
        payload: data,
    };
}

export function confirmedGetSouscripteursAction(data) {
    return {
        type: GET_SOUSCRPTS_CONFIRMED_ACTION,
        payload: data,
    };
}

export function getSouscripteursFailedAction(data) {
    return {
        type: GET_SOUSCRIPTS_FAILED_ACTION,
        payload: data,
    };
}


export function loadingToggleAction(status) {
    return {
        type: LOADING_TOGGLE_ACTION,
        payload: status,
    };
}
