import React, { useState, useEffect, useRef } from 'react';
import {Spinner, Button, ListGroup} from 'react-bootstrap';
import { useParams, withRouter } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import { Steps } from 'primereact/steps';
import { useTranslation } from "react-i18next";
import { TreeTableBase } from "../DataTables/TreeTableBase";
import { Dialog } from 'primereact/dialog';
import { getBeneficiairesAction } from '../../../store/actions/ContratActions';
import { searchDiseasesAction, searchDrugsAction, getActesByTypeAction, 
getActesHospitalisationAction, getConsultationByCodeDataAction, delieverExamensAction,
updateConsultationAction, addActeDetailAction, markActeAsDoneAction, delieverDrugsAction,
endHospiAction, startLoader as startConsultationLoader } from '../../../store/actions/ConsultationActions';
import { searchActesConsultAction } from '../../../store/actions/ProviderActions';
import { getPrestationAction, startLoader } from '../../../store/actions/FacturationAction';
import { currentUser } from "../../../store/selectors/CurrentUserSelector";
import Select from 'react-select';
import { DatePicker, DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import PerfectScrollbar from "react-perfect-scrollbar";
import { TabView, TabPanel } from 'primereact/tabview';
import { InputNumber } from 'primereact/inputnumber';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { InputText } from 'primereact/inputtext';
import { Button as PButton } from 'primereact/button';
import { Tag } from 'primereact/tag';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { TriStateCheckbox } from 'primereact/tristatecheckbox';
import { ProgressSpinner } from 'primereact/progressspinner';
import { DataTableBase } from "../DataTables/DataTableBase";
import swal from "sweetalert";
import { STATUSES } from '../../globals';
        


const PrestationForm = (props) => {
    const { t } = useTranslation();
    const { id } = useParams();
    const dispatch = useDispatch();
    const [minDate, setMinDate] = useState(new Date());
    const [maxDate, setMaxDate] = useState(new Date());
    const [visible, setVisible] = useState(false);
    const [numBon, setNumBon] = useState("");
    const [numPolice, setNumPolice] = useState("");
    const [assureId, setAssureId] = useState(null);
    const [assureName, setAssureName] = useState("");
    const [consultation, setConsultation] = useState({});
    const [pharmacie, setPharmacie] = useState([]);
    const [laboratoire, setLaboratoire] = useState([]);
    const [soins, setSoins] = useState([]);
    const [hospitalisation, setHospitalisation] = useState({});
    const [optique, setOptique] = useState({});
    const [activeIndex, setActiveIndex] = useState(0);
    const motives = [{label: t('common.motherhood'), value: 'maternity'}, {label: t('common.accident'), value: "accident"}, {label: t('common.natural-illness'), value: "sickness"}];

    
    const [acteId, setActeId] = useState("");
    const [acteName, setActeName] = useState("");
    const [ecartPupillaire, setEcartPupillaire] = useState(0);
    const [vlod, setVlod] = useState("");
    const [vlog, setVlog] = useState("");
    const [vpod, setVpod] = useState("");
    const [vpog, setVpog] = useState("");
    const [details, setDetails] = useState([]);

    
    const [chambre, setChambre] = useState("");
    const [duree, setDuree] = useState(3);
    const [price, setPrice] = useState(0);
    const [inHospi, setInHospi] = useState(false);
    
    const [filters, setFilters] = useState(null);
    const [globalFilterValue, setGlobalFilterValue] = useState('');

    const [examFilters, setExamFilters] = useState(null);
    const [globalExamFilterValue, setGlobalExamFilterValue] = useState('');

    const [acteSpecFilters, setActeSpecFilters] = useState(null);
    const [globalActeSpecFilterValue, setGlobalActeSpecFilterValue] = useState('');

    //const prestation = JSON.parse(localStorage.getItem('prestations'))?.reverse()[0];

    const footerContent = (
        <div>
            <PButton label="Fermer" icon="pi pi-times" onClick={() => setVisible(false)} className="p-button-text" />
        </div>
    );

    const getTableColumns = () => {
        return [
            {field: 'intitule', header: t('common.entitle'), expander: true, headerClassName: 'text-xs', style: { width: '450px' }},
            /*{field: 'montant', header: t('common.abr-tier-payant'), expander: false, headerClassName: 'text-xs'},
            {field: 'quantite', header: t('quantity.top'), expander: false, headerClassName: 'text-xs'},
            {field: 'montantTotal', header: t('common.total'), expander: false, headerClassName: 'text-xs'},
            {field: 'montantPatient', header: 'Part Assuré', expander: false, headerClassName: 'text-xs'},
            {field: 'montantAssureur', header: 'Part Assureur', expander: false, headerClassName: 'text-xs'},*/
            {field: 'date', header: 'Date', expander: false, headerClassName: 'text-xs'},
            {field: 'doctorName', header: t('common.doctor'), expander: false, headerClassName: 'text-xs'},
            {field: 'patientName', label: t('common.assure'), expander: false, headerClassName: 'text-xs'},
        ];
    }

    

    const rowClassName = (node) => {
        return { 'p-highlight': (node.key.split('').includes('é')),
                 'text-sm': true, 
                 'text-xs': (node.key.split(' ').includes('BonDetail')),
                 'font-bold': (!node.key.split(' ').includes('BonDetail')),
                 'p-0': true,
                 'w-4': true,
                };
    }

    const setDatas = () => {
        let consultationBons = props.prestation.consultationBons;
        let consultationArr = consultationBons?.map(bon => {
            return {
                key: "Bon "+bon.id,
                data: {
                    intitule: "Bon N°"+bon.code,
                    montant: bon.amount,
                    quantite: '-',
                    montantTotal: bon.amount,
                    montantPatient: bon.amountUserFee,
                    montantAssureur: bon.amount,
                    date: bon.createdAt ? formatDate(new Date(bon.createdAt)) : "",
                    doctorName: bon.doctorName,
                    patientName: bon.beneficiaryName
                },
                children: [{
                    key: "BonDetail "+bon.acteId,
                    data: {
                        intitule: bon.acteName,
                        montant: bon.amount,
                        quantite: '-',
                        montantTotal: bon.amount,
                        montantPatient: bon.montantTM,
                        montantAssureur: bon.amount,
                        date: bon.date ? formatDate(new Date(bon.date)) : "",
                        doctorName: bon.doctorName,
                        patientName: bon.beneficiaryName
                    },
                    children: []
                }]
            }
        });
        let examBons = props.prestation.examensBons;
        let examArr = examBons?.map(bon => {
            return {
                key: "Bon "+bon.id,
                data: {
                    intitule: "Bon N°"+bon.code,
                    montant: bon.amount,
                    quantite: '-',
                    montantTotal: bon.amount,
                    montantPatient: bon.amountUserFee,
                    montantAssureur: bon.amount,
                    date: bon.createdAt ? formatDate(new Date(bon.createdAt)) : "",
                    doctorName: bon.doctorName,
                    patientName: bon.beneficiaryName
                },
                children: bon.examenBonDetail?.map(bondetail => {
                    return {
                        key: "BonDetail "+bondetail.id,
                        data: {
                            intitule: bondetail.acteName,
                            montant: bondetail.amount,
                            quantite: '-',
                            montantTotal: bondetail.amount,
                            montantPatient: bondetail.montantTM,
                            montantAssureur: bondetail.amount,
                            date: bondetail.executeDate ? formatDate(new Date(bondetail.executeDate)) : "",
                            doctorName: bon.doctorName,
                            patientName: bon.beneficiaryName
                        },
                        children: []
                    }
                })
            }
        });
        let acteSpecBons = props.prestation.acteSpecialiseBons;
        let acteSpecArr = acteSpecBons?.map(bon => {
            return {
                key: "Bon "+bon.id,
                data: {
                    intitule: "Bon N°"+bon.code,
                    montant: bon.amount,
                    quantite: '-',
                    montantTotal: bon.amount,
                    montantPatient: bon.amountUserFee,
                    montantAssureur: bon.amount,
                    date: bon.createdAt ? formatDate(new Date(bon.createdAt)) : "",
                    doctorName: bon.doctorName,
                    patientName: bon.beneficiaryName
                },
                children: bon.examenBonDetail?.map(bondetail => {
                    return {
                        key: "BonDetail "+bondetail.id,
                        data: {
                            intitule: bondetail.acteName,
                            montant: bondetail.amount,
                            quantite: '-',
                            montantTotal: bondetail.amount,
                            montantPatient: bondetail.montantTM,
                            montantAssureur: bondetail.amount,
                            date: bondetail.executeDate ? formatDate(new Date(bondetail.executeDate)) : "",
                            doctorName: bon.doctorName,
                            patientName: bon.beneficiaryName
                        },
                        children: []
                    }
                })
            }
        });
        let pharmacieBons = props.prestation.pharmacieBons;
        let pharmacieArr = pharmacieBons?.map(bon => {
            return {
                key: "Bon "+bon.id,
                data: {
                    intitule: "Bon N°"+bon.code,
                    montant: bon.amount,
                    quantite: '-',
                    montantTotal: bon.amount,
                    montantPatient: bon.amountUserFee,
                    montantAssureur: bon.amount,
                    date: bon.createdAt ? formatDate(new Date(bon.createdAt)) : "",
                    doctorName: bon.doctorName,
                    patientName: bon.beneficiaryName
                },
                children: bon.pharmacieBonDetails?.map(bondetail => {
                    return {
                        key: "BonDetail "+bondetail.id,
                        data: {
                            intitule: bondetail.medicament?.name,
                            montant: bondetail.montant,
                            quantite: '-',
                            montantTotal: bondetail.montant,
                            montantPatient: bondetail.tm,
                            montantAssureur: bondetail.montant,
                            date: bondetail.deliveryDate ? formatDate(new Date(bondetail.deliveryDate)) : "",
                            doctorName: bon.doctorName,
                            patientName: bon.patientName
                        },
                        children: []
                    }
                })
            }
        });

        let res = [];
        if(consultationArr && consultationArr.length > 0){
            res = [...res, {
                key: "consultations",
                data: {
                    intitule: "Consultation",
                    montant: '',
                    quantite: '',
                    montantTotal: '',
                    montantPatient: '',
                    montantAssureur: '',
                    date: '',
                    doctorName: '',
                    patientName: ''
                },
                children: consultationArr
            }];
        }
        if(examArr && examArr.length > 0){
            res = [...res, {
                key: "Examens",
                data: {
                    intitule: t('common.exam'),
                    montant: '',
                    quantite: '',
                    montantTotal: '',
                    montantPatient: '',
                    montantAssureur: '',
                    date: '',
                    doctorName: '',
                    patientName: ''
                },
                children: examArr
            }];
        }
        if(acteSpecArr && acteSpecArr.length > 0){
            res = [...res, {
                key: "Soins",
                data: {
                    intitule: t('common.care'),
                    montant: '',
                    quantite: '',
                    montantTotal: '',
                    montantPatient: '',
                    montantAssureur: '',
                    date: '',
                    doctorName: '',
                    patientName: ''
                },
                children: acteSpecArr
            }];
        }
        if(pharmacieArr && pharmacieArr.length > 0){
            res = [...res, {
                key: "Pharmacie",
                data: {
                    intitule: t('layout.pharmacy'),
                    montant: '',
                    quantite: '',
                    montantTotal: '',
                    montantPatient: '',
                    montantAssureur: '',
                    date: '',
                    doctorName: '',
                    patientName: ''
                },
                children: pharmacieArr
            }];
        }

        return res;
    };

    const initFilters = () => {
        setFilters({
            global: { value: null, matchMode: FilterMatchMode.CONTAINS },
            name: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            quantite: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
            unit: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            deliveryName: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            deliveryDate: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
            isDelivery: { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
        });
        setGlobalFilterValue("");
    };

    const renderHeader = () => {
        return (
            <div className="flex justify-content-between">
                <PButton type="button" icon="pi pi-filter-slash"  label={t('common.reinit')} outlined onClick={clearFilter} />
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText value={globalFilterValue} onChange={onGlobalFilterChange}  placeholder={t('common.search')} />
                </span>
            </div>
        );
    };

    const clearFilter = () => {
        initFilters();
    };

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };

        _filters['global'].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
    };
    

    const setColumns = () => {
        return [
            {selectionMode: "multiple", style: { width: '3rem' }},
            {name: 'name', headerName: t('common.drug'), filterPlaceholder: t('common.drug-filter'), style: { minWidth: '12rem' }, filter: true, bodyTemplate: medicamentNameBodyTemplate},
            {name: 'prix', dataType: 'numeric', headerName: t('common.unit-price'), filterPlaceholder: 'Filtrer par prix unitaire', style: { minWidth: '6rem' }, filter: true, bodyTemplate: getAmountTemplate},
            {name: 'qte', dataType: 'numeric', headerName: t('common.abr-quantity'), filterPlaceholder: 'Filtrer par quantité', style: { minWidth: '2rem' }, filter: true, bodyTemplate: null},
            {name: 'prixTotal', dataType: 'numeric', headerName: t('common.abr-tier-payant'), filterPlaceholder: 'Filtrer par prix total', style: { minWidth: '6rem' }, filter: true, bodyTemplate: null},
            {name: 'unit', headerName: t('common.unit'), filterPlaceholder: 'Filtrer par unité', style: { minWidth: '5rem' }, filter: true, bodyTemplate: null},
            {name: 'deliveryDate', filterField: 'date', dataType: 'date',  filterPlaceholder: t('common.executed-on'), headerName: t('common.dateFilterText'), style: { minWidth: '12rem' }, filter: true, bodyTemplate: dateBodyTemplate, filterElement: dateFilterTemplate},
            {name: 'deliveryName', headerName:  t('common.executant'),  filterPlaceholder: t('common.executantFilterText'), style: { minWidth: '8rem' }, filter: true, bodyTemplate: null},
            {name: 'isDelivery', headerName: t('common.status'), dataType: 'boolean',  filterPlaceholder: t('common.status-filter-text'), style: { minWidth: '4rem' }, filter: true, bodyTemplate: statusDelBodyTemplate, filterElement: statusDelFilterTemplate},
            //{name: 'actions', headerName: 'Actions', filterPlaceholder: '', style: { minWidth: '4rem'}, filter: false, bodyTemplate: null},
        ]
    };


    const getAmountTemplate = (rowData) => {
        return <input type='number' 
            className='form-control'
            min="0" 
            disabled={rowData.isDelivery}
            onChange={e => {
                setPrixTotal(rowData.id, e);
            }} 
            value={consultation.prixPharmacie?.find(elt => elt.id === rowData.id)?.prix}
        />
    }

    const setPrixTotal = (detailId, e) => {
        setConsultation({
            ...consultation,
            prixPharmacie: consultation.prixPharmacie.map(elt => {
                if(elt.id === detailId) {
                    elt.prix = e.target.value;
                    elt.prixTotal = (e.target.value * elt.qte).toFixed(2);
                }
                return elt;
            })
        });
    }

    const statusDelFilterTemplate = (options) => {
        return (
            <div className="flex align-items-center gap-2">
                <label htmlFor="isClose-filter" className="font-bold">
                {t('common.delivered')}
                </label>
                <TriStateCheckbox inputId="isClose-filter" value={options.value} onChange={(e) => options.filterCallback(e.value)} />
            </div>
        );
    };

    const dateFilterTemplate = (options) => {
        return <Calendar value={options.value} onChange={(e) => options.filterCallback(e.value, options.index)} dateFormat="dd/mm/yy" placeholder="dd/mm/yyyy" mask="99/99/9999" />;
    };

    const dateBodyTemplate = (rowData) => {
        return <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DatePicker
                autoOk
                label=""
                clearable
                disabled={rowData.isDelivery}
                minDate={minDate}
                maxDate={maxDate}
                value={rowData.deliveryDate}
                format="dd/MM/yyyy"
                disableFuture
                required
                onChange={val => {
                    setConsultation({
                        ...consultation,
                        prixPharmacie: consultation.prixPharmacie.map(drug => {
                            if(drug.id === rowData.id) {
                                drug.deliveryDate = val;
                            }
                            return drug;
                        })
                    })
                }}
            />
        </MuiPickersUtilsProvider>
    };

    const medicamentNameBodyTemplate = (rowData) => {
        return <>
            <span>{rowData.name}</span><br/>
        </>
    };

    const statusDelBodyTemplate = (rowData) => {
        return <Tag value={rowData.isDelivery ? t('common.delivered') : t('common.not-delivered')} severity={getSeverity(rowData.isDelivery)} />;
    };

    const getSeverity = (status) => {
        return status ? 'success' : 'warning'
    };

    const formatDate = (value) => {
        return value ? value.toLocaleDateString('fr-FR', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric'
        }) : '';
    };

    const isDrugSelectable = (rowData) => {
        return !rowData.isDelivery;
    }

    const delieverDrugs = () => {
        let data = {};
        data.medicaments = pharmacie.map(drug => {
            return {
                pharmacieBonDetailId: drug.id,
                MedicamentId: drug.medicament.id,
                MedicamentCode: drug.medicament.code, 
                MedicamentName: drug.medicament.name, 
                Quantite: drug.qte,
                deliveryDate: new Date(), 
                Amount: consultation.prixPharmacie.filter(drg => drg.id === drug.id)[0]?.prix,
                facturePrestataireTMPId: id 
            }
        });
        data.deliveryName = props.prestation.providerName;
        data.deliveryBy = props.prestation.providerId;
        data.deliveryDate = new Date();
        console.log(data);
        dispatch(startConsultationLoader());
        dispatch(delieverDrugsAction(data, numBon));
    }

    const initExamFilters = () => {
        setFilters({
            global: { value: null, matchMode: FilterMatchMode.CONTAINS },
            acteName: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            executedBy: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            status: { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
        });
        setGlobalExamFilterValue("");
    };

    const initActeSpecFilters = () => {
        setFilters({
            global: { value: null, matchMode: FilterMatchMode.CONTAINS },
            acteName: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            executedBy: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            status: { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
        });
        setGlobalActeSpecFilterValue("");
    };

    const renderExamHeader = () => {
        return (
            <div className="flex justify-content-between">
                <PButton type="button" icon="pi pi-filter-slash"  label={t('common.reinit')} outlined onClick={clearExamFilter} />
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText value={globalExamFilterValue} onChange={onGlobalExamFilterChange}  placeholder={t('common.search')} />
                </span>
            </div>
        );
    };

    const renderActeSpecHeader = () => {
        return (
            <div className="flex justify-content-between">
                <PButton type="button" icon="pi pi-filter-slash"  label={t('common.reinit')} outlined onClick={clearActeSpecFilter} />
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText value={globalExamFilterValue} onChange={onGlobalActeSpecFilterChange}  placeholder={t('common.search')} />
                </span>
            </div>
        );
    };

    const clearExamFilter = () => {
        initExamFilters();
    };

    const clearActeSpecFilter = () => {
        initActeSpecFilters();
    };

    const onGlobalExamFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...examFilters };

        _filters['global'].value = value;

        setExamFilters(_filters);
        setGlobalExamFilterValue(value);
    };

    const onGlobalActeSpecFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...acteSpecFilters };

        _filters['global'].value = value;

        setActeSpecFilters(_filters);
        setGlobalActeSpecFilterValue(value);
    };

    const setExamColumns = () => {
        return [
            {selectionMode: "multiple", style: { width: '3rem' }},
            {name: 'acteName', headerName: t('common.exam'), filterPlaceholder:  t('common.examFilter'), style: { minWidth: '12rem' }, filter: true, bodyTemplate: acteNameBodyTemplate},
            {name: 'status', headerName: t('common.status'),  filterPlaceholder: t('common.status-filter-text'), style: { minWidth: '5rem' }, filter: true, bodyTemplate: statusBodyTemplate, filterElement: statusFilterTemplate},
            {name: 'executeDate', filterField: 'date', dataType: 'date',  filterPlaceholder: t('common.executed-on'), headerName: t('common.dateFilterText'), style: { minWidth: '12rem' }, filter: true, bodyTemplate: dateExamBodyTemplate, filterElement: dateFilterTemplate},
            {name: 'executedBy', headerName:  t('common.executant'), filterPlaceholder: t('common.executant-filter'), style: { minWidth: '12rem' }, filter: true, bodyTemplate: null},
        ]
    };

    const setActeSpecColumns = () => {
        return [
            {selectionMode: "multiple", style: { width: '3rem' }},
            {name: 'acteName', headerName: t('common.care'), filterPlaceholder: t('common.care-filter'), style: { minWidth: '12rem' }, filter: true, bodyTemplate: acteNameBodyTemplate},
            {name: 'status', headerName: t('common.status'),  filterPlaceholder: t('common.status-filter-text'), style: { minWidth: '5rem' }, filter: true, bodyTemplate: statusBodyTemplate, filterElement: statusFilterTemplate},
            {name: 'executeDate', filterField: 'date', dataType: 'date',  filterPlaceholder: t('common.executed-on'), headerName: t('common.dateFilterText'), style: { minWidth: '12rem' }, filter: true, bodyTemplate: dateActeSpecBodyTemplate, filterElement: dateFilterTemplate},
            {name: 'executedBy', headerName:  t('common.executant'), filterPlaceholder: t('common.executant-filter'), style: { minWidth: '12rem' }, filter: true, bodyTemplate: null},
        ]
    };

    const dateExamBodyTemplate = (rowData) => {
        return <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DatePicker
                autoOk
                label=""
                clearable
                disabled={rowData.status !== STATUSES.pending}
                minDate={minDate}
                maxDate={maxDate}
                value={rowData.executeDate}
                format="dd/MM/yyyy"
                disableFuture
                required
                onChange={val => {
                    setConsultation({
                        ...consultation,
                        examArr: consultation.examArr.map(exam => {
                            if(exam.id === rowData.id) {
                                exam.executeDate = val;
                            }
                            return exam;
                        })
                    })
                }}
            />
        </MuiPickersUtilsProvider>
    };

    const dateActeSpecBodyTemplate = (rowData) => {
        return <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DatePicker
                autoOk
                label=""
                clearable
                disabled={rowData.status !== STATUSES.pending}
                minDate={minDate}
                maxDate={maxDate}
                value={rowData.executeDate}
                format="dd/MM/yyyy"
                disableFuture
                required
                onChange={val => {
                    setConsultation({
                        ...consultation,
                        actesSpec: consultation.actesSpec.map(exam => {
                            if(exam.id === rowData.id) {
                                exam.executeDate = val;
                            }
                            return exam;
                        })
                    })
                }}
            />
        </MuiPickersUtilsProvider>
    };

    const acteNameBodyTemplate = (rowData) => {
        return <>
            <span>{rowData.acteName}</span><br/>
        </>
    };

    const statusFilterTemplate = (options) => {
        return <Dropdown value={options.value} options={props.bon?.examenBonDetail.map(facture => {
            return facture.status
        }).reduce(function (acc, curr) {
            if (!acc.includes(curr))
                acc.push(curr);
            return acc;
        }, [])} onChange={(e) => options.filterCallback(e.value, options.index)} itemTemplate={statusItemTemplate}  placeholder={t('common.choose')} className="p-column-filter" showClear />;
    };

    const statusBodyTemplate = (rowData) => {
        return <Tag value={t('statuses.'+rowData.status)} severity={getExamSeverity(rowData.status)} />;
    };

    const statusItemTemplate = (option) => {
        return <Tag value={option} severity={getExamSeverity(option)} />;
    };

    const getExamSeverity = (status) => {
        switch (status) {
            case STATUSES.exectuted:
                return 'success';

            case STATUSES.rejeted:
                return 'success';

            default:
                return 'warning';
        }
    };

    const loadExecutedExams = () => {
        let data = {};
        let exams = consultation.examArr.filter(elt => elt.selected).map(exam => {
            return {
                examenDetailId: exam.id,
                executeDate: exam.executeDate,
                facturePrestataireTMPId: id
            }
        })
        data.examen = exams;
        data.executedBy = props.prestation.providerId;
        data.executedName = props.prestation.providerName;
        console.log(data);
        
        dispatch(startConsultationLoader());
        dispatch(delieverExamensAction(data, numBon));
    }

    const loadExecutedActeSpec = () => {
        let data = {};
        let exams = consultation.actesSpec.filter(elt => elt.selected).map(exam => {
            return {
                acteSpecialiseBonId: exam.id,
                executeDate: exam.executeDate,
                facturePrestataireTMPId: id
            }
        })
        data.acteDetails = exams;
        data.executedBy = props.prestation.providerId;
        data.executedName = props.prestation.providerName;

        console.log(data);
        
        dispatch(startConsultationLoader());
        dispatch(markActeAsDoneAction(data, numBon));
    }

    const isExamSelectable = (rowData) => {
        return rowData.status === STATUSES.pending || STATUSES.accepted
    }

    const loadSelectedExams = (selecExams) => {
        setConsultation({
            ...consultation,
            examArr: consultation.examArr.map(exam => {
                exam.selected = selecExams.includes(exam);
                return exam;
            })
        });
    }

    const loadSelectedActeSpec = (selecExams) => {
        setConsultation({
            ...consultation,
            actesSpec: consultation.actesSpec.map(exam => {
                exam.selected = selecExams.includes(exam);
                return exam;
            })
        });
    }

    const changeHospiRoom = () => {
        let data = {
            hospitalisationBonId: consultation.hospiBonId,
            consultationId: consultation.dossierConsultationId,
            acteId: hospitalisation.newChambre,
            acteName: hospitalisation.name,
            acteCode: hospitalisation.code,
            quantite: hospitalisation.duree,
            montant: hospitalisation.price,
            montantConvention: hospitalisation.price,
            MontantTM: 0,
            montantpolice: 0,
            facturePrestataireTMPId: consultation.facturePrestataireTMPId
        };
        dispatch(startConsultationLoader());
        dispatch(addActeDetailAction('hospitalisation', data, numBon));
    }

    const saveConsultation = (e) => {
        e.preventDefault();
        if(consultation.dossierConsultationId) {
            dispatch(startConsultationLoader());
            if(consultation.drugs) {
                consultation.drugs.forEach(drug => {
                    let data = {
                        pharmacieBonId: consultation.pharmacieBonId ? consultation.pharmacieBonId : -1,
                        consultationId: consultation.dossierConsultationId,
                        medicamentId: drug.id,
                        medicamentCode: drug.code,
                        medicamentName: drug.name,
                        quantite: drug.quantity,
                        montant: drug.amount,
                        posologie: [],
                        posologieString: "",
                        //facturePrestataireTMPId: consultation.facturePrestataireTMPId
                    };
                    dispatch(addActeDetailAction('pharmacie', data, numBon));
                });
            }
            if(consultation.examens) {
                consultation.examens.forEach(exam => {
                    let data = {
                        examenBonId: consultation.examenBonId ? consultation.examenBonId : -1,
                        consultationId: consultation.dossierConsultationId,
                        acteId: exam.id,
                        acteName: exam.nameFr,
                        acteCode: exam.code,
                        //facturePrestataireTMPId: consultation.facturePrestataireTMPId
                    };
                    dispatch(addActeDetailAction('examens', data, numBon));
                });
            }
            if(consultation.actesSpecialises) {
                consultation.actesSpecialises.forEach(exam => {
                    let data = {
                        examenBonId: consultation.actesSpecBonId ? consultation.actesSpecBonId : -1,
                        consultationId: consultation.dossierConsultationId,
                        acteId: exam.id,
                        acteName: exam.nameFr,
                        acteCode: exam.code,
                        dents: [],
                        //facturePrestataireTMPId: consultation.facturePrestataireTMPId
                    };
                    dispatch(addActeDetailAction('actes spécialisés', data, numBon));
                });
            }
        }else{
            let data = {
                bonId: consultation.bonId,
                facturePrestataireTMPId: consultation.facturePrestataireTMPId ? consultation.facturePrestataireTMPId : id,
                diagnostics: {
                    final: true,
                    diagnostic: consultation.diagnostic
                },
                questions: {
                    motif: consultation.motif,
                    allergies: [],
                    habits: [],
                    persAnts: [],
                    famAnts: [],
                    identities: [],
                    inspections: [],
                    palpations: [],
                    oscultations: [],
                    percussions: [],
                    observations: "",
                },
                //hospitalisation: consultation.hospitalisation ? [consultation.hospitalisation] : [],
                optique: consultation.optique ? consultation.optique : {}
            }
            if(consultation.hospitalisation) {
                data = {
                    ...data,
                    hospitalisation: [{
                        ...consultation.hospitalisation,
                        quantite: consultation.hospitalisation.quantite || 3
                    }]
                }
            }
            if(consultation.drugs) {
                data = {
                    ...data,
                    medocs: consultation.drugs.map(drug => {
                        let res = {...drug, posologieString: ""};
                        delete res['active'];
                        return res;
                    })
                }
            }
            if(consultation.examens) {
                data = {
                    ...data,
                    examens: consultation.examens.map(exam => {
                        let res = exam;
                        delete res['label'];
                        delete res['value'];
                        return res;
                    })
                }
            }
            if(consultation.actesSpecialises) {
                data = {
                    ...data,
                    specialActs: consultation.actesSpecialises?.map(exam => {
                        let res = exam;
                        res.dents = [];
                        delete res['label'];
                        delete res['value'];
                        return res;
                    })
                }
            }
            dispatch(startConsultationLoader());
            dispatch(updateConsultationAction(data, numBon));
        }
    }

    const noChange = () => {
        return (!consultation.drugs || consultation.drugs.length === 0) && 
                !consultation.diagnostic &&
                (!consultation.examens || consultation.examens.length === 0) &&
                (!consultation.actesSpecialises || consultation.actesSpecialises.length === 0) &&
                !consultation.hospitalisation &&
                !consultation.optique
    }

    const onSave = () => {

    }

    const canDo = (menuCode, actionCode) => {
        let theMenu = props.currentUser.menus?.find(menu => menu.code === menuCode);
        if(!theMenu){
            return false;
        }else{
            return theMenu.actionMenus?.filter(action => action.code === actionCode).length > 0;
        }
    }
    
    const hasMenu = (menuCode) => {
        return props.currentUser.menus?.filter(menu => menu.code === menuCode).length > 0;
    }

    useEffect(() => {
        if(hasMenu('MENPRESTATION') && canDo('MENPRESTATION', 'VIEWPRESTATION')){
            dispatch(startLoader());
            dispatch(getPrestationAction(id));
            dispatch(getActesByTypeAction('optique'));
            dispatch(getActesByTypeAction('Accessoires verres'));
        }else{
            props.history.push('/access-denied');
        }
    }, []);

    useEffect(() => {
        if(props.prestation.providerId){
            dispatch(getActesHospitalisationAction(props.prestation.providerId));
            let limitMonth = parseInt(t('revMois.'+props.prestation.mois));
            let limitYear = parseInt(props.prestation.annee);
            console.log(limitMonth);
            console.log(limitYear);
            console.log(new Date(limitYear, limitMonth - 1, 1));
            console.log(new Date(limitYear, limitMonth, 0));
            setMinDate(new Date(limitYear, limitMonth - 1, 1));
            setMaxDate(new Date(limitYear, limitMonth, 0));
        }
    }, [props.prestation]);

    useEffect(() => {
        console.log(props.consultationData);
        if(props.consultationData) {
            if(!props.consultationData.dossierConsultation?.id && 
                props.consultationData.providerId+"" !== props.prestation.providerId+"") {
                    swal('Attention', t('common.presta-warning-1'), "infos");
            }else if(new Date(props.consultationData.date) > maxDate){
                swal('Attention', t('common.presta-warning-2'), "infos");
            }else{
                setConsultation({
                    bonId: props.consultationData.id,
                    facturePrestataireTMPId: props.consultationData.facturePrestataireTMPId,
                    beneficiaryId: props.consultationData.beneficiaryId,
                    beneficiaryName: props.consultationData.beneficiaryName,
                    doctorId: props.consultationData.doctorId,
                    doctorName: props.consultationData.doctorName,
                    acteId: props.consultationData.acteId,
                    acteName: props.consultationData.acteName,
                    acteCode: props.consultationData.acteCode,
                    date: props.consultationData.date,
                    motif: props.consultationData.motif,
                    dossierConsultationId: props.consultationData.dossierConsultation?.id,
                    pharmacieBonId: props.consultationData.prescription?.examensBon[0]?.id,
                    examenBonId: props.consultationData.prescription?.pharmacieBon[0]?.id,
                    actesSpecBonId: props.consultationData.prescription?.acteSpecialiseBon[0]?.id,
                    hospiBonId: props.consultationData.prescription?.hospitalisationBon[0]?.id,
                    optiqueBonId: props.consultationData.prescription?.opticBon[0]?.id,
                    prixPharmacie: props.consultationData.prescription?.pharmacieBon[0]?.pharmacieBonDetails?.map(elt => {
                        return {
                            id: elt.id,
                            name: elt.medicament.name,
                            prix: elt.medicament.amount,
                            qte: elt.quantite,
                            prixTotal: (elt.medicament.amount * elt.quantite).toFixed(2),
                            unit: elt.medicament.unit,
                            deliveryName: elt.deliveryName,
                            deliveryDate: elt.deliveryDate,
                            isDelivery: elt.isDelivery,
                            medicament: elt.medicament
                        }
                    }),
                    examArr: props.consultationData.prescription?.examensBon[0]?.examenBonDetail?.map(elt => {
                        elt.selected = false;
                        return elt;
                    }),
                    actesSpec: props.consultationData.prescription?.acteSpecialiseBon[0]?.specialiseBonDetails?.map(elt => {
                        elt.selected = false;
                        return elt;
                    }),
                    hospiArr: props.consultationData.prescription?.hospitalisationBon[0],
                })
            }
        }
        dispatch(getPrestationAction(id));
    }, [props.consultationData]);

    /*useEffect(() => {
        if(cons) {
            setPrixPharmacie(bon.pharmacieBonDetails?.map(elt => {
                return {
                    id: elt.id,
                    name: elt.medicament.name,
                    prix: elt.medicament.amount,
                    qte: elt.quantite,
                    prixTotal: (elt.medicament.amount * elt.quantite).toFixed(2),
                    unit: elt.medicament.unit,
                    deliveryName: elt.deliveryName,
                    deliveryDate: elt.deliveryDate,
                    isDelivery: elt.isDelivery,
                    tm: 0,
                    prixPatient: (elt.medicament.amount * elt.quantite).toFixed(2),
                    drugSubtitution: elt.drugSubtitution,
                    medicament: elt.medicament
                }
            }));
        }
    }, [consultation]);*/

    return (
        <>
            <div className="card py-4">
                <h3 className='text-center mb-4 '>
                    {props.prestation?.providerName} - {props.prestation?.mois}{' '}{props.prestation?.annee}
                </h3>

                <div className='row p-4 justify-content-end'>
                    {hasMenu('MENPRESTATION') && canDo('MENPRESTATION', 'ADDPRESTATION') && props.prestation && props.prestation.statut === STATUSES.pending && <Button
                        onClick={() => setVisible(true)}
                        variant="outline-primary"
                    >
                        + {t('common.add-prestation')}
                    </Button>}

                    <Dialog header="Nouvelle ligne de prestation" visible={visible} maximizable style={{ width: '50vw'}} onHide={() => setVisible(false)} footer={footerContent}>
                        <form onSubmit={onSave}>
                            <div className='row my-2 align-items-center'>
                                <div className="col-4">
                                    <div className="form-group">
                                        <label className='mb-1 '>
                                            <strong>{t('common.code')} du bon</strong>
                                            <span className='text-danger ml-1'>*</span>
                                        </label>
                                        <input
                                            value={numBon}
                                            type='text'
                                            width={"50px"}
                                            className='form-control input-group-prepend'
                                            onChange={e => {
                                                setNumBon(e.target.value);
                                            }}
                                            disabled={props.showConsultLoading}
                                            onKeyDown={(e) => {
                                                if(e.key === "Enter") {
                                                    e.preventDefault();
                                                    dispatch(startConsultationLoader());
                                                    dispatch(getConsultationByCodeDataAction(numBon))
                                                }
                                            }}
                                        />
                                    </div>
                                </div>
                                {props.showConsultLoading && <div className="col-1">
                                    <ProgressSpinner style={{width: '25px', height: '25'}} strokeWidth="8" fill="var(--surface-ground)" animationDuration=".5s" />
                                </div>}
                                <div className={props.showConsultLoading ? "col-7" : "col-8"}>
                                    <div className="form-group">
                                        <label className='mb-1 '>
                                            <strong>Assuré</strong>
                                            <span className='text-danger ml-1'>*</span>
                                        </label>
                                        <input
                                            value={consultation.beneficiaryId+" - "+consultation.beneficiaryName}
                                            type='text'
                                            width={"50px"}
                                            className='form-control input-group-prepend'
                                            disabled
                                        />
                                    </div>
                                </div>
                            </div>

                            <TabView>

                                <TabPanel header="Consultation">
                                    <div className='px-1 pt-1'>
                                        <div className='row align-items-center'>
                                            <div className="col-3">
                                                <div className="form-group">
                                                    <label className='mb-1 '>
                                                        <strong>{t('client.type')}</strong>
                                                        <span className='text-danger ml-1'>*</span>
                                                    </label>
                                                    <Select
                                                        className="select-search"
                                                        value={{label: consultation.acteName, value: consultation.acteId, code: consultation.acteCode}}
                                                        options={props.actesConsultation?.map(act => {
                                                            return {label: act.nameFr, value: act.id, code: act.code}
                                                        })}
                                                        name="Type"
                                                        placeholder="Choisir un type"
                                                        search
                                                        isRequired
                                                        isDisabled={true}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-3">
                                                <div className="form-group">
                                                    <label className='mb-1 '>
                                                        <strong>{t('common.doctor')}</strong>
                                                        <span className='text-danger ml-1'>*</span>
                                                    </label>
                                                    <Select
                                                        className="select-search"
                                                        value={{label: consultation.doctorName, value: consultation.doctorId}}
                                                        options={props.provider?.providerUsers?.map(provUser => {
                                                            return {label: provUser.user.username, value: provUser.userId}
                                                        })}
                                                        name="Doctor"
                                                        placeholder="Choisir un médecin"
                                                        disabled={props.showLoading}
                                                        search
                                                        isRequired
                                                        isDisabled={true}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-3">
                                                <div className="form-group">
                                                    <label className='mb-1 '>
                                                        <strong>{t('common.hourdate')}</strong>
                                                        <span className='text-danger ml-1'>*</span>
                                                    </label>
                                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                        <DateTimePicker
                                                            autoOk
                                                            label=""
                                                            clearable
                                                            value={consultation.date}
                                                            format="dd/MM/yyyy"
                                                            required
                                                            disabled
                                                        />
                                                    </MuiPickersUtilsProvider>
                                                </div>
                                            </div>
                                            <div className="col-3">
                                                <div className="form-group">
                                                    <label className='mb-1 '>
                                                        <strong>{t('appointment.reason')}</strong>
                                                        <span className='text-danger ml-1'>*</span>
                                                    </label>
                                                    <Select
                                                        className="select-search"
                                                        options={motives}
                                                        value={motives.filter(elt => elt.value === consultation.motif).map(res => {return {label: res.label, value: res.value}})}
                                                        name="Type"
                                                        placeholder="Choisir un type"
                                                        isDisabled={true}
                                                        search
                                                        isRequired
                                                    />
                                                </div>
                                            </div>
                                            {!consultation.dossierConsultationId && <div className='col-12'>
                                                <div className='form-group'>
                                                    <label className='mb-1 '>
                                                        <strong>{t('common.diagnose')}</strong>
                                                        <span className='text-danger ml-1'>*</span>
                                                    </label>
                                                    <Select
                                                        className="select-search"
                                                        options={props.diseases?.map(elt => {return {label: elt.name, value: elt.code}})}
                                                        placeholder="Saisir un mot clé pour trouver l'affection"
                                                        search
                                                        isMulti
                                                        onInputChange={(val) => {
                                                            console.log(val);
                                                            if(val.length >= 1){
                                                                dispatch(searchDiseasesAction(val));
                                                            }
                                                        }}
                                                        isDisabled={props.showConsultLoading}
                                                        onChange={(vals) => {
                                                            setConsultation({...consultation, diagnostic: vals ? vals.map(val => {
                                                                return {code: val.value, name: val.label}
                                                            }) : []});
                                                        }}
                                                    />
                                                </div>
                                            </div>}
                                        </div>

                                        <h5 className='mt-4 text-primary'>Ordonance</h5>
                                        <div className="border-top mt-2 mb-4"></div>
                                        <div className='row justify-content-between align-items-center'>
                                            <div className="col-12 mb-2">
                                                <div className='form-group'>
                                                    <label className='mb-1 '>
                                                        <strong>{t('common.search-drug')}</strong>
                                                    </label>
                                                    <Select
                                                        className="select-search"
                                                        options={props.drugs?.map(elt => {return {...elt, label: elt.name, value: elt.code}})}
                                                        placeholder="Saisir un mot clé pour trouver le médicament"
                                                        search
                                                        isMulti
                                                        isDisabled={props.showConsultLoading}
                                                        onInputChange={(val) => {
                                                            console.log(val);
                                                            if(val.length >= 1){
                                                                dispatch(searchDrugsAction(val));
                                                            }
                                                        }}
                                                        onChange={(vals) => {
                                                            setConsultation({...consultation, drugs:vals ? vals.map((val, i) => {
                                                                let res = {
                                                                    active: i===vals.length - 1,
                                                                    id: val.id,
                                                                    code: val.code,
                                                                    name: val.name,
                                                                    posologie: [],
                                                                    coverage: val.isExclu,
                                                                    unit: val.unit,
                                                                    quantity: 1
                                                                }
                                                                return res;
                                                            }) : []});
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            
                                            {consultation.drugs && <div className='col-12'>
                                                <PerfectScrollbar
                                                    id="DZ_W_Todo2"
                                                    className="widget-media dz-scroll ps ps--active-y height300 text-center"
                                                >
                                                    {consultation.drugs?.length === 0 && <span className='text-light'>Ajouter des médicaments...</span>}
                                                    {consultation.drugs?.length > 0 && 
                                                        
                                                        <div className="basic-list-group">
                                                            <ListGroup>
                                                                {consultation.drugs?.map((drug, j) => {
                                                                    return <ListGroup.Item
                                                                        action
                                                                        active={drug.active}
                                                                        key={j}
                                                                        className="mb-1 flex-column align-items-start"
                                                                        style={{padding: "10px 20px", zIndex: "unset"}}
                                                                        onClick={(e) => {
                                                                            e.preventDefault();
                                                                            setConsultation({...consultation, drugs: consultation.drugs.map(drg => {
                                                                                drg.active = drg.code === drug.code;
                                                                                return drg;
                                                                            })});
                                                                        }}
                                                                        >
                                                                        <h6 className={drug.active ? "text-white m-0" : "text-dark m-0"}>
                                                                            {drug.name}
                                                                            {drug.active ? 'active' : 'inactive'}
                                                                            <small className={'text-' + (drug.coverage ? 'danger' : 'success')}>{drug.coverage ? '(Exclu)' : '(Couvert)'}</small>
                                                                        </h6>
                                                                        <div className="p-inputgroup" style={{width: '40%', height: '25px'}}>
                                                                            <InputNumber 
                                                                                placeholder="Price"
                                                                                value={drug.quantity}
                                                                                min={1}
                                                                                disabled={props.showConsultLoading}
                                                                                onChange={(e) => {
                                                                                        setConsultation({...consultation, drugs: consultation.drugs.map(drg => { 
                                                                                            if(drg.code === drug.code){
                                                                                                drg.quantity = e.value
                                                                                            }
                                                                                            return drg;
                                                                                        })});
                                                                                    }
                                                                                } 
                                                                            />
                                                                            <span className="p-inputgroup-addon">{drug.unit}</span>
                                                                        </div>
                                                                        </ListGroup.Item>
                                                                    })
                                                                }
                                                            </ListGroup>
                                                        </div>
                                                    }
                                                </PerfectScrollbar>

                                            </div>}
                                        </div>

                                        
                                        <h5 className='mt-4 text-primary'>{t('common.exam')}</h5>
                                        <div className="border-top mt-2 mb-4"></div>
                                        <div className='row justify-content-between align-items-center'>
                                            <div className='col-12'>
                                                <div className='form-group'>
                                                    <label className='mb-1 '>
                                                        <strong>{t('common.search-exam')}</strong>
                                                    </label>
                                                    <Select
                                                        className="select-search"
                                                        options={props.actesConsult?.map(elt => {return {...elt, label: elt.nameFr+(elt.isAccord ? '(Accord Préalable)' : ''), value: elt.code}})}
                                                        placeholder="Saisir un mot clé pour trouver l'examen"
                                                        search
                                                        isMulti
                                                        isDisabled={props.showConsultLoading}
                                                        onInputChange={(val) => {
                                                            console.log(val);
                                                            if(val.length >= 1){
                                                                dispatch(searchActesConsultAction(val, props.prestation.providerId, 'Examen'));
                                                            }
                                                        }}
                                                        onChange={(vals) => {
                                                            setConsultation({...consultation, examens: vals ? vals.map(val => {
                                                                return val;
                                                            }) : []});
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <h5 className='mt-4 text-primary'>{t('layout.specialized-acts')}</h5>
                                        <div className="border-top mt-2 mb-4"></div>
                                        <div className='row justify-content-between align-items-center'>
                                            <div className='col-12'>
                                                <div className='form-group'>
                                                    <label className='mb-1 '>
                                                        <strong>{t('common.search-acte')}</strong>
                                                    </label>
                                                    <Select
                                                        className="select-search"
                                                        options={props.actesConsult?.map(elt => {return {...elt, label: elt.nameFr+(elt.isAccord ? '(Accord Préalable)' : ''), value: elt.code}})}
                                                        placeholder="Saisir un mot clé pour trouver l'acte"
                                                        search
                                                        isMulti
                                                        isDisabled={props.showConsultLoading}
                                                        onInputChange={(val) => {
                                                            console.log(val);
                                                            if(val.length >= 1){
                                                                dispatch(searchActesConsultAction(val, props.prestation.providerId, 'Acte spécialisé'));
                                                            }
                                                        }}
                                                        onChange={(vals) => {
                                                            setConsultation({...consultation, actesSpecialises: vals ? vals.map(val => {
                                                                return val;
                                                            }) : []});
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        {/*{!consultation.dossierConsultationId && <><h5 className='mt-4 text-primary'>{t('layout.optics')}</h5>
                                        <div className="border-top mt-2 mb-4"></div>
                                        <div className='row justify-content-between align-items-center'>
                                            <div className='form-group col-4'>
                                                <label className='mb-1 '>
                                                    <strong>Choisir l'acte</strong>
                                                </label>
                                                <Select
                                                    className="select-search"
                                                    options={props.actesByType?.map(act => {
                                                        return {label: act.nameFr, value: act.id}
                                                    })}
                                                    name="Type"
                                                    placeholder="Choisir l'acte"
                                                    search
                                                    isDisabled={props.showConsultLoading}
                                                    onChange={(val) => {
                                                        setConsultation({...consultation, optique: {
                                                            ...consultation.optique,
                                                            acteId: val.value,
                                                            acteName: val.label
                                                        }});
                                                    }}
                                                />

                                            </div>
                                            <div className='form-group col-4'>
                                                <label className='mb-1 '>
                                                    <strong>{t('consultation.ecart-pupil')}</strong>
                                                </label>
                                                <input
                                                    value={ecartPupillaire}
                                                    type='number'
                                                    min='1'
                                                    width={"50px"}
                                                    disabled={props.showConsultLoading}
                                                    className='form-control input-group-prepend'
                                                    onChange={e => {
                                                        setConsultation({...consultation, optique: {
                                                            ...consultation.optique,
                                                            ecartPupillaire: e.target.value
                                                        }});
                                                    }}
                                                />
                                            </div>
                                            <div className='form-group col-4'>
                                                <label className='mb-1 '>
                                                    <strong>Pour voir de loin - oeil droit</strong>
                                                </label>
                                                <input
                                                    value={vlod}
                                                    type='text'
                                                    width={"50px"}
                                                    disabled={props.showConsultLoading}
                                                    className='form-control input-group-prepend'
                                                    onChange={e => {
                                                        setConsultation({...consultation, optique: {
                                                            ...consultation.optique,
                                                            vlod: e.target.value
                                                        }});
                                                    }}
                                                />
                                            </div>
                                            <div className='form-group col-4'>
                                                <label className='mb-1 '>
                                                    <strong>Pour voir de loin - oeil gauche</strong>
                                                </label>
                                                <input
                                                    value={vlog}
                                                    type='text'
                                                    width={"50px"}
                                                    disabled={props.showConsultLoading}
                                                    className='form-control input-group-prepend'
                                                    onChange={e => {
                                                        setConsultation({...consultation, optique: {
                                                            ...consultation.optique,
                                                            vlog: e.target.value
                                                        }});
                                                    }}
                                                />
                                            </div>
                                            <div className='form-group col-4'>
                                                <label className='mb-1 '>
                                                    <strong>Pour voir de près - oeil droit</strong>
                                                </label>
                                                <input
                                                    value={vpod}
                                                    type='text'
                                                    width={"50px"}
                                                    disabled={props.showConsultLoading}
                                                    className='form-control input-group-prepend'
                                                    onChange={e => {
                                                        setConsultation({...consultation, optique: {
                                                            ...consultation.optique,
                                                            vpod: e.target.value
                                                        }});
                                                    }}
                                                />
                                            </div>
                                            <div className='form-group col-4'>
                                                <label className='mb-1 '>
                                                    <strong>Pour voir de près - oeil gauche</strong>
                                                </label>
                                                <input
                                                    value={vpog}
                                                    type='text'
                                                    width={"50px"}
                                                    disabled={props.showConsultLoading}
                                                    className='form-control input-group-prepend'
                                                    onChange={e => {
                                                        setConsultation({...consultation, optique: {
                                                            ...consultation.optique,
                                                            vpog: e.target.value
                                                        }});
                                                    }}
                                                />
                                            </div>
                                            {
                                                props.accessoireVerre?.map((access, i) => {
                                                    return <div className='col-3 custom-control custom-checkbox mb-3 checkbox-primary'>
                                                        <input
                                                        type='checkbox'
                                                        checked={details.filter(elt => elt.acteId === access.id).length > 0}
                                                        disabled={props.showConsultLoading}
                                                        onChange={(e) => {
                                                            if(e.target.checked){
                                                                setConsultation({...consultation, optique: {
                                                                    ...consultation.optique,
                                                                    details: [...consultation.optique?.details, {
                                                                        acteId: access.id,
                                                                        acteName: access.nameFr,
                                                                        acteCode: access.code
                                                                    }]
                                                                }});
                                                            }else{
                                                                setConsultation({...consultation, optique: {
                                                                    ...consultation.optique,
                                                                    details: consultation.optique?.details?.filter(elt => elt.acteId !== access.id)
                                                                }});
                                                            }
                                                        }}
                                                        className='custom-control-input'
                                                        id={'customCheckBoxOptiq'+i}
                                                        />
                                                        <label
                                                        className='custom-control-label text-primary text-bold'
                                                        htmlFor={'customCheckBoxOptiq'+i}
                                                        >
                                                            {access.nameFr}
                                                        </label>
                                                    </div>
                                                })
                                            }
                                        </div></>}*/}

                                        {!consultation.dossierConsultationId && <><h5 className='mt-4 text-primary'>{t('statuses.100')}</h5>
                                        <div className="border-top mt-2 mb-4"></div>
                                        <div className='d-flex justify-content-start'>
                                            <div className='custom-control custom-checkbox mb-3 checkbox-danger'>
                                                <input
                                                    type='checkbox'
                                                    checked={inHospi}
                                                    onChange={(e) => {
                                                        setInHospi(e.target.checked);
                                                        if(!e.target.checked) {
                                                            let temp = {...consultation};
                                                            delete temp[hospitalisation];
                                                        }
                                                    }}
                                                    className='custom-control-input'
                                                    id='customCheckBoxHospi1'
                                                    required
                                                />
                                                <label
                                                className='custom-control-label'
                                                htmlFor='customCheckBoxHospi1'
                                                >
                                                   {t('common.hospitalize-patient')}
                                                </label>
                                            </div>
                                        </div>
                                        <div className='row justify-content-between align-items-center'>
                                            <div className='form-group col-6'>
                                                <label className='mb-1 '>
                                                    <strong> {t('common.dayPeriod')}</strong>
                                                </label>
                                                <input
                                                    value={duree}
                                                    type='number'
                                                    min='1'
                                                    max='3'
                                                    width={"50px"}
                                                    className='form-control input-group-prepend'
                                                    disabled={props.showConsultLoading || !inHospi}
                                                    onChange={e => {
                                                        setDuree(e.target.value);
                                                        setConsultation({...consultation, hospitalisation: {
                                                            ...consultation.hospitalisation,
                                                            quantite: e.target.value
                                                        }})
                                                    }}
                                                />

                                            </div>
                                            <div className='form-group col-6'>
                                                <label className='mb-1 '>
                                                    <strong>{t('common.chooseroom')}</strong>
                                                </label>
                                                <Select
                                                    className="select-search"
                                                    options={props.actesHospitalisation?.map(act => {
                                                        return {label: (act.acte.nameFr+' - '+(act.value * act.acte.coefLettre)), acteVal: act.acte, value: act.id, code: act.acte.code, name: act.acte.nameFr, rootValue: act.acte.id, price: (act.value * act.acte.coefLettre)}
                                                    })}
                                                    name="Type"
                                                    placeholder={t('common.chooseroom')}
                                                    search
                                                    isDisabled={props.showConsultLoading || !inHospi}
                                                    onChange={(val) => {
                                                        setConsultation({...consultation, hospitalisation: {
                                                            ...consultation.hospitalisation,
                                                            code: val.code,
                                                            nameFr: val.name,
                                                            nameEn: val.name,
                                                            isAccord: val.acteVal.isAccord,
                                                            isAlert: val.acteVal.isAlert,
                                                            isExclu: val.acteVal.isExclu,
                                                            id: val.rootValue,
                                                            montant: val.price,
                                                            montantConvention: val.price,
                                                            MontantTM: 0,
                                                            montantpolice: 0
                                                        }})
                                                    }}
                                                />

                                            </div>
                                        </div> </>}

                                        <div className='row justify-content-end px-3 align-items-center'>
                                            { props.showConsultLoading && 
                                                <Button variant="primary" disabled>
                                                    <Spinner
                                                        as="span"
                                                        animation="grow"
                                                        size="sm"
                                                        role="status"
                                                        aria-hidden="true"
                                                    />{' '}
                                                    <span className="visually-hidden">{t('common.await-text')}</span>
                                                </Button> }
                                            { !props.showConsultLoading && 
                                                <button className='btn btn-primary' disabled={noChange()} onClick={e => saveConsultation(e)}>Enregistrer</button>
                                            }
                                        </div>
                                    </div>
                                </TabPanel>

                                <TabPanel header="Pharmacie" disabled={!consultation.prixPharmacie || consultation.prixPharmacie.length === 0 || props.showConsultLoading || !noChange()}>
                                    <div className="row">
                                        <DataTableBase 
                                            data={consultation.prixPharmacie?.map(bon => {
                                                bon.deliveryDate = new Date(bon.deliveryDate);
                                                return bon;
                                            })}
                                            size="small"
                                            emptyMessage="Aucun médicament prescrit" 
                                            filters={filters}
                                            globalFiltersFields={['name', 'prix', 'qte', 'prixTotal', 'unit', 'deliveryName', 'deliveryDate', 'isDelivery']}
                                            header={renderHeader()}
                                            columns={setColumns()}
                                            rows={10} 
                                            loading={props.showConsultLoading}
                                            rowClick={true}
                                            selectedData={pharmacie}
                                            setSelectedDatas={(val) => setPharmacie(val)}
                                            isRowSelectable={val => isDrugSelectable(val)}
                                        />
                                    </div>
                                    <div className="row mt-2"> 
                                        { props.showConsultLoading && 
                                            <Button variant="primary" disabled>
                                                <Spinner
                                                    as="span"
                                                    animation="grow"
                                                    size="sm"
                                                    role="status"
                                                    aria-hidden="true"
                                                />{' '}
                                                <span className="visually-hidden">{t('common.await-text')}</span>
                                            </Button> }         
                                        { !props.showConsultLoading && <Button
                                            as='a'
                                            variant='primary light'
                                            href='#'
                                            className='btn-card'
                                            disabled={pharmacie.length === 0}
                                            onClick={() => delieverDrugs()}
                                            >
                                           {t('common.validate')}
                                        </Button> }
                                    </div>
                                </TabPanel>

                                <TabPanel header="Laboratoire" disabled={!consultation.examArr || consultation.examArr.length === 0 || props.showConsultLoading || !noChange()}>
                                    <div className="row">
                                        <DataTableBase 
                                            data={consultation.examArr}
                                            size="small"
                                            tableStyle={{ minWidth: '50rem', width: '100%' }}
                                            emptyMessage={t('common.noExam')} 
                                            filters={examFilters}
                                            globalExamFiltersFields={['acteName', 'status', 'executeDate', 'executedBy']}
                                            header={renderExamHeader()}
                                            columns={setExamColumns()}
                                            rows={10} 
                                            loading={props.showConsultLoading}
                                            rowClick={true}
                                            selectedData={consultation.examArr?.filter(exam => exam.selected)}
                                            setSelectedDatas={(val) => loadSelectedExams(val)}
                                            isRowSelectable={val => isExamSelectable(val)}
                                        />
                                    </div>
                                    <div className="row mt-2">
                                        { props.showConsultLoading && 
                                            <Button variant="primary" disabled>
                                                <Spinner
                                                    as="span"
                                                    animation="grow"
                                                    size="sm"
                                                    role="status"
                                                    aria-hidden="true"
                                                />{' '}
                                                <span className="visually-hidden">{t('common.await-text')}</span>
                                            </Button> }         
                                        { !props.showConsultLoading && <Button
                                            as='a'
                                            variant='primary light'
                                            href='#'
                                            className='btn-card'
                                            onClick={() =>  loadExecutedExams()}
                                            disabled={consultation.examArr?.filter(exam => exam.selected) === 0}
                                            >
                                           {t('common.validate')}
                                        </Button> }
                                    </div>
                                </TabPanel>

                                <TabPanel header="Soins" disabled={!consultation.actesSpec || consultation.actesSpec.length === 0 || props.showConsultLoading || !noChange()}>
                                    <div className="row">
                                        <DataTableBase 
                                            data={consultation.actesSpec}
                                            size="small"
                                            tableStyle={{ minWidth: '50rem', width: '100%' }}
                                            emptyMessage="Aucun soin trouvé" 
                                            filters={examFilters}
                                            globalExamFiltersFields={['acteName', 'status', 'executeDate', 'executedBy']}
                                            header={renderActeSpecHeader()}
                                            columns={setActeSpecColumns()}
                                            rows={10} 
                                            loading={props.showConsultLoading}
                                            rowClick={true}
                                            selectedData={consultation.actesSpec?.filter(exam => exam.selected)}
                                            setSelectedDatas={(val) => loadSelectedActeSpec(val)}
                                            isRowSelectable={val => isExamSelectable(val)}
                                        />
                                    </div>
                                    <div className="row mt-2">
                                        { props.showConsultLoading && 
                                            <Button variant="primary" disabled>
                                                <Spinner
                                                    as="span"
                                                    animation="grow"
                                                    size="sm"
                                                    role="status"
                                                    aria-hidden="true"
                                                />{' '}
                                                <span className="visually-hidden">{t('common.await-text')}</span>
                                            </Button> }         
                                        { !props.showConsultLoading && <Button
                                            as='a'
                                            variant='primary light'
                                            href='#'
                                            className='btn-card'
                                            onClick={() =>  loadExecutedActeSpec()}
                                            disabled={consultation.actesSpec?.filter(exam => exam.selected) === 0}
                                            >
                                           {t('common.validate')}
                                        </Button> }
                                    </div>
                                </TabPanel>

                                <TabPanel header="Hospitalisation" disabled={!consultation.hospiArr || props.showConsultLoading || !noChange()}>

                                    <div className='row'>
                                        <div className="col-6">
                                            <div className="form-group">
                                                <label className='mb-1 '>
                                                    <strong>{t('common.startdate')}</strong>
                                                    <span className='text-danger ml-1'>*</span>
                                                </label>
                                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                    <DateTimePicker
                                                        autoOk
                                                        label=""
                                                        clearable
                                                        value={consultation.date}
                                                        format="dd/MM/yyyy"
                                                        required
                                                        disabled
                                                    />
                                                </MuiPickersUtilsProvider>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className="form-group">
                                                <label className='mb-1 '>
                                                    <strong>{t('common.enddate')}</strong>
                                                    <span className='text-danger ml-1'>*</span>
                                                </label>
                                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                    <DateTimePicker
                                                        autoOk
                                                        label=""
                                                        clearable
                                                        value={hospitalisation.dateFin || consultation.hospiArr?.dateFin}
                                                        minDate={consultation.date}
                                                        maxDate={maxDate}
                                                        format="dd/MM/yyyy"
                                                        disableFuture
                                                        required
                                                        disabled={consultation.hospiArr?.isClose}
                                                        onChange={val => setHospitalisation({...hospitalisation, dateFin: val})}
                                                    />
                                                </MuiPickersUtilsProvider>
                                            </div>
                                        </div>
                                    </div>
                                    {consultation.hospiArr?.hospitalisationBonDetail?.map(hospiDetails => {
                                        return <div className='row'>
                                                    <div className='form-group col-6'>
                                                        <label className='mb-1 '>
                                                            <strong>{t('common.room')}</strong>
                                                        </label>
                                                        <input type="text" className='form-control input-group-prepend' disabled value={hospiDetails.acteName+" - "+hospiDetails.montant} />
                                                    </div>
                                                    <div className='form-group col-6'>
                                                        <label className='mb-1 '>
                                                            <strong> {t('common.dayPeriod')}</strong>
                                                        </label>
                                                        <input
                                                            value={hospiDetails.quantite}
                                                            type='number'
                                                            width={"50px"}
                                                            className='form-control input-group-prepend'
                                                            disabled
                                                        />
                                                    </div>
                                                </div>
                                        })
                                    }
                                    {hospitalisation.newChambre &&
                                        <>
                                            <h5 className='mt-4 text-primary'>{t('common.changeroom')}</h5>
                                            <div className="border-top mt-2 mb-4"></div>
                                            <div className='row'>
                                                <div className='form-group col-6'>
                                                    <label className='mb-1 '>
                                                        <strong>{t('common.chooseroom')}</strong>
                                                    </label>
                                                    <Select
                                                        className="select-search"
                                                        options={props.actesHospitalisation?.map(act => {
                                                            return {label: (act.acte.nameFr+' - '+(act.value * act.acte.coefLettre)), value: act.id, code: act.acte.code, name: act.acte.nameFr, rootValue: act.acte.id, price: (act.value * act.acte.coefLettre)}
                                                        })}
                                                        name="Type"
                                                        placeholder={t('common.chooseroom')}
                                                        search
                                                        onChange={(val) => {
                                                            setHospitalisation({...hospitalisation, newChambre: val.rootValue, code: val.code, name: val.name, price: val.price});
                                                        }}
                                                    />

                                                </div>
                                                <div className='form-group col-6'>
                                                    <label className='mb-1 '>
                                                        <strong> {t('common.dayPeriod')}</strong>
                                                    </label>
                                                    <input
                                                        value={hospitalisation.duree}
                                                        type='number'
                                                        min='1'
                                                        max={consultation.hospiArr?.hospitalisationBonDetail[consultation.hospiArr.hospitalisationBonDetail.length - 1]?.quantite}
                                                        width={"50px"}
                                                        className='form-control input-group-prepend'
                                                        onChange={e => {
                                                            setHospitalisation({...hospitalisation, duree: e.target.value});
                                                        }}
                                                    />

                                                </div>
                                            </div>
                                        </>
                                    }
                                    {!consultation.hospiArr?.isClose && <div className='row justifify-content-between'>
                                        <button className='btn btn-info mr-3' 
                                            disabled={consultation.hospiArr?.hospitalisationBonDetail[consultation.hospiArr.hospitalisationBonDetail.length - 1]?.quantite <= 1 || hospitalisation.duree < 1 || hospitalisation.newChambre === "-"}
                                            onClick={e => {
                                                e.preventDefault();
                                                if(hospitalisation.newChambre){
                                                    e.preventDefault();
                                                    changeHospiRoom();
                                                }else{
                                                    setHospitalisation({...hospitalisation, newChambre: "-", duree: 0, prix: 0, code: "", name: ""});
                                                }
                                            }
                                        }>
                                            {!hospitalisation.newChambre ? t('common.changeroom') : t('common.save')}
                                        </button>
                                        <button 
                                            onClick={e => {
                                                e.preventDefault();
                                                dispatch(startConsultationLoader());
                                                dispatch(endHospiAction({
                                                    bonId: consultation.hospiBonId,
                                                    date: hospitalisation.dateFin
                                                }, numBon));
                                            }}
                                            disabled={(new Date(consultation.hospiArr?.dateFin) < new Date(consultation.hospiArr?.dateDebut)) 
                                                || new Date(consultation.hospiArr?.dateFin) > maxDate} 
                                            className='btn btn-danger'>
                                                {t('common.closehospi')}
                                        </button>
                                    </div>}
                                    
                                </TabPanel>

                                {/*<TabPanel header="Optique">
                                    
                                </TabPanel>*/}

                            </TabView>
                        </form>
                    </Dialog>
                </div>

                <div className='row p-4'>
                    <TreeTableBase
                        data={setDatas()}
                        style={{ minWidth: '50rem', fontSize: '12px !important' }}
                        columns={getTableColumns()}
                        rowClassName={rowClassName}
                    />
                </div>
            </div>
        </>
    );

};

const mapStateToProps = (state) => {
    return {
        consultationData: state.consultation.consultationByCode,
        beneficiaires: state.contrat.beneficiaires,
        diseases: state.consultation.diseases,
        drugs: state.consultation.drugs,
        actesConsult: state.provider.actesConsult,
        actesByType: state.consultation.actesByType,
        accessoireVerre: state.consultation.accessoireVerre,
        actesHospitalisation: state.consultation.actesHospitalisation,
        prestation: state.facturation.prestation,
        errorMessage: state.clientAuth.errorMessage,
        successMessage: state.clientAuth.successMessage,
        showLoading: state.provider.showLoading,
        showConsultLoading: state.consultation.showLoading,
        currentUser: currentUser(state)
    };
};

export default withRouter(connect(mapStateToProps)(PrestationForm));