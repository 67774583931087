import React, { Fragment,useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux';
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Row, Col, Card, Tab, Nav, Spinner } from 'react-bootstrap'
import { currentUser } from "../../../store/selectors/CurrentUserSelector";
import { 
    getCollegePerContractAction, 
    getFamillePerCollegeAction, 
    getContractPerClientAction, 
    getBeneficiairesAction, 
    loadingToggleAction,
    getRequestAvenantAction 
} from '../../../store/actions/ContratActions';
import Select from 'react-select';
import { DatePicker, DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { DataTableBase } from "../DataTables/DataTableBase";
import { Calendar } from 'primereact/calendar';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Tag } from 'primereact/tag';
import { STATUSES } from '../../globals';

const Historique = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const typeDemandes = ['incorporation', 'retrait', 'suspension', 'annulation', 'renouvellement', 'changementCollege'];
    
    const [currentDemande, setCurrentDemande] = useState('incorporation');
    const [currentDemandeData, setCurrentDemandeData] = useState([]);

    const [filters, setFilters] = useState(null);
    const [globalFilterValue, setGlobalFilterValue] = useState('');
    
    const {incorporationRequests, retraitRequests, suspensionRequests, annulationRequests,
        renouvellementRequests, changementCollegeRequests, beneficiaires, showLoading
    } = props;

    const initFilters = () => {
        setFilters({
            global: { value: null, matchMode: FilterMatchMode.CONTAINS },
            numeroPolice: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            assures: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            createdAt: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
        });
        setGlobalFilterValue("");
    };

    const renderHeader = () => {
        return (
            <div className="flex justify-content-between">
                <Button type="button" icon="pi pi-filter-slash"  label={t('common.reinit')} outlined onClick={clearFilter} />
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText value={globalFilterValue} onChange={(e) => onGlobalFilterChange(e)}  placeholder={t('common.search')} />
                </span>
            </div>
        );
    };

    const clearFilter = () => {
        initFilters();
    };

    const setColumns = () => {
        return [
            {name: 'numeroPolice', headerName: "Police", filterPlaceholder: t('common.filter-name'), style: { minWidth: '12rem', fontSize: '14px' }, filter: true, bodyTemplate: null, sortable: true},
            {name: 'assures', headerName: "Assurés", filterPlaceholder: "Filtrer par assuré", style: { minWidth: '12rem', fontSize: '14px' }, filter: true, bodyTemplate: assuresBodyTemplate, sortable: true},
            {name: 'status', headerName: 'Statut', filterPlaceholder: 'Filtrer par statut', style: { minWidth: '12rem' }, filter: true, bodyTemplate: statusBodyTemplate},
            {name: 'createdAt', filterField: 'createdAt', dataType: 'date', headerName: "Date de demande", filterPlaceholder: t('common.filter-datenaiss'), style: { minWidth: '6rem', fontSize: '14px' }, filter: true, bodyTemplate: dateNaissBodyTemplate, filterElement: dateFilterTemplate, sortable: true},
            {name: 'actions', headerName: 'Actions', filterPlaceholder: '', style: { minWidth: '6rem', fontSize: '14px'}, filter: false, bodyTemplate: getActionsTemplate},
        ]
    };

    const statusBodyTemplate = (rowData) => {
        return <Tag value={t('statuses.'+rowData.status)} severity={getSeverity(rowData.status)} />;
    };


    const getSeverity = (status) => {
        switch (status) {
            case STATUSES.rejeted:
                return 'danger';
            
            case STATUSES.pending:
                return 'warning';

            default:
                return 'success';
        }
    };

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };

        _filters['global'].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
    };

    const getActionsTemplate = (rowData) => {
        return <div style={{ width: '100%', display: 'flex', justifyContent: 'center'}}>
            <button className="btn btn-info shadow btn-xs sharp mr-2"
                onClick={e => console.log(e)}
            >
                <i className="fa fa-eye"></i>
            </button>
        </div>
    }

    const dateNaissBodyTemplate = (rowData) => {
        return formatDate(rowData.createdAt);
    };

    const dateFilterTemplate = (options) => {
        return <Calendar value={options.value} onChange={(e) => options.filterCallback(e.value, options.index)} dateFormat="dd/mm/yy" placeholder="dd/mm/yyyy" mask="99/99/9999" />;
    };

    const formatDate = (value) => {
        return value.toLocaleDateString('fr-FR', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric'
        });
    };

    const assuresBodyTemplate = (rowData) => {
        return rowData.assures?.map(assure => {
            return <Tag value={assure.nom} severity={'info'} />;
        })
    };

    const canDo = (menuCode, actionCode) => {
        let theMenu = props.currentUser.menus?.find(menu => menu.code === menuCode);
        if(!theMenu){
            return false;
        }else{
            return theMenu.actionMenus?.filter(action => action.code === actionCode).length > 0;
        }
    }
    
    const hasMenu = (menuCode) => {
        return props.currentUser.menus?.filter(menu => menu.code === menuCode).length > 0;
    }

    useEffect(() => {
        if(hasMenu('MENAVENADMIN') && canDo('MENAVENADMIN', 'ACVIEWAVENREQUEST')){
            dispatch(loadingToggleAction(true));
            dispatch(getRequestAvenantAction('incorporation'));
        }else{
            props.history.push('/access-denied');
        } 
    }, []);

    useEffect(() => {
        switch (currentDemande) {
            case 'incorporation':
                setCurrentDemandeData(incorporationRequests);
                return;
            case 'retrait':
                setCurrentDemandeData(retraitRequests);
                return;
            case 'suspension':
                setCurrentDemandeData(suspensionRequests);
                return;
            case 'annulation':
                setCurrentDemandeData(annulationRequests);
                return;
            case 'renouvellement':
                setCurrentDemandeData(renouvellementRequests);
                return;
            default:
                setCurrentDemandeData(changementCollegeRequests);
                return;
        }
    }, [incorporationRequests, retraitRequests, suspensionRequests, annulationRequests, renouvellementRequests, changementCollegeRequests]);
    
    return (
        <div>
            <div className="row justify-content-start">
                <div className="col-lg-3 mb-2">
                    <div className="form-group">
                        <label className='mb-1 '>
                            <strong>Demandes d'avenant</strong>
                        </label>
                        <Select
                            className="select-search"
                            value={typeDemandes.filter(elt => elt === currentDemande).map((opt)=>{return {label:opt,value:opt}})}
                            options={typeDemandes.map((opt)=>{return {label:opt,value:opt}})}
                            placeholder="Choisir un type"
                            search
                            onChange={(val) => {
                                setCurrentDemande(val.value);
                                dispatch(getRequestAvenantAction(val.value));
                            }}
                            
                            style={{
                                lineHeight: '40px',
                                color: '#7e7e7e',
                                paddingLeft: ' 15px',
                            }}
                        />
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-xl-12">
                    <DataTableBase 
                        data={ currentDemandeData.map(elt => {
                            elt.createdAt = new Date(elt.createdAt);
                            elt.assureText = elt.assures?.map(val => val.nom) || '';
                            return elt;
                        })}
                        emptyMessage="Aucune demande trouvée" 
                        filters={filters}
                        globalFiltersFields={['numPolice', 'assures', 'createdAt']}
                        header={renderHeader()}
                        columns={setColumns()}
                        exportColumns={[
                            { title: "Police", dataKey: 'numPolice' },
                            { title: 'Assurés', dataKey: 'assureText' },
                            { title: 'Crée le', dataKey: 'createdAt' },
                        ]}
                        rows={10} 
                        showExport={true}
                        exportFilename={currentDemande}
                        loading={showLoading}
                        lazy={false}
                    />
                    
                </div>
            </div>

        </div>
    )
}
const mapStateToProps = (state) => {
    return {
        currentUser: currentUser(state),
        incorporationRequests: state.contrat.incorporationRequests,
        retraitRequests: state.contrat.retraitRequests,
        suspensionRequests: state.contrat.suspensionRequests,
        annulationRequests: state.contrat.annulationRequests,
        renouvellementRequests: state.contrat.renouvellementRequests,
        changementCollegeRequests: state.contrat.changementCollegeRequests,
        showLoading: state.contrat.showLoading
    };
};
export default connect(mapStateToProps)(Historique);