import React, { Fragment, useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import PerfectScrollbar from "react-perfect-scrollbar";
import { Link, useParams } from "react-router-dom";
import { Tab, Nav, ListGroup } from "react-bootstrap";
import { currentUser } from '../../../../store/selectors/CurrentUserSelector';
import { getClientConsultationsAction } from '../../../../store/actions/ConsultationActions';
import {format} from 'date-fns';
import { useTranslation } from "react-i18next";

const DossierPatient = (props) => {
    const {datas} = props;
    const { t } = useTranslation();
    
    return (
        <Fragment>
            <div className="row">
                <div className='col-12 h300'>
                    <div className='card'>
                        <div className='card-header border-0 bg-primary'>
                            <h4 className='text-white d-flex align-items-center'>
                                <i className='la la-user mx-1' style={{fontSize: "25px"}}></i>
                                {t('medical.patient-file')}
                            </h4>
                        </div>
                        <div className='card-body'>

                            <div className='row mt-4 mb-1'>
                                <h4 className='text-primary'>
                                    <i className='la la-user mr-2 rounded-circle p-2 border-primary bg-primary text-white' style={{fontSize: "30px"}}></i>
                                    {t('client.identity')}
                                </h4>
                            </div>

                            <div className='row px-3'>
                                <div className='col-6'>
                                    <div className='row my-3'>
                                        <div className='col-3'>
                                            <b>{t('common.name')}</b>
                                        </div>
                                        <div className='col-9 border-primary border-bottom'>
                                            <span className='text-primary'>{datas.beneficiaire?.firstName}</span>
                                        </div>
                                    </div>
                                    <div className='row my-3'>
                                        <div className='col-3'>
                                            <b>{t('common.firstname')}</b>
                                        </div>
                                        <div className='col-9 border-primary border-bottom'>
                                            <span className='text-primary'>{datas.beneficiaire?.lastName}</span>
                                        </div>
                                    </div>
                                    <div className='row my-3'>
                                        <div className='col-3'>
                                            <b>{t('common.gender')}</b>
                                        </div>
                                        <div className='col-9 border-primary border-bottom'>
                                            <span className='text-primary'>{datas.beneficiaire?.sexe}</span>
                                        </div>
                                    </div>
                                    <div className='row my-3'>
                                        <div className='col-3'>
                                            <b>{t('common.birthdate')}</b>
                                        </div>
                                        <div className='col-9 border-primary border-bottom justify-items-end'>
                                            <span className='text-primary'>{format(new Date(datas.beneficiaire?.birthdate), 'dd/mm/yyyy')}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-6'>
                                    <div className='row my-3'>
                                        <div className='col-3'>
                                            <b>{t('common.matrimony')}</b>
                                        </div>
                                        <div className='col-9 border-primary border-bottom'>
                                            <span className='text-primary'>{datas.beneficiaire?.maritalSituation}</span>
                                        </div>
                                    </div>
                                    <div className='row my-3'>
                                        <div className='col-3'>
                                            <b>{t('common.job')}</b>
                                        </div>
                                        <div className='col-9 border-primary border-bottom'>
                                            <span className='text-primary'>{datas.beneficiaire?.profession}</span>
                                        </div>
                                    </div>
                                    <div className='row my-3'>
                                        <div className='col-3'>
                                            <b>{t('common.contract-number')}</b>
                                        </div>
                                        <div className='col-9 border-primary border-bottom'>
                                            <span className='text-primary'>123456789</span>
                                        </div>
                                    </div>
                                    <div className='row my-3'>
                                        <div className='col-3'>
                                            <b>{t('common.insured-number')}</b>
                                        </div>
                                        <div className='col-9 border-primary border-bottom'>
                                            <span className='text-primary'>789MO124PO</span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='row mt-4 mb-1 justify-content-between'>
                                <h4 className='text-primary'>
                                    <i className='la la-map-marker-alt mr-2 rounded-circle p-2 border-primary bg-primary text-white' style={{fontSize: "30px"}}></i>
                                    {t('common.home-location')}
                                </h4>
                                
                                <div className='d-flex'>
                                    <button className='btn btn-dark btn-sm rounded p-2 mx-1'>
                                        {t('common.home-map')}
                                    </button>
                                    <button className='btn btn-dark btn-sm rounded p-2 mx-1'>
                                        {t('common.home-way')}
                                    </button>
                                </div>
                            </div>

                            <div className='row px-3'>
                                <div className='col-6'>
                                    <div className='row my-3'>
                                        <div className='col-3'>
                                            <b>{t('common.country')}</b>
                                        </div>
                                        <div className='col-9 border-primary border-bottom'>
                                            <span className='text-primary'>{datas.beneficiaire?.country}</span>
                                        </div>
                                    </div>
                                    <div className='row my-3'>
                                        <div className='col-3'>
                                            <b>{t('common.city')}</b>
                                        </div>
                                        <div className='col-9 border-primary border-bottom'>
                                            <span className='text-primary'>{datas.beneficiaire?.city}</span>
                                        </div>
                                    </div>
                                    <div className='row my-3'>
                                        <div className='col-3'>
                                            <b>{t('common.address')}</b>
                                        </div>
                                        <div className='col-9 border-primary border-bottom'>
                                            <span className='text-primary'>{datas.beneficiaire?.childNumber}</span>
                                        </div>
                                    </div>
                                    <div className='row my-3'>
                                        <div className='col-3'>
                                            <b>{t('common.postal-code')}</b>
                                        </div>
                                        <div className='col-9 border-primary border-bottom'>
                                            <span className='text-primary'>{datas.beneficiaire?.postal}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-6'>
                                    <div className='row my-3'>
                                        <div className='col-3'>
                                            <b>{t('common.phone')}</b>
                                        </div>
                                        <div className='col-9 border-primary border-bottom'>
                                            <span className='text-primary'>{datas.beneficiaire?.phoneNumber}</span>
                                        </div>
                                    </div>
                                    <div className='row my-3'>
                                        <div className='col-3'>
                                            <b>Whatsapp</b>
                                        </div>
                                        <div className='col-9 border-primary border-bottom'>
                                            <span className='text-primary'>{datas.beneficiaire?.whatsappPhone}</span>
                                        </div>
                                    </div>
                                    <div className='row my-3'>
                                        <div className='col-3'>
                                            <b>{t('common.email')}</b>
                                        </div>
                                        <div className='col-9 border-primary border-bottom'>
                                            <span className='text-primary'>{datas.beneficiaire?.email}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

const mapStateToProps = (state) => {
    return {
        currentUser: currentUser(state)
    };
};

export default connect(mapStateToProps)(DossierPatient);