
const initialData = {
	administration : {},
    provinces: [],
    typesExclusions: [],
    errorMessage: '',
    successMessage: '',
    showLoading: false,
}

export function AdministrationReducer(state = initialData, action) {
    if (action.type === "ADD_CONFIRMED_ACTION") {
        return {
            ...state,
            errorMessage: '',
	        administration : {
                activities: action.payload.type ===  'activite' || action.payload.type ===  'profession' ? 
                    (action.payload.type ===  'activite' ? 
                        [...state.administration.activities, action.payload] : 
                        state.administration.activities.map(elt => {
                            if(elt.id === action.payload.activityAreaId) {
                                elt.prefession = [...elt.prefession, action.payload];
                            }
                            return elt
                    })) : 
                    state.administration.activities,
                countries: action.payload.type ===  'country' || action.payload.type ===  'city' ? 
                    (action.payload.type ===  'country' ? 
                    [...state.administration.countries, action.payload] : 
                    state.administration.countries.map(elt => {
                        if(elt.id === action.payload.countryId) {
                            elt.city = [...elt.city, action.payload];
                        }
                        return elt
                    })) : 
                    state.administration.countries,
                exclusions: action.payload.type ===  'exclusion' ? 
                    [...state.administration.exclusions, action.payload] : 
                    state.administration.exclusions,
            },
            provinces: action.payload.type ===  'province' ? 
                [...state.provinces, action.payload] : 
                state.provinces,
            typesExclusions: action.payload.type ===  'type-exclusion' ? 
                [...state.typesExclusions, action.payload] : 
                state.typesExclusions,
            successMessage: 'Enregistrement réussi!',
            showLoading: false,
        };
    }
    if (action.type === "GET_CONFIRMED_ACTION") {
        return {
            ...state,
            administration: action.payload,
            showLoading: false,
        };
    }
    if (action.type === "ADD_FAILED_ACTION") {
        return {
            ...state,
            errorMessage: 'Une erreur est survenue',
            successMessage: '',
            showLoading: false,
        };
    }
    if (action.type === "GET_FAILED_ACTION") {
        return {
            ...state,
            errorMessage: 'Une erreur est survenue',
            successMessage: '',
            showLoading: false,
        };
    }
    if (action.type === "GET_DICT_TYPE_EXCL_ACTION") {
        return {
            ...state,
            typesExclusions: action.payload,
            showLoading: false,
        };
    }
    if (action.type === "GET_DICT_PROV_ACTION") {
        return {
            ...state,
            provinces: action.payload,
            showLoading: false,
        };
    }
    if (action.type === "UPDATE_CONFIRMED_ACTION") {
        return {
            ...state,
	        administration : {
                activities: action.payload.type ===  'activite' || action.payload.type ===  'profession' ? 
                    (action.payload.type ===  'activite' ? state.administration.activities.map(elt => {
                        if(elt.id === action.payload.id) {
                            elt.code = action.payload.code;
                            elt.nameFr = action.payload.nameFr;
                            elt.nameEn = action.payload.nameEn;
                        }
                        return elt
                    }) : state.administration.activities.map(elt => {
                        if(elt.id === action.payload.activityAreaId) {
                            elt.prefession.map(prof => {
                                if(prof.id === action.payload.id) {
                                    prof.code = action.payload.code;
                                    prof.nameFr = action.payload.nameFr;
                                    prof.nameEn = action.payload.nameEn;
                                    prof.activityAreaId = action.payload.activityAreaId;
                                }
                                return prof;
                            })
                        }
                        return elt
                    })) : 
                    state.administration.activities,
                countries: action.payload.type ===  'country' || action.payload.type ===  'city' ? 
                    (action.payload.type ===  'country' ? state.administration.countries.map(elt => {
                        if(elt.id === action.payload.id) {
                            elt.code = action.payload.code;
                            elt.name = action.payload.name;
                        }
                        return elt
                    }) : state.administration.countries.map(elt => {
                        if(elt.id === action.payload.countryId) {
                            elt.city.map(prof => {
                                if(prof.id === action.payload.id) {
                                    prof.code = action.payload.code;
                                    prof.name = action.payload.name;
                                    prof.countryId = action.payload.countryId;
                                }
                                return prof;
                            })
                        }
                        return elt
                    })) : 
                    state.administration.countries,
                exclusions: action.payload.type ===  'exclusion' ? 
                    state.administration.exclusions.map(elt => {
                        if(elt.id === action.payload.id) {
                            elt.code = action.payload.code;
                            elt.nameFr = action.payload.nameFr;
                            elt.nameEn = action.payload.nameEn;
                        }
                        return elt
                    }) : 
                    state.administration.exclusions,
            },
            provinces: action.payload.type ===  'province' ? 
                state.provinces.map(elt => {
                    if(elt.id === action.payload.id) {
                        elt.code = action.payload.code;
                        elt.nameFr = action.payload.nameFr;
                        elt.nameEn = action.payload.nameEn;
                        elt.reference = action.payload.reference;
                        elt.parent = action.payload.parent;
                    }
                    return elt
                }) : 
                state.provinces,
            typesExclusions: action.payload.type ===  'type-exclusion' ? 
                state.typesExclusions.map(elt => {
                    if(elt.id === action.payload.id) {
                        elt.code = action.payload.code;
                        elt.nameFr = action.payload.nameFr;
                        elt.nameEn = action.payload.nameEn;
                        elt.reference = action.payload.reference;
                        elt.parent = action.payload.parent;
                    }
                    return elt
                }) : 
                state.typesExclusions,
            successMessage: 'Enregistrement réussi!',
            showLoading: false,
        };
    }
    if (action.type === "UPDATE_FAILED_ACTION") {
        return {
            ...state,
            errorMessage: 'Une erreur est survenue',
            successMessage: '',
            showLoading: false,
        };
    }
    if (action.type === "DELETE_CONFIRMED_ACTION") {
        console.log(action.payload)
        return {
            ...state,
	        administration : {
                activities: action.payload.type ===  'activite' || action.payload.type ===  'profession' ? 
                    (action.payload.type ===  'activite' ? 
                        state.administration.activities.filter(elt => elt.id !== action.payload.id) :
                        state.administration.activities.map(elt => {
                            if(elt.id === action.payload.activityAreaId) {
                                elt.prefession = elt.prefession.filter(prof => prof.id !== action.payload.id)
                            }
                            return elt
                        })
                    ) : 
                    state.administration.activities,
                countries: action.payload.type ===  'country' || action.payload.type ===  'city' ? 
                    (action.payload.type ===  'country' ? 
                        state.administration.countries.filter(elt => elt.id !== action.payload.id) : 
                        state.administration.countries.map(elt => {
                            if(elt.id === action.payload.countryId) {
                                elt.city = elt.city.filter(prof => prof.id !== action.payload.id);
                            }
                            return elt
                        })
                    ) : 
                    state.administration.countries,
                exclusions: action.payload.type ===  'exclusion' ? 
                    state.administration.exclusions.filter(elt => elt.id !== action.payload.id) : 
                    state.administration.exclusions,
            },
            provinces: action.payload.type ===  'province' ? 
                state.provinces.filter(elt => elt.id !== action.payload.id) : 
                state.provinces,
            typesExclusions: action.payload.type ===  'type-exclusion' ? 
                state.typesExclusions.filter(elt => elt.id !== action.payload.id) : 
                state.typesExclusions,
            successMessage: 'Suppression réussi!',
            showLoading: false,
        };
    }
    if (action.type === "DELETE_FAILED_ACTION") {
        return {
            ...state,
            errorMessage: 'Une erreur est survenue',
            successMessage: '',
            showLoading: false,
        };
    }
    if (action.type === "LOADING_TOGGLE_ACTION") {
        return {
            ...state,
            showLoading: true,
        };
    }
    return state;
}

    
