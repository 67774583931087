import { START_LOADER_ACTION,CREATE_CONFIRMED_ACTION, CREATE_FAILED_ACTION, 
    GET_PROFILS_CONFIRMED_ACTION, GET_USERS_CONFIRMED_ACTION,
    GET_ACTIONS_CONFIRMED_ACTION, UPDATE_CONFIRMED_ACTION, UPDATE_FAILED_ACTION,
    DELETE_CONFIRMED_ACTION, DELETE_FAILED_ACTION, GET_ROLES_CONFIRMED_ACTION,
    GET_MENUS_CONFIRMED_ACTION, CREATE_MENU_ROLE_ACTIONS_CONFIRMED_ACTION, RESET_SAVE_DONE_ACTION,
    GET_USER_PARAMETER_CONFIRMED_ACTION, RESET_PASSWORD_FAILED_ACTION, RESET_PASSWORD_CONFIRMED_ACTION,
    RESEND_PARAMS_CONFIRMED_ACTION, RESEND_PARAMS_FAILED_ACTION, GET_CODES_VALIDATION_CONFIRMED_ACTION,
    GET_CODES_VALIDATION_FAILED_ACTION
 } from "../actions/UserAction";

const initialData = {
	users : [],
    profils : [],
    menus :[],
    roles :[],
    roleDatas: [],
    menusDatas: [],
    actions: [],
    codesValidation: [],
    errorMessage: '',
    successMessage: '',
    deleteDone: false,
    saveDone: false,
    showLoading: false,
}

export function UserReducer(state = initialData, action) {
    switch (action.type) {
        case  GET_USERS_CONFIRMED_ACTION :
        return {
            ...state,
            users: action.payload,
            showLoading: false,
        };
    
    case GET_PROFILS_CONFIRMED_ACTION:
        return {
            ...state,
            profils: action.payload,
            showLoading: false,
        };
    
    case GET_USER_PARAMETER_CONFIRMED_ACTION:
        return {
            ...state,
            profils: action.payload.profils,
            menus: action.payload.menus,
            roles: action.payload.roles,
            showLoading: false,
        };
    
    case GET_ACTIONS_CONFIRMED_ACTION:
        return {
            ...state,
            actions: action.payload,
            showLoading: false,
        };
    
    case GET_ROLES_CONFIRMED_ACTION:
        return {
            ...state,
            roleDatas: action.payload,
            showLoading: false,
        };
    
    case GET_MENUS_CONFIRMED_ACTION:
        return {
            ...state,
            menusDatas: action.payload,
            showLoading: false,
        };
    case CREATE_CONFIRMED_ACTION:
            return {
                ...state,
                users: action.payload.typeData === 'user' ? [...state.users, action.payload] : state.users,
                profils: action.payload.typeData === 'profil' ? [...state.profils, action.payload] : state.profils,
                menus: action.payload.typeData === 'menu' ? [...state.menus, action.payload] : state.menus,
                roles: action.payload.typeData === 'role' ? [...state.roles, action.payload] : state.roles,
                roleDatas: action.payload.typeData === 'roleData' ? [...state.roleDatas, action.payload] : state.roleDatas,
                menusDatas: action.payload.typeData === 'menuData' ? [...state.menusDatas, action.payload] : state.menusDatas,
                actions: action.payload.typeData === 'action' ? [...state.actions, action.payload] : state.actions,
                successMessage: 'Data Successfully Create'+action.payload,
                showLoading: false,
            };
    case CREATE_FAILED_ACTION:
            return {
                ...state,
                errorMessage: action.payload,
                showLoading: false,
            };
    case UPDATE_CONFIRMED_ACTION:
            return {
                ...state,
                users: action.payload.typeData === 'user' ? state.users.map(user => {
                    if(user.id === action.payload.id) {
                        user = {...user, ...action.payload}
                    }
                    return user
                }) : state.users,
                profils: action.payload.typeData === 'profil' ? state.profils.map(user => {
                    if(user.id === action.payload.id) {
                        user = {...user, ...action.payload}
                    }
                    return user
                }) : state.profils,
                menus: action.payload.typeData === 'menu' ? state.menus.map(user => {
                    if(user.id === action.payload.id) {
                        user = {...user, ...action.payload}
                    }
                    return user
                }) : state.menus,
                roles: action.payload.typeData === 'role' ? state.roles.map(user => {
                    if(user.id === action.payload.id) {
                        user = {...user, ...action.payload}
                    }
                    return user
                }) : state.roles,
                roleDatas: action.payload.typeData === 'roleData' ? state.roleDatas.map(user => {
                    if(user.id === action.payload.id) {
                        user = {...user, ...action.payload}
                    }
                    return user
                }) : state.roleDatas,
                menusDatas: action.payload.typeData === 'menuData' ? state.menusDatas.map(user => {
                    if(user.id === action.payload.id) {
                        user = {...user, ...action.payload}
                    }
                    return user
                }) : state.menusDatas,
                actions: action.payload.typeData === 'menuData' ? state.actions.map(user => {
                    if(user.id === action.payload.id) {
                        user = {...user, ...action.payload}
                    }
                    return user
                }) : state.actions,
                successMessage: 'Data Successfully Updated'+action.payload,
                showLoading: false,
            };
    case UPDATE_FAILED_ACTION:
            return {
                ...state,
                errorMessage: action.payload,
                showLoading: false,
            };
    case DELETE_CONFIRMED_ACTION:
            return {
                ...state,
                users: action.payload.typeData === 'user' ? state.users.filter(user => user.id !== action.payload.id) : state.users,
                profils: action.payload.typeData === 'profil' ? state.profils.filter(user => user.id !== action.payload.id) : state.profils,
                menus: action.payload.typeData === 'menu' ? state.menus.filter(user => user.id !== action.payload.id) : state.menus,
                roles: action.payload.typeData === 'role' ? state.roles.filter(user => user.id !== action.payload.id) : state.roles,
                roleDatas: action.payload.typeData === 'roleData' ? state.roleDatas.filter(user => user.id !== action.payload.id) : state.roleDatas,
                menusDatas: action.payload.typeData === 'menuData' ? state.menusDatas.filter(user => user.id !== action.payload.id) : state.menusDatas,
                actions: action.payload.typeData === 'action' ? state.actions.filter(user => user.id !== action.payload.id) : state.menusDatas,
                successMessage: 'Data Successfully Deleted'+action.payload,
                showLoading: false,
            };
    case DELETE_FAILED_ACTION:
            return {
                ...state,
                errorMessage: action.payload,
                showLoading: false,
            };  
    case CREATE_MENU_ROLE_ACTIONS_CONFIRMED_ACTION:
            return {
                ...state,
                successMessage: 'Menu roles actions successfully associated'+action.payload,
                showLoading: false,
            };
    case RESET_PASSWORD_FAILED_ACTION:
            return {
                ...state,
                errorMessage: action.payload,
                showLoading: false,
            };  
    case RESET_PASSWORD_CONFIRMED_ACTION:
            return {
                ...state,
                successMessage: 'User password successfully reset'+action.payload,
                showLoading: false,
            };   
    case RESEND_PARAMS_FAILED_ACTION:
            return {
                ...state,
                errorMessage: action.payload,
                showLoading: false,
            };  
    case RESEND_PARAMS_CONFIRMED_ACTION:
            return {
                ...state,
                successMessage: 'User params successfully sent'+action.payload,
                showLoading: false,
            };   
    case GET_CODES_VALIDATION_CONFIRMED_ACTION:
            return {
                ...state,
                codesValidation: action.payload,
                errorMessage: action.payload,
                showLoading: false,
            };  
    case GET_CODES_VALIDATION_FAILED_ACTION:
            return {
                ...state,
                successMessage: 'Codes validation could not be retrieved'+action.payload,
                showLoading: false,
            };   
    case START_LOADER_ACTION:
            return {
                ...state,
                showLoading: true,
            };  
    case RESET_SAVE_DONE_ACTION:
            return {
                ...state,
                saveDone: false,
            };
    default:
            return state;
        };
}