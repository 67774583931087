import React, {useState,useEffect } from 'react'
import { connect, useDispatch } from 'react-redux';
import { Modal } from 'react-bootstrap';
import BasicDatatable from '../DataTable';
import { Link } from "react-router-dom";
import {
    getProviderActeurAction,
    startLoader,
    createProviderActeurAction,
} from '../../../../store/actions/ProviderActions';
import { useTranslation } from "react-i18next";
const ProviderType = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    let errorsObj = { name: '', code: '' };
    const [nameFr, setNameFr] = useState('');
    const [nameEn, setNameEn] = useState('');
    const [code, setCode] = useState('');
    const [errors, setErrors] = useState(errorsObj);
    //Modal box
    const [addCard, setAddCard] = useState(false);
    const header =["Code","Nom Fr","Nom En","Date de création","Action"]
    useEffect(() => {
        dispatch(getProviderActeurAction());    
    }, []);
    function onProviderActeurSave(e) {
        e.preventDefault();
        let error = false;
        const errorObj = { ...errorsObj };
        if (code === '') {
            errorObj.code =t('common.code-required');
            error = true;
        }
        if (nameFr === '') {
            errorObj.name =t('common.name-required');
            error = true;
        }
        if (nameEn === '') {
            errorObj.name =t('common.name-required');
            error = true;
        }
        setErrors(errorObj);
        if (error) {
            swal('Oops', t('common.check-form-fields'), "infos");
			return ;
		}
        dispatch(startLoader());
        
        let obj = { nameFr: nameFr,nameEn: nameEn, code: code };
        dispatch(createProviderActeurAction(obj));
    }
    const tableBody = props.acteurs?.length > 0 ?  props.acteurs?.map(key => {
        return (
          <tr key={key.id}>
            <td>{key.code}</td>
            <td>{key.nameFr}</td>
            <td>{key.nameEn}</td>
            <td>{key.created}</td>
            <td>
              <span>
                <Link href="#" to="#" className="mr-4" title="Edit">
                  <i className="fa fa-pencil color-muted"></i>{" "}
                </Link>
                <Link href="#" to="#" title="Close">
                  <i className="fa fa-close color-danger"></i>
                </Link>
              </span>
            </td>
          </tr>
        );
      }):[];
    return (
        <>

            <div className="form-head d-flex mb-3 mb-md-4 align-items-start">
                <div className="mr-auto d-none d-lg-block">
                    <Link
                        to="#"
                        className="btn btn-primary btn-rounded"
                        onClick={() => setAddCard(true)}
                    >
                        + Add New
                    </Link>
                </div>
                <div className="input-group search-area ml-auto d-inline-flex mr-3">
                    <input
                        type="text"
                        className="form-control"
                        placeholder="Search here"
                    />
                    <div className="input-group-append">
                        <button type="button" className="input-group-text">
                            <i className="flaticon-381-search-2" />
                        </button>
                    </div>
                </div>
                <Link to="#" className="settings-icon">
                    <i className="flaticon-381-settings-2 mr-0" />
                </Link>
            </div>
            <Modal className="modal fade" show={addCard} onHide={setAddCard} >
            <div className="">
                <div className="card">
                    <div className="card-header">
                        <h4 className="card-title">Créer un nouveau type de Personnel</h4>
                    </div>
                    <div className='card-body'>
                        <div className='basic-form'>
                            <form onSubmit={onProviderActeurSave}>
                                <div className='form-group row'>
                                    <label className='col-sm-3 col-form-label'>{t('common.code')} du personnel</label>
                                    <div className='col-sm-9'>
                                        <input
                                            type='text'
                                            name='code'
                                            className='form-control'
                                            placeholder='MD'
                                            onChange={(e) => setCode(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className='form-group row'>
                                    <label className='col-sm-3 col-form-label'>{t('common.label')} Fr</label>
                                    <div className='col-sm-9'>
                                        <input
                                            type='text'
                                            name='nameFr'
                                            className='form-control'
                                            placeholder='Médecin'
                                            onChange={(e) => setNameFr(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className='form-group row'>
                                    <label className='col-sm-3 col-form-label'>{t('common.label')} En</label>
                                    <div className='col-sm-9'>
                                        <input
                                            type='text'
                                            name='nameEn'
                                            className='form-control'
                                            placeholder='Doctor'
                                            onChange={(e) => setNameEn(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <hr />
                                <div className='form-group align-items-center justify-content-center'>
                                    <div className=''>
                                        <button type='submit' className='btn btn-primary'>
                                          {t('common.save')}
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            </Modal>
            <BasicDatatable header={header} body={tableBody}></BasicDatatable>
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        acteurs: state.provider.acteurs,
        errorMessage: state.clientAuth.errorMessage,
        successMessage: state.clientAuth.successMessage,
        showLoading: state.provider.showLoading,
    };
};

export default connect(mapStateToProps)(ProviderType);