import {
    GET_RAPPORT_CONFIRMED_ACTION,
    GET_RAPPORT_FAILED_ACTION,
    LOADING_TOGGLE_ACTION,
    GET_PATOLOGIE_AMBU_CONFIRMED_ACTION,
    GET_PATOLOGIE_HOSPI_CONFIRMED_ACTION,
    GET_PRESTATION_AMBU_CONFIRMED_ACTION,
    GET_PRESTATION_HOSPI_CONFIRMED_ACTION,
    GET_TOP_DENTISTE_CONFIRMED_ACTION,
    GET_TOP_PHARMACIE_CONFIRMED_ACTION,
    GET_TOP_OPTIQUE_CONFIRMED_ACTION,
    GET_RAPPORT_FINANCE_CONFIRMED_ACTION,
    GET_RAPPORT_DEPENSE_FAMILLE_CONFIRMED_ACTION,
    GET_GLOBAL_CLIENT_STATS_CONFIRMED_ACTION,
    GET_DETAILDED_CLIENT_STATS_CONFIRMED_ACTION,
    GET_VISITS_CLIENT_STATS_CONFIRMED_ACTION,
    GET_CONSOMMATIONS_CLIENT_STATS_CONFIRMED_ACTION,
    GET_TOP_CONSOMMATIONS_CLIENT_STATS_CONFIRMED_ACTION
} from '../actions/RapportAction';

const initialState = {
    dataDepenseFami: {},
    dataAmbuSous: [],
    prestAmbu: [],
    prestHospi: [],
    patoAmbu: [],
    patoHospi: [],
    topPharmacies: [],
    topDentistes: [],
    topOptiques: [],
    rapportFinance: [],
    globalClientStats: {},
    detailedClientStats: {},
    visitsClientsStats: [],
    consommationsClientStats: [],
    topConsommationsClientStats: [],
    errorMessage: '',
    successMessage: '',
    showLoading: false,
};

export const RapportReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_RAPPORT_CONFIRMED_ACTION:
            return {
                ...state,
                dataAmbuSous: action.payload,
                errorMessage: '',
                successMessage: 'Notifications listing Successfully Completed',
                showLoading: false,
            };
        case GET_RAPPORT_FINANCE_CONFIRMED_ACTION:
            return {
                ...state,
                rapportFinance: action.payload,
                errorMessage: '',
                successMessage: 'Notifications listing Successfully Completed',
                showLoading: false,
            };
        case GET_PRESTATION_AMBU_CONFIRMED_ACTION:
            return {
                ...state,
                prestAmbu: action.payload,
                errorMessage: '',
                successMessage: 'Notifications listing Successfully Completed',
                showLoading: false,
        }; 
        case GET_PRESTATION_HOSPI_CONFIRMED_ACTION:
            return {
                ...state,
                prestHospi: action.payload,
                errorMessage: '',
                successMessage: 'Notifications listing Successfully Completed',
                showLoading: false,
        }; 
        case GET_PATOLOGIE_AMBU_CONFIRMED_ACTION:
            return {
                ...state,
                patoAmbu: action.payload,
                errorMessage: '',
                successMessage: 'Notifications listing Successfully Completed',
                showLoading: false,
            };
        case GET_PATOLOGIE_HOSPI_CONFIRMED_ACTION:
            return {
                ...state,
                patoHospi: action.payload,
                errorMessage: '',
                successMessage: 'Notifications listing Successfully Completed',
                showLoading: false,
            };  
        case GET_TOP_DENTISTE_CONFIRMED_ACTION:
            return {
                ...state,
                topDentistes: action.payload,
                errorMessage: '',
                successMessage: 'Notifications listing Successfully Completed',
                showLoading: false,
            };   
        case GET_TOP_OPTIQUE_CONFIRMED_ACTION:
            return {
                ...state,
                topOptiques: action.payload,
                errorMessage: '',
                successMessage: 'Notifications listing Successfully Completed',
                showLoading: false,
            };
        case GET_RAPPORT_DEPENSE_FAMILLE_CONFIRMED_ACTION:
            return {
                ...state,
                dataDepenseFami: action.payload,
                errorMessage: '',
                successMessage: 'Notifications listing Successfully Completed',
                showLoading: false,
            }; 
        case GET_TOP_PHARMACIE_CONFIRMED_ACTION:
            return {
                ...state,
                topPharmacies: action.payload,
                errorMessage: '',
                successMessage: 'Notifications listing Successfully Completed',
                showLoading: false,
            }; 
        case GET_GLOBAL_CLIENT_STATS_CONFIRMED_ACTION:
            return {
                ...state,
                globalClientStats: action.payload,
                errorMessage: '',
                successMessage: 'Notifications listing Successfully Completed',
                showLoading: false,
            }; 
        case GET_DETAILDED_CLIENT_STATS_CONFIRMED_ACTION:
            return {
                ...state,
                detailedClientStats: action.payload,
                successMessage: 'Notifications listing Successfully Completed',
                showLoading: false,
            }; 
        case GET_VISITS_CLIENT_STATS_CONFIRMED_ACTION:
            return {
                ...state,
                visitsClientsStats: action.payload,
                successMessage: 'Notifications listing Successfully Completed',
                showLoading: false,
            }; 
        case GET_CONSOMMATIONS_CLIENT_STATS_CONFIRMED_ACTION:
            return {
                ...state,
                consommationsClientStats: action.payload,
                successMessage: 'Notifications listing Successfully Completed',
                showLoading: false,
            }; 
        case GET_TOP_CONSOMMATIONS_CLIENT_STATS_CONFIRMED_ACTION:
            return {
                ...state,
                topConsommationsClientStats: action.payload,
                successMessage: 'Notifications listing Successfully Completed',
                showLoading: false,
            };      
        case GET_RAPPORT_FAILED_ACTION:
            return {
                ...state,
                errorMessage: action.payload,
                successMessage: '',
                showLoading: false,
            };

        case LOADING_TOGGLE_ACTION:
            return {
                ...state,
                showLoading: true,
            };
        default:
          return state;
    };
};