import { connect, useDispatch } from 'react-redux';
import React, {Fragment, useEffect, useState, useRef} from "react";
import { Link, useParams } from "react-router-dom";
import { Row, Col, Card, Accordion, Button, Spinner, Badge, Modal } from 'react-bootstrap';
import { validateProformaLineAction, loadingToggleAction, validateProformaAction, rejectProformaAction, getProformaAction, restoreProformaAction } from '../../../../store/actions/AccordPreaActions';
import { getMotifsExclusionAction } from "../../../../store/actions/FacturationAction";
import { currentUser } from "../../../../store/selectors/CurrentUserSelector";
import { getProviderDataAction } from '../../../../store/actions/ProviderActions';
import { useReactToPrint } from 'react-to-print';
import MotifRejetModal from "./MotifRejetModal";
import { STATUSES } from '../../../globals';
import { useTranslation } from "react-i18next";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

class FactureTemplate extends React.Component {

    state = {
        currentKey: ""
    }

    manageCommentModal = () => {
        this.setState(state => ({...state, currentKey: ''}));
    }

    cancelDeletion = (lineId) => {
        this.props.dispatch(loadingToggleAction());
        this.props.dispatch(validateProformaLineAction(lineId));
    }

    render() {
        return (<div className="row">
                    <div className="col-lg-12">
                        <div className="card mt-3">
                        <div className="card-header">
                            {" "}
                            <strong>{t('common.proforma-header')} {this.props.facture.beneficiaryName}</strong>{" "}
                            <strong>{t('common.prescribe-of')}{this.props.facture.prescription?.consultation?.doctorName}</strong>{" "}
                            <span className="float-right">
                                <strong>{t('common.status')}:</strong> <span>{this.props.t('statuses.'+this.props.facture.status)}</span>
                            </span>{" "}
                        </div>
                        <div className="card-body">
                            {this.props.facture.motifExclusion && 
                                <p className='text-danger'>{this.props.facture.motifExclusion}</p>
                            }
                            <div className="row mb-5">
                            <div className="mt-4 col-xl-6 col-lg-6 col-md-6 col-sm-12">
                                <h6>{t('common.from')}:</h6>
                                <div>
                                {" "}
                                <strong>{this.props.provider.providerName}</strong>{" "}
                                </div>
                                <div>{this.props.provider.city?.name}, {this.props.provider.country?.name}</div>
                                <div>{t('common.email')}: {this.props.provider.email}</div>
                                <div>{t('common.phone')}: +{this.props.provider.phoneNumber}</div>
                            </div>
                            <div className="mt-4 col-xl-6 col-lg-6 col-md-6 col-sm-12 align-items-end justify-content-end">
                                <h6>À:</h6>
                                <div>
                                {" "}
                                <strong>{t('common.advisor-text')}</strong>{" "}
                                </div>
                                <div>{t('common.paymet-service')}</div>
                                <div>Rue Prince de Galles - Douala, Cameroun</div>
                                <div>{t('common.email')}: paiement@group-active.com</div>
                                <div>{t('common.phone')}: +237692880000</div>
                            </div>
                            </div>
                            <div className='row'>
                                <div className='col-4'>
                                    <b>{t('consultation.ecart-pupil')}: <span className='text-primary'>{this.props.facture.ecartPupillaire}</span></b>
                                </div>
                                <div className='col-4'>
                                    <b>{t('consultation.vl-left')}: <span className='text-primary'>{this.props.facture.vlog}</span></b>
                                </div>
                                <div className='col-4'>
                                    <b>{t('consultation.vl-right')}: <span className='text-primary'>{this.props.facture.vlod}</span></b>
                                </div>
                                <div className='col-4'>
                                    <b>{t('consultation.vp-left')}: <span className='text-primary'>{this.props.facture.vpog}</span></b>
                                </div>
                                <div className='col-4'>
                                    <b>{t('consultation.vp-right')}: <span className='text-primary'>{this.props.facture.vpod}</span></b>
                                </div>
                            </div>
                            <div className="table-responsive">
                            <table className="table table-striped">
                                <thead>
                                    <tr>
                                        <th className="center">#</th>
                                        <th>{t('common.label')}</th>
                                        <th className="right">{t('common.price')}</th>
                                        <th className="right">{t('common.status')}</th>
                                        <th className="right">Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.props.facture.opticBonDetails.map((data, i) => { 
                                        return (
                                            <tr>
                                                <td className="center">{i}</td>
                                                <td className="left strong">
                                                    {data.acteName}<br/>
                                                    <span className='text-danger'>{data.motifExclusion}</span>
                                                </td>
                                                <td className="right">{data.amount}</td>
                                                <td className="center">{this.props.t('statuses.'+data.status)}</td>
                                                <td className="center">
                                                    {!data.motifExclusion && !this.props.facture.isDelivered &&
                                                        <>
                                                            <button
                                                                onClick={e => this.setState(state => ({...state, currentKey: data.id}))}
                                                                className="btn btn-danger btn-rounded mr-2"
                                                            >
                                                                <i className="fa fa-trash"></i>
                                                            </button>
                                                            <Modal
                                                                className="fade bd-example-modal-lg"
                                                                show={this.state.currentKey === data.id}
                                                                size="lg"
                                                            >
                                                                <MotifRejetModal 
                                                                    showModal={this.state.currentKey === data.id}
                                                                    acte={data}
                                                                    manageCommentModal={this.manageCommentModal}
                                                                    history={this.props.history}
                                                                    exclusions={this.props.motifExclusion}
                                                                    motifRejet={data.motifRejet}
                                                                    isDetail={true}
                                                                />
                                                            </Modal>
                                                        </>
                                                    }
                                                    {data.motifExclusion && !this.props.facture.isDelivered &&
                                                        <button
                                                            onClick={e => this.cancelDeletion(data.id)}
                                                            className="tooltip-test btn btn-success btn-rounded mr-2"
                                                            data-toggle="tooltip"
                                                            title={data.motifRejet}
                                                        >
                                                            <i className="la la-trash-restore"></i>
                                                        </button>
                                                    }
                                                </td>
                                            </tr>
                                        )
                                    })
                                    }
                                </tbody>
                            </table>
                            </div>
                            <div className="row">
                            <div className="col-lg-4 col-sm-5"> </div>
                            <div className="col-lg-4 col-sm-5 ml-auto">
                                <table className="table table-clear">
                                <tbody>
                                    <tr>
                                        <td className="left">
                                            <strong>{t('common.total')}</strong>
                                        </td>
                                        <td className="right">{this.props.facture.amount}</td>
                                    </tr>
                                    <tr>
                                        <td className="left">
                                            <strong>{t('common.tm')}</strong>
                                        </td>
                                        <td className="right">{this.props.facture.tm} %</td>
                                    </tr>
                                    <tr>
                                    <td className="left">
                                        <strong>{t('common.total-patient-amount')}</strong>
                                    </td>
                                    <td className="right">
                                        <strong>{this.props.facture.amountTM}</strong>
                                    </td>
                                    </tr>
                                </tbody>
                                </table>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
            </div>
        );
    };

}

const ProformaView = (props) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { id, providerId } = useParams();
    const [currentKey, setCurrentKey] = useState("");
    const [opticDatas, setOpticDatas] = useState([]);
    const { proformaData, currentUser, history, errorMessage, successMessage, showLoading, motifExclusion, provider } = props;

    const componentRef = useRef();

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    const acteBodyTemplate = (rowData) => {
        return rowData.subDataVl ? 
                <>
                    <span>{rowData.acte}</span><br/>
                    <small className='text-primary'>{t('common.from-far')}: {rowData.subDataVl}</small><br/>
                    <small className='text-primary'>{t('common.from-near')}: {rowData.subDataVp}</small>
                </> :
                rowData.acte;
    };

    const actionsBodyTemplate = (rowData) => {
        return  <>
                    {(rowData.acte === "Total" || rowData.acte === "Monture") && <>
                        {!rowData.isRejet && <button
                            onClick={e => setCurrentKey(rowData.acte)}
                            className="btn btn-danger p-2 mr-2"
                        >
                            <i className="fa fa-trash"></i>
                        </button>}
                        <Modal
                            className="fade bd-example-modal-lg"
                            show={currentKey === rowData.acte}
                            size="lg"
                        >
                            <MotifRejetModal 
                                showModal={currentKey === rowData.acte}
                                acte={rowData}
                                manageCommentModal={manageCommentModal}
                                history={history}
                                exclusions={motifExclusion}
                                motifRejet={rowData.motifRejet}
                                total={rowData.prixPrestataire}
                                isDetail={true}
                            />
                        </Modal>
                        {rowData.isRejet && <button
                            onClick={e => this.cancelDeletion(data.id)}
                            className="tooltip-test btn btn-success p-2 mr-2"
                            data-toggle="tooltip"
                            title={rowData.motifRejet}
                        >
                            <i className="la la-trash-restore"></i>
                        </button>}
                    </>}
                </>
            
    };

    const canDo = (menuCode, actionCode) => {
        let theMenu = props.currentUser.menus?.find(menu => menu.code === menuCode);
        if(!theMenu){
            return false;
        }else{
            return theMenu.actionMenus?.filter(action => action.code === actionCode).length > 0;
        }
    }
    
    const hasMenu = (menuCode) => {
        return props.currentUser.menus?.filter(menu => menu.code === menuCode).length > 0;
    }

    useEffect(() => {
        if(hasMenu('MENPROFORMA') && canDo('MENPROFORMA', 'VIEWPROFORMA')){
            dispatch(loadingToggleAction());
            dispatch(getProformaAction(id));
            dispatch(getProviderDataAction(providerId));
            dispatch(getMotifsExclusionAction());
        }else{
            props.history.push('/access-denied');
        }
    }, []);

    useEffect(() => {
        dispatch(loadingToggleAction());
        dispatch(getProformaAction(id));
    }, [successMessage, errorMessage]);

    useEffect(() => {
        if(Object.keys(proformaData).length > 0) {
            let vlod = proformaData.opticBon.prescriptionEyes.find(elt => elt.visionEye === 'VLOD');
            let vlog = proformaData.opticBon.prescriptionEyes.find(elt => elt.visionEye === 'VLOG');
            let vpod = proformaData.opticBon.prescriptionEyes.find(elt => elt.visionEye === 'VPOD');
            let indiceOdVl = vlod.sphere+'('+vlod.cylindre+' à '+vlod.axe+'°)';
            let indiceOgVl = vlog.sphere+'('+vlog.cylindre+' à '+vlog.axe+'°)';
            let indiceVp = vpod.sphere+' ADD';
            setOpticDatas([
                {category: t('common.glasses'), acte: t('common.right-eye'), subDataVl: indiceOdVl, subDataVp: indiceVp, prixActiva: proformaData.proformat.OeilDroit.PrixActiva || 0, prixPrestataire: proformaData.proformat.OeilDroit.PrixPrestataire},
                {category: t('common.glasses'), acte: t('common.left-eye'), subDataVl: indiceOgVl, subDataVp: indiceVp, prixActiva: proformaData.proformat.OeilGauche.PrixActiva || 0, prixPrestataire: proformaData.proformat.OeilGauche.PrixPrestataire},
                {category: t('common.glasses'), acte: t('common.treatment'), acte: t('common.antireflet'), prixActiva: proformaData.proformat.Traitement.PrixActiva || 0, prixPrestataire: proformaData.proformat.Traitement.PrixPrestataire},
                {category: t('common.glasses'), acte: t('common.total'), subData: null, prixActiva: proformaData.totalVerreActiva || 0, prixPrestataire: proformaData.totalVerrePrestataire, acteId: proformaData.opticBon?.opticBonDetails[0]?.id, acteName: proformaData.opticBon?.opticBonDetails[0]?.acteName, motifRejet: proformaData.opticBon?.opticBonDetails[0]?.motifExclusion, montantRejet: proformaData.opticBon?.opticBonDetails[0]?.amountExclu, isRejet: proformaData.opticBon?.opticBonDetails[0]?.amountExclu > 0},
                {category: t('common.monture'), acte: t('common.monture'), subData: null, prixActiva: proformaData.proformat.Monture.PrixActiva || 0, prixPrestataire: proformaData.proformat.Monture.PrixPrestataire, acteId: proformaData.opticBon?.opticBonDetails[1]?.id, acteName: proformaData.opticBon?.opticBonDetails[1]?.acteName, motifRejet: proformaData.opticBon?.opticBonDetails[1]?.motifExclusion, montantRejet: proformaData.opticBon?.opticBonDetails[1]?.amountExclu, isRejet: proformaData.opticBon?.opticBonDetails[1]?.amountExclu > 0},
            ])
        }
    }, [proformaData]);
    
    const manageCommentModal = () => {
       setCurrentKey(false);
    }

    return (
        <Fragment>
            <div className="bg-white p-5">
                {Object.keys(proformaData).length > 0 && 
                    <>
                        {/*<div className='row justify-content-end my-4'>
                            <div className='col-2'>
                                <button onClick={e => handlePrint()} type='button' className='btn btn-primary btn-block'>
                                    {t('common.print')}
                                </button>
                            </div>
                        </div>
                        <FactureTemplate facture={proformaData} currentUser={currentUser} ref={componentRef} t={t} motifExclusion={motifExclusion} provider={provider} dispatch={dispatch} />*/}
                        <h2 className='text-center mb-5'>{t('common.proforma-title')}</h2>
                        <h4 className='text-center'>
                            <b className='text-warning text-center'>{t('statuses.'+proformaData.opticBon.status)}</b>
                        </h4>
                        {proformaData.motifRejet && proformaData.motifRejet.length > 0 && <p className='text-center'>
                            <b className='text-danger text-center'>{proformaData.motifRejet}</b>
                        </p>}
                        <div className='row'>
                            <div className='col-6'>
                                <h4 className='mt-2 text-dark text-start'>
                                    <span style={{textDecoration: 'underline'}}>{t('common.executant')}:</span>
                                    <span className='text-bold text-primary ml-2'>{proformaData.proformat.ProviderName}</span>
                                </h4>
                                <h4 className='mt-2 text-dark text-start'>
                                    <span style={{textDecoration: 'underline'}}>{t('common.submission-date')}:</span>
                                    <span className='text-bold text-primary ml-2'>{proformaData.createdAt}</span>
                                </h4>
                            </div>
                            <div className='col-6 text-right'>
                                <h4 className='mt-2 text-dark text-start'>
                                    <span style={{textDecoration: 'underline'}}>{t('common.patient')}:</span>
                                    <span className='text-bold text-primary ml-2'>{proformaData.opticBon.beneficiaryName}</span>
                                </h4>
                                <h4 className='mt-2 text-dark text-start'>
                                    <span style={{textDecoration: 'underline'}}>{t('common.prescriptor')}:</span>
                                    <span className='text-bold text-primary ml-2'>{proformaData.opticBon.providerName}</span>
                                </h4>
                            </div>
                        </div>
                        <div className="border-top my-2"></div>

                        <div className='row'>
                            <div className='form-group col-4'>
                                <label className='mb-1 '>
                                    <strong>{t('consultation.ecart-pupil')}: </strong>
                                    <span className='text-primary'>
                                        {proformaData.opticBonDetailCaracteristique.find(elt => elt.typeCaracteristique === 'ecartPupillaire')?.valeurCaracteristique}
                                    </span>
                                </label>
                            </div>
                            <div className='form-group col-4'>
                                <label className='mb-1 '>
                                    <strong>{t('client.type')}: </strong>
                                    <span className='text-primary'>
                                        {proformaData.opticBonDetailCaracteristique.find(elt => elt.typeCaracteristique === 'typeVerre')?.valeurCaracteristique}
                                    </span>
                                </label>
                            </div>
                            <div className='form-group col-4'>
                                <label className='mb-1 '>
                                    <strong>{t('common.matiere')}: </strong>
                                    <span className='text-primary'>
                                        {proformaData.opticBonDetailCaracteristique.find(elt => elt.typeCaracteristique === 'baseVerre')?.valeurCaracteristique}
                                    </span>
                                </label>
                            </div>
                            <div className='form-group col-4'>
                                <label className='mb-1 '>
                                    <strong>{t('common.teinte')}: </strong>
                                    <span className='text-primary'>
                                        {proformaData.opticBonDetailCaracteristique.find(elt => elt.typeCaracteristique === 'teinteVerre')?.valeurCaracteristique}
                                    </span>
                                </label>
                            </div>
                            <div className='form-group col-4'>
                                <label className='mb-1 '>
                                    <strong>{t('common.treatment')}: </strong>
                                    <span className='text-primary'>
                                        {proformaData.opticBonDetailCaracteristique.find(elt => elt.typeCaracteristique === 'traimentVerre')?.valeurCaracteristique}
                                    </span>
                                </label>
                            </div>
                            <div className='form-group col-4'>
                                <label className='mb-1 '>
                                    <strong>{t('common.glasses-wear')}: </strong>
                                    <span className='text-primary'>
                                        {proformaData.opticBonDetailCaracteristique.find(elt => elt.typeCaracteristique === 'portVerre')?.valeurCaracteristique}
                                    </span>
                                </label>
                            </div>
                        </div>
                        <div className="card">
                            <DataTable value={opticDatas} showGridlines rowGroupMode="rowspan" groupRowsBy="category" tableStyle={{ minWidth: '50rem' }}>
                                <Column field="category" header="" style={{ minWidth: '200px' }}></Column>
                                <Column field="acte" header="" style={{ minWidth: '200px' }} body={acteBodyTemplate}></Column>
                                <Column field="prixActiva" header={t('common.activa-price')} style={{ minWidth: '200px' }}></Column>
                                <Column field="prixPrestataire" header={t('common.price')} style={{ minWidth: '150px' }}></Column>
                                <Column field="montantRejet" header={t('common.exl-amount')} style={{ minWidth: '150px' }}></Column>
                                <Column field="motifRejet" header={t('common.exl-motif')} style={{ minWidth: '150px' }}></Column>
                                <Column field="actions" header="Actions" body={actionsBodyTemplate}></Column>
                            </DataTable>
                        </div>
                        <div className="col-lg-4 col-sm-5 ml-auto">
                            <table className="table table-clear">
                            <tbody>
                                <tr>
                                    <td className="left">
                                        <strong>{t('common.total-amount')}</strong>
                                    </td>
                                    <td className="right">{proformaData.montantTotal}</td>
                                </tr>
                                {/*<tr>
                                    <td className="left">
                                        <strong>Montant Convention</strong>
                                    </td>
                                    <td className="right">{proformaData.amountConvention}</td>
                                </tr>*/}
                                <tr>
                                    <td className="left">
                                        <strong>{t('common.top')}</strong>
                                    </td>
                                    <td className="right">{proformaData.amountPolice}</td>
                                </tr>
                                <tr>
                                    <td className="left">
                                        <strong>{t('common.tm')}</strong>
                                    </td>
                                    <td className="right">{proformaData.amountTM}</td>
                                </tr>
                                {proformaData.amountRejet && <tr>
                                    <td className="left">
                                        <strong>{t('common.reject-amount')}</strong>
                                    </td>
                                    <td className="right">{proformaData.amountRejet}</td>
                                </tr>}
                                <tr>
                                    <td className="left">
                                        <strong>{t('common.act-part')}</strong>
                                    </td>
                                    <td className="right">{proformaData.montantTotal - proformaData.amountTM - proformaData.amountRejet}</td>
                                </tr>
                            </tbody>
                            </table>
                        </div>
                        
                        {proformaData.opticBon.status === STATUSES.waitingAccordPreable && <div className='row justify-content-end my-2'>
                            {hasMenu('MENPROFORMA') && canDo('MENPROFORMA', 'VALIDATEPROFORMA') && <div className='col-2'>
                                <button onClick={e => dispatch(validateProformaAction(proformaData.opticBon.code))} type='button' className='btn btn-success btn-block'>
                                   {t('common.validate')}
                                </button>
                            </div>}
                            {proformaData.opticBon.status !== STATUSES.rejeted && hasMenu('MENPROFORMA') && canDo('MENPROFORMA', 'REJECTPROFORMA') && <div className='col-2'>
                                <button onClick={e => setCurrentKey("proforma")} type='button' className='btn btn-danger btn-block'>
                                   {t('common.rejected')}
                                </button>
                                <Modal
                                    className="fade bd-example-modal-lg"
                                    show={currentKey === "proforma"}
                                    size="lg"
                                >
                                    <MotifRejetModal 
                                        showModal={currentKey === "proforma"}
                                        acte={proformaData}
                                        manageCommentModal={manageCommentModal}
                                        history={history}
                                        exclusions={motifExclusion}
                                        motifRejet={proformaData.motifExclusion}
                                        isDetail={true}
                                        montantAssureur={proformaData.montantTotal - proformaData.amountTM - proformaData.amountRejet}
                                        montantRejete={proformaData.amountRejet}
                                    />
                                </Modal>
                            </div>}
                        </div>}
                        {(proformaData.opticBon.status === STATUSES.rejeted) && <div className='row justify-content-end my-2'>
                            <div className='col-2'>
                                {hasMenu('MENPROFORMA') && canDo('MENPROFORMA', 'RESTOREPROFORMA') && <button onClick={e => dispatch(restoreProformaAction(proformaData.opticBon.id))} type='button' className='btn btn-success btn-block'>
                                   {t('common.restore')}
                                </button>}
                            </div>
                        </div>}
                        {proformaData.opticBon.status === STATUSES.exectuted && <div className='row justify-content-end my-2'>
                            <div className='col-2'>
                                <b className='text-success'>{t('common.glasses-delivered')} </b>
                            </div>
                        </div>}
                    </>
                }
            </div>
        </Fragment>
    )
};
const mapStateToProps = (state) => {
    return {
       motifExclusion: state.facturation.motifExclusion,
       proformaData: state.accordPrea.proforma,
       successMessage: state.accordPrea.successMessage,
       errorMessage: state.accordPrea.errorMessage,
       showLoading: state.accordPrea.showLoading,
       provider: state.provider.provider,
       currentUser: currentUser(state)
    };
};
export default connect(mapStateToProps)(ProformaView);