import React, { Fragment, useEffect, useState } from "react";
import { connect, useDispatch } from 'react-redux';
import { useParams } from "react-router-dom";
import loadable from "@loadable/component";
import { DatePicker, DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { currentUser } from "../../../store/selectors/CurrentUserSelector";
import {
    getDataRapportAction, getPrestationAmbuAction, getPatologieAmbuAction,
    getTopDentisteAction, getTopOptiqueAction, getTopPharmacieAction,getRapportFinanceAction
} from '../../../store/actions/RapportAction';

const RapportGlobal = loadable(() => import("./RapportsGlobal/index"));

export const RapportsGlobal = (props) => {
    const dispatch = useDispatch();
    const { id } = useParams();
    const { history, showLoading } = props;

    const [searchKey, setSearchKey] = useState('');
    var date = new Date();
    const [dateMin, setDateMin] = useState(new Date(date.getFullYear(), date.getMonth(), 1));
    const [dateMax, setDateMax] = useState(new Date());

    const canDo = (menuCode, actionCode) => {
        let theMenu = props.currentUser.menus?.find(menu => menu.code === menuCode);
        if(!theMenu){
            return false;
        }else{
            return theMenu.actionMenus?.filter(action => action.code === actionCode).length > 0;
        }
    }
    
    const hasMenu = (menuCode) => {
        return props.currentUser.menus?.filter(menu => menu.code === menuCode).length > 0;
    }

    useEffect(() => {
        if(hasMenu('MENREPORTING') && canDo('MENREPORTING', 'VIEWREPORT')){
            let data = {
               dateDebut: dateMin, 
               dateFin: dateMax
            };
            dispatch(getDataRapportAction(data));
            dispatch(getPrestationAmbuAction(data));
            dispatch(getPatologieAmbuAction(data));
            dispatch(getTopDentisteAction(data));
            dispatch(getTopOptiqueAction(data));
            dispatch(getTopPharmacieAction(data));
            dispatch(getRapportFinanceAction(data));
        }else{
            props.history.push('/access-denied');
        } 
    }, [dateMin, dateMax]);



    return (
        <Fragment>
            <div className="row justify-content-end">
                <div className="col-lg-3 mb-2">
                    <div className="form-group">
                        <label className='mb-1 '>
                            <strong>Date minimum</strong>
                        </label>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <DatePicker
                                autoOk
                                label=""
                                clearable
                                format="dd/MM/yyyy"
                                disableFuture
                                value={dateMin}
                                onChange={setDateMin}
                            />
                        </MuiPickersUtilsProvider>
                    </div>
                </div>
                <div className="col-lg-3 mb-2">
                    <div className="form-group">
                        <label className='mb-1 '>
                            <strong>Date maximum</strong>
                        </label>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <DatePicker
                                autoOk
                                label=""
                                clearable
                                format="dd/MM/yyyy"
                                disableFuture
                                value={dateMax}
                                onChange={setDateMax}
                            />
                        </MuiPickersUtilsProvider>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-xl-12 col-xxl-12">
                    <div className="card">
                        <div className="card-header">
                            <div className="row">
                                    <h4 className="card-title">Rapport Statistique A-CARE Période  </h4>
                                    
                                    {/* <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <DateTimePicker
                                            autoOk
                                            label=""
                                            clearable
                                            format="dd/MM/yyyy hh:mm"
                                            value=""
                                            disableFuture
                                            required
                                            onChange={(val) => {
                                            className="small"
                                            }}
                                        />
                                    </MuiPickersUtilsProvider>

                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <DateTimePicker
                                            autoOk
                                            label=""
                                            clearable
                                            format="dd/MM/yyyy hh:mm"
                                            value=""
                                            disableFuture
                                            required
                                            onChange={(val) => {

                                            }}
                                        />
                                    </MuiPickersUtilsProvider> */}
                            </div>
                        </div>
                        <div className="card-body">
                            <section>
                                <div className="card-body pb-0">
                                    <div className="tab-pan active show pb-0">
                                        <div
                                            className="chart-warp"
                                            style={{ overflow: "hidden" }}
                                        >
                                            <RapportGlobal rapport={props.rapport} />
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
};

const mapStateToProps = (state) => {
    return {
        rapport: state.rapport,
        currentUser: currentUser(state)
    };
};

export default connect(mapStateToProps)(RapportsGlobal);