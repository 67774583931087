import React, {useState,useEffect } from 'react'
import { connect, useDispatch } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { Link } from "react-router-dom";
import Select from 'react-select';
import { listGlobalActsAction, startLoader, mapGlobalActAction, updateGlobalActMappingAction, deleteGlobalActAction } from '../../../store/actions/ParamerterAction';
import { searchActesAction } from '../../../store/actions/ProviderActions';
import { getActesProdAction } from '../../../store/actions/ContratActions';
import swal from 'sweetalert';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { InputText } from 'primereact/inputtext';
import {Spinner, Button} from 'react-bootstrap';
import { Button as PButton } from 'primereact/button';
import { DataTableBase } from '../DataTables/DataTableBase';
import { currentUser } from "../../../store/selectors/CurrentUserSelector";
import { useTranslation } from "react-i18next";


const GlobalActs = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    let errorsObj = { acte: '', code: '' };
    const [id, setId] = useState('');
    const [acteCode, setActeCode] = useState('');
    const [acteId, setActeId] = useState('');
    const [acteName, setActeName] = useState('');
    const [acteProdCode, setActeProdCode] = useState('');
    const [acteProdName, setActeProdName] = useState('');
    const [typeActe, setTypeActe] = useState('');
    const [errors, setErrors] = useState(errorsObj);
    //Modal box
    const [addCard, setAddCard] = useState(false);

    const [filters, setFilters] = useState(null);
    const [updateMode, setUpdateMode] = useState(false);
    const [globalFilterValue, setGlobalFilterValue] = useState('');

    const actTypes = [
        {label: t('common.glasses'), value: "verre"},
        {label: t('common.monture'), value: "monture"},
        {label: t('layout.pharmacy'), value: "pharmacie"},
    ]

    const initFilters = () => {
        setFilters({
            global: { value: null, matchMode: FilterMatchMode.CONTAINS },
            acteCode: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            acteName: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            acteProdCode: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            acteProdName: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            typeActe: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
        });
        setGlobalFilterValue("");
    };

    const renderHeader = () => {
        return (
            <div className="flex justify-content-between">
                <PButton type="button" icon="pi pi-filter-slash"  label={t('common.reinit')} outlined onClick={clearFilter} />
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText value={globalFilterValue} onChange={(e) => onGlobalFilterChange(e)}  placeholder={t('common.search')} />
                </span>
            </div>
        );
    };

    const clearFilter = () => {
        initFilters();
    };

    const setColumns = () => {
        return [
            {name: 'id', headerName: "Id", style: { minWidth: '12rem' }, filter: false, bodyTemplate: null},
            {name: 'typeActe', headerName: t('client.type'), filterPlaceholder: t('provider.type-filter'), style: { minWidth: '12rem' }, filter: true, bodyTemplate: null},
            {name: 'acteCode', headerName: t('provider.act-code'), filterPlaceholder: t('provider.code-act-filter'), style: { minWidth: '12rem' }, filter: true, bodyTemplate: null},
            {name: 'acteName', headerName: t('provider.nom-acte'), filterPlaceholder: t('provider.name-act-filter'), style: { minWidth: '12rem' }, filter: true, bodyTemplate: null},
            {name: 'acteProdCode', headerName: t('provider.acte-prod-code'), filterPlaceholder: t('provider.code-act-prod-filter'), style: { minWidth: '12rem' }, filter: true, bodyTemplate: null},
            {name: 'acteProdName', headerName: t('provider.acte-prod-name'), filterPlaceholder: t('provider.name-act-prod-filter'), style: { minWidth: '12rem' }, filter: true, bodyTemplate: null},
            {name: 'actions', headerName: 'Actions', filterPlaceholder: '', style: { minWidth: '6rem'}, filter: false, bodyTemplate: getActionsTemplate},
        ]
    };

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };

        _filters['global'].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
    };

    const getActionsTemplate = (rowData) => {
        return <div style={{ width: '100%', display: 'flex', justifyContent: 'center'}}>
            {hasMenu('MENPARAM') && canDo('MENPARAM', 'UPDATESTANDACT') && <Link href="#" to="#" onClick={e => updateData(rowData)} className="mr-4" title="Edit">
                <i className="fa fa-pencil color-muted"></i>
            </Link>}
            {hasMenu('MENPARAM') && canDo('MENPARAM', 'DELETESTANDACT') && <Link href="#" to="#" title="Close"
                onClick={() =>
                    swal({
                        title: t('client.delete-standart-act')+rowData.acteName+" ?",
                        text: t('client.delete-message'),
                        icon: "warning",
                        buttons: true,
                        dangerMode: true,
                    }).then((willDelete) => {
                        if (willDelete) {
                            dispatch(startLoader());
                            dispatch(deleteGlobalActAction(rowData.id));
                        }
                    })
                    }>
                <i className="fa fa-close color-danger"></i>
            </Link>}
        </div>
    }

    const updateData = (data) => {
        setId(data.id);
        setActeCode(data.acteCode);
        setActeName(data.acteName);
        setActeId(data.acteId);
        setActeProdName(data.acteProdName);
        setActeProdCode(data.acteProdCode);
        setTypeActe(data.typeActe);
        setAddCard(true);
        setUpdateMode(true);
    }

    const canDo = (menuCode, actionCode) => {
        let theMenu = props.currentUser.menus?.find(menu => menu.code === menuCode);
        if(!theMenu){
            return false;
        }else{
            return theMenu.actionMenus?.filter(action => action.code === actionCode).length > 0;
        }
    }
    
    const hasMenu = (menuCode) => {
        return props.currentUser.menus?.filter(menu => menu.code === menuCode).length > 0;
    }

    useEffect(() => {
        dispatch(startLoader());
        initFilters();
        dispatch(listGlobalActsAction());  
        dispatch(getActesProdAction());    
    }, []);


    useEffect(() => {
        setUpdateMode(false);
        setId("");
        setActeCode("");
        setActeId("");
        setActeName("");
        setActeProdName("");
        setActeProdCode("");
        setTypeActe("");
        setAddCard(false);  
    }, [props.globalActs]);
    
    function onLettreCleSave(e) {
        e.preventDefault();
        let error = false;
        const errorObj = { ...errorsObj };

        if (acteCode === '') {
            errorObj.acteCode = t('common.acte-error');
            error = true;
        }
        if (acteProdCode === '') {
            errorObj.acteProdCode = t('common.acte-prod-error');
            error = true;
        }
        if (typeActe === '') {
            errorObj.typeActe = t('common.type-error');
            error = true;
        }

        setErrors(errorObj);
        if (error) {
            swal('Oops', t('common.check-form-fields'), "infos");
			return ;
		}
        dispatch(startLoader());
        

        if(updateMode) {
            let obj = { id, typeActe, acteCode, acteId, acteProdCode };
            dispatch(updateGlobalActMappingAction(obj));
        }else{
            let obj = { typeActe, acteCode, acteId, acteProdCode };
            dispatch(mapGlobalActAction(obj));
        }
    }


    return (
        <>
            <div className="form-head d-flex mb-3 mb-md-4 align-items-start">
                <div className="mr-auto d-none d-lg-block">
                    {hasMenu('MENPARAM') && canDo('MENPARAM', 'CREATESTANDACT') && <Link
                        to="#"
                        className="btn btn-primary btn-rounded"
                        onClick={() => setAddCard(true)}
                    >
                        + {t('common.new-standart-act')}
                    </Link>}
                </div>
            </div>
            <Modal className="modal fade" show={addCard} onHide={setAddCard} >
            <div className="">
                <div className="card">
                    <div className="card-header">
                        <h4 className="card-title">{t('common.create-standart-act')}</h4>
                    </div>
                    <div className='card-body'>
                        <div className='basic-form'>
                            <form onSubmit={onLettreCleSave}>
                                <div className='form-group col-12'>
                                    <label className='mb-1'>{t('provider.act-type')}</label>
                                    <Select
                                        className="select-search"
                                        value={actTypes.filter(elt => elt.value === typeActe)}
                                        placeholder="Choisir un type"
                                        onChange={(val) => setTypeActe(val.value)}
                                        disabled={props.showLoading}
                                        required={props.showLoading}
                                        options={actTypes}
                                        style={{
                                            lineHeight: '40px',
                                            color: '#7e7e7e',
                                            paddingLeft: ' 15px',
                                        }}
                                    />
                                </div>
                                
                                <div className='form-group col-12'>
                                    <label className='mb-1'>{t('provider.acte')}</label>
                                    <Select
                                        className="select-search"
                                        value={{label: acteName, value: acteId, code: acteCode}}
                                        options={props.actes.map(elt => {return {label: elt.nameFr, value: elt.id, code: elt.code}})}
                                         placeholder={t('common.choose')}
                                        search
                                        isRequired
                                        onInputChange={(val) => {
                                            console.log(val);
                                            if(val.length >= 3){
                                                dispatch(searchActesAction(val));
                                            }
                                        }}
                                        onChange={(val) => {
                                            setActeName(val.label);
                                            setActeCode(val.code);
                                            setActeId(val.value);
                                        }}
                                    />
                                </div>

                                <div className='form-group col-12'>
                                    <label className='mb-1'>{t('provider.acte-production')}</label>
                                    <Select
                                        className="select-search"
                                        value={{label: acteProdName, value: acteProdCode}}
                                        options={props.actesProd.map(elt => {return {label: elt.name, value: elt.code}})}
                                         placeholder={t('common.choose')}
                                        search
                                        isRequired
                                        onChange={(val) => {
                                            setActeProdName(val.label);
                                            setActeProdCode(val.value);
                                        }}
                                    />
                                </div>
                                <hr />
                                <div className='form-group align-items-center justify-content-center'>
                                    { props.showLoading && 
                                        <Button variant="primary" disabled>
                                            <Spinner
                                                as="span"
                                                animation="grow"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                            />{' '}
                                            <span className="visually-hidden">{t('common.await-text')}</span>
                                        </Button> }
                                    { !props.showLoading &&
                                        <div className='col-4'>
                                            <button type='submit' className='btn btn-primary'>
                                               {t('common.save')}
                                            </button>
                                        </div> }
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            </Modal>
            <DataTableBase 
                data={props.globalActs.length > 0 ? props.globalActs.map((act) => {
                    return {
                        ...act,
                        acteName: act.acte.nameFr,
                        acteProdName: act.acte.acteProdName
                    }
                }) : []}
                emptyMessage={t('common.no-standart-act')} 
                filters={filters}
                globalFiltersFields={['id', 'acteCode', 'acteName', 'acteProdCode', 'acteProdName', 'typeActe']}
                header={renderHeader()}
                columns={setColumns()}
                exportColumns={[
                    { title: 'ID', dataKey: 'id' },
                    { title: t('common.act-type'), dataKey: 'typeActe' },
                    { title: t('common.act-name'), dataKey: 'acteName' },
                    { title: t('common.act-code'), dataKey: 'acteCode' },
                    { title: t('common.act-prod-name'), dataKey: 'acteProdName' },
                    { title: t('common.act-prod-code'), dataKey: 'acteProdCode' },
                ]}
                rows={10} 
                showExport={true}
                exportFilename={"actes_standarts"}
                loading={props.showLoading}
                lazy={false}
            />

        </>
    );
};

const mapStateToProps = (state) => {
    return {
        globalActs: state.parameters.globalActs,
        actes: state.provider.actesRes,
        actesProd: state.contrat.actes,
        errorMessage: state.clientAuth.errorMessage,
        successMessage: state.clientAuth.successMessage,
        showLoading: state.parameters.showLoading,
        currentUser: currentUser(state)
    };
};

export default connect(mapStateToProps)(GlobalActs);