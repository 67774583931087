import {
    START_LOADER_ACTION,
    GET_CONSULTATIONS_CONFIRMED_ACTION,
    GET_CONSULTATIONS_FAILED_ACTION,
    GET_CONSULTATION_DATA_CONFIRMED_ACTION,
    GET_CONSULTATION_DATA_FAILED_ACTION,
    REQUEST_CONSULTATION_CONFIRMED_ACTION,
    REQUEST_CONSULTATION_FAILED_ACTION,
    SEARCH_DRUGS_CONFIRMED_ACTION,
    SEARCH_DRUGS_FAILED_ACTION,
    SEARCH_DISEASES_CONFIRMED_ACTION,
    SEARCH_DISEASES_FAILED_ACTION,
    GET_SUGGESTIONS_CONFIRMED_ACTION,
    GET_SUGGESTIONS_FAILED_ACTION,
    UPDATE_CONSULTATION_CONFIRMED_ACTION,
    UPDATE_CONSULTATION_FAILED_ACTION,
    GET_CLIENT_CONSUTATIONS_CONFIRMED_ACTION,
    GET_CLIENT_CONSUTATIONS_FAILED_ACTION,
    GET_CLIENT_MEDICAL_FILE_CONFIRMED_ACTION,
    GET_CLIENT_MEDICAL_FILE_FAILED_ACTION,
    GET_PARAMS_HISTORY_CONFIRMED_ACTION,
    GET_PARAMS_HISTORY_FAILED_ACTION,
    UPDATE_QUANTITY_CONFIRMED_ACTION,
    UPDATE_QUANTITY_FAILED_ACTION,
    GET_ACTES_CONSULTATION_CONFIRMED_ACTION,
    GET_ACTES_CONSULTATION_FAILED_ACTION,
    GET_ACTES_BY_TYPE_CONFIRMED_ACTION,
    GET_ACTES_BY_TYPE_FAILED_ACTION,
    GET_ACCESSOIRE_VERRE_CONFIRMED_ACTION,
    GET_ACTES_HOSPITALISATION_CONFIRMED_ACTION,
    GET_ACTES_HOSPITALISATION_FAILED_ACTION,
    GET_CONSULTATION_BY_CODE_DATA_CONFIRMED_ACTION,
    GET_CONSULTATION_BY_CODE_DATA_FAILED_ACTION,
    ADD_ACTE_DETAIL_CONFIRMED_ACTION,
    ADD_ACTE_DETAIL_FAILED_ACTION,
} from '../actions/ConsultationActions';

import { DELETE_DATA_CONFIRMED_ACTION } from '../actions/ProviderActions';

const initialState = {
    consultations: [],
    consultation: {},
    consultationByCode: {},
    clientConsultations: [],
    drugs: [],
    diseases: [],
    allergies: [],
    habitudes: [],
    antecedentsFam: [],
    antecedentsPers: [],
    identites: [],
    inspections: [],
    palpations: [],
    auscultations: [],
    percussions: [],
    medicalFile: {},
    paramsHistory: {},
    actesConsultation: [],
    actesByType: [],
    accessoireVerre: [],
    actesHospitalisation: [],
    updateActDone: false,
    errorMessage: '',
    successMessage: '',
    showLoading: false,
    updatQuantiteDone: false
};

export const ConsultationReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_CONSULTATIONS_CONFIRMED_ACTION:
            return {
                ...state,
                consultations: action.payload,
                errorMessage: '',
                successMessage: 'Consultations gotten Successfully Completed',
                showLoading: false,
            };
        case GET_CONSULTATIONS_FAILED_ACTION:
            return {
                ...state,
                errorMessage: action.payload,
                successMessage: '',
                showLoading: false,
            };
        case GET_CONSULTATION_DATA_CONFIRMED_ACTION:
            return {
                ...state,
                consultation: action.payload,
                errorMessage: '',
                successMessage: 'Consultation gotten Successfully Completed',
                showLoading: false,
            };
        case GET_CONSULTATION_DATA_FAILED_ACTION:
            return {
                ...state,
                errorMessage: action.payload,
                successMessage: '',
                showLoading: false,
            };
        case GET_CONSULTATION_BY_CODE_DATA_CONFIRMED_ACTION:
            return {
                ...state,
                consultationByCode: action.payload,
                errorMessage: '',
                successMessage: 'Consultation gotten Successfully Completed',
                showLoading: false,
            };
        case GET_CONSULTATION_BY_CODE_DATA_FAILED_ACTION:
            return {
                ...state,
                errorMessage: action.payload,
                successMessage: '',
                showLoading: false,
            };
        
        case REQUEST_CONSULTATION_CONFIRMED_ACTION:
            return {
                ...state,
                errorMessage: '',
                successMessage: 'Consultation Request Successfully Completed',
                showLoading: false,
            };
        case REQUEST_CONSULTATION_FAILED_ACTION:
            return {
                ...state,
                errorMessage: action.payload,
                successMessage: '',
                showLoading: false,
            };
            
        case SEARCH_DRUGS_CONFIRMED_ACTION:
            return {
                ...state,
                drugs: action.payload,
                errorMessage: '',
                successMessage: 'Drugs gotten Successfully Completed',
                showLoading: false,
            };
        case SEARCH_DRUGS_FAILED_ACTION:
            return {
                ...state,
                errorMessage: action.payload,
                successMessage: '',
                showLoading: false,
            };

        case SEARCH_DISEASES_CONFIRMED_ACTION:
            return {
                ...state,
                diseases: action.payload,
                errorMessage: '',
                successMessage: 'Diseases gotten Successfully Completed',
                showLoading: false,
            };
        case SEARCH_DISEASES_FAILED_ACTION:
            return {
                ...state,
                errorMessage: action.payload,
                successMessage: '',
                showLoading: false,
            };
        case GET_SUGGESTIONS_CONFIRMED_ACTION:
            return {
                ...state,
                allergies: action.payload.typeData === 'allergy' ? action.payload.data : state.allergies,
                habitudes: action.payload.typeData === 'habitude' ? action.payload.data : state.habitudes,
                antecedentsFam: action.payload.typeData === 'antecedent-familial' ? action.payload.data : state.antecedentsFam,
                antecedentsPers: action.payload.typeData === 'antecedent-personnel' ? action.payload.data : state.antecedentsPers,
                identites: action.payload.typeData === 'identite' ? action.payload.data : state.identites,
                inspections: action.payload.typeData === 'inspection' ? action.payload.data : state.inspections,
                palpations: action.payload.typeData === 'palpation' ? action.payload.data : state.palpations,
                auscultations: action.payload.typeData === 'auscultation' ? action.payload.data : state.auscultations,
                percussions: action.payload.typeData === 'percussion' ? action.payload.data : state.percussions,
                errorMessage: '',
                successMessage: 'Diseases gotten Successfully Completed',
                showLoading: false,
            };
        case GET_SUGGESTIONS_FAILED_ACTION:
            return {
                ...state,
                errorMessage: action.payload,
                successMessage: '',
                showLoading: false,
            };
        case UPDATE_CONSULTATION_CONFIRMED_ACTION:
            return {
                ...state,
                errorMessage: '',
                successMessage: 'Consultation Updated Successfully Completed',
                showLoading: false,
            };
        case UPDATE_CONSULTATION_FAILED_ACTION:
            return {
                ...state,
                errorMessage: action.payload,
                successMessage: '',
                showLoading: false,
            };
        
        case GET_CLIENT_CONSUTATIONS_CONFIRMED_ACTION:
            return {
                ...state,
                clientConsultations: action.payload,
                errorMessage: '',
                successMessage: 'Client consultations gotten Successfully Completed',
                showLoading: false,
            };
        case GET_CLIENT_CONSUTATIONS_FAILED_ACTION:
            return {
                ...state,
                errorMessage: action.payload,
                successMessage: '',
                showLoading: false,
            };
        
        case GET_CLIENT_MEDICAL_FILE_CONFIRMED_ACTION:
            return {
                ...state,
                medicalFile: action.payload,
                errorMessage: '',
                successMessage: 'Client consultations gotten Successfully Completed',
                showLoading: false,
            };
        case GET_CLIENT_MEDICAL_FILE_FAILED_ACTION:
            return {
                ...state,
                errorMessage: action.payload,
                successMessage: '',
                showLoading: false,
            };
        
        case GET_PARAMS_HISTORY_CONFIRMED_ACTION:
            return {
                ...state,
                paramsHistory: action.payload,
                errorMessage: '',
                successMessage: 'Client params history gotten Successfully Completed',
                showLoading: false,
            };
        case GET_PARAMS_HISTORY_FAILED_ACTION:
            return {
                ...state,
                errorMessage: action.payload,
                successMessage: '',
                showLoading: false,
            };
        
        case UPDATE_QUANTITY_CONFIRMED_ACTION:
            return {
                ...state,
                errorMessage: '',
                updatQuantiteDone: true,
                successMessage: 'Quantity prescribed Successfully Completed',
                showLoading: false,
            };
        case UPDATE_QUANTITY_FAILED_ACTION:
            return {
                ...state,
                errorMessage: action.payload,
                updatQuantiteDone: false,
                successMessage: '',
                showLoading: false,
            };
        case GET_ACTES_CONSULTATION_CONFIRMED_ACTION:
            return {
                ...state,
                actesConsultation: action.payload,
                errorMessage: '',
                successMessage: 'Consultations acts gotten Successfully Completed',
                showLoading: false,
            };
        case GET_ACTES_CONSULTATION_FAILED_ACTION:
            return {
                ...state,
                errorMessage: action.payload,
                successMessage: '',
                showLoading: false,
            };
        case DELETE_DATA_CONFIRMED_ACTION:
            return {
                ...state,
                drugs: action.payload.type === 'MEDICAMENT' ?
                    state.drugs.filter(elt => elt.id !== action.payload.id) : 
                    state.drugs,
                errorMessage: action.payload,
                successMessage: '',
                showLoading: false,
            };

        case GET_ACTES_BY_TYPE_CONFIRMED_ACTION:
            return {
                ...state,
                errorMessage: '',
                actesByType: action.payload,
                successMessage: 'Get actes by type Successfully Completed',
                showLoading: false,
            };

        case GET_ACCESSOIRE_VERRE_CONFIRMED_ACTION:
            return {
                ...state,
                errorMessage: '',
                accessoireVerre: action.payload,
                successMessage: 'Get actes by type Successfully Completed',
                showLoading: false,
            };
        case GET_ACTES_BY_TYPE_FAILED_ACTION:
            return {
                ...state,
                errorMessage: action.payload,
                successMessage: '',
                showLoading: false,
            };
        case GET_ACTES_HOSPITALISATION_CONFIRMED_ACTION:
            return {
                ...state,
                actesHospitalisation: action.payload,
                errorMessage: '',
                successMessage: 'Hospitalisation acts gotten Successfully Completed',
                showLoading: false,
            };
        case GET_ACTES_HOSPITALISATION_FAILED_ACTION:
            return {
                ...state,
                errorMessage: action.payload,
                successMessage: '',
                showLoading: false,
            };
            
        
        
        case ADD_ACTE_DETAIL_CONFIRMED_ACTION:
            return {
                ...state,
                updateActDone: true,
                errorMessage: '',
                successMessage: 'Add acte Successfully Completed',
                showLoading: false,
            };
        case ADD_ACTE_DETAIL_FAILED_ACTION:
            return {
                ...state,
                updateActDone: true,
                errorMessage: action.payload,
                successMessage: '',
                showLoading: false,
            };
            
        case START_LOADER_ACTION:
            return {
                ...state,
                showLoading: true,
            };
        default:
            return state;
    };
};