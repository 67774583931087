import React, { Fragment, useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { connect } from 'react-redux';
import { Link, useParams } from "react-router-dom";
import { ListGroup } from "react-bootstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import map from "../../../images/svg/map.svg";
import widget8 from "../../../images/avatar/5.png";
import task6 from "../../../images/task/img6.jpg";
import { getConsultationDataAction, startLoader } from "../../../store/actions/ConsultationActions";
import { getSubstitutionRequestsAction, substituteDrugAction } from "../../../store/actions/PharmacieActions";
import { listAllAccordRequestAction, loadingToggleAction, sendAccordMessageAction } from "../../../store/actions/AccordPreaActions";
import { currentUser } from "../../../store/selectors/CurrentUserSelector";
import { Row, Col, Card, Accordion, Button, Badge, Modal } from 'react-bootstrap';
import { QuantiteModal } from "./QuantiteModal";
import avatar1 from '../../../images/avatar/5.png';
import avatar2 from '../../../images/avatar/2.png';
import './AccordPreaView.css';
import {format} from 'date-fns';
import { STATUSES } from '../../globals';
import { useTranslation } from "react-i18next";

/* --- BEGIN DetailsConsultation
	@param(Object): props
    description: Details de consultation
*/
export const DetailsConsultation = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { id } = useParams();
    const { currentUser, consultation, history, subsRequests, accordPreas, showLoading, showAccordLoading, updatQuantiteDone } = props;
    
    const [activeBordered, setActiveBordered] = useState(0);
    const [currentKey, setCurrentKey] = useState('');
    const [message, setMessage] = useState('');
    const [fileSrc, setFileSrc] = useState(null);
    const [showDiscuss, setShowDiscuss] = useState(true);

    const suggestions = [
        t('common.suggest-accord-1'),
        t('common.suggest-accord-2'),
        t('common.suggest-accord-3'),
    ];
    

    const canDo = (menuCode, actionCode) => {
        let theMenu = props.currentUser.menus?.find(menu => menu.code === menuCode);
        if(!theMenu){
            return false;
        }else{
            return theMenu.actionMenus?.filter(action => action.code === actionCode).length > 0;
        }
    }
    
    const hasMenu = (menuCode) => {
        return props.currentUser.menus?.filter(menu => menu.code === menuCode).length > 0;
    }

    useEffect(() => {
        if(hasMenu('MENCONSULTADMIN') && canDo('MENCONSULTADMIN', 'VIEWCONSULTADMIN')){
            dispatch(startLoader());
            dispatch(getSubstitutionRequestsAction());
            dispatch(getConsultationDataAction(id));
            dispatch(loadingToggleAction());
            dispatch(listAllAccordRequestAction());
        }else{
            props.history.push('/access-denied');
        }
    }, []);

    useEffect(() => {
        if(updatQuantiteDone){
            dispatch(startLoader());
            dispatch(getSubstitutionRequestsAction());
            dispatch(getConsultationDataAction(id));
            dispatch(loadingToggleAction());
            dispatch(listAllAccordRequestAction());
        }
    }, [updatQuantiteDone]);

    const defaultAccordion = [
        {
          title: t('common.exam'),
          bg: 'primary',
        },
        {
          title: t('layout.pharmacy'),
          bg: 'info',
        },
        {
          title: t('layout.specialized-acts'),
          bg: 'success',
        },
        {
          title: t('statuses.100'),
          bg: 'success',
        },
        {
          title: t('layout.optics'),
          bg: 'secondary',
        },
      ]
    
    /* --- BEGIN respondDemande
        @param(string, int): status, demandeId
        description: Fonction de validation de demande de substitution de médicament
    */
    const respondDemande = (status, demandeId) => {
        dispatch(substituteDrugAction(status, demandeId));
    }
    /* --- END respondDemande */

    /* --- BEGIN loadFile
        @param(Event): e
        description: Fonction de gestion de fichier chargé
    */
    const loadFile = (e) => {
        let upFiles = e.target.files;
        const keys = Object.keys(upFiles);
        console.log(upFiles[keys[0]]);
        setFileSrc(upFiles[keys[0]]);
    }
    /* --- END loadFile */

    /* --- BEGIN sendMessage
        @param(Event, int): e, accordId
        description: Fonction d'envoie de message dans les accords préalables
    */
    const sendMessage = (e, accordId) => {
        e.preventDefault();
        let data = {
            senderId: currentUser.id,
            accordPreableId: accordId,
            message,
            fileSrc,
        };
        dispatch(loadingToggleAction());
        dispatch(sendAccordMessageAction(data, history));
        dispatch(listAllAccordRequestAction(id));
    }
    /* --- END sendMessage */

    /* --- BEGIN manageQuantiteModal
        @param(): aucun
        description: Fonction de gestion de la modale de changement de quantité de medicaments prescrite
    */
    const manageQuantiteModal = () => {
        setCurrentKey('');
    }
    /* --- END manageQuantiteModal */

    const dayHospiString = () => {
        let str = "";
        let diff = Math.abs(new Date() - new Date(consultation.dateDebutHospi));
        let diff2 = Math.abs(new Date(consultation.dateFinHospi) - new Date());

        let days = Math.ceil(diff / (1000*60*60*24));
        let remainDays = Math.ceil(diff2 / (1000*60*60*24));
        
        if(days <= 0){
            str = t('common.first-hospi-day');
        }else{
            str = days + t('common.nth-hospi-day');
        }

        if(remainDays < 0){
            str += t('common.out-of-hospi');
        }else if(remainDays === 0){
            str += t('common.futur-hospi-outing');
        }else{
            str += t('common.futur-hospi')+(remainDays - 1)+t('common.days')
        }

        return str;

    }

    return (
        <>
            { showLoading && 
            <div id="preloader">
                <div className="sk-three-bounce">
                    <div className="sk-child sk-bounce1"></div>
                    <div className="sk-child sk-bounce2"></div>
                    <div className="sk-child sk-bounce3"></div>
                </div>
            </div> }

            { !showLoading &&
            <>
                <div className="page-titles">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item active">
                            <Link to="/provider-list">t('common.consult-folder')</Link>
                        </li>
                        <li className="breadcrumb-item">
                            <Link>{consultation.consultationBon?.beneficiaryName}</Link>
                        </li>
                    </ol>
                </div>
                <div className='d-md-flex d-block mb-3 align-items-center justify-content-between'>
                    <div className="d-flex flex-column">
                        <div className="widget-timeline-icon py-3 py-md-2 px-1 overflow-auto">
                            <ul className="timeline">
                                <li>
                                    <div className="icon bg-warning" />
                                    <Link
                                        className="timeline-panel text-muted"
                                        to="#"
                                    >
                                        <h4 className="mb-2 mt-0 text-warning fs-16 font-w600">
                                        {t('patient.in-consultation')}
                                        </h4>
                                        {consultation.openDate && <p className="fs-14 mb-0 ">
                                            {format(new Date(consultation.openDate), 'dd/MM/yyyy hh:mm')}
                                        </p>}
                                    </Link>
                                </li>
                                {consultation.isHospi && 
                                <>
                                    <li className="border-info">
                                        <div className="icon bg-info" />
                                        <Link
                                            className="timeline-panel text-muted"
                                            to="#"
                                        >
                                            <h4 className="mb-2 mt-0 text-info fs-16 font-w600">
                                            {t('patient.in-hospitalisation')}
                                            </h4>
                                            {consultation.dateDebutHospi && <p className="fs-14 mb-0 ">
                                                {format(new Date(consultation.dateDebutHospi), 'dd/MM/yyyy hh:mm')}
                                            </p>}
                                        </Link>
                                    </li>
                                    <li>
                                        <div className="icon bg-primary" />
                                        <Link
                                            className="timeline-panel text-muted"
                                            to="#"
                                        >
                                            <h4 className="mb-2 text-primary mt-0 fs-16 font-w600">
                                            {t('patient.out-on')}
                                            </h4>
                                            {consultation.dateFinHospi && <p className="fs-14 mb-0 ">
                                                {format(new Date(consultation.dateFinHospi), 'dd/MM/yyyy hh:mm')}
                                            </p>}
                                        </Link>
                                    </li>
                                </>
                                }
                            </ul>
                        </div>
                        {consultation.isHospi === true && consultation.isclose === false && <div className="my-2">
                            <span className="text-info font-weight-bold">{dayHospiString()}</span>
                        </div>}
                    </div>
                    <div className='row justify-content-end'>
                        <Link
                        to={"/dossier-medical/"+consultation.consultationBon?.beneficiaryId}
                        className="btn btn-success btn-rounded wspace-no mx-2"
                        >
                        <i className="las scale5 la-folder-plus mr-2" /> {t('patient.medical-folder')}
                        </Link>
                        {accordPreas?.filter(acc => {return acc.dossierConsultationId === consultation.id}).length > 0 && 
                            <button type='button' onClick={e => setShowDiscuss(!showDiscuss)} className='btn btn-primary'>
                                {showDiscuss ? t('common.hide-discussr') : t('common.show-discussr')}
                            </button>
                        }
                    </div>
                </div>
                <div className={accordPreas?.filter(acc => {return acc.dossierConsultationId === consultation.id}).length > 0 && showDiscuss ? "row custom-chatbox" : "row"}>
                    <div className={accordPreas?.filter(acc => {return acc.dossierConsultationId === consultation.id}).length > 0 && showDiscuss ? "col-8" : "col-12"}>
                        <div className="row">
                            <div className="col-xl-12 col-xxl-12 col-lg-12">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="media d-sm-flex d-block text-center text-sm-left pb-4 mb-4 border-bottom">
                                            <img
                                            alt="widget"
                                            className="rounded mr-sm-4 mr-0"
                                            width={130}
                                            src={task6}
                                            />
                                            <div className="media-body align-items-center">
                                                <div className="d-sm-flex d-block justify-content-between my-3 my-sm-0">
                                                    <div>
                                                        <h3 className="fs-22 text-black font-w600 mb-0">
                                                            {consultation.consultationBon?.beneficiaryName}
                                                        </h3>
                                                        <h5 className="my-2 my-sm-2 text-danger">
                                                            <b>{t('appointment.reason')} </b>: {consultation.motif}
                                                        </h5>
                                                        <small className="mb-2 mb-sm-2 text-info">
                                                            <b>{t('common.consultation-date')}</b>: {consultation.consultationBon?.date}
                                                        </small>
                                                    </div>
                                                    <span>{t('statuses.'+consultation.consultationBon?.status)}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-4 mb-3">
                                                <div className="media">
                                                    <span className="p-2 border border-primary-light rounded-circle mr-3">
                                                        <i className="las la-temperature-high text-primary" style={{fontSize: '30px'}}></i>
                                                    </span>
                                                    <div className="media-body">
                                                        <span className="d-block text-primary mb-2">
                                                           {t('common.temperature')}
                                                        </span>
                                                        <p className="fs-16 text-dark">
                                                            <strong>{consultation.healhtParameter?.temperature}° C</strong>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 mb-3">
                                                <div className="media">
                                                    <span className="p-2 border border-primary-light rounded-circle mr-3">
                                                        <i className="las la-text-height text-primary" style={{fontSize: '30px'}}></i>
                                                    </span>
                                                    <div className="media-body">
                                                        <span className="d-block text-primary mb-2">
                                                        {t('common.height')}
                                                        </span>
                                                        <p className="fs-16 text-dark">
                                                            <strong>{consultation.healhtParameter?.height} Cm</strong>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 mb-3">
                                                <div className="media">
                                                    <span className="p-2 border border-primary-light rounded-circle mr-3">
                                                        <i className="las la-weight text-primary" style={{fontSize: '30px'}}></i>
                                                    </span>
                                                    <div className="media-body">
                                                        <span className="d-block text-primary mb-2">
                                                        {t('common.weight')}
                                                        </span>
                                                        <p className="fs-16 text-dark">
                                                            <strong>{consultation.healhtParameter?.weight} Kg</strong>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 mb-3">
                                                <div className="media">
                                                    <span className="p-2 border border-primary-light rounded-circle mr-3">
                                                        <i className="las la-heartbeat text-primary" style={{fontSize: '30px'}}></i>
                                                    </span>
                                                    <div className="media-body">
                                                        <span className="d-block text-primary mb-2">
                                                           {t('client.systolicPressure')}
                                                        </span>
                                                        <p className="fs-16 text-dark">
                                                            <strong>{consultation.healhtParameter?.bloodpressionS} mmHg</strong>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 mb-3">
                                                <div className="media">
                                                    <span className="p-2 border border-primary-light rounded-circle mr-3">
                                                        <i className="las la-heartbeat text-primary" style={{fontSize: '30px'}}></i>
                                                    </span>
                                                    <div className="media-body">
                                                        <span className="d-block text-primary mb-2">
                                                        {t('client.diastolicPressure')}
                                                        </span>
                                                        <p className="fs-16 text-dark">
                                                            <strong>{consultation.healhtParameter?.bloodPressionD} mmHg</strong>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 mb-3">
                                                <div className="media">
                                                    <span className="p-2 border border-primary-light rounded-circle mr-3">
                                                        <i className="las la-balance-scale text-primary" style={{fontSize: '30px'}}></i>
                                                    </span>
                                                    <div className="media-body">
                                                        <span className="d-block text-primary mb-2">
                                                           {t('common.imc')}
                                                        </span>
                                                        <p className="fs-16 text-dark">
                                                            <strong>{consultation.healhtParameter?.imc}</strong>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div className="row">
                            <div className="col-xl-4 col-xxl-4 col-lg-4">
                                <div className="card">
                                    <div className="card-header border-0 pb-0 d-flex justify-content-between">
                                        <h4 className="fs-20 font-w600 mb-0">
                                            {t('common.intervenants')}
                                        </h4>
                                    </div>
                                    <div className="card-body pt-4">
                                        <PerfectScrollbar
                                            id="DZ_W_Todo2"
                                            className="widget-media dz-scroll ps ps--active-y height370"
                                        >
                                            <ul className="timeline">
                                                <li>
                                                    <div className="timeline-panel bgl-dark flex-wrap border-0 p-3 rounded">
                                                        <div className="media bg-transparent mr-2">
                                                            <img
                                                            className="rounded-circle"
                                                            alt="widget"
                                                            width={48}
                                                            src={widget8}
                                                            />
                                                        </div>
                                                        <div className="media-body">
                                                            <h5 className="mb-1 fs-18">{consultation.consultationBon?.doctorName}</h5>
                                                            <span>{t('common.doctor')}</span>
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                        </PerfectScrollbar>
                                    </div>
                                </div>
                            </div>

                            <div className="col-xl-8 col-xxl-8 col-lg-8">
                                <div className="card">
                                    <div className="card-header border-0 pb-0 d-flex justify-content-between">
                                        <h4 className="fs-20 font-w600 mb-0">
                                            {t('accord.consultation-data')}
                                        </h4>
                                    </div>
                                    <div className="card-body pt-4">
                                        <PerfectScrollbar
                                            id="DZ_W_Todo2"
                                            className="widget-media dz-scroll ps ps--active-y height370"
                                        >
                                            <div className="row">
                                            
                                                <div className="col-lg-6 mb-3">
                                                    <div className="media">
                                                        <span className="p-2 border border-primary-light rounded-circle mr-3">
                                                            <i className="las la-allergies" style={{fontSize: '30px'}}></i>
                                                        </span>
                                                        <div className="media-body">
                                                            <span className="d-block text-primary mb-2">
                                                                {t('common.allergies')}
                                                            </span>
                                                            <p className="fs-14 text-dark">
                                                                <strong>{consultation.consultationQuestion?.allergies.toString()}</strong>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 mb-3">
                                                    <div className="media">
                                                        <span className="p-2 border border-primary-light rounded-circle mr-3">
                                                            <i className="las la-users" style={{fontSize: '30px'}}></i>
                                                        </span>
                                                        <div className="media-body">
                                                            <span className="d-block text-primary mb-2">
                                                               {t('consultation.family-history')}
                                                            </span>
                                                            <p className="fs-14 text-dark">
                                                                <strong>{consultation.consultationQuestion?.famAnts.toString()}</strong>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 mb-3">
                                                    <div className="media">
                                                        <span className="p-2 border border-primary-light rounded-circle mr-3">
                                                            <i className="las la-user" style={{fontSize: '30px'}}></i>
                                                        </span>
                                                        <div className="media-body">
                                                            <span className="d-block text-primary mb-2">
                                                            {t('consultation.personal-history')}
                                                            </span>
                                                            <p className="fs-14 text-dark">
                                                                <strong>{consultation.consultationQuestion?.persAnts.toString()}</strong>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 mb-3">
                                                    <div className="media">
                                                        <span className="p-2 border border-primary-light rounded-circle mr-3">
                                                            <i className="las la-smoking" style={{fontSize: '30px'}}></i>
                                                        </span>
                                                        <div className="media-body">
                                                            <span className="d-block text-primary mb-2">
                                                            {t('consultation.habits')}
                                                            </span>
                                                            <p className="fs-14 text-dark">
                                                                <strong>{consultation.consultationQuestion?.habits.toString()}</strong>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 mb-3">
                                                    <div className="media">
                                                        <span className="p-2 border border-primary-light rounded-circle mr-3">
                                                            <i className="las la-briefcase" style={{fontSize: '30px'}}></i>
                                                        </span>
                                                        <div className="media-body">
                                                            <span className="d-block text-primary mb-2">
                                                                {t('common.job')}
                                                            </span>
                                                            <p className="fs-14 text-dark">
                                                                <strong>{consultation.consultationQuestion?.identities.toString()}</strong>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 mb-3">
                                                    <div className="media">
                                                        <span className="p-2 border border-primary-light rounded-circle mr-3">
                                                            <i className="las la-search" style={{fontSize: '30px'}}></i>
                                                        </span>
                                                        <div className="media-body">
                                                            <span className="d-block text-primary mb-2">
                                                                {t('common.inspection')}
                                                            </span>
                                                            <p className="fs-14 text-dark">
                                                                <strong>{consultation.consultationQuestion?.inspections.toString()}</strong>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 mb-3">
                                                    <div className="media">
                                                        <span className="p-2 border border-primary-light rounded-circle mr-3">
                                                            <i className="las la-hand-paper" style={{fontSize: '30px'}}></i>
                                                        </span>
                                                        <div className="media-body">
                                                            <span className="d-block text-primary mb-2">
                                                                {t('common.palpation')}
                                                            </span>
                                                            <p className="fs-14 text-dark">
                                                                <strong>{consultation.consultationQuestion?.palpations.toString()}</strong>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 mb-3">
                                                    <div className="media">
                                                        <span className="p-2 border border-primary-light rounded-circle mr-3">
                                                            <i className="las la-stethoscope" style={{fontSize: '30px'}}></i>
                                                        </span>
                                                        <div className="media-body">
                                                            <span className="d-block text-primary mb-2">
                                                                {t('common.auscultation')}
                                                            </span>
                                                            <p className="fs-14 text-dark">
                                                                <strong>{consultation.consultationQuestion?.oscultations.toString()}</strong>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 mb-3">
                                                    <div className="media">
                                                        <span className="p-2 border border-primary-light rounded-circle mr-3">
                                                            <i className="las la-gavel" style={{fontSize: '30px'}}></i>
                                                        </span>
                                                        <div className="media-body">
                                                            <span className="d-block text-primary mb-2">
                                                                {t('common.percussion')}
                                                            </span>
                                                            <p className="fs-14 text-dark">
                                                                <strong>{consultation.consultationQuestion?.percussions.toString()}</strong>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 mb-3">
                                                    <div className="media">
                                                        <span className="p-2 border border-primary-light rounded-circle mr-3">
                                                            <i className="las la-gavel" style={{fontSize: '30px'}}></i>
                                                        </span>
                                                        <div className="media-body">
                                                            <span className="d-block text-primary mb-2">
                                                                {t('common.observations')}
                                                            </span>
                                                            <p className="fs-14 text-dark">
                                                                {consultation.consultationQuestion?.observations}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </PerfectScrollbar>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-12">
                                <div className="card">
                                    <div className="card-header">
                                        <h4 className="card-title">{t('common.prescriptions')}</h4>
                                    </div>
                                    <div className="card-body">
                                        <div>
                                                
                                        <Accordion
                                                className='accordion accordion-danger-solid'
                                                defaultActiveKey='0'
                                            >
                                                {defaultAccordion.map((data, i) => (
                                                <div className='accordion__item' key={i}>
                                                    <Accordion.Toggle
                                                    className={`accordion__header ${
                                                        activeBordered !== i ? 'collapsed' : ''
                                                    }`}
                                                    as={Card.Text}
                                                    eventKey={`${i}`}
                                                    onClick={() =>
                                                        setActiveBordered(activeBordered === i ? -1 : i)
                                                    }
                                                    >
                                                        {' '}
                                                        <span className='accordion__header--text w100 d-flex justify-content-between'>
                                                            {data.title+(
                                                            i===0 ? '('+consultation.consultationBon?.prescription.examensBon?.reduce((sum, elt) => {return sum + elt.examenBonDetail.length}, 0)+')' : (
                                                                i===1 ? '('+consultation.consultationBon?.prescription.pharmacieBon?.reduce((sum, elt) => {return sum + elt.pharmacieBonDetails.length}, 0)+')' : (
                                                                    i===2 ? '('+consultation.consultationBon?.prescription.acteSpecialiseBon?.reduce((sum, elt) => {return sum + elt.specialiseBonDetails.length}, 0)+')' : 
                                                                        i===3 ? '('+consultation.consultationBon?.prescription.hospitalisationBon?.reduce((sum, elt) => {return sum + elt.hospitalisationBonDetail.length}, 0)+')' :
                                                                            '('+consultation.consultationBon?.prescription.opticBon?.reduce((sum, elt) => {return sum + elt.opticBonDetails.length}, 0)+')'
                                                                )
                                                            )
                                                            )
                                                            }
                                                        </span>
                                                        <span className='accordion__header--indicator'></span>
                                                    </Accordion.Toggle>
                                                    <Accordion.Collapse
                                                    eventKey={`${i}`}
                                                    className='accordion__body'
                                                    >
                                                    <div className='row accordion__body--text'>
                                                        {i===0 &&
                                                            <>
                                                                {
                                                                    consultation.consultationBon?.prescription.examensBon?.map(examen => {
                                                                        return <div className="col-12">
                                                                            <h3 className='mt-2 text-dark text-start'>{t('common.bonNum')}: {examen.id}</h3>
                                                                            <div className="border-top my-2"></div>
                                                                            <div className="row">
                                                                                {
                                                                                    examen.examenBonDetail?.map(detail => {
                                                                                        return <Col xl='4'>
                                                                                        <Card className='text-white bg-info'>
                                                                                        <Card.Header>
                                                                                            <Card.Title className='text-white'>{t('common.code')} Examen: {detail.acteCode}</Card.Title>
                                                                                        </Card.Header>
                                                                                        <Card.Body className=' mb-0'>
                                                                                            <Card.Text>
                                                                                                {detail.acteName}
                                                                                            </Card.Text>
                                                                                            {
                                                                                                detail.executedBy && 
                                                                                                <div className="row">
                                                                                                    <Button
                                                                                                    as='a'
                                                                                                    variant='success light'
                                                                                                    href={detail.examenResult[0].resultUrl}
                                                                                                    target="_blank"
                                                                                                    className='btn-card mt-3 mr-3'
                                                                                                    >
                                                                                                    <i className="la la-file-download"></i>
                                                                                                    {t('common.results')}
                                                                                                    </Button>
                                                                                                    
                                                                                                    {/*<Button
                                                                                                    as='a'
                                                                                                    variant='success light'
                                                                                                    href='#'
                                                                                                    className='btn-card mt-3'
                                                                                                    onClick={(e) => {
                                                                                                        e.preventDefault();
                                                                                                        setCurrentKey(detail.id);
                                                                                                    }}
                                                                                                    >
                                                                                                    Commentaires
                                                                                                    </Button>

                                                                                                    <Modal
                                                                                                        className="fade bd-example-modal-lg"
                                                                                                        show={currentKey === detail.id}
                                                                                                        size="lg"
                                                                                                    >
                                                                                                        <CommentaireModal 
                                                                                                            showModal={currentKey === detail.id}
                                                                                                            commentaire={detail.examenResult[0].resultDescription}
                                                                                                            sentive={detail.examenResult[0].isSensitive} 
                                                                                                            examen={detail}
                                                                                                            manageCommentModal={manageCommentModal}
                                                                                                            history={history}
                                                                                                        />
                                                                                                    </Modal>*/}
                                                                                                </div>
                                                                                            }
                                                                                        </Card.Body>
                                                                                        <Card.Footer className=' bg-transparent border-0 text-white'>
                                                                                            <p>{t('statuses.'+detail.status)}</p>
                                                                                            {detail.status!== STATUSES.pending && <>
                                                                                                <p className="font-weight-bold" >{t('common.exec-by')}: {detail.executedBy}</p>
                                                                                                <p  className="font-weight-bold" >{t('common.exec-on')}: {format(new Date(detail.executeDate), "dd/MM/yyyy hh:mm")}</p>
                                                                                            </>}
                                                                                            {detail.status === STATUSES.pending && <>
                                                                                                <p className="text-warning font-weight-bold" >{t('common.waiting-for-permit')}</p>
                                                                                            </>}
                                                                                        </Card.Footer>
                                                                                        </Card>
                                                                                    </Col>
                                                                                    })
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    })
                                                                }
                                                            </>
                                                        }
                                                        {i===1 &&
                                                            <>
                                                                {
                                                                    consultation.consultationBon?.prescription.pharmacieBon?.map(bon => {
                                                                        return <div className="col-12">
                                                                            <h3 className='mt-2 text-dark text-start'>{t('common.code')}: {bon.id}</h3>
                                                                            <div className="border-top my-2"></div>
                                                                            <div className="row">
                                                                                {
                                                                                    bon.pharmacieBonDetails?.map(detail => {
                                                                                        return <Col xl='4'>
                                                                                        <Card className={subsRequests?.filter(req =>{return req.oldMedicamentId === detail.medicament.id}).length > 0 ? 'text-white bg-warning' : 'text-white bg-success'}>
                                                                                        <Card.Header>
                                                                                            <Card.Title className='text-white'>{t('common.code')} Medicament: {detail.medicament.medicamentCode}</Card.Title>
                                                                                        </Card.Header>
                                                                                        <Card.Body className=' mb-0'>
                                                                                            <Card.Text>
                                                                                            {detail.medicament.name}
                                                                                            {subsRequests?.filter(req =>{return req.oldMedicamentId === detail.medicament.id}).length > 0 && subsRequests?.filter(req =>{return req.oldMedicamentId === detail.medicament.id})[0].status === STATUSES.pending && 
                                                                                            <div className="row p-2 mt-2 rounded bg-light bg-opacity-10">
                                                                                                <small className="text-dark"><b >{t('common.pending-demand')}</b></small>
                                                                                                <span className="text-primary my-2">{subsRequests?.filter(req =>{return req.oldMedicamentId === detail.medicament.id})[0]?.newMedicament.name}</span>
                                                                                                <div className="d-flex justify-content-end mt-4">
                                                                                                    <Button
                                                                                                    as='a'
                                                                                                    variant='success light'
                                                                                                    target="_blank"
                                                                                                    className='btn-card mr-3'
                                                                                                    onClick={(e) => respondDemande(true, subsRequests?.filter(req =>{return req.oldMedicamentId === detail.medicament.id})[0]?.id)}
                                                                                                    >
                                                                                                    <i className="fa fa-check"></i>
                                                                                                    {t('common.accept')}
                                                                                                    </Button>
                                                                                                    
                                                                                                    <Button
                                                                                                    as='a'
                                                                                                    variant='danger light'
                                                                                                    href='#'
                                                                                                    className='btn-card'
                                                                                                    onClick={(e) => respondDemande(false, subsRequests?.filter(req =>{return req.oldMedicamentId === detail.medicament.id})[0]?.id)}
                                                                                                    >
                                                                                                    <i className="fa fa-close"></i>
                                                                                                    {t('common.reject')}
                                                                                                    </Button>
                                                                                                </div>
                                                                                            </div>}
                                                                                            </Card.Text>
                                                                                        </Card.Body>
                                                                                        <Card.Footer className=' bg-transparent border-0 text-white'>
                                                                                            <p>{detail.isDelivery ? "Livré" : t('common.not-delivered')}</p>
                                                                                            {detail.isDelivery && <>
                                                                                                <p className="font-weight-bold">Livré par: {detail.deliveryName}</p>
                                                                                                <p  className="font-weight-bold">Livré le: {detail.deliveryDate ? format(new Date(detail.deliveryDate), "dd/MM/yyyy hh:mm") : ""}</p>
                                                                                            </>}
                                                                                        </Card.Footer>
                                                                                        </Card>
                                                                                    </Col>
                                                                                    })
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    })
                                                                }
                                                            </>
                                                        }
                                                        {i===2 &&
                                                            <>
                                                                {
                                                                    consultation.consultationBon?.prescription.acteSpecialiseBon?.map(bon => {
                                                                        return <div className="col-12">
                                                                            <h3 className='mt-2 text-dark text-start'>{t('common.bonNum')}: {bon.id}</h3>
                                                                            <div className="border-top my-2"></div>
                                                                            <div className="row">
                                                                                {
                                                                                    bon.specialiseBonDetails?.map(detail => {
                                                                                        return <Col xl='4'>
                                                                                        <Card className='text-white bg-primary'>
                                                                                        <Card.Header>
                                                                                            <Card.Title className='text-white'>{t('common.code')} {t('provider.acte')}: {detail.acteId}</Card.Title>
                                                                                        </Card.Header>
                                                                                        <Card.Body className=' mb-0'>
                                                                                            <Card.Text>
                                                                                            {detail.acteName}
                                                                                            </Card.Text>
                                                                                        </Card.Body>
                                                                                        <Card.Footer className=' bg-transparent border-0 text-white'>
                                                                                            {detail.executedBy ? "Exécuté" : "Non Exécuté"}
                                                                                            {detail.executedBy && <>
                                                                                                <p className="font-weight-bold" >{t('common.exec-by')}: {detail.executedBy}</p>
                                                                                                <p  className="font-weight-bold" >{t('common.exec-on')}: {format(new Date(detail.executeDate), "dd/MM/yyyy hh:mm")}</p>
                                                                                            </>}
                                                                                        </Card.Footer>
                                                                                        </Card>
                                                                                    </Col>
                                                                                    })
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    })
                                                                }
                                                            </>
                                                        }
                                                        {i===3 &&
                                                            <>
                                                                {
                                                                    consultation.consultationBon?.prescription.hospitalisationBon?.map(bon => {
                                                                        return <div className="col-12">
                                                                            <h3 className='mt-2 text-dark text-start'>{t('common.bonNum')}: {bon.id}</h3>
                                                                            <div className="border-top my-2"></div>
                                                                            <div className="row">
                                                                                {
                                                                                    bon.hospitalisationBonDetail?.map(detail => {
                                                                                        return <Col xl='4'>
                                                                                        <Card className='text-white bg-warning'>
                                                                                        <Card.Header>
                                                                                            <Card.Title className='text-white'>{t('common.code')} {t('provider.acte')}: {detail.medicamentCode}</Card.Title>
                                                                                        </Card.Header>
                                                                                        <Card.Body className=' mb-0'>
                                                                                            <Card.Text>
                                                                                                <p>{detail.acteName} - {detail.montant}</p>
                                                                                                <p>{detail.quantite} {t('common.days')}</p>
                                                                                            </Card.Text>
                                                                                        </Card.Body>
                                                                                        <Card.Footer className=' bg-transparent border-0 text-white'>
                                                                                            {detail.status}
                                                                                        </Card.Footer>
                                                                                        </Card>
                                                                                    </Col>
                                                                                    })
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    })
                                                                }
                                                            </>
                                                        }
                                                        {i===4 &&
                                                            <>
                                                                {
                                                                    consultation.consultationBon?.prescription.opticBon?.map(bon => {
                                                                        return <div className="col-12">
                                                                            <h3 className='mt-2 text-dark text-start'>{t('common.bonNum')}: {bon.id}</h3>
                                                                            <div className="border-top my-2"></div>
                                                                            <div className="row">
                                                                                {
                                                                                    bon.opticBonDetails?.map(detail => {
                                                                                        return <Col xl='4'>
                                                                                        <Card className='text-white bg-secondary'>
                                                                                        <Card.Header>
                                                                                            <Card.Title className='text-white'>{t('common.code')} {t('provider.acte')}: {detail.acteId}</Card.Title>
                                                                                        </Card.Header>
                                                                                        <Card.Body className=' mb-0'>
                                                                                            <Card.Text>
                                                                                                <p>{detail.acteName}</p>
                                                                                            </Card.Text>
                                                                                        </Card.Body>
                                                                                        <Card.Footer className=' bg-transparent border-0 text-white'>
                                                                                            {detail.status}
                                                                                        </Card.Footer>
                                                                                        </Card>
                                                                                    </Col>
                                                                                    })
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    })
                                                                }
                                                            </>
                                                        }
                                                    </div>
                                                    </Accordion.Collapse>
                                                </div>
                                                ))}
                                            </Accordion>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <>
                        { showAccordLoading && 
                        <div id="preloader">
                            <div className="sk-three-bounce">
                                <div className="sk-child sk-bounce1"></div>
                                <div className="sk-child sk-bounce2"></div>
                                <div className="sk-child sk-bounce3"></div>
                            </div>
                        </div> }
                        {accordPreas?.filter(acc => {return acc.dossierConsultationId === consultation.id}).length > 0 && showDiscuss && !showAccordLoading &&
                            <div className="col-4">
                                {accordPreas?.filter(acc => {return acc.dossierConsultationId === consultation.id})?.map(accordBlock => {
                                    return <div className="row mb-4">
                                        <div className="card chat dz-chat-history-box">
                                        <div className="card-header chat-list-header justify-content-center text-center">
                                            <div>
                                                <h6 className="mb-1">{accordBlock?.dossierConsultation?.consultationBon?.doctorName}</h6>
                                                <p className="mb-0 text-success">{accordBlock?.acteName}</p>
                                            </div>
                                        </div>
                                        <PerfectScrollbar
                                            className="card-body msg_card_body dz-scroll ps ps--active-y height370"
                                            id="DZ_W_Contacts_Body3"
                                            scrollTop="100"
                                        >
                                            {  accordBlock?.accordPreableDisccusions?.map(messag => {
                                                if(messag.senderId === currentUser.id){
                                                    return <div className="d-flex justify-content-end mb-4">
                                                                <div className="msg_cotainer_send">
                                                                    {messag.message}
                                                                    {messag.docUrl && <a
                                                                        href={messag.docUrl}
                                                                        target="_blank"
                                                                        className="btn btn-light btn-rounded py-1"
                                                                        >
                                                                        <i className="las la-file-download mr-2"></i>
                                                                        <small>{t('common.document')}</small>
                                                                    </a>}
                                                                    <span className="msg_time_send">{messag.createdAt}</span>
                                                                </div>
                                                                <div className="img_cont_msg">
                                                                    <img
                                                                        src={avatar2}
                                                                        className="rounded-circle user_img_msg"
                                                                        alt=""
                                                                    />
                                                                </div>
                                                            </div>
                                                }else{
                                                    return <div className="d-flex justify-content-start mb-4">
                                                    <div className="img_cont_msg">
                                                        <img
                                                            src={avatar1}
                                                            className="rounded-circle user_img_msg"
                                                            alt=""
                                                        />
                                                    </div>
                                                    <div className="msg_cotainer">
                                                        {messag.message}
                                                        {messag.docUrl && <a
                                                            href={messag.docUrl}
                                                            target="_blank"
                                                            className="btn btn-light btn-rounded py-1"
                                                            >
                                                            <i className="las la-file-download mr-2"></i>
                                                            <small>{t('common.document')}</small>
                                                        </a>}
                                                        <span className="msg_time">{messag.createdAt}</span>
                                                    </div>
                                                </div>

                                                }
                                            })}
                                        </PerfectScrollbar>
                                        <div className="card-footer">
                                            <div className="mb-2">
                                                {suggestions.map((suggestion, i) => {
                                                    return (
                                                        <Badge variant="dark light mr-1 mb-1" key={i} onClick={(e) => setMessage(suggestion)}>
                                                            <span>{suggestion}</span>
                                                        </Badge>
                                                    )
                                                })}
                                            </div>
                                            <form onSubmit={e => sendMessage(e, accordPreas?.filter(acc => {return acc.dossierConsultationId === consultation.id})[0]?.id)}>
                                                <div className="row">
                                                    <textarea
                                                        value={message}
                                                        onChange={(e) => setMessage(e.target.value)}
                                                        className="form-control mb-2"
                                                        cols={20}
                                                        required
                                                        placeholder="Type your message..."
                                                    ></textarea>
                                                </div>
                                                <div className='row mb-3'>
                                                    <div className='custom-file'>
                                                        <input type="file" className='custom-file-input' onChange={(e) => loadFile(e)} />
                                                        <label className='custom-file-label'>{fileSrc ? <small>{fileSrc.name}</small> : 'Choisir'}</label>
                                                    </div>
                                                </div>
                                                <div className=" row input-group-append d-flex justify-content-end">
                                                    <button type="submit" className="btn btn-primary">
                                                        <i className="fa fa-location-arrow"></i>
                                                    </button>
                                                </div>
                                            </form>
                                        </div>
                                        </div>
                                    </div>
                                })}
                            </div>
                        }
                    </>
                </div>

            </>
            }
        </>
    )
};
/* --- END DetailsConsultation */

const mapStateToProps = (state) => {
    return {
        consultation: state.consultation.consultation,
        subsRequests: state.pharmacie.subsRequests,
        accordPreas: state.accordPrea.accordPreas,
        currentUser: currentUser(state),
        showLoading: state.consultation.showLoading,
        showAccordLoading: state.accordPrea.showLoading,
        updatQuantiteDone: state.consultation.updatQuantiteDone,
    };
};

export default connect(mapStateToProps)(DetailsConsultation);