import axios from 'axios';

const headers = { 'Authorization': 'Bearer '+JSON.parse(localStorage.getItem('userDetails'))?.token, 'Accept-Language': localStorage.getItem('currentLanguage')};

const ConsultServiceRequest = axios.create({
    baseURL: process.env.REACT_APP_BASEURL_CONSULTATION_BACKEND,
    headers: headers
});


//Dashboard
export const getdataDashboard = (data) => ConsultServiceRequest.get('/dashboard', {params: data});
export const getRapportAmbuHospi = (data) => ConsultServiceRequest.get('/rapport-ambu-hospi/'+data.dateDebut+'/'+data.dateFin/*, {params: data}*/);
