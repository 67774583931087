import { toast } from 'react-toastify'

export const errorToastr = (message) => {
    toast.error(
        '❌ '+message, 
        {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
        }
    );
};

export const successToastr = (message) => {
    toast.success(
        '✅ '+message, 
        {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
        }
    );
};

export const infoToastr = (message) => {
    toast.info(
        '❓ '+message, 
        {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
        }
    );
};

export const warningToastr = (message) => {
    toast.warn(
        '❗ '+message, 
        {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
        }
    );
};