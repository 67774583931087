import React, { useState } from "react";
import { connect, useDispatch } from 'react-redux';
import { Button, Modal } from "react-bootstrap";
import { updateQuantityAction, startLoader } from "../../../store/actions/ConsultationActions";
import { useTranslation } from "react-i18next";

/* --- BEGIN QuantiteModal
	@param(Object): props
    description: Modale de changement de la quantité de médicaments prescrite
*/
export const QuantiteModal = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { quantite, manageQuantiteModal, history, drug, sentive } = props;
    const [error, setError] = useState(null);
    const [quantity, setQuantity] = useState(quantite || 1);

    
    /* --- BEGIN changeQuantityRequest
        @param(): aucun
        description: Fonction de changement de la quantité de médicaments prescrite
    */
    const changeQuantityRequest = () => {
        if(quantity === 0) {
            setError(t('common.quantity-message'));
            return;
        }
        dispatch(startLoader());
        dispatch(updateQuantityAction(drug.id, quantity));
        manageQuantiteModal(false, true);

    }
    /* --- END changeQuantityRequest */

    return (
            <>
                    <Modal.Header>
                        <Modal.Title className="text-center" >
                                {t('common.update-quantity')}{' '}<br/>
                                <b className="text-primary" >{drug.medicament.name}</b>
                        </Modal.Title>
                        <Button
                            variant=""
                            className="close"
                            onClick={() => manageQuantiteModal(false)}
                        >
                            <span>&times;</span>
                        </Button>
                    </Modal.Header>
                    <Modal.Body>
                        
                        { error && <small className="text-danger">{{error}}</small> }

                        <form onSubmit={(e) => e.preventDefault()}>

                            <div className='form-group'>
                                <input type="number" className='form-control' min={quantity} value={quantity} onChange={e => setQuantity(e.target.value)} />
                            </div>
                        </form>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant="danger light"
                            onClick={() => manageQuantiteModal(false, false)}
                        >
                           {t('common.cancel')}
                        </Button>
                        <Button
                            variant=""
                            type="button"
                            className="btn btn-primary"
                            disabled={quantity <= 0}
                            onClick={() => changeQuantityRequest()}
                        >
                           {t('client.submit')}
                        </Button>
                    </Modal.Footer>
            </>
    )
};
/* --- END QuantiteModal */

const mapStateToProps = (state) => {
    return {
        drugList: state.consultation.drugs
    };
};

export default connect(mapStateToProps)(QuantiteModal);