/// Menu
import MetisMenu from "metismenujs";
import React, { Component } from "react";
import { connect } from 'react-redux';
/// Scroll
import PerfectScrollbar from "react-perfect-scrollbar";
/// Link
import { Link } from "react-router-dom";

import { currentUser } from '../../../store/selectors/CurrentUserSelector';
import { withTranslation } from 'react-i18next';


class MM extends Component {
  componentDidMount() {
    this.$el = this.el;
    this.mm = new MetisMenu(this.$el);
  }
  componentWillUnmount() {
  }
  render() {
    return (
      <div className="mm-wrapper">
        <ul className="metismenu" ref={(el) => (this.el = el)}>
          {this.props.children}
        </ul>
      </div>
    );
  }
}

class SideBar extends Component {
  /// Open menu
  componentDidMount() {
    // sidebar open/close
    var btn = document.querySelector(".nav-control");
    var aaa = document.querySelector("#main-wrapper");
    function toggleFunc() {
      return aaa.classList.toggle("menu-toggle");
    }
    btn.addEventListener("click", toggleFunc);
  }
  state = {
    loveEmoji: false
  };

  hasAccess(menuName) {
      return this.props.currentUser.menus?.filter(menu => menu.code === menuName).length > 0;
  }
  
  canDo(menuCode, actionCode){
    let theMenu = this.props.currentUser.menus?.find(menu => menu.code === menuCode);
    if(!theMenu){
      return false;
    }else{
      return theMenu.actionMenus?.filter(action => action.code === actionCode).length > 0;
    }
  }

  hasMenu(menuCode) {
      return this.props.currentUser.menus?.filter(menu => menu.code === menuCode).length > 0;
  }

  render() {
    /// Path
    let path = window.location.pathname;
    path = path.split("/");
    path = path[path.length - 1];

    /// Active menu
    let dashBoard = [
      "",
    ]

    let authentification = [
      "patient-list",
      "provider-view"
    ];
    
    /// Active menu
    let clients = [
      "client-list",
      "client-group-register",
      "assure-search"
  ];
  /// Active menu
  let providers = [
    "provider-list",
    "provider-form",
  ];

  /// Active menu
  let accordsPrea = [
    "accords-list",
    "proformas"
  ];

  let settings = [
    "Parametrage",
    "user-profil",
    "provider-setting",
    "parametre-generaux"
  ]

  /// Active menu
  let consultations = [
    "consultation-list",
  ];

  /// Active menu
  let facturation = [
    "facturation"
  ];

  /// Active menu
  let remboursement = [
    "remboursement-form",
    "demande-remboursement",
    "remboursement-list"
  ];

  /// Active menu
  let rapport = [
    "rapportform",
    "consommation-list",
    "etats-prestataires"
  ];

  /// Active menu
  let prestations = [
    "prestations"
  ];

  let monitoring = [
    "monitoring",
    "historiques-avenants",
    "codes-validation",
    "historique-identifications"
  ];

  let avenants = [
    "demandes-avenant"
  ]


    return (
      <div className="deznav">
        <PerfectScrollbar className="deznav-scroll">
          <MM className="metismenu" id="menu">
            <li className={`${dashBoard.includes(path) ? "mm-active" : ""}`}>
              <Link className="has-arrow ai-icon" to="#" >
                <i className="la la-chart-bar"></i>
                <span className="nav-text">Dashboard</span>
              </Link>
              <ul >
                <li>
                  <Link className={`${path === "" ? "mm-active" : ""}`} to="" onClick={() => {
                      this.props.onClick3();
                      this.props.closeMenu();
                    }}>
                    <i className="la la-chalkboard"></i>
                    <span className="nav-text">Dashboard</span>
                  </Link>
                </li>
              </ul>
            </li>

            
            {(this.hasMenu('MENIDENT') || this.hasMenu('MENENROLL')) && <li className={`${authentification.includes(path) ? "mm-active" : ""}`}>
              <Link className="has-arrow ai-icon" to="#" >
                <i className="la la-user-cog"></i>
                <span className="nav-text">{this.props.t('layout.authentication')}</span>
              </Link>
              <ul >
                <li>
                  <Link className={`${path === "" ? "mm-active" : ""}`} onClick={() => this.props.onClick()} to="/identification">
                    <i className="la la-address-card"></i>
                    <span className="nav-text">{this.props.t('layout.patient-identification')}</span>
                  </Link>
                </li>
                {this.hasAccess('ProfilPrestataire') && <li>
                  <Link className={`${path === "patient-list" ? "mm-active" : ""}`} onClick={() => this.props.onClick()} to="/client-search">
                    <i className="la la-user-injured"></i>
                    <span className="nav-text">{this.props.t('layout.patient-enrollment')}</span>
                  </Link>
                </li>}
                {this.hasAccess('ProfilPrestataire') && <li>
                  <Link className={`${path === "assure-search" ? "mm-active" : ""}`} onClick={() => this.props.onClick()} to="/assure-search">
                    <i className="la la-user-injured"></i>
                    <span className="nav-text">Assurés enrôlés</span>
                  </Link>
                </li>}
              </ul>
            </li>}

            {this.hasMenu('MENENROLL') && <li className={`${clients.includes(path) ? "mm-active" : ""}`}>
              <Link className="has-arrow ai-icon" to="#" >
                <i className="la la-user-cog"></i>
                <span className="nav-text">{this.props.t('layout.client')}</span>
              </Link>
              <ul >
                <li>
                  <Link className={`${path === "" ? "mm-active" : ""}`} to="/client-list" onClick={() => this.props.onClick3()}>
                    <i className="la la-users"></i>
                    <span className="nav-text">{this.props.t('layout.all-client')}</span>
                  </Link>
                </li>
                <li>
                  <Link className={`${path === "client-group-register" ? "mm-active" : ""}`} onClick={() => this.props.onClick()} to="/client-group-register">
                    <i className="la la-user-alt"></i>
                    <span className="nav-text">{this.props.t('layout.client-group-save')}</span>
                  </Link>
                </li>
              </ul>
            </li>}

            {this.hasMenu('MENPROVIDER') && <li className={`${providers.includes(path) ? "mm-active" : ""}`}>
              <Link className="has-arrow ai-icon" to="#" >
                <i className="la la-hospital"></i>
                <span className="nav-text" >{this.props.t('dashboard.providers')}</span>
              </Link>
              <ul >
                <li>
                  <Link className={`${path === "" ? "mm-active" : ""}`} to="/provider-list" onClick={() => this.props.onClick3()}>
                    <i className="la la-stream"></i>
                    <span className="nav-text">{this.props.t('layout.list')}</span>
                  </Link>
                </li>
                <li>
                  <Link className={`${path === "provider-form" ? "mm-active" : ""}`} onClick={() => this.props.onClick()} to="/provider-form">
                    
                    <i className="la la-hospital-alt"></i>
                    <span className="nav-text">{this.props.t('layout.new-provider')}</span>
                    
                  </Link>
                </li>
              </ul>
            </li>}
            {(this.hasMenu('MENPARAM') || this.hasMenu('MENPARAMUSER') || this.hasMenu('MENPARAMACTES') ) && 
              <li className={`${settings.includes(path) ? "mm-active" : ""}`}>
                <Link className="has-arrow ai-icon" to="#" >
                  <i className="la la-tools"></i>
                  <span className="nav-text">{this.props.t('layout.parameter')}</span>
                </Link>
                <ul>
                  {this.hasMenu('MENPARAM') && <li>
                    <Link className={`${path === "parametre-standarts" ? "mm-active" : ""}`} onClick={() => this.props.onClick()} to="/parametre-standarts">
                      
                      <i className="la la-tools"></i>
                      <span className="nav-text">{this.props.t('layout.standart-params')}</span>
                      
                    </Link>
                  </li>}
                  {this.hasMenu('MENPARAM') && <li>
                    <Link className={`${path === "parametrage" ? "mm-active" : ""}`} onClick={() => this.props.onClick()} to="/parametrage">
                      
                      <i className="la la-tools"></i>
                      <span className="nav-text">{this.props.t('layout.general-params')}</span>
                      
                    </Link>
                  </li>}
                  {this.hasMenu('MENPARAM') && <li>
                    <Link className={`${path === "user-profil" ? "mm-active" : ""}`} onClick={() => this.props.onClick()} to="/user-profil">
                      <i className="la la-users"></i>
                      <span className="nav-text"> {this.props.t('layout.user-roles')}</span>
                     
                    </Link>
                  </li>}
                  {this.hasMenu('MENPARAM') && <li>
                    <Link className={`${path === "provider-setting" ? "mm-active" : ""}`} onClick={() => this.props.onClick()} to="/provider-setting">
                      
                      <i className="la la-cogs"></i>
                      <span className="nav-text">{this.props.t('layout.act-prices')}</span>
                      
                    </Link>
                  </li>}
                </ul>
              </li>
            }
            {(this.hasMenu('MENACCORD') || this.hasMenu('MENPROFORMA')) && 
              <li className={`${accordsPrea.includes(path) ? "mm-active" : ""}`}>
                <Link className="has-arrow ai-icon" to="#" >
                  <i className="la la-hand-paper"></i>
                  <span className="nav-text">{this.props.t('layout.agreement')}</span>
                </Link>
                <ul>
                {this.hasMenu('MENACCORD') && <li>
                    <Link className={`${path === "accords-list" ? "mm-active" : ""}`} onClick={() => this.props.onClick()} to="/accords-list">
                      <i className="la la-praying-hands"></i>
                      <span className="nav-text">{this.props.t('layout.other-acts')}</span>  
                      
                    </Link>
                  </li>}
                  {this.hasMenu('MENPROFORMA') && <li>
                    <Link className={`${path === "proformas" ? "mm-active" : ""}`} onClick={() => this.props.onClick()} to="/proformas">
                      <i className="la la-glasses"></i>
                      <span className="nav-text">{this.props.t('layout.optics')}</span>  
                      
                    </Link>
                  </li>}
                  {this.hasMenu('MENPROFORMA') && <li>
                    <Link className={`${path === "proformas-kine" ? "mm-active" : ""}`} onClick={() => this.props.onClick()} to="/proformas-kine">
                      <i className="la la-wheelchair"></i>
                      <span className="nav-text">{this.props.t('layout.kines')}</span>  
                      
                    </Link>
                  </li>}
                </ul>
              </li>
            }
            {this.hasMenu('MENCONSULTADMIN') && <li className={`${consultations.includes(path) ? "mm-active" : ""}`}>
              <Link className="has-arrow ai-icon" to="#" >
                <i className="la la-stethoscope"></i>
                <span className="nav-text" >{this.props.t('dashboard.consults')}</span>
              </Link>
              <ul >
                <li>
                  <Link className={`${path === "consultation-list" ? "mm-active" : ""}`} onClick={() => this.props.onClick()} to={"/consultation-list"}>
                    <i className="la la-notes-medical"></i>
                    <span className="nav-text">{this.props.t('layout.consult-folders')}</span>
                  </Link>

                </li>
              </ul>
            </li>}
            {this.hasMenu('MENFACTUREADMIN') && <li className={`${facturation.includes(path) ? "mm-active" : ""}`}>
              <Link className="has-arrow ai-icon" to="#" >
                <i className="la la-file-invoice-dollar"></i>
                <span className="nav-text">{this.props.t('layout.bills')}</span>
              </Link>
              <ul >
                <li>
                  <Link className={`${path === "facturation" ? "mm-active" : ""}`} onClick={() => this.props.onClick()} to={"/facturation"}>
                    <i className="la la-file-invoice"></i>
                    <span className="nav-text">{this.props.t('layout.history')}</span>
                  </Link>
                </li>
              </ul>
            </li>}
            {this.hasMenu('MENREMBOURS') && <li className={`${remboursement.includes(path) ? "mm-active" : ""}`}>
              <Link className="has-arrow ai-icon" to="#" >
                <i className="la la-file-invoice-dollar"></i>
                <span className="nav-text">{this.props.t('layout.payback')}</span>
              </Link>
              <ul >
                <li>
                  <Link className={`${path === "demande-remboursement" ? "mm-active" : ""}`} onClick={() => this.props.onClick()} to={"/demande-remboursement"}>
                    <i className="la la-file-invoice"></i>
                    <span className="nav-text">{this.props.t('layout.request')}</span>
                  </Link>
                </li>
                <li>
                  <Link className={`${path === "remboursement-list" ? "mm-active" : ""}`} onClick={() => this.props.onClick()} to={"/remboursement-list"}>
                    <i className="la la-file-invoice"></i>
                    <span className="nav-text">{this.props.t('layout.list')}</span>
                  </Link>
                </li>
              </ul>
            </li>}
            {this.hasMenu('MENPRESTATION') && <li className={`${prestations.includes(path) ? "mm-active" : ""}`}>
              <Link className="has-arrow ai-icon" to="#" >
                <i className="la la-file-invoice-dollar"></i>
                <span className="nav-text" >{this.props.t('dashboard.prestations')}</span>
              </Link>
              <ul >
                <li>
                  <Link className={`${path === "prestations" ? "mm-active" : ""}`} onClick={() => this.props.onClick()} to={"/prestations"}>
                    <i className="la la-file-invoice"></i>
                    <span className="nav-text">{this.props.t('layout.history')}</span>
                  </Link>
                </li>
              </ul>
            </li>}
            {this.hasMenu('MENREPORTING') && <li className={`${rapport.includes(path) ? "mm-active" : ""}`}>
              <Link className="has-arrow ai-icon" to="#" >
                <i className="la la-file-invoice-dollar"></i>
                <span className="nav-text">{this.props.t('dashboard.souscipt')}</span>
              </Link>
              <ul >
                <li>
                  <Link className={`${path === "etats-prestataires" ? "mm-active" : ""}`} onClick={() => this.props.onClick()} to={"/etats-prestataires"}>
                    <i className="la la-file-invoice"></i>
                    <span className="nav-text">{this.props.t('layout.state')}</span>
                  </Link>
                </li>
                <li>
                  <Link className={`${path === "rapport-global" ? "mm-active" : ""}`} onClick={() => this.props.onClick()} to={"/rapport-global"}>
                    <i className="la la-file-invoice"></i>
                    <span className="nav-text">{this.props.t('layout.global-state')}</span>
                  </Link>
                </li>
              </ul>
            </li>}
            {this.hasMenu('MENMONITORING') && <li className={`${monitoring.includes(path) ? "mm-active" : ""}`}>
              <Link className="has-arrow ai-icon" to="#" >
                <i className="la la-desktop"></i>
                <span className="nav-text">{this.props.t('layout.monitor')}</span>
              </Link>
              <ul >
                <li>
                  <Link className={`${path === "monitoring" ? "mm-active" : ""}`} onClick={() => this.props.onClick()} to={"/monitoring"}>
                    <i className="la la-desktop"></i>
                    <span className="nav-text">{this.props.t('layout.monitor')}</span>
                  </Link>
                </li>
                <li>
                  <Link className={`${path === "codes-validation" ? "mm-active" : ""}`} onClick={() => this.props.onClick()} to={"/codes-validation"}>
                    <i className="la la-desktop"></i>
                    <span className="nav-text">{this.props.t('layout.validation-code')}</span>
                  </Link>
                </li>
                <li>
                  <Link className={`${path === "historique-identifications" ? "mm-active" : ""}`} onClick={() => this.props.onClick()} to={"/historique-identifications"}>
                    <i className="la la-desktop"></i>
                    <span className="nav-text">{this.props.t('layout.auth-history')}</span>
                  </Link>
                </li>
              </ul>
            </li>}
            {this.hasMenu('MENAVENADMIN') && <li className={`${avenants.includes(path) ? "mm-active" : ""}`}>
              <Link className="has-arrow ai-icon" to="#" >
                <i className="la la-file-invoice-dollar"></i>
                <span className="nav-text">Demandes d'avenant</span>
              </Link>
              <ul >
                <li>
                  <Link className={`${path === "demandes-avenant" ? "mm-active" : ""}`} onClick={() => this.props.onClick()} to={"/demandes-avenant"}>
                    <i className="la la-file-invoice"></i>
                    <span className="nav-text">Historique</span>
                  </Link>
                </li>
              </ul>
            </li>}
          </MM>
        </PerfectScrollbar>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
      currentUser: currentUser(state),
      authData: state.auth.auth,
  };
};

export default withTranslation()(connect(mapStateToProps)(SideBar));
