import {getCountries, getWorkingFields, getGlobalActs, mapGlobalAct, updateGlobalActMapping, deleteGlobalAct,
getParamIdent, mapParamIdent, updateParamIdent, deleteParamIdent} from '../../services/ParameterService';

import { errorToastr, successToastr } from '../../Utils/ToastrMessage';

export const LIST_COUNTRIES_CONFIRMED_ACTION = '[listCountries action] confirmed listCountries';
export const LIST_COUNTRIES_FAILED_ACTION = '[listCountries action] failed listCountries';
export const LIST_WORKINGFIELDS_CONFIRMED_ACTION = '[listWorkingFields client action] confirmed listWorkingFields';
export const LIST_WORKINGFIELDS_FAILED_ACTION = '[listWorkingFields client action] failed listWorkingFields';

export const LIST_GLOBAL_ACTS_CONFIRMED_ACTION = '[listGlobalActs client action] confirmed listGlobalActs';
export const LIST_GLOBAL_ACTS_FAILED_ACTION = '[listGlobalActs client action] failed listGlobalActs';

export const MAP_GLOBAL_ACTS_FAILED_ACTION = '[mapGlobalActs client action] failed mapGlobalActs';

export const UPDATE_GLOBAL_ACTS_MAPPING_FAILED_ACTION = '[updateGlobalActsMapping client action] failed updateGlobalActsMapping';

export const DELETE_GLOBAL_ACTS_FAILED_ACTION = '[deleteGlobalActs client action] failed deleteGlobalActs';

export const LIST_PARAM_IDENT_CONFIRMED_ACTION = '[listParamIdent client action] confirmed listParamIdent';
export const LIST_PARAM_IDENT_FAILED_ACTION = '[listParamIdent client action] failed listParamIdent';

export const MAP_PARAM_IDENT_FAILED_ACTION = '[mapParamIdent client action] failed mapParamIdent';

export const UPDATE_GPARAM_IDENT_MAPPING_FAILED_ACTION = '[updateParamIdent client action] failed updateParamIdent';

export const DELETE_PARAM_IDENT_FAILED_ACTION = '[deleteParamIdent client action] failed deleteParamIdent';

export const START_LOADER_ACTION = '[parameterGenLoader action] started parameterGenLoader';

var I18n = require('react-redux-i18n').I18n;

export const listCountriesAction = () => {
    return (dispatch) => {

        getCountries()
        .then((response) => {
            dispatch(confirmedCountriesListingAction([response.data]));
        })
        .catch((error) => {
            console.log(error);
            dispatch(countriesListingFailedAction(error.response?.data?.message));
        });
    };
};

export const listWorkingFieldsAction = () => {
    return (dispatch) => {

        getWorkingFields()
        .then((response) => {
            dispatch(confirmedWorkingFieldsListingAction(response.data));
        })
        .catch((error) => {
            console.log(error);
            dispatch(workingFieldsListingFailedAction(error.response?.data?.message));
        });
    };
};

export const listGlobalActsAction = () => {
    return (dispatch) => {

        getGlobalActs()
        .then((response) => {
            dispatch(confirmedGlobalActsListingAction(response.data));
        })
        .catch((error) => {
            console.log(error);
            errorToastr(I18n.t('parameter.globalAct.error')+error.response?.data?.message);
            dispatch(globalActsListingFailedAction(error.response?.data?.message));
        });
    };
};

export const mapGlobalActAction = (params) => {
    return (dispatch) => {

        mapGlobalAct(params)
        .then((response) => {
            successToastr(I18n.t('parameter.globalAct.success_mapping'));
            dispatch(listGlobalActsAction());
        })
        .catch((error) => {
            console.log(error);
            errorToastr(I18n.t('parameter.globalAct.error_mapping')+error.response?.data?.message);
            dispatch(globalActsMappingFailedAction(error.response?.data?.message));
        });
    };
};

export const updateGlobalActMappingAction = (params) => {
    return (dispatch) => {

        updateGlobalActMapping(params)
        .then((response) => {
            successToastr(I18n.t('parameter.globalAct.success_mapping_update'));
            dispatch(listGlobalActsAction());
        })
        .catch((error) => {
            console.log(error);
            errorToastr(I18n.t('parameter.globalAct.error_mapping_update')+error.response?.data?.message);
            dispatch(updateGlobalActsMappingFailedAction(error.response?.data?.message));
        });
    };
};

export const deleteGlobalActAction = (id) => {
    return (dispatch) => {

        deleteGlobalAct(id)
        .then((response) => {
            successToastr(I18n.t('parameter.globalAct.success_mapping_delete'));
            dispatch(listGlobalActsAction());
        })
        .catch((error) => {
            console.log(error);
            errorToastr(I18n.t('parameter.globalAct.success_mapping_delete')+error.response?.data?.message);
            dispatch(deleteGlobalActsFailedAction(error.response?.data?.message));
        });
    };
};

export const listParamIdentAction = () => {
    return (dispatch) => {

        getParamIdent()
        .then((response) => {
            dispatch(confirmedParamIdentListingAction(response.data));
        })
        .catch((error) => {
            console.log(error);
            errorToastr(I18n.t('parameter.globalAct.error')+error.response?.data?.message);
            dispatch(paramIdentListingFailedAction(error.response?.data?.message));
        });
    };
};

export const mapParamIdenttAction = (params) => {
    return (dispatch) => {

        mapParamIdent(params)
        .then((response) => {
            successToastr(I18n.t('parameter.globalAct.success_mapping'));
            dispatch(listParamIdentAction());
        })
        .catch((error) => {
            console.log(error);
            errorToastr(I18n.t('parameter.globalAct.error_mapping')+error.response?.data?.message);
            dispatch(paramIdentMappingFailedAction(error.response?.data?.message));
        });
    };
};

export const updateParamIdentMappingAction = (params) => {
    return (dispatch) => {

        updateParamIdent(params)
        .then((response) => {
            successToastr(I18n.t('parameter.globalAct.success_mapping_update'));
            dispatch(listParamIdentAction());
        })
        .catch((error) => {
            console.log(error);
            errorToastr(I18n.t('parameter.globalAct.error_mapping_update')+error.response?.data?.message);
            dispatch(updateParamIdentMappingFailedAction(error.response?.data?.message));
        });
    };
};

export const deleteParamIdentAction = (id) => {
    return (dispatch) => {

        deleteParamIdent(id)
        .then((response) => {
            successToastr(I18n.t('parameter.globalAct.success_mapping_delete'));
            dispatch(listParamIdentAction());
        })
        .catch((error) => {
            console.log(error);
            errorToastr(I18n.t('parameter.globalAct.success_mapping_delete')+error.response?.data?.message);
            dispatch(deleteParamIdentFailedAction(error.response?.data?.message));
        });
    };
};

export function deleteParamIdentFailedAction(payload) {
    return {
        type: DELETE_PARAM_IDENT_FAILED_ACTION,
        payload,
    };
};

export function updateParamIdentMappingFailedAction(payload) {
    return {
        type: UPDATE_GPARAM_IDENT_MAPPING_FAILED_ACTION,
        payload,
    };
};

export function paramIdentMappingFailedAction(payload) {
    return {
        type: MAP_PARAM_IDENT_FAILED_ACTION,
        payload,
    };
};

export function paramIdentListingFailedAction(payload) {
    return {
        type: LIST_PARAM_IDENT_FAILED_ACTION,
        payload,
    };
};

export function confirmedParamIdentListingAction(payload) {
    return {
        type: LIST_PARAM_IDENT_CONFIRMED_ACTION,
        payload,
    };
};



export function deleteGlobalActsFailedAction(payload) {
    return {
        type: DELETE_GLOBAL_ACTS_FAILED_ACTION,
        payload,
    };
};

export function updateGlobalActsMappingFailedAction(payload) {
    return {
        type: UPDATE_GLOBAL_ACTS_MAPPING_FAILED_ACTION,
        payload,
    };
};

export function confirmedGlobalActsListingAction(payload) {
    return {
        type: LIST_GLOBAL_ACTS_CONFIRMED_ACTION,
        payload,
    };
};

export function globalActsListingFailedAction(payload) {
    return {
        type: LIST_GLOBAL_ACTS_FAILED_ACTION,
        payload,
    };
};

export function globalActsMappingFailedAction(payload) {
    return {
        type: MAP_GLOBAL_ACTS_FAILED_ACTION,
        payload,
    };
};

export function confirmedCountriesListingAction(payload) {
    return {
        type: LIST_COUNTRIES_CONFIRMED_ACTION,
        payload,
    };
};

export function countriesListingFailedAction(payload) {
    return {
        type: LIST_COUNTRIES_FAILED_ACTION,
        payload,
    };
};

export function confirmedWorkingFieldsListingAction(payload) {
    return {
        type: LIST_WORKINGFIELDS_CONFIRMED_ACTION,
        payload,
    };
};

export function workingFieldsListingFailedAction(payload) {
    return {
        type: LIST_WORKINGFIELDS_FAILED_ACTION,
        payload,
    };
};

export function startLoader() {
    return {
        type: START_LOADER_ACTION
    };
};