import { createMenu, createProfil, createRole, createUser, getUserParameter,getUsers,getMenus, getProfils,
    affectUserProfil, getActions, createAction, updateAction, updateMenu, updateRole, updateProfil, 
    updateUser, getRoles, deleteMenu, deleteAction, deleteProfil, deleteRole, deleteUser,
    createMenuRoleActions, resetPassword, resendParameters, listCodesValidation } from "../../services/UserService";
import { errorToastr, successToastr } from '../../Utils/ToastrMessage';
export const GET_USERS_CONFIRMED_ACTION = '[get users action] confirmed users get';
export const GET_PROFILS_CONFIRMED_ACTION = '[get profils action] confirmed profils get';
export const GET_ROLES_CONFIRMED_ACTION = '[get roles action] confirmed roles get';
export const GET_MENUS_CONFIRMED_ACTION = '[get menus action] confirmed menus get';
export const GET_ACTIONS_CONFIRMED_ACTION = '[get actions action] confirmed actions get';
export const CREATE_CONFIRMED_ACTION = '[create action] confirmed create';
export const CREATE_FAILED_ACTION = '[create action] failed create';
export const UPDATE_CONFIRMED_ACTION = '[update action] confirmed update';
export const UPDATE_FAILED_ACTION = '[update action] failed update';
export const GET_FAILED_ACTION = '[get action] failed get';
export const GET_CONFIRMED_ACTION = '[get action] confirmed get';
export const DELETE_CONFIRMED_ACTION = '[delete action] confirmed delete';
export const DELETE_FAILED_ACTION = '[delete action] failed delete';
export const START_LOADER_ACTION = '[providerLoader action] started providerLoader';
export const CREATE_MENU_ROLE_ACTIONS_CONFIRMED_ACTION = '[create menuRoleActions action] confirmed create';
export const RESET_SAVE_DONE_ACTION = '[resetSaveDone action] started resetSaveDone';
export const GET_USER_PARAMETER_CONFIRMED_ACTION = '[get userparams action] started userparams';
export const RESET_PASSWORD_CONFIRMED_ACTION = '[resetPassword action] confirmed resetPassword';
export const RESET_PASSWORD_FAILED_ACTION = '[resetPassword action] failed resetPassword';
export const RESEND_PARAMS_CONFIRMED_ACTION = '[resendParams action] confirmed resendParams';
export const RESEND_PARAMS_FAILED_ACTION = '[resendParams action] failed resendParams';
export const GET_CODES_VALIDATION_CONFIRMED_ACTION = '[getCodesValidation action] confirmed getCodesValidation';
export const GET_CODES_VALIDATION_FAILED_ACTION = '[getCodesValidation action] failed getCodesValidation';

var I18n = require('react-redux-i18n').I18n;

export const getUsersAction = () => {
    return (dispatch) => {
        getUsers().then((response) => {
            dispatch(confirmedGetUsersAction(response.data));
        })
        .catch((error) => {
            errorToastr(I18n.t('users.listing_error')+' utilisateurs'+error.response?.data?.message);
            dispatch(failedGetAction(error.response?.data?.message));
        });
    };
}

export const getMenusAction = () => {
    return (dispatch) => {
        getMenus().then((response) => {
            dispatch(confirmedGetMenusAction(response.data));
        })
        .catch((error) => {
            errorToastr(I18n.t('users.listing_error')+' menus'+error.response?.data?.message);
            dispatch(failedGetAction(error.response?.data?.message));
        });
    };
}
export const getProfilAction = () => {
    return (dispatch) => {
        getProfils().then((response) => {
            dispatch(confirmedGetProfilAction(response.data));
        })
        .catch((error) => {
            errorToastr(I18n.t('users.listing_error')+' profils'+error.response?.data?.message);
            dispatch(failedGetAction(error.response?.data?.message));
        });
    };
}
export const getRolesAction = () => {
    return (dispatch) => {
        getRoles().then((response) => {
            dispatch(confirmedGetRolesAction(response.data));
        })
        .catch((error) => {
            errorToastr(I18n.t('users.listing_error')+' roles'+error.response?.data?.message);
            dispatch(failedGetAction(error.response?.data?.message));
        });
    };
}
export const getUserParameterAction = () => {
    return (dispatch) => {
        getUserParameter().then((response) => {
            dispatch(confirmedGetUserParameterAction(response.data));
        })
        .catch((error) => {
            errorToastr(I18n.t('users.listing_error')+' paramètres utilisateur'+error.response?.data?.message);
            dispatch(failedGetAction(error.response?.data?.message));
        });
    };
}
export const createMenuAction = (menu) => {
    return async (dispatch) => {

        createMenu(menu).then((response) => {
            successToastr(I18n.t('provider.create_success'));
            console.log(response);
            response.data['typeData'] = 'menuData';
            dispatch(confirmedCreateAction(response.data));
        })
        .catch((error) => {
            errorToastr(I18n.t('provider.create_error')+error.response?.data?.message);
            dispatch(confirmedFailedAction(error.response?.data?.message));
        });
    };
};
export const createRolection = (menu) => {
    return async (dispatch) => {

        createRole(menu).then((response) => {
            successToastr(I18n.t('provider.create_success'));
            console.log(response);
            response.data['typeData'] = 'roleData';
            dispatch(confirmedCreateAction(response.data));
        })
        .catch((error) => {
            errorToastr(I18n.t('provider.create_error')+error.response?.data?.message);
            dispatch(confirmedFailedAction(error.response?.data?.message));
        });
    };
};
export const createProfilaction = (menu) => {
    return async (dispatch) => {

        createProfil(menu).then((response) => {
            successToastr(I18n.t('provider.create_success'));
            console.log(response);
            response.data['typeData'] = "profil";
            dispatch(confirmedCreateAction(response.data));
        })
        .catch((error) => {
            errorToastr(I18n.t('provider.create_error')+error.response?.data?.message);
            dispatch(confirmedFailedAction(error.response?.data?.message));
        });
    };
};
export const createUserAction = (menu) => {
    return async (dispatch) => {

        createUser(menu).then((response) => {
            successToastr(I18n.t('provider.create_success'));
            console.log(response);
            response.data['typeData'] = 'user';
            dispatch(confirmedCreateAction(response.data));
        })
        .catch((error) => {
            console.log(error);
            errorToastr(I18n.t('provider.create_error')+error.response?.data?.message);
            dispatch(confirmedFailedAction(error.response?.data?.message));
        });
    };
};
export const affectUserProfilAction = (menu) => {
    return async (dispatch) => {

        affectUserProfil(menu).then((response) => {
            successToastr(I18n.t('provider.create_success'));
            console.log(response);
            dispatch(confirmedCreateAction(response.data));
        })
        .catch((error) => {
            errorToastr(I18n.t('provider.create_error')+error.response?.data?.message);
            dispatch(confirmedFailedAction(error.response?.data?.message));
        });
    };
};

export const getActionsAction = () => {
    return (dispatch) => {
        getActions().then((response) => {
            dispatch(confirmedGetActionsAction(response.data));
        })
        .catch((error) => {
            errorToastr(I18n.t('users.listing_error')+' actions'+error.response?.data?.message);
            dispatch(failedGetAction(error.response?.data?.message));
        });
    };
}


export const createActionAction = (data) => {
    return async (dispatch) => {

        createAction(data).then((response) => {
            successToastr(I18n.t('provider.create_success'));
            console.log(response);
            response.data['typeData'] = 'action';
            dispatch(confirmedCreateAction(response.data));
        })
        .catch((error) => {
            errorToastr(I18n.t('provider.create_error')+error.response?.data?.message);
            dispatch(confirmedFailedAction(error.response?.data?.message));
        });
    };
};


export const updateActionAction = (id, data) => {
    return async (dispatch) => {

        updateAction(id, data).then((response) => {
            successToastr(I18n.t('provider.create_success'));
            console.log(response);
            response.data['typeData'] = 'action';
            dispatch(confirmedUpdateAction(response.data));
        })
        .catch((error) => {
            errorToastr(I18n.t('provider.create_error')+error.response?.data?.message);
            dispatch(confirmedUpdateFailedAction(error.response?.data?.message));
        });
    };
};


export const updateMenuAction = (id, data) => {
    return async (dispatch) => {

        updateMenu(id, data).then((response) => {
            successToastr(I18n.t('provider.create_success'));
            console.log(response);
            response.data['typeData'] = 'menuData';
            dispatch(confirmedUpdateAction(response.data));
        })
        .catch((error) => {
            errorToastr(I18n.t('provider.create_error')+error.response?.data?.message);
            dispatch(confirmedUpdateFailedAction(error.response?.data?.message));
        });
    };
};


export const updateRoleAction = (id, data) => {
    return async (dispatch) => {

        updateRole(id, data).then((response) => {
            successToastr(I18n.t('provider.create_success'));
            console.log(response);
            response.data['typeData'] = 'roleData';
            dispatch(confirmedUpdateAction(response.data));
        })
        .catch((error) => {
            errorToastr(I18n.t('provider.create_error')+error.response?.data?.message);
            dispatch(confirmedUpdateFailedAction(error.response?.data?.message));
        });
    };
};


export const updateProfilAction = (id, data) => {
    return async (dispatch) => {

        updateProfil(id, data).then((response) => {
            successToastr(I18n.t('provider.create_success'));
            console.log(response);
            response.data['typeData'] = "profil";
            dispatch(confirmedUpdateAction(response.data));
        })
        .catch((error) => {
            errorToastr(I18n.t('provider.create_error')+error.response?.data?.message);
            dispatch(confirmedUpdateFailedAction(error.response?.data?.message));
        });
    };
};


export const updateUserAction = (id, data) => {
    return async (dispatch) => {

        updateUser(id, data).then((response) => {
            successToastr(I18n.t('provider.create_success'));
            console.log(response);
            response.data['typeData'] = 'user';
            dispatch(confirmedUpdateAction(response.data));
        })
        .catch((error) => {
            console.log(error);
            errorToastr(I18n.t('provider.create_error')+error.response?.data?.message);
            dispatch(confirmedUpdateFailedAction(error.response?.data?.message));
        });
    };
};


export const deleteParamAction = (type, data) => {
    return async (dispatch) => {
        if(type === "action"){
            deleteAction(data).then((response) => {
                successToastr(I18n.t('provider.deleted_success'));
                console.log(response);
                let resp = {};
                resp['typeData'] = 'action';
                resp['id'] = data.id;
                dispatch(confirmedDeleteAction(resp));
            })
            .catch((error) => {
                errorToastr(I18n.t('provider.deleted_error')+error.response?.data?.message);
                dispatch(confirmedDeleteFailedAction(error.response?.data?.message));
            });
        }else if(type === "menu"){
            deleteMenu(data).then((response) => {
                successToastr(I18n.t('provider.deleted_success'));
                console.log(response);
                let resp = {};
                resp['typeData'] = 'menuData';
                resp['id'] = data.id;
                dispatch(confirmedDeleteAction(resp));
            })
            .catch((error) => {
                errorToastr(I18n.t('provider.deleted_error')+error.response?.data?.message);
                dispatch(confirmedDeleteFailedAction(error.response?.data?.message));
            });
        }else if(type === "role"){
            deleteRole(data).then((response) => {
                successToastr(I18n.t('provider.deleted_success'));
                console.log(response);
                let resp = {};
                resp['typeData'] = 'roleData';
                resp['id'] = data.id;
                dispatch(confirmedDeleteAction(resp));
            })
            .catch((error) => {
                errorToastr(I18n.t('provider.deleted_error')+error.response?.data?.message);
                dispatch(confirmedDeleteFailedAction(error.response?.data?.message));
            });
        }else if(type === "profil"){
            deleteProfil(data).then((response) => {
                successToastr(I18n.t('provider.deleted_success'));
                console.log(response);
                let resp = {};
                resp['typeData'] = 'profil';
                resp['id'] = data.id;
                dispatch(confirmedDeleteAction(resp));
            })
            .catch((error) => {
                errorToastr(I18n.t('provider.deleted_error')+error.response?.data?.message);
                dispatch(confirmedDeleteFailedAction(error.response?.data?.message));
            });
        }else{
            deleteUser(data).then((response) => {
                successToastr(I18n.t('provider.deleted_success'));
                console.log(response);
                let resp = {};
                resp['typeData'] = 'user';
                resp['id'] = data.id;
                dispatch(confirmedDeleteAction(resp));
            })
            .catch((error) => {
                console.log(error);
                errorToastr(I18n.t('provider.deleted_error')+error.response?.data?.message);
                dispatch(confirmedDeleteFailedAction(error.response?.data?.message));
            });
        }
    };
};


export const createMenuRoleActionsAction = (data) => {
    return async (dispatch) => {

        createMenuRoleActions(data).then((response) => {
            successToastr(I18n.t('provider.create_success'));
            console.log(response);
            dispatch(confirmedCreateMenuRoleActionsAction(response.data));
        })
        .catch((error) => {
            errorToastr(I18n.t('provider.create_error')+error.response?.data?.message);
            dispatch(confirmedFailedAction(error.response?.data?.message));
        });
    };
};


export const resetUserPasswordActionsAction = (data) => {
    return async (dispatch) => {

        resetPassword(data).then((response) => {
            successToastr(I18n.t('users.reset_password_confirm'));
            console.log(response);
            dispatch(confirmedResetPasswordActionsAction(response.data));
        })
        .catch((error) => {
            errorToastr(I18n.t('users.reset_password_error')+error.response?.data?.message);
            dispatch(resetPasswordFailedAction(error.response?.data?.message));
        });
    };
};


export const resendUserParamsActionsAction = (data) => {
    return async (dispatch) => {

        resendParameters(data).then((response) => {
            successToastr(I18n.t('users.resend_user_param_confirm'));
            console.log(response);
            dispatch(confirmedResendParamsActionsAction(response.data));
        })
        .catch((error) => {
            errorToastr(I18n.t('users.resend_user_param_error')+error.response?.data?.message);
            dispatch(resendParamsFailedAction(error.response?.data?.message));
        });
    };
};


export const getCodesValidationAction = (assure, telephone, numAssure) => {
    return async (dispatch) => {

        listCodesValidation(assure, telephone, numAssure).then((response) => {
            console.log(response);
            dispatch(confirmedGetCodesValidationAction(response.data));
        })
        .catch((error) => {
            errorToastr(I18n.t('users.listing_error')+' codes des validation'+error.response?.data?.message);
            dispatch(getCodesValidationFailedAction(error.response?.data?.message));
        });
    };
};

export function confirmedGetCodesValidationAction(payload){
    return {
        type: GET_CODES_VALIDATION_CONFIRMED_ACTION,
        payload,
    };
}

export function getCodesValidationFailedAction(payload){
    return {
        type: GET_CODES_VALIDATION_FAILED_ACTION,
        payload,
    };
}

export function confirmedResendParamsActionsAction(payload){
    return {
        type: RESEND_PARAMS_CONFIRMED_ACTION,
        payload,
    };
}

export function resendParamsFailedAction(payload){
    return {
        type: RESEND_PARAMS_FAILED_ACTION,
        payload,
    };
}

export function confirmedResetPasswordActionsAction(payload){
    return {
        type: RESET_PASSWORD_CONFIRMED_ACTION,
        payload,
    };
}

export function resetPasswordFailedAction(payload){
    return {
        type: RESET_PASSWORD_FAILED_ACTION,
        payload,
    };
}

export function confirmedCreateMenuRoleActionsAction(payload){
    return {
        type: CREATE_MENU_ROLE_ACTIONS_CONFIRMED_ACTION,
        payload,
    };
}

export function confirmedGetRolesAction(payload){
    return {
        type: GET_ROLES_CONFIRMED_ACTION,
        payload,
    };
}

export function confirmedGetMenusAction(payload){
    return {
        type: GET_MENUS_CONFIRMED_ACTION,
        payload,
    };
}

export function confirmedGetActionsAction(payload){
    return {
        type: GET_ACTIONS_CONFIRMED_ACTION,
        payload,
    };
}

export function confirmedGetUsersAction(payload){
    return {
        type: GET_USERS_CONFIRMED_ACTION,
        payload,
    };
}
export function confirmedGetProfilAction(payload){
    return {
        type: GET_PROFILS_CONFIRMED_ACTION,
        payload,
    };
}
export function confirmedGetUserParameterAction(payload){
    return {
        type: GET_USER_PARAMETER_CONFIRMED_ACTION,
        payload,
    };
}
export function confirmedGetAction(payload) {
    return {
        type: GET_CONFIRMED_ACTION,
        payload,
    };
};
export function failedGetAction(payload) {
    return {
        type: GET_FAILED_ACTION,
        payload,
    };
};
export function confirmedCreateAction(payload) {
    return {
        type: CREATE_CONFIRMED_ACTION,
        payload,
    };
};
export function confirmedFailedAction(payload) {
    return {
        type: CREATE_FAILED_ACTION,
        payload,
    };
};
export function confirmedUpdateAction(payload) {
    return {
        type: UPDATE_CONFIRMED_ACTION,
        payload,
    };
};
export function confirmedUpdateFailedAction(payload) {
    return {
        type: UPDATE_FAILED_ACTION,
        payload,
    };
};
export function confirmedDeleteAction(payload) {
    return {
        type: DELETE_CONFIRMED_ACTION,
        payload,
    };
};
export function confirmedDeleteFailedAction(payload) {
    return {
        type: DELETE_FAILED_ACTION,
        payload,
    };
};
export function startLoader() {
    return {
        type: START_LOADER_ACTION
    };
};
export function resetSaveDoneAction() {
    return {
        type: RESET_SAVE_DONE_ACTION
    };
};