import React, { useState, useEffect, useRef } from "react";
import { connect, useDispatch } from 'react-redux';
import { listProvidersAction, startLoader } from '../../../../store/actions/ProviderActions';
import { Link, withRouter } from "react-router-dom";
import { Modal } from "react-bootstrap";
import { MotifSuppressionModal } from "../ProviderModals/MotifSuppressionModal";
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { InputText } from 'primereact/inputtext';
import { Tag } from 'primereact/tag';
import { Dropdown } from 'primereact/dropdown';
import { TriStateCheckbox } from 'primereact/tristatecheckbox';
import { Button as PButton } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { DataTableBase } from '../../DataTables/DataTableBase';
import { currentUser } from "../../../../store/selectors/CurrentUserSelector";
import { useTranslation } from "react-i18next";

export const ProviderList = (props) => {
    const { t } = useTranslation();

    const dispatch = useDispatch();

    const [supModal, setSupModal] = useState(false);
    const [currentProv, setCurrentProv] = useState(null);

    const [filters, setFilters] = useState(null);
    const [globalFilterValue, setGlobalFilterValue] = useState('');

    const initFilters = () => {
        setFilters({
            global: { value: null, matchMode: FilterMatchMode.CONTAINS },
            providerName: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            'city.name': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            phoneNumber: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },            
            //email: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
            isConventionate: { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
            createdAt: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
        });
        setGlobalFilterValue("");
    };

    const renderHeader = () => {
        return (
            <div className="flex justify-content-between">
                <PButton type="button" icon="pi pi-filter-slash"  label={t('common.reinit')} outlined onClick={clearFilter} />
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText value={globalFilterValue} onChange={(e) => onGlobalFilterChange(e)}  placeholder={t('common.search')} />
                </span>
            </div>
        );
    };

    const clearFilter = () => {
        initFilters();
    };

    const setColumns = () => {
        return [
            {name: 'providerName', headerName: t('common.name'), filterPlaceholder: t('common.filter-name'), style: { minWidth: '12rem' }, filter: true, bodyTemplate: null},
            {name: 'city.name', headerName: "Ville", filterPlaceholder: 'Filtrer par ville', style: { minWidth: '12rem' }, filter: true, bodyTemplate: null},
            {name: 'phoneNumber', headerName: t('common.phone'), filterPlaceholder: t('common.phone-filter'), style: { minWidth: '12rem' }, filter: true, bodyTemplate: null},
            //{name: 'email', dataType: 'numeric', headerName:  t('common.amount'), filterPlaceholder: t('facturation.filter-by-amount'), style: { minWidth: '6rem', fontSize: '14px' }, filter: true, bodyTemplate: null},
            {name: 'isConventionate', headerName: 'Conventionné', filterPlaceholder: 'Filtrer par convention', style: { minWidth: '12rem', fontSize: '14px' }, filter: true, bodyTemplate: statusBordBodyTemplate, filterElement: statusBordFilterTemplate},
            {name: 'createdAt', filterField: 'createdAt', dataType: 'date', headerName: 'Créé le', filterPlaceholder: t('facturation.create-date-filter'), style: { minWidth: '6rem' }, filter: true, bodyTemplate: datePaiementBodyTemplate, filterElement: dateFilterTemplate},
            {name: 'actions', headerName: 'Actions', filterPlaceholder: '', style: { minWidth: '6rem'}, filter: false, bodyTemplate: getActionsTemplate},
        ]
    };

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };

        _filters['global'].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
    };

    const getActionsTemplate = (rowData) => {
        return <div style={{ width: '100%', display: 'flex', justifyContent: 'center'}}>
            {hasMenu('MENPRESTATAIREADMIN') && canDo('MENPRESTATAIREADMIN', 'VIEWPRESTATAIREDETAIL') && <button className="btn btn-info shadow btn-xs sharp mr-2"
                onClick={e => viewProvider(rowData)}
            >
                <i className="fa fa-eye"></i>
            </button>}
            {hasMenu('MENPRESTATAIREADMIN') && canDo('MENPRESTATAIREADMIN', 'UPDATEPRESTATAIRE') && <button className="btn btn-primary shadow btn-xs sharp mr-2"
                onClick={e => updateProvider(rowData)}
            >
                <i className="fa fa-pencil"></i>
            </button>}
            {hasMenu('MENPRESTATAIREADMIN') && canDo('MENPRESTATAIREADMIN', 'SUSPENDPRESTATAIRE') && <button className="btn btn-danger shadow btn-xs sharp mr-2"
                onClick={(e) => {
                        setCurrentProv(rowData);
                        setSupModal(true);
                    }
                }
            >
                <i className="fa fa-trash"></i>
            </button>}
        </div>
    }

    const statusBordFilterTemplate = (options) => {
        return (
            <div className="flex align-items-center gap-2">
                <label htmlFor="isClose-filter" className="font-bold">
                    Conventionné
                </label>
                <TriStateCheckbox inputId="isClose-filter" value={options.value} onChange={(e) => options.filterCallback(e.value)} />
            </div>
        );
    };

    const statusBordBodyTemplate = (rowData) => {
        return <Tag value={rowData.isConventionate ? 'Conventionné' : "Non conventionné"} severity={getSeverity(rowData.isConventionate)} />;
    };

    const dateFilterTemplate = (options) => {
        return <Calendar value={options.value} onChange={(e) => options.filterCallback(e.value, options.index)} dateFormat="dd/mm/yy" placeholder="dd/mm/yyyy" mask="99/99/9999" />;
    };

    const getSeverity = (status) => {
        switch (status) {
            case false:
                return 'danger';
            default:
                return 'success';
        }
    };

    const datePaiementBodyTemplate = (rowData) => {
        return formatDate(rowData.createdAt);
    };

    const formatDate = (value) => {
        return value.toLocaleDateString('fr-FR', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric'
        });
    };

    const viewProvider = (provider) => {
        props.history.push('/provider-view/'+provider.id);
    }

    const updateProvider = (provider) => {
        props.history.push('/provider-update/'+provider.id);
    }

    const manageSupModal = (val, reload) => {
        setSupModal(val);
        if(reload){
            window.location.reload(false);
        };
    }

    const canDo = (menuCode, actionCode) => {
        let theMenu = props.currentUser.menus?.find(menu => menu.code === menuCode);
        if(!theMenu){
            return false;
        }else{
            return theMenu.actionMenus?.filter(action => action.code === actionCode).length > 0;
        }
    }
    
    const hasMenu = (menuCode) => {
        return props.currentUser.menus?.filter(menu => menu.code === menuCode).length > 0;
    }

    
    useEffect(() => {
        if(hasMenu('MENPRESTATAIREADMIN') && canDo('MENPRESTATAIREADMIN', 'LISTPRESTATAIRE')){
            initFilters();
            dispatch(startLoader());
            dispatch(listProvidersAction());
        }else{
            props.history.push('/access-denied');
        } 
    }, []); 

    return (
        <>
            { props.showLoading && 
            <div id="preloader">
                <div className="sk-three-bounce">
                    <div className="sk-child sk-bounce1"></div>
                    <div className="sk-child sk-bounce2"></div>
                    <div className="sk-child sk-bounce3"></div>
                </div>
            </div> }

            { !props.showLoading &&
            <>
            <div className="form-head d-flex mb-3 mb-md-4 align-items-start">
                <div className="mr-auto d-none d-lg-block">
                    <Link
                    to="/provider-form"
                    className="btn btn-primary btn-rounded"
                    >
                    + nouveau prestataire
                    </Link>
                </div>
            </div>

            <div className="row">
                <div className="col-xl-12">
                    <DataTableBase 
                        data={ props.providers.length > 0 ? 
                            props.providers.map(provider => {
                                provider.createdAt = new Date(provider.createdAt);
                                provider.ville = provider.city.name;
                                return provider;
                            }) :
                            []
                        }
                        emptyMessage="Aucun prestataire trouvé" 
                        filters={filters}
                        globalFiltersFields={['providerName', 'city.name', 'phoneNumber', /*'email',*/ 'isConventionate', 'createdAt']}
                        header={renderHeader()}
                        columns={setColumns()}
                        exportColumns={[
                            { title: t('common.name'), dataKey: 'providerName' },
                            { title: 'Ville', dataKey: 'ville' },
                            { title: t('common.phone'), dataKey: 'phoneNumber' },
                            { title: 'Email', dataKey: 'email' },
                            { title: 'Conventionné', dataKey: 'isConventionate' },
                            { title: t('common.create-date'), dataKey: 'createdAt' },
                        ]}
                        rows={10} 
                        showExport={true}
                        exportFilename={"prestataires"}
                        loading={props.showLoading}
                        lazy={false}
                    />
                    
                    <Modal
                        className="fade bd-example-modal-lg"
                        show={supModal}
                        size="lg"
                    >
                        <MotifSuppressionModal 
                            showModal={supModal} 
                            providerRef={currentProv} 
                            manageSupModal={manageSupModal}
                            history={history}
                        />
                    </Modal>
                </div>
            </div>
            </>
            }
        </> 
    )
};

const mapStateToProps = (state) => {
    return {
        providers: state.provider.providers,
        errorMessage: state.clientAuth.errorMessage,
        successMessage: state.clientAuth.successMessage,
        showLoading: state.provider.showLoading,
        currentUser: currentUser(state)
    };
};

export default withRouter(connect(mapStateToProps)(ProviderList));