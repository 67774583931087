import React, { useState, useEffect, useRef } from "react";
import { connect, useDispatch } from 'react-redux';
import { getProformasAction, loadingToggleAction } from "../../../../store/actions/AccordPreaActions";
import { currentUser } from "../../../../store/selectors/CurrentUserSelector";
import { Link, withRouter } from "react-router-dom";
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { InputText } from 'primereact/inputtext';
import { Tag } from 'primereact/tag';
import { Dropdown } from 'primereact/dropdown';
import { TriStateCheckbox } from 'primereact/tristatecheckbox';
import { Button as PButton } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { DataTableBase } from '../../DataTables/DataTableBase';
import { Button } from 'primereact/button';
import { STATUSES } from '../../../globals';
import { useTranslation } from "react-i18next";


/* --- BEGIN AccordsList
	@param(Object): props
    description: Listing des accords préalables
*/
export const ProformasList = (props) => {

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [data, setData] = useState([]);
    const [searchValue, setSearchValue] = useState("");
    const [filterResults, setFilterResults] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [statusFilter, setStatusFilter] = useState(0);

    const [filters, setFilters] = useState(null);
    const [globalFilterValue, setGlobalFilterValue] = useState('');
    
    const initFilters = () => {
        setFilters({
            global: { value: null, matchMode: FilterMatchMode.CONTAINS },
            acteName: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            beneficiaryName: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            providerName: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            amount: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },            
            status: { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
            createdAt: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
        });
        setGlobalFilterValue("");
    };

    const renderHeader = () => {
        return (
            <div className="flex justify-content-between">
                <Button type="button" icon="pi pi-filter-slash" label={t('common.reinit')} outlined onClick={clearFilter} />
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder={t('common.search')} />
                </span>
            </div>
        );
    };

    const clearFilter = () => {
        initFilters();
    };

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };

        _filters['global'].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
    };

    const setColumns = () => {
        return [
            {name: 'acteName', headerName: t('provider.acte'), filterPlaceholder: t('accord.filter-act'), style: { minWidth: '12rem', fontSize: '14px' }, filter: true, bodyTemplate: null},
            {name: 'beneficiaryName', headerName: t('common.patient'), filterPlaceholder: t('common.filter-by-patient'), style: { minWidth: '12rem', fontSize: '14px' }, filter: true, bodyTemplate: null},
            {name: 'providerName', headerName: t('common.optician'), filterPlaceholder: t('common.filter-by-opticien'), style: { minWidth: '12rem', fontSize: '14px' }, filter: true, bodyTemplate: null},
            {name: 'amount', dataType: 'numeric', headerName:  t('common.amount'), filterPlaceholder: t('facturation.filter-by-amount'), style: { minWidth: '6rem', fontSize: '14px' }, filter: true, bodyTemplate: null},
            {name: 'status', headerName: t('common.status'), filterPlaceholder: t('facturation.filter-by-payment-status'), style: { minWidth: '12rem', fontSize: '14px' }, filter: true, bodyTemplate: statusBodyTemplate, filterElement: statusFilterTemplate},
            {name: 'createdAt', filterField: 'createdAt', dataType: 'date', headerName: t('common.created-on'), filterPlaceholder: t('common.filter-by-creation'), style: { minWidth: '6rem', fontSize: '14px' }, filter: true, bodyTemplate: datePaiementBodyTemplate, filterElement: dateFilterTemplate},
            {name: 'actions', headerName: 'Actions', filterPlaceholder: '', style: { minWidth: '6rem', fontSize: '14px'}, filter: false, bodyTemplate: getActionsTemplate},
        ]
    };

    const getActionsTemplate = (rowData) => {
        return <div style={{ width: '100%', display: 'flex', justifyContent: 'center'}}>
                {hasMenu('MENPROFORMA') && canDo('MENPROFORMA', 'VIEWPROFORMA') && rowData.status === STATUSES.waitingAccordPreable && <button className="btn btn-danger shadow btn-xs sharp mr-2"
                    onClick={e => viewProvider(rowData)}
                >
                    <i className="fa fa-eye"></i>
                </button>}
        </div>
    }

    const viewProvider = (proforma) => {
        props.history.push('/proforma-details/'+proforma.id+'/'+proforma.providerId);
    }

    const statusFilterTemplate = (options) => {
        return <Dropdown value={options.value} options={props.proformas.map(proforma => {
            return proforma.status
        }).reduce(function (acc, curr) {
            if (!acc.includes(curr))
                acc.push(curr);
            return acc;
        }, [])} onChange={(e) => options.filterCallback(e.value, options.index)} itemTemplate={statusItemTemplate}  placeholder={t('common.choose')} className="p-column-filter" showClear />;
    };

    const dateFilterTemplate = (options) => {
        return <Calendar value={options.value} onChange={(e) => options.filterCallback(e.value, options.index)} dateFormat="dd/mm/yy" placeholder="dd/mm/yyyy" mask="99/99/9999" />;
    };

    const statusBodyTemplate = (rowData) => {
        return <Tag value={t('statuses.'+rowData.status)} severity={getSeverity(rowData.status)} />;
    };

    const statusItemTemplate = (option) => {
        return <Tag value={option} severity={getSeverity(option)} />;
    };

    const getSeverity = (status) => {
        switch (status) {
            case STATUSES.pending:
                return 'warning';
            
            case STATUSES.accepted:
                return 'success';
            
            case STATUSES.exectuted:
                return 'success';

            default:
                return 'danger';
        }
    };

    const datePaiementBodyTemplate = (rowData) => {
        return formatDate(rowData.createdAt);
    };

    const formatDate = (value) => {
        return value.toLocaleDateString('fr-FR', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric'
        });
    };

    const canDo = (menuCode, actionCode) => {
        let theMenu = props.currentUser.menus?.find(menu => menu.code === menuCode);
        if(!theMenu){
            return false;
        }else{
            return theMenu.actionMenus?.filter(action => action.code === actionCode).length > 0;
        }
    }
    
    const hasMenu = (menuCode) => {
        return props.currentUser.menus?.filter(menu => menu.code === menuCode).length > 0;
    }

    useEffect(() => {
        if(hasMenu('MENPROFORMA') && canDo('MENPROFORMA', 'LISTPROFORMA')){
            initFilters();
            dispatch(loadingToggleAction());
            dispatch(getProformasAction());
        }else{
            props.history.push('/access-denied');
        }
    }, []); 


    return (
        <>
            { props.showLoading && 
            <div id="preloader">
                <div className="sk-three-bounce">
                    <div className="sk-child sk-bounce1"></div>
                    <div className="sk-child sk-bounce2"></div>
                    <div className="sk-child sk-bounce3"></div>
                </div>
            </div> }

            { !props.showLoading &&
            <>

            <div className="row">
                <div className="col-xl-12">
                    <DataTableBase 
                        data={props.proformas.map(proforma => {
                            proforma.createdAt = new Date(proforma.createdAt);
                            return proforma;
                        })}
                        emptyMessage={t('common.no-accord')} 
                        filters={filters}
                        globalFiltersFields={['acteName', 'beneficiaryName', 'providerName', 'amount', 'status', 'createdAt']}
                        header={renderHeader()}
                        columns={setColumns()}
                        rows={10} 
                        loading={props.showLoading}
                        exportFilename={"proformas"}
                        size={'small'}
                        exportColumns={[
                            { title: t('provider.acte'), dataKey: 'acteName' },
                            { title: t('common.patient'), dataKey: 'beneficiaryName' },
                            { title: t('common.optician'), dataKey: 'providerName' },
                            { title: t('facturation.amount'), dataKey: 'amount' },
                            { title: t('common.status'), dataKey: 'status' },
                            { title: t('common.create-date'), dataKey: 'createdAt' },
                        ]}
                        showExport={true}
                    />
                </div>
            </div>
            </>
            }
        </> 
    )
};
/* --- END AccordsList */

const mapStateToProps = (state) => {
    return {
        proformas: state.accordPrea.proformas,
        showLoading: state.accordPrea.showLoading,
        currentUser: currentUser(state)
    };
};

export default withRouter(connect(mapStateToProps)(ProformasList));