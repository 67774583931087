import React, {useState,useEffect } from 'react'
import { connect, useDispatch } from 'react-redux';
import { Modal, Button as BButton, Spinner } from 'react-bootstrap';
import Select from 'react-select';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { InputText } from 'primereact/inputtext';
import { Link } from "react-router-dom";
import { Button as PButton } from 'primereact/button';
import { Tag } from 'primereact/tag';
import { Calendar } from 'primereact/calendar';
import { DataTableBase } from '../../DataTables/DataTableBase';
import { TabView, TabPanel } from 'primereact/tabview';
import { Tooltip } from 'primereact/tooltip';
import { getOptiqueParameterAction, getMonturesAction, createParametreOptiqueAction, updateParametreOptiqueAction,
createMontureAction, updateMontureAction, getOpticPrizeGridAction, updateOpticPrizeGridAction, deleteOpticParamAction,
startLoader } from '../../../../store/actions/ProviderActions';
import { useTranslation } from "react-i18next";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';

const Optique = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const {opticParameters, montures, opticPrizeGrid, showLoading} = props;
    const allTypesParam = [
        {label: "Type de verre", value: "type_verre"},
        {label: "Matière", value: "matiere"},
        {label: "Teinte de verre", value: "teinte_verre"},
        {label: "Traitement de verre", value: "traitement_verre"},
        {label: "Port de verre", value: "port_verre"},
    ];
    const typesVerre = ['unifocaux', 'bifocaux', 'progressifs'];
    const basesVerre = ['organiques', 'mineraux'];

    const [paramId, setParamId] = useState("");
    const [nameParam, setNameParam] = useState('');
    const [typeParam, setTypeParam] = useState('');

    const [montureId, setMontureId] = useState("");
    const [name, setName] = useState('');
    const [code, setCode] = useState('');
    const [amount, setAmount] = useState(0);

    const [addParamCard, setAddParamCard] = useState(false);
    const [updateParamMode, setUpdateParamMode] = useState(false);
    const [addMontureCard, setAddMontureCard] = useState(false);
    const [updateMontureMode, setUpdateMontureMode] = useState(false);

    const [paramFilters, setParamFilters] = useState(null);
    const [globalParamFilterValue, setGlobalParamFilterValue] = useState('');

    const [montureFilters, setMontureFilters] = useState(null);
    const [globalMontureFilterValue, setGlobalMontureFilterValue] = useState('');

    const [opticDatas, setOpticDatas] = useState([]);

    const [currentTypeVerre, setCurrentTypeVerre] = useState('unifocaux');
    const [currentBaseVerre, setCurrentBaseVerre] = useState('mineraux');

    const initFilters = () => {
        setParamFilters({
            global: { value: null, matchMode: FilterMatchMode.CONTAINS },
            id: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            nameFr: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            labelType: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
        });
        setGlobalParamFilterValue("");
        setMontureFilters({
            global: { value: null, matchMode: FilterMatchMode.CONTAINS },
            id: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            code: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            name: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            amount: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
            createdAt: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
        });
        setGlobalMontureFilterValue("");
    };

    const renderParamHeader = () => {
        return (
            <div className="flex justify-content-between">
                <PButton type="button" icon="pi pi-filter-slash"  label={t('common.reinit')} outlined onClick={clearFilter} />
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText value={globalParamFilterValue} onChange={(e) => onGlobalParamFilterChange(e)}  placeholder={t('common.search')} />
                </span>
            </div>
        );
    };

    const renderMontureHeader = () => {
        return (
            <div className="flex justify-content-between">
                <PButton type="button" icon="pi pi-filter-slash"  label={t('common.reinit')} outlined onClick={clearFilter} />
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText value={globalMontureFilterValue} onChange={(e) => onGlobalMontureFilterChange(e)}  placeholder={t('common.search')} />
                </span>
            </div>
        );
    };

    const clearFilter = () => {
        initFilters();
    };

    const setParamsColumns = () => {
        return [
            {name: 'id', headerName: "Code", filterPlaceholder: 'Filtrer par code', style: { minWidth: '12rem' }, filter: true, bodyTemplate: null},
            {name: 'nameFr', headerName: t('common.name'), filterPlaceholder: t('common.filter-name'), style: { minWidth: '12rem' }, filter: true, bodyTemplate: null},
            {name: 'labelType', headerName: t('client.type'), headerName: t('provider.type-filter'), style: { minWidth: '12rem' }, filter: true, bodyTemplate: null},
            {name: 'actions', headerName: 'Actions', filterPlaceholder: '', style: { minWidth: '6rem'}, filter: false, bodyTemplate: getParamActionsTemplate},
        ]
    };

    const setMontureColumns = () => {
        return [
            {name: 'code', headerName: "Code", filterPlaceholder: 'Filtrer par code', style: { minWidth: '12rem' }, filter: true, bodyTemplate: null},
            {name: 'name', headerName: t('common.name'), filterPlaceholder: t('common.filter-name'), style: { minWidth: '12rem' }, filter: true, bodyTemplate: null},
            {name: 'amount', dataType: 'numeric', headerName: 'prix', filterPlaceholder: 'Filtrer par prix', style: { minWidth: '6rem', fontSize: '14px' }, filter: true, bodyTemplate: null},
            {name: 'actions', headerName: 'Actions', filterPlaceholder: '', style: { minWidth: '6rem'}, filter: false, bodyTemplate: getMontureActionsTemplate},
        ]
    };
    
    const onGlobalParamFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...paramFilters };

        _filters['global'].value = value;

        setParamFilters(_filters);
        setGlobalParamFilterValue(value);
    };
    
    const onGlobalMontureFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...montureFilters };

        _filters['global'].value = value;

        setFilters(_filters);
        setGlobalMontureFilterValue(value);
    };

    const getParamActionsTemplate = (rowData) => {
        return <div style={{ width: '100%', display: 'flex', justifyContent: 'center'}}>
            <Link href="#" to="#" onClick={e => updateParam(e, rowData)} className="mr-4" title="Edit">
                <i className="fa fa-pencil color-muted"></i>{" "}
            </Link>
            <Link href="#" to="#" title="Close"
                onClick={() =>
                    swal({
                        title: "Supprimer le paramètre "+rowData.nameFr+" ?",
                        text:
                            "Une fois le paramètre supprimé, il ne pourra plus être accessible",
                        icon: "warning",
                        buttons: true,
                        dangerMode: true,
                    }).then((willDelete) => {
                        if (willDelete) {
                            dispatch(startLoader());
                            dispatch(deleteOpticParamAction(rowData.id));
                        }
                    })
                    }
            >
                <i className="fa fa-close color-danger"></i>
            </Link>
        </div>
    }

    const getMontureActionsTemplate = (rowData) => {
        return <div style={{ width: '100%', display: 'flex', justifyContent: 'center'}}>
            <Link href="#" to="#" onClick={e => updateMonture(e, rowData)} className="mr-4" title="Edit">
                <i className="fa fa-pencil color-muted"></i>{" "}
            </Link>
            <Link href="#" to="#" title="Close"
                onClick={() =>
                    swal({
                        title: "Supprimer la monture "+rowData.nameFr+" ?",
                        text:
                            "Une fois la monture supprimée, elle ne pourra plus être accessible",
                        icon: "warning",
                        buttons: true,
                        dangerMode: true,
                    }).then((willDelete) => {
                        if (willDelete) {
                            let data = {
                                type: "MONTURE",
                                id: rowData.id
                            }
                            dispatch(startLoader());
                            dispatch(deleteDataAction(data));
                        }
                    })
                    }
            >
                <i className="fa fa-close color-danger"></i>
            </Link>
        </div>
    }

    const updateParam = (e, data) => {
        e.preventDefault();
        setParamId(data.id);
        setNameParam(data.nameFr);
        setTypeParam(data.code);
        setUpdateParamMode(true);
        setAddParamCard(true);
    }

    const updateMonture = (e, data) => {
        e.preventDefault();
        setMontureId(data.id);
        setName(data.name);
        setCode(data.code);
        setAmount(data.amount);
        setUpdateMontureMode(true);
        setAddMontureCard(true);
    }
    
    const onParamSave = (e) => {
        e.preventDefault();
        let data = {
            nameEn: nameParam,
            nameFr: nameParam,
            code: typeParam,
            reference: 'paramOptique'
        };
        dispatch(startLoader());
        updateParamMode ? 
        dispatch(updateParametreOptiqueAction(paramId, data)) : 
        dispatch(createParametreOptiqueAction(data));
    }
    
    const onMontureSave = (e) => {
        e.preventDefault();
        let data = {
            name,
            code,
            amount
        };
        dispatch(startLoader());
        updateMontureMode ? 
        dispatch(updateMontureAction(montureId, data)) : 
        dispatch(createMontureAction(data));
    }

    useEffect(() => {
        dispatch(startLoader());
        initFilters();
        dispatch(getOptiqueParameterAction('paramOptique'));
        //dispatch(getMonturesAction());
        dispatch(getOpticPrizeGridAction());  
    }, []);

    useEffect(() => {
        console.log(opticPrizeGrid)
        if(Object.keys(opticPrizeGrid).length > 0) {
            let unifocauxData = opticPrizeGrid.tarifData.find(elt => elt.type === "unifocaux");
            let bifocauxData = opticPrizeGrid.tarifData.find(elt => elt.type === "bifocaux");
            let progressifData = opticPrizeGrid.tarifData.find(elt => elt.type === "progressifs");

            let unifocaux = {
                mineraux: {
                    traitement: unifocauxData.datas.find(elt => elt.matiere === 'mineraux').traitement,
                    prizes: unifocauxData.datas.find(elt => elt.matiere === 'mineraux').datas
                },
                organiques: {
                    traitement: unifocauxData.datas.find(elt => elt.matiere === 'organiques').traitement,
                    prizes: unifocauxData.datas.find(elt => elt.matiere === 'organiques').datas
                },
            };
            let bifocaux = {
                mineraux: {
                    traitement: bifocauxData.datas.find(elt => elt.matiere === 'mineraux').traitement,
                    prizes: bifocauxData.datas.find(elt => elt.matiere === 'mineraux').datas
                },
                organiques: {
                    traitement: bifocauxData.datas.find(elt => elt.matiere === 'organiques').traitement,
                    prizes: bifocauxData.datas.find(elt => elt.matiere === 'organiques').datas
                },
            };
            let progressifs = {
                mineraux: {
                    traitement: progressifData.datas.find(elt => elt.matiere === 'mineraux').traitement,
                    prizes: progressifData.datas.find(elt => elt.matiere === 'mineraux').datas
                },
                organiques: {
                    traitement: progressifData.datas.find(elt => elt.matiere === 'organiques').traitement,
                    prizes: progressifData.datas.find(elt => elt.matiere === 'organiques').datas
                },
            };
            setOpticDatas([
                // unifocaux - mineraux
                ...unifocaux.mineraux.prizes.map((price, index) => {
                    return {
                        id: "UM"+index, 
                        type: "unifocaux", 
                        base: "mineraux", 
                        minNeg: price.minimumNegatif, 
                        minPos: price.minimumPositif, 
                        maxNeg: price.maximumNegatif, 
                        maxPos: price.maximumPositif, 
                        blancSpherique: price.blanc.spherique, 
                        blancTorique: price.blanc.torique, 
                        photoSpherique: price.photochromique.spherique, 
                        photoTorique: price.photochromique.torique 
                    }
                }),
                {id: 26, type: "unifocaux", base: "mineraux", label: "AR",  traitement: unifocaux.mineraux.traitement},
                // unifocaux - organiques
                ...unifocaux.organiques.prizes.map((price, index) => {
                    return {
                        id: "UO"+index, 
                        type: "unifocaux", 
                        base: "organiques", 
                        minNeg: price.minimumNegatif, 
                        minPos: price.minimumPositif, 
                        maxNeg: price.maximumNegatif, 
                        maxPos: price.maximumPositif, 
                        blancSpherique: price.blanc.spherique, 
                        blancTorique: price.blanc.torique, 
                        photoSpherique: price.photochromique.spherique, 
                        photoTorique: price.photochromique.torique 
                    }
                }),
                {id: 27, type: "unifocaux", base: "organiques", label: "AR",  traitement: unifocaux.organiques.traitement},
                // bifocaux - mineraux
                ...bifocaux.mineraux.prizes.map((price, index) => {
                    return {
                        id: "BM"+index, 
                        type: "bifocaux", 
                        base: "mineraux", 
                        minNeg: price.minimumNegatif, 
                        minPos: price.minimumPositif, 
                        maxNeg: price.maximumNegatif, 
                        maxPos: price.maximumPositif, 
                        blancSpherique: price.blanc.spherique, 
                        blancTorique: price.blanc.torique, 
                        photoSpherique: price.photochromique.spherique, 
                        photoTorique: price.photochromique.torique 
                    }
                }),
                {id: 28, type: "bifocaux", base: "mineraux", label: "AR",  traitement: bifocaux.mineraux.traitement},
                // bifocaux - organiques
                ...bifocaux.organiques.prizes.map((price, index) => {
                    return {
                        id: "BM"+index, 
                        type: "bifocaux", 
                        base: "organiques", 
                        minNeg: price.minimumNegatif, 
                        minPos: price.minimumPositif, 
                        maxNeg: price.maximumNegatif, 
                        maxPos: price.maximumPositif, 
                        blancSpherique: price.blanc.spherique, 
                        blancTorique: price.blanc.torique, 
                        photoSpherique: price.photochromique.spherique, 
                        photoTorique: price.photochromique.torique 
                    }
                }),
                {id: 29, type: "bifocaux", base: "organiques", label: "AR",  traitement: bifocaux.organiques.traitement},
                // progressifs - mineraux
                ...progressifs.mineraux.prizes.map((price, index) => {
                    return {
                        id: "BM"+index, 
                        type: "progressifs", 
                        base: "mineraux", 
                        minNeg: price.minimumNegatif, 
                        minPos: price.minimumPositif, 
                        maxNeg: price.maximumNegatif, 
                        maxPos: price.maximumPositif, 
                        blancSpherique: price.blanc.spherique, 
                        blancTorique: price.blanc.torique, 
                        photoSpherique: price.photochromique.spherique, 
                        photoTorique: price.photochromique.torique 
                    }
                }),
                {id: 30, type: "progressifs", base: "mineraux", label: "AR + antireflets + hydrophobe",  traitement: progressifs.mineraux.traitement},
                // progressifs - organiques
                ...progressifs.organiques.prizes.map((price, index) => {
                    return {
                        id: "BM"+index, 
                        type: "progressifs", 
                        base: "organiques", 
                        minNeg: price.minimumNegatif, 
                        minPos: price.minimumPositif, 
                        maxNeg: price.maximumNegatif, 
                        maxPos: price.maximumPositif, 
                        blancSpherique: price.blanc.spherique, 
                        blancTorique: price.blanc.torique, 
                        photoSpherique: price.photochromique.spherique, 
                        photoTorique: price.photochromique.torique 
                    }
                }),
                {id: 31, type: "progressifs", base: "organiques", label: "AR",  traitement: progressifs.organiques.traitement},
            ]);
        }
    }, [opticPrizeGrid]);


    useEffect(() => {
        setName("");
        setNameParam("");
        setCode("");
        setAmount(0);
        setTypeParam("");
        setParamId("");
        setMontureId("");
        setUpdateParamMode(false);
        setUpdateMontureMode(false);
        setAddParamCard(false);
        setAddMontureCard(false);   
    }, [opticParameters/*, montures*/]);

    const serieTemplate = (rowData) => {
        return rowData.minNeg === -2.00 ?
        <span>Plan à +/- 2.00</span> :
        <>
            <span>{rowData.minNeg+" à "+rowData.maxNeg}</span><br/>
            <span>{"+"+rowData.minPos+" à +"+rowData.maxPos}</span>
        </>
    }

    const blancSpheriqueBodyTemplate = (rowData) => {
        return <input
            type={typeof(rowData.blancSpherique) === 'string' ? 'text' : 'number'}
            className='form-control'
            placeholder='MD'
            value={rowData.blancSpherique}
            onChange={(e) => setOpticDatas(opticDatas.map(elt => {
                if(elt.id === rowData.id) {
                    elt.blancSpherique = e.target.value;
                }
                return elt;
            }))}
        />
    }

    const blancToriqueBodyTemplate = (rowData) => {
        return <input
            type={typeof(rowData.blancTorique) === 'string' ? 'text' : 'number'}
            className='form-control'
            placeholder='MD'
            value={rowData.blancTorique}
            onChange={(e) => setOpticDatas(opticDatas.map(elt => {
                if(elt.id === rowData.id) {
                    elt.blancTorique = e.target.value;
                }
                return elt;
            }))}
        />
    }

    const photoSpheriqueBodyTemplate = (rowData) => {
        return <input
            type={typeof(rowData.photoSpherique) === 'string' ? 'text' : 'number'}
            className='form-control'
            placeholder='MD'
            value={rowData.photoSpherique}
            onChange={(e) => setOpticDatas(opticDatas.map(elt => {
                if(elt.id === rowData.id) {
                    elt.photoSpherique = e.target.value;
                }
                return elt;
            }))}
        />
    }

    const photoToriqueBodyTemplate = (rowData) => {
        return <input
            type={typeof(rowData.photoTorique) === 'string' ? 'text' : 'number'}
            className='form-control'
            placeholder='MD'
            value={rowData.photoTorique}
            onChange={(e) => setOpticDatas(opticDatas.map(elt => {
                if(elt.id === rowData.id) {
                    elt.photoTorique = e.target.value;
                }
                return elt;
            }))}
        />
    }

    const headerGroup = (
        <ColumnGroup>
            <Row>
                <Column header="Séries" rowSpan={2}/>
                <Column header="Blanc" colSpan={2} />
                <Column header="Photochromique" colSpan={2} />
            </Row>
            <Row>
                <Column header="Sphérique" field="blancSpherique" />
                <Column header="Torique" field="blancTorique" />
                <Column header="Sphérique" field="photoSpherique" />
                <Column header="Torique" field="photoTorique" />
            </Row>
        </ColumnGroup>
    );
    
    const vpBodyTemplate = () => {
        let currentTraitement = opticDatas.find(elt => (elt.type === currentTypeVerre && elt.base === currentBaseVerre && elt.label));
        return <input
            value={currentTraitement.traitement}
            type='text'
            width={"50px"}
            className='form-control input-group-prepend'
            onChange={e => {
                setOpticDatas(opticDatas.map(elt => {
                    if(elt.id === currentTraitement.id) {
                        elt.traitement = e.target.value;
                    };
                    return elt;
                }))
            }}
        />
    };
    
    const vpLabelTemplate = () => {
        let currentTraitement = opticDatas.find(elt => (elt.type === currentTypeVerre && elt.base === currentBaseVerre && elt.label));
        return <>
            <span>{t('common.treatment')}</span><br/>
            <small>{currentTraitement.label}</small>
        </>
    };

    const footerGroup =  (
        <ColumnGroup>
            <Row>
                <Column footer={vpLabelTemplate}/>
                <Column footer={vpBodyTemplate} colSpan={4}/>
            </Row>
        </ColumnGroup>
    );

    const saveGrille = (e) => {
        let data = typesVerre.map(type => {
            return {
                type: type,
                datas: basesVerre.map(base => {
                    return {
                        matiere: base,
                        traitement: opticDatas.find(elt => (elt.type === type && elt.base === base && elt.label))?.traitement,
                        datas: opticDatas.filter(elt => elt.type === type && elt.base === base).map(optDat => {
                            return {
                                minimumNegatif: optDat.minNeg,
                                maximumNegatif: optDat.maxNeg,
                                minimumPositif: optDat.minPos,
                                maximumPositif: optDat.maxPos,
                                blanc: {
                                    spherique: optDat.blancSpherique,
                                    torique: optDat.blancTorique
                                },
                                photochromique: {
                                    spherique: optDat.photoSpherique,
                                    torique: optDat.photoTorique,
                                }

                            }
                        })
                    }
                })
            }
        });
        console.log(data);
        dispatch(startLoader());
        dispatch(updateOpticPrizeGridAction({id: opticPrizeGrid.id, tarifData: data, status: opticPrizeGrid.status}));
    }

    return (
        <>
            <div className="form-head d-flex mb-3 mb-md-4 align-items-start">
                <div className="mr-2 d-none d-lg-block">
                    <Link
                        to="#"
                        className="btn btn-primary btn-rounded"
                        onClick={() => setAddParamCard(true)}
                    >
                        + Nouveau paramètre optique
                    </Link>
                </div>
                {/*<div className="d-none d-lg-block">
                    <Link
                        to="#"
                        className="btn btn-primary btn-rounded"
                        onClick={() => setAddMontureCard(true)}
                    >
                        + Nouvelle monture
                    </Link>
                </div> */}
            </div>
            <Modal className="modal fade" show={addParamCard} onHide={setAddParamCard} >
                <div className="">
                    <div className="card">
                        <div className="card-header">
                            {!updateParamMode && <h4 className="card-title">Créer un nouveau paramètre</h4>}
                            {updateParamMode && <h4 className="card-title">Modifier le paramètre {nameParam}</h4>}
                        </div>
                        <div className='card-body'>
                            <div className='basic-form'>
                                <form onSubmit={e => onParamSave(e)}>
                                    <div className='form-group row'>
                                        <label className='col-sm-3 col-form-label'>{t('client.type')}</label>
                                        <div className='col-sm-9'>
                                            <Select
                                                className="select-search"
                                                value={allTypesParam.filter((opt)=>{return opt.value === typeParam})}
                                                placeholder={t('common.choose-type')}
                                                onChange={(val) => setTypeParam(val.value)}
                                                isDisabled={showLoading}
                                                required={showLoading}
                                                options={allTypesParam}
                                                style={{
                                                lineHeight: '40px',
                                                color: '#7e7e7e',
                                                paddingLeft: ' 15px',
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className='form-group row'>
                                        <label className='col-sm-3 col-form-label'>Nom du paramètre</label>
                                        <div className='col-sm-9'>
                                            <input
                                                type='text'
                                                name='username'
                                                className='form-control'
                                                placeholder='MD'
                                                value={nameParam}
                                                onChange={(e) => setNameParam(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                    <hr />
                                    <div className='form-group align-items-center justify-content-center'>
                                        <div className=''>
                                            { showLoading && 
                                                <BButton variant="primary" disabled>
                                                    <Spinner
                                                        as="span"
                                                        animation="grow"
                                                        size="sm"
                                                        role="status"
                                                        aria-hidden="true"
                                                    />{' '}
                                                    <span className="visually-hidden">{t('common.await-text')}</span>
                                                </BButton> }
                                            { !showLoading &&
                                                <button type='submit' className='btn btn-primary'>
                                               {t('common.save')}
                                                </button> }
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
            <Modal className="modal fade" show={addMontureCard} onHide={setAddMontureCard} >
                <div className="">
                    <div className="card">
                        <div className="card-header">
                            {!updateMontureMode && <h4 className="card-title">Créer un nouvelle monture</h4>}
                            {updateMontureMode && <h4 className="card-title">Modifier la monture {name}</h4>}
                        </div>
                        <div className='card-body'>
                            <div className='basic-form'>
                                <form onSubmit={e => onMontureSave(e)}>
                                    <div className='form-group row'>
                                        <label className='col-sm-3 col-form-label'>{t('common.code')}</label>
                                        <div className='col-sm-9'>
                                            <input
                                                type='text'
                                                name='name'
                                                className='form-control'
                                                placeholder='MD'
                                                value={code}
                                                onChange={(e) => setCode(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                    <div className='form-group row'>
                                        <label className='col-sm-3 col-form-label'>{t('common.name')}</label>
                                        <div className='col-sm-9'>
                                            <input
                                                type='text'
                                                name='name'
                                                className='form-control'
                                                placeholder='MD'
                                                value={name}
                                                onChange={(e) => setName(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                    <div className='form-group row'>
                                        <label className='col-sm-3 col-form-label'>{t('common.price')}</label>
                                        <div className='col-sm-9'>
                                            <input
                                                type='number'
                                                name='amount'
                                                min='0'
                                                className='form-control'
                                                placeholder='MD'
                                                value={amount}
                                                onChange={(e) => setAmount(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                    <hr />
                                    <div className='form-group align-items-center justify-content-center'>
                                        <div className=''>
                                            { showLoading && 
                                                <BButton variant="primary" disabled>
                                                    <Spinner
                                                        as="span"
                                                        animation="grow"
                                                        size="sm"
                                                        role="status"
                                                        aria-hidden="true"
                                                    />{' '}
                                                    <span className="visually-hidden">{t('common.await-text')}</span>
                                                </BButton> }
                                            { !showLoading &&
                                                <button type='submit' className='btn btn-primary'>
                                               {t('common.save')}
                                                </button> }
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
            <TabView>
                <TabPanel header="Paramètres de prescription">
                    <DataTableBase 
                        data={opticParameters.map(param => {
                            param.labelType = allTypesParam.find(elt => elt.value === param.code).label;
                            return param;
                        })}
                        emptyMessage="Aucun paramètre trouvé" 
                        filters={paramFilters}
                        globalFiltersFields={['nameFr', 'labelType']}
                        header={renderParamHeader()}
                        columns={setParamsColumns()}
                        exportColumns={[
                            { title: t('common.name'), dataKey: 'nameFr' },
                            { title: t('client.type'), dataKey: 'labelType' }
                        ]}
                        rows={10} 
                        showExport={true}
                        exportFilename={"paramètres_optique"}
                        loading={showLoading}
                    />
                </TabPanel>
                {/* <TabPanel header="Montures">
                    <DataTableBase 
                        data={montures}
                        emptyMessage="Aucune monture trouvée" 
                        filters={montureFilters}
                        globalFiltersFields={['code', 'name', 'amount']}
                        header={renderMontureHeader()}
                        columns={setMontureColumns()}
                        exportColumns={[
                            { title: 'Code', dataKey: 'code' },
                            { title: t('common.name'), dataKey: 'name' },
                            { title: 'Prix', dataKey: 'amount' }
                        ]}
                        rows={10} 
                        showExport={true}
                        exportFilename={"montures"}
                        loading={showLoading}
                    />
                </TabPanel> */}
                <TabPanel header="Grille tarrifaire homologuée">
                    <div className='form-group row'>
                        <div className='col-6'>
                            <label className='mb-1'>{t('client.type')}</label>
                            <Select
                                className="select-search"
                                placeholder={t('common.choose-type')}
                                value={typesVerre.filter(elt => elt === currentTypeVerre).map((opt)=>{return {value: opt, label: opt}})}
                                onChange={(val) => setCurrentTypeVerre(val.value)}
                                isDisabled={showLoading}
                                required={showLoading}
                                options={typesVerre.map((opt)=>{return {value: opt, label: opt}})}
                                style={{
                                lineHeight: '40px',
                                color: '#7e7e7e',
                                paddingLeft: ' 15px',
                                }}
                            />
                        </div>
                        <div className='col-6'>
                            <label className='mb-1'>{t('common.matiere')}</label>
                            <Select
                                className="select-search"
                                placeholder={t('common.choose-type')}
                                value={basesVerre.filter(elt => elt === currentBaseVerre).map((opt)=>{return {value: opt, label: opt}})}
                                onChange={(val) => setCurrentBaseVerre(val.value)}
                                isDisabled={showLoading}
                                required={showLoading}
                                options={basesVerre.map((opt)=>{return {value: opt, label: opt}})}
                                style={{
                                lineHeight: '40px',
                                color: '#7e7e7e',
                                paddingLeft: ' 15px',
                                }}
                            />
                        </div>
                    </div>
                    
                    <div className="card">
                        <DataTable 
                            value={opticDatas.filter(elt => (elt.type === currentTypeVerre && elt.base === currentBaseVerre && !elt.label))} 
                            showGridlines 
                            headerColumnGroup={headerGroup} 
                            groupRowsBy="category" 
                            tableStyle={{ minWidth: '50rem' }}
                            footerColumnGroup={footerGroup}
                        >
                            <Column field="serie" body={serieTemplate}></Column>
                            <Column field="blancSpherique" body={blancSpheriqueBodyTemplate}></Column>
                            <Column field="blancTorique" body={blancToriqueBodyTemplate}></Column>
                            <Column field="photoSperique" body={photoSpheriqueBodyTemplate}></Column>
                            <Column field="photoTorique" body={photoToriqueBodyTemplate}></Column>
                        </DataTable>
                    </div>
                    <div className='form-group align-items-center justify-content-center'>
                        <div className=''>
                            { showLoading && 
                                <BButton variant="primary" disabled>
                                    <Spinner
                                        as="span"
                                        animation="grow"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />{' '}
                                    <span className="visually-hidden">{t('common.await-text')}</span>
                                </BButton> }
                            { !showLoading &&
                                <button type='button' onClick={e => saveGrille(e)} className='btn btn-primary'>
                               {t('common.save')}
                                </button> }
                        </div>
                    </div>
                </TabPanel>
            </TabView>
        </>
    )
};

const mapStateToProps = (state) => {
    return {
        opticParameters: state.provider.parametresOptique,
        opticPrizeGrid: state.provider.opticPrizeGrid,
        montures: state.provider.montures,
        showLoading: state.provider.showLoading,
    };
};

export default connect(mapStateToProps)(Optique);