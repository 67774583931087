import {
    LIST_COUNTRIES_CONFIRMED_ACTION,
    LIST_COUNTRIES_FAILED_ACTION,
    LIST_WORKINGFIELDS_CONFIRMED_ACTION,
    LIST_WORKINGFIELDS_FAILED_ACTION,
    LIST_GLOBAL_ACTS_CONFIRMED_ACTION,
    LIST_GLOBAL_ACTS_FAILED_ACTION,
    MAP_GLOBAL_ACTS_FAILED_ACTION,
    UPDATE_GLOBAL_ACTS_MAPPING_FAILED_ACTION,
    DELETE_GLOBAL_ACTS_FAILED_ACTION,
    LIST_PARAM_IDENT_CONFIRMED_ACTION,
    LIST_PARAM_IDENT_FAILED_ACTION,
    MAP_PARAM_IDENT_FAILED_ACTION,
    UPDATE_GPARAM_IDENT_MAPPING_FAILED_ACTION,
    DELETE_PARAM_IDENT_FAILED_ACTION,
    START_LOADER_ACTION
} from '../actions/ParamerterAction';

const initialState = {
    countries: [
        {id: 1, name: "Cameroun", city: [{id: 1, name:"DOuala"}]}
    ],
    workingFields: [],
    globalActs: [],
    identParams: [],
    errorMessage: '',
    successMessage: '',
    showLoading: false
};

export const ParameterReducer = (state = initialState, action) => {
    switch (action.type) {
        case LIST_COUNTRIES_CONFIRMED_ACTION:
            return {
                ...state,
                countries: action.payload,
                errorMessage: '',
                successMessage: 'Countries Successfully listed',
                showLoading: false,
            };
        case LIST_COUNTRIES_FAILED_ACTION:
            return {
                ...state,
                errorMessage: action.payload,
                successMessage: '',
                showLoading: false,
            };
        case LIST_WORKINGFIELDS_CONFIRMED_ACTION:
            return {
                ...state,
                workingFields: action.payload,
                errorMessage: '',
                successMessage: 'Working fields Successfully listed',
                showLoading: false,
            };
        case LIST_WORKINGFIELDS_FAILED_ACTION:
            return {
                ...state,
                errorMessage: action.payload,
                successMessage: '',
                showLoading: false,
            };
        case LIST_GLOBAL_ACTS_CONFIRMED_ACTION:
            return {
                ...state,
                globalActs: action.payload,
                successMessage: '',
                showLoading: false,
            };
        case LIST_GLOBAL_ACTS_FAILED_ACTION:
            return {
                ...state,
                errorMessage: action.payload,
                successMessage: '',
                showLoading: false,
            };
        case MAP_GLOBAL_ACTS_FAILED_ACTION:
            return {
                ...state,
                errorMessage: action.payload,
                successMessage: '',
                showLoading: false,
            };
        case UPDATE_GLOBAL_ACTS_MAPPING_FAILED_ACTION:
            return {
                ...state,
                errorMessage: action.payload,
                successMessage: '',
                showLoading: false,
            };
        case DELETE_GLOBAL_ACTS_FAILED_ACTION:
            return {
                ...state,
                errorMessage: action.payload,
                successMessage: '',
                showLoading: false,
            };
        case LIST_PARAM_IDENT_CONFIRMED_ACTION:
            return {
                ...state,
                identParams: action.payload,
                successMessage: '',
                showLoading: false,
            };
        case LIST_PARAM_IDENT_FAILED_ACTION:
            return {
                ...state,
                errorMessage: action.payload,
                successMessage: '',
                showLoading: false,
            };
        case MAP_PARAM_IDENT_FAILED_ACTION:
            return {
                ...state,
                errorMessage: action.payload,
                successMessage: '',
                showLoading: false,
            };
        case UPDATE_GPARAM_IDENT_MAPPING_FAILED_ACTION:
            return {
                ...state,
                errorMessage: action.payload,
                successMessage: '',
                showLoading: false,
            };
        case DELETE_PARAM_IDENT_FAILED_ACTION:
            return {
                ...state,
                errorMessage: action.payload,
                successMessage: '',
                showLoading: false,
            };
        case START_LOADER_ACTION:
            return {
                ...state,
                showLoading: true,
            };
        default:
          return state;
    };
};