import axios from 'axios';
import { errorToastr } from '../Utils/ToastrMessage';
import {
    loginConfirmedAction,
    logout,
} from '../store/actions/AuthActions';
import { axiosInstance } from './AxiosInstance';
const baseURL = process.env.REACT_APP_BASEURL_ADMIN_BACKEND

 export function signUp(email, password) {
    //axios call

//     const postData = {
//         email,
//         password,
//         returnSecureToken: true,
//     };

//     return axios.post(
//         `https://identitytoolkit.googleapis.com/v1/accounts:signUp?key=AIzaSyD3RPAp3nuETDn9OQimqn_YF6zdzqWITII`,
//         postData,
//     );
 }

export function login(email, password) {
    const postData = {
        email,
        password,
        returnSecureToken: true,
    };

    return axios.post(
        baseURL+'/Parameter/User/Authenticate',
        postData,
    );
}

export function updatePassword(data) {
    const token = JSON.parse(localStorage.getItem('userDetails'))?.token;
    const headers = {
        Authorization: 'Bearer '+token,
    };
    return axios.put(
        baseURL+'/Parameter/User/UpdatePassword',
        data,
        {headers}
    )
}

export function formatError(errorResponse) {
    switch (errorResponse.error.response?.data?.message) {
        case 'EMAIL_EXISTS':
            //return 'Email already exists';
            errorToastr("Email already exists");
            break;
        case 'EMAIL_NOT_FOUND':
            //return 'Email not found';
            errorToastr("Email not found");
           break;
        case 'INVALID_PASSWORD':
            //return 'Invalid Password';
            errorToastr("Invalid Password");
            break;
        case 'USER_DISABLED':
            errorToastr("User Disabled");
            return 'User Disabled';

        default:
            return '';
    }
}

export function saveTokenInLocalStorage(tokenDetails) {
    tokenDetails.expireDate = new Date(
        new Date().getTime() + tokenDetails.expiresIn * 1000,
    );
    localStorage.setItem('userDetails', JSON.stringify(tokenDetails));
}

export function runLogoutTimer(dispatch, timer, history) {
    setTimeout(() => {
        dispatch(logout(history));
    }, timer);
}

export function checkAutoLogin(dispatch, history) {
    const tokenDetailsString = localStorage.getItem('userDetails');
    let tokenDetails = '';
    if (!tokenDetailsString) {
        dispatch(logout(history));
        return;
    }

    tokenDetails = JSON.parse(tokenDetailsString);
    let expireDate = new Date(tokenDetails.expireDate);
    let todaysDate = new Date();

    if (todaysDate > expireDate) {
        dispatch(logout(history));
        return;
    }
    dispatch(loginConfirmedAction(tokenDetails));

    const timer = expireDate.getTime() - todaysDate.getTime();
    runLogoutTimer(dispatch, timer, history);
}
