import React, {useState,useEffect } from 'react'
import { connect, useDispatch } from 'react-redux';
import { Modal } from 'react-bootstrap';
import BasicDatatable from './DataTable';
import { Link } from "react-router-dom";
import {createMenuRoleActionsAction, resetSaveDoneAction, getRolesAction, startLoader} from '../../../store/actions/UserAction'
import swal from 'sweetalert';
import Select from 'react-select';
import { DateRange } from 'react-date-range';
import { useTranslation } from "react-i18next";
const MenuRoleAction = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    let errorsObj = { name: '', code: '' };
    const [datas, setDatas] = useState([]);

    useEffect(() => {
        if(props.roles.length > 0) {
            setDatas(props.roles.map(role => {
                return role.menuRole?.map(menuRole => {
                    return {
                        menuRoleId: menuRole.id,
                        actions: menuRole.menus?.actionMenus.map(action => {
                            let dat = action.menuRoleActions.filter(elt => elt.menuRoleId === menuRole.id);
                            if(dat.length > 0) {
                                return action.id
                            }
                        }).filter(key => key)
                    }
                })
            }).flat(1));
        }    
    }, [props.roles]);

    useEffect(() => {
        if(props.saveDone) {
            dispatch(startLoader());
            dispatch(resetSaveDoneAction());
            dispatch(getRolesAction());
        }
    }, [props.saveDone]);

    function onMenuSave(e) {
        e.preventDefault();
        dispatch(startLoader());
        
        console.log(datas);
        dispatch(createMenuRoleActionsAction(datas.filter(elt => elt.actions.length >= 0)));
    }

    const header = ["Menu & Role", "actions"];

    const tableBody = props.roles?.length > 0 ?  props.roles?.map((role, k) => {
        return (
        <>
            <tr>
                <th colSpan="12" className="center">
                    {role.nameFr}
                </th>
            </tr>
            {role.menuRole?.map((key, j) => {
                return (<tr key={key.id} >
                        <td><span className="ml-4">{key.menus?.nameFr}</span></td>
                        <td className="d-flex" style={{flexWrap: "wrap"}}>
                            {props.menus?.find(elmt => elmt.id === key.menus?.id)?.actionMenus.map((action, i) => {
                            return <div className='col-3 custom-control custom-checkbox mb-3 checkbox-primary'>
                                <input
                                type='checkbox'
                                checked={datas.filter(elt => (elt.menuRoleId === key.id && elt.actions.includes(action.id))).length > 0}
                                onChange={(e) => {
                                    if(e.target.checked){
                                        let datElt = datas.find(elt => elt.menuRoleId === key.id);
                                        
                                        datElt ? 
                                        setDatas(datas.map(dat => {
                                            if(dat.menuRoleId === key.id){
                                                dat.actions.push(action.id);
                                            }
                                            return dat;
                                        }))
                                        : setDatas([...datas, {
                                            menuRoleId: key.id,
                                            actions: [action.id]
                                        }]);
                                    }else{
                                        setDatas(datas.map(dat => {
                                            if(dat.menuRoleId === key.id){
                                                dat.actions = dat.actions.filter(dat => dat !== action.id);
                                            }
                                            return dat;
                                        }));
                                    }
                                }}
                                name={'customCheckBox'+k+j+i}
                                className='custom-control-input'
                                id={'customCheckBox'+k+j+i}
                                />
                                <label
                                className='custom-control-label text-primary text-bold'
                                htmlFor={'customCheckBox'+k+j+i}
                                >
                                    {action.nameFr}
                                </label>
                            </div>})}
                        </td>
                    </tr>)})
            }
        </>);
      }):[];

    return (
        <>
            { props.showLoading && 
            <div id="preloader">
                <div className="sk-three-bounce">
                    <div className="sk-child sk-bounce1"></div>
                    <div className="sk-child sk-bounce2"></div>
                    <div className="sk-child sk-bounce3"></div>
                </div>
            </div> }

            { !props.showLoading && 
            <form onSubmit={onMenuSave}>
                <BasicDatatable header={header} body={tableBody}></BasicDatatable>
                <hr />
                <div className='form-group align-items-center justify-content-center'>
                    <div className=''>
                        <button type='submit' className='btn btn-primary'>
                          {t('common.save')}
                        </button>
                    </div>
                </div>
            </form>}
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        roles: state.user.roleDatas,
        menus: state.user.menuDatas,
        actionsData: state.user.actions,
        errorMessage: state.user.errorMessage,
        successMessage: state.user.successMessage,
        showLoading: state.user.showLoading,
        saveDone: state.user.saveDone
    };
};

export default connect(mapStateToProps)(MenuRoleAction);