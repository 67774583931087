import {
    REGISTER_PROVIDER_CONFIRMED_ACTION,
    REGISTER_PROVIDER_FAILED_ACTION,
    GET_PROVIDER_CONFIRMED_ACTION,
    GET_PROVIDER_FAILED_ACTION,
    UPDATE_PROVIDER_CONFIRMED_ACTION,
    UPDATE_PROVIDER_FAILED_ACTION,
    SUSPEND_PROVIDER_CONFIRMED_ACTION,
    SUSPEND_PROVIDER_FAILED_ACTION,
    START_LOADER_ACTION,
    GET_FAMILIES_CONFIRMED_ACTION,
    GET_FAMILIES_FAILED_ACTION,
    GET_PROVIDER_TYPES_CONFIRMED_ACTION,
    GET_PROVIDER_TYPES_FAILED_ACTION,
    GET_PROVIDER_SPECIALITIES_CONFIRMED_ACTION,
    GET_PROVIDER_SPECIALITIES_FAILED_ACTION,
    REGISTER_PROVIDER_SPECIALITIES_CONFIRMED_ACTION,
    REGISTER_PROVIDER_SPECIALITIES_FAILED_ACTION,
    GET_ALL_TARIFS_FAILED_ACTION,
    GET_ALL_TARIFS_CONFIRMED_ACTION,
    UPDATE_PROVIDER_TARIFICATION_CONFIRMED_ACTION,
    UPDATE_PROVIDER_TARIFICATION_FAILED_ACTION,
    UPDATE_PROVIDER_UPDATED_TARIFICATION_CONFIRMED_ACTION,
    UPDATE_PROVIDER_UPDATED_TARIFICATION_FAILED_ACTION,
    UPDATE_PROVIDER_NEW_TARIFICATION_CONFIRMED_ACTION,
    UPDATE_PROVIDER_NEW_TARIFICATION_ACTION,
    REGISTER_PROVIDER_TYPE_CONFIRMED_ACTION,
    REGISTER_PROVIDER_TYPE_FAILED_ACTION,
    FILE_IMPORT_CONFIRMED_ACTION,
    FILE_IMPORT_FAILED_ACTION,
    REGISTER_PROVIDER_SPECIALITY_FAILED_ACTION,
    REGISTER_PROVIDER_SPECIALITY_CONFIRMED_ACTION,
    GET_LETTRE_CLE_CONFIRMED_ACTION,
    GET_LETTRE_CLE_FAILED_ACTION,
    REGISTER_LETTRE_CLE_CONFIRMED_ACTION,
    REGISTER_TYPE_ACTE_CONFIRMED_ACTION,
    GET_TYPEACTE_CONFIRMED_ACTION,
    GET_ACTE_CONFIRMED_ACTION,
    GET_TARIFS_CONFIRMED_ACTION,
    GET_ACTEUR_CONFIRMED_ACTION,
    REGISTER_PROVIDER_BANK_FAILED_ACTION,
    REGISTER_PROVIDER_BANK_CONFIRMED_ACTION,
    GET_PROVIDER_DATA_CONFIRMED_ACTION,
    GET_PROVIDER_DATA_FAILED_ACTION,
    GET_GARANTIES_CONFIRMED_ACTION,
    CREATE_NEW_TARFIF_CONFIRMED_ACTION,
    UPDATE_TARFIF_CONFIRMED_ACTION,
    UPDATE_ACTE_CONFIRMED_ACTION,
    SEARCH_ACTES_CONFIRMED_ACTION,
    SEARCH_ACTES_FAILED_ACTION,
    GET_ACTE_PAGES_CONFIRMED_ACTION,
    GET_FAMILLEACTE_CONFIRMED_ACTION,
    CREATE_MEDICAMENT_CONFIRMED_ACTION,
    UPDATE_MEDICAMENT_CONFIRMED_ACTION,
    GET_MEDICAMENT_CONFIRMED_ACTION,
    DELETE_DATA_CONFIRMED_ACTION,
    DELETE_DATA_FAILED_ACTION,
    REMOVE_TARIF_CONFIRMED_ACTION,
    REMOVE_TARIF_FAILED_ACTION,
    UPDATE_PROVIDER_TYPE_CONFIRMED_ACTION,
    UPDATE_PROVIDER_TYPE_FAILED_ACTION,
    UPDATE_SPECIALITY_CONFIRMED_ACTION,
    UPDATE_SPECIALITY_FAILED_ACTION,
    UPDATE_FAMILLE_ACTES_CONFIRMED_ACTION,
    UPDATE_FAMILLE_ACTES_FAILED_ACTION,
    UPDATE_TYPE_ACTES_CONFIRMED_ACTION,
    UPDATE_TYPE_ACTES_FAILED_ACTION,
    UPDATE_LETTRE_CLE_CONFIRMED_ACTION,
    UPDATE_LETTRE_CLE_FAILED_ACTION,
    REGISTER_FAMILLE_ACTE_CONFIRMED_ACTION,
    CREATE_ACTE_CONFIRMED_ACTION,
    SEARCH_ACTES_CONSULT_CONFIRMED_ACTION,
    SEARCH_ACTES_CONSULT_FAILED_ACTION,
    GET_ALL_MONTURE_CONFIRMED_ACTION,
    GET_ALL_MONTURE_FAILED_ACTION,
    GET_ALL_OPTIC_PARAMS_CONFIRMED_ACTION,
    GET_ALL_OPTIC_PARAMS_FAILED_ACTION,
    GET_OPTIC_PRIZE_GRID_CONFIRMED_ACTION,
    GET_ALL_TARIFS_MEDICAMENT_CONFIRMED_ACTION,
    GET_ALL_TARIFS_MEDICAMENT_FAILED_ACTION,
    CREATE_NEW_TARFIF_MEDICAMENT_CONFIRMED_ACTION,
    UPDATE_TARFIF_MEDICAMENT_CONFIRMED_ACTION,
    REMOVE_TARIF_MEDICAMENT_CONFIRMED_ACTION,
    REMOVE_TARIF_MEDICAMENT_FAILED_ACTION,
    DELETE_TARIF_MEDICAMENT_CONFIRMED_ACTION,
    DELETE_TARIF_MEDICAMENT_FAILED_ACTION,
    GET_TARIFS_PROVIDER_CONFIRMED_ACTION,
    GET_TARIFS_PROVIDER_FAILED_ACTION,
    GET_TARIFS_PROVIDER_MED_CONFIRMED_ACTION,
    GET_TARIFS_PROVIDER_MED_FAILED_ACTION
} from '../actions/ProviderActions';

const initialState = {
    providers: [],
    provider: {},
    specialities: [],
    tarifs: [],
    actes: [],
    actesConsult: [],
    actesPages: {},
    acteurs: [],
    lettreCles: [],
    typeActes: [],
    providerTypes: [],
    tarifs: [],
    tarifsMedicament: [],
    families: [],
    garanties: [],
    actesRes: [],
    familleActes: [],
    medicamentsPages: {},
    parametresOptique: [],
    providerTarifs: [],
    providerTarifsMed: {},
    montures: [],
    opticPrizeGrid: {},
    errorMessage: '',
    successMessage: '',
    showLoading: false,
};

export const ProviderReducer = (state = initialState, action) => {
    switch (action.type) {
        case REMOVE_TARIF_MEDICAMENT_CONFIRMED_ACTION:
            return {
                ...state,
                errorMessage: '',
                successMessage: 'Tarif provider removed successfully',
                showLoading: false,
            };
        case REMOVE_TARIF_MEDICAMENT_FAILED_ACTION:
            return {
                ...state,
                errorMessage: action.payload,
                successMessage: '',
                showLoading: false,
            };
        case REGISTER_PROVIDER_CONFIRMED_ACTION:
            return {
                ...state,
                errorMessage: '',
                successMessage: 'Provider Registry Successfully Completed',
                showLoading: false,
            };
        case REGISTER_PROVIDER_FAILED_ACTION:
            return {
                ...state,
                errorMessage: action.payload,
                successMessage: '',
                showLoading: false,
            };
        case GET_PROVIDER_CONFIRMED_ACTION:
            return {
                ...state,
                providers: action.payload,
                errorMessage: '',
                successMessage: 'Provider Listing Successfully Completed',
                showLoading: false,
            };
        case GET_PROVIDER_FAILED_ACTION:
            return {
                ...state,
                errorMessage: action.payload,
                successMessage: '',
                showLoading: false,
            };
        case GET_PROVIDER_DATA_CONFIRMED_ACTION:
            return {
                ...state,
                provider: action.payload,
                errorMessage: '',
                successMessage: 'Provider gotten Successfully Completed',
                showLoading: false,
            };
        case GET_PROVIDER_DATA_FAILED_ACTION:
            return {
                ...state,
                errorMessage: action.payload,
                successMessage: '',
                showLoading: false,
            };
        case UPDATE_PROVIDER_CONFIRMED_ACTION:
            return {
                ...state,
                errorMessage: '',
                successMessage: 'Provider Update Successfully Completed',
                showLoading: false,
            };
        case UPDATE_PROVIDER_FAILED_ACTION:
            return {
                ...state,
                errorMessage: action.payload,
                successMessage: '',
                showLoading: false,
            };
        case SUSPEND_PROVIDER_CONFIRMED_ACTION:
            return {
                ...state,
                providers: state.providers.filter((provider) => { return provider.id !== action.payload }),
                errorMessage: '',
                successMessage: 'Provider Suspension Successfully Completed',
                showLoading: false,
            };
        case SUSPEND_PROVIDER_FAILED_ACTION:
            return {
                ...state,
                errorMessage: action.payload,
                successMessage: '',
                showLoading: false,
            };
        case GET_FAMILIES_CONFIRMED_ACTION:
            return {
                ...state,
                families: action.payload,
                showLoading: false,
            };
        case GET_FAMILIES_FAILED_ACTION:
            return {
                ...state,
                errorMessage: action.payload,
                showLoading: false,
            };
        case GET_PROVIDER_TYPES_CONFIRMED_ACTION:
            return {
                ...state,
                providerTypes: action.payload,
                showLoading: false,
            };
        case GET_PROVIDER_TYPES_FAILED_ACTION:
            return {
                ...state,
                errorMessage: action.payload,
                showLoading: false,
            };

        case GET_PROVIDER_SPECIALITIES_CONFIRMED_ACTION:
            return {
                ...state,
                specialities: action.payload
            };
        case GET_PROVIDER_SPECIALITIES_FAILED_ACTION:
            return {
                ...state,
                errorMessage: action.payload,
                showLoading: false,
            };
        case REGISTER_PROVIDER_SPECIALITIES_CONFIRMED_ACTION:
            return {
                ...state,
                errorMessage: '',
                successMessage: 'Provider Specialities Registry Successfully Completed',
                showLoading: false,
            };
        case REGISTER_PROVIDER_SPECIALITIES_FAILED_ACTION:
            return {
                ...state,
                errorMessage: action.payload,
                successMessage: '',
                showLoading: false,
            };
        case GET_ALL_TARIFS_CONFIRMED_ACTION:
            return {
                ...state,
                tarifs: action.payload,
                showLoading: false,
            };
        case GET_ALL_TARIFS_FAILED_ACTION:
            return {
                ...state,
                errorMessage: action.payload,
                showLoading: false,
            };
        case GET_ALL_TARIFS_MEDICAMENT_CONFIRMED_ACTION:
            return {
                ...state,
                tarifsMedicament: action.payload,
                showLoading: false,
            };
        case GET_ALL_TARIFS_MEDICAMENT_FAILED_ACTION:
            return {
                ...state,
                errorMessage: action.payload,
                showLoading: false,
            };
        
        case UPDATE_PROVIDER_TARIFICATION_CONFIRMED_ACTION:
            return {
                ...state,
                showLoading: false,
            };
        case UPDATE_PROVIDER_TARIFICATION_FAILED_ACTION:
            return {
                ...state,
                errorMessage: action.payload,
                showLoading: false,
            };

        case UPDATE_PROVIDER_UPDATED_TARIFICATION_CONFIRMED_ACTION:
            return {
                ...state,
                showLoading: false,
            };
        case UPDATE_PROVIDER_UPDATED_TARIFICATION_FAILED_ACTION:
            return {
                ...state,
                errorMessage: action.payload,
                showLoading: false,
            };

        case UPDATE_PROVIDER_NEW_TARIFICATION_CONFIRMED_ACTION:
            return {
                ...state,
                showLoading: false,
            };
        case UPDATE_PROVIDER_NEW_TARIFICATION_ACTION:
            return {
                ...state,
                errorMessage: action.payload,
                showLoading: false,
            };
        // Begin provider type
        case REGISTER_PROVIDER_TYPE_CONFIRMED_ACTION:
            return {
                ...state,
                providerTypes: [...state.providerTypes, action.payload],
                errorMessage: '',
                successMessage: 'Provider type Successfully Create',
                showLoading: false,
            };
        case REGISTER_PROVIDER_TYPE_FAILED_ACTION:
            return {
                ...state,
                errorMessage: action.payload,
                showLoading: false,
            };
        case FILE_IMPORT_CONFIRMED_ACTION:
            return {
                ...state,
                successMessage: "fichier importé avec success",
                showLoading: false,
            };
        case FILE_IMPORT_FAILED_ACTION:
            return {
                ...state,
                errorMessage: action.payload,
                showLoading: false,
            };
        // End provider type
        // Begin provider speciality
        case REGISTER_PROVIDER_SPECIALITY_CONFIRMED_ACTION:
            return {
                ...state,
                specialities: [...state.specialities, action.payload],
                errorMessage: '',
                successMessage: 'Provider speciality Successfully Create',
                showLoading: false,
            };
        case REGISTER_PROVIDER_SPECIALITY_FAILED_ACTION:
            return {
                ...state,
                errorMessage: action.payload,
                showLoading: false,
            };
        // End provider speciality

        case GET_LETTRE_CLE_FAILED_ACTION:
            return {
                ...state,
                errorMessage: action.payload,
                showLoading: false,
            };
        case GET_LETTRE_CLE_CONFIRMED_ACTION:
            return {
                ...state,
                lettreCles: action.payload,
                showLoading: false,
            };
        case GET_TYPEACTE_CONFIRMED_ACTION:
            //console.log(action.payload)
            return {
                ...state,
                typeActes: action.payload,
                showLoading: false,
            };
        case GET_FAMILLEACTE_CONFIRMED_ACTION:
            //console.log(action.payload)
            return {
                ...state,
                familleActes: action.payload,
                showLoading: false,
            };
        case GET_TARIFS_CONFIRMED_ACTION:
            //console.log(action.payload)
            return {
                ...state,
                tarifs: action.payload,
                showLoading: false,
            };
        case GET_ACTE_CONFIRMED_ACTION:
            //console.log(action.payload)
            return {
                ...state,
                actes: action.payload,
                showLoading: false,
            };
        case GET_ACTE_PAGES_CONFIRMED_ACTION:
            //console.log(action.payload)
            return {
                ...state,
                actesPages: action.payload,
                showLoading: false,
            };
        case GET_MEDICAMENT_CONFIRMED_ACTION:
            //console.log(action.payload)
            return {
                ...state,
                medicamentsPages: action.payload,
                showLoading: false,
            };
        case GET_ACTEUR_CONFIRMED_ACTION:
            //console.log(action.payload)
            return {
                ...state,
                acteurs: action.payload,
                showLoading: false,
            };
        case GET_GARANTIES_CONFIRMED_ACTION:
            //console.log(action.payload)
            return {
                ...state,
                garanties: action.payload,
                showLoading: false,
            };
            
        case REGISTER_TYPE_ACTE_CONFIRMED_ACTION:
                return {
                    ...state,
                    typeActes: [...state.typeActes, action.payload],
                    successMessage: 'type actes Creation Successfully Completed',
                    showLoading: false,
                };
            
        case CREATE_NEW_TARFIF_CONFIRMED_ACTION:
            return {
                ...state,
                tarifs: [...state.tarifs, action.payload],
                successMessage: 'Tarif Creation Successfully Completed',
                showLoading: false,
            };
            
        case CREATE_NEW_TARFIF_MEDICAMENT_CONFIRMED_ACTION:
            return {
                ...state,
                tarifsMedicament: [...state.tarifsMedicament, action.payload],
                successMessage: 'Tarif Creation Successfully Completed',
                showLoading: false,
            };
            
        case UPDATE_TARFIF_CONFIRMED_ACTION:
            return {
                ...state,
                tarifs: state.tarifs.map(elt => {
                    if(elt.id === action.payload.id) {
                        elt = {...elt, ...action.payload}
                    }
                    return elt;
                }),
                successMessage: 'Tarif Update Successfully Completed',
                showLoading: false,
            };
            
        case UPDATE_TARFIF_MEDICAMENT_CONFIRMED_ACTION:
            return {
                ...state,
                tarifsMedicament: state.tarifsMedicament.map(elt => {
                    if(elt.id === action.payload.id) {
                        elt = {...elt, ...action.payload}
                    }
                    return elt;
                }),
                successMessage: 'Tarif Update Successfully Completed',
                showLoading: false,
            };
            
        case UPDATE_ACTE_CONFIRMED_ACTION:
            return {
                ...state,
                actesPages: {
                    ...state.actesPages,
                    data: state.actesPages.data?.map(elt => {
                        if(elt.id === action.payload.id) {
                            elt = {...elt, ...action.payload};
                        }
                        return elt;
                    })
                },
                actesRes: state.actesRes.map(elt => {
                    if(elt.id === action.payload.id) {
                        elt = {...elt, ...action.payload};
                    }
                    return elt;
                }),
                successMessage: 'Acte Update Successfully Completed',
                showLoading: false,
            };
                
        case REGISTER_PROVIDER_BANK_CONFIRMED_ACTION:
            return {
                ...state,
                errorMessage: '',
                successMessage: 'Provider Bank Registry Successfully Completed',
                showLoading: false,
            };
        case REGISTER_PROVIDER_BANK_FAILED_ACTION:
            return {
                ...state,
                errorMessage: action.payload,
                successMessage: '',
                showLoading: false,
            };

        case SEARCH_ACTES_CONFIRMED_ACTION:
            return {
                ...state,
                actesRes: action.payload,
                errorMessage: '',
                successMessage: 'ACts search results gotten Successfully Completed',
                showLoading: false,
            };
        case SEARCH_ACTES_FAILED_ACTION:
            return {
                ...state,
                errorMessage: action.payload,
                successMessage: '',
                showLoading: false,
            };
            
        case UPDATE_MEDICAMENT_CONFIRMED_ACTION:
            return {
                ...state,
                medicamentsPages: {
                    ...state.medicamentsPages,
                    data: state.medicamentsPages.data?.map(elt => {
                        if(elt.id === action.payload.id) {
                            elt = {...elt, ...action.payload};
                        }
                        return elt;
                    })
                },
                successMessage: 'Medicament Update Successfully Completed',
                errorMessage: '',
                showLoading: false,
            };
            
        case CREATE_MEDICAMENT_CONFIRMED_ACTION:
            return {
                ...state,
                medicamentsPages: {...state.medicamentsPages, data: [...state.medicamentsPages.data, action.payload]},
                successMessage: 'Medicament created Successfully Completed',
                errorMessage: '',
                showLoading: false,
            };
            
        case DELETE_TARIF_MEDICAMENT_CONFIRMED_ACTION:
            return {
                ...state,
                tarifsMedicament: state.tarifsMedicament.filter(elt => elt.id !== action.payload),
                successMessage: 'Medicament created Successfully Completed',
                errorMessage: '',
                showLoading: false,
            };
            
        case DELETE_TARIF_MEDICAMENT_FAILED_ACTION:
            return {
                ...state,
                successMessage: '',
                errorMessage: 'Tarif medicament could not be delete',
                showLoading: false,
            };
            
        case DELETE_DATA_CONFIRMED_ACTION:
            return {
                ...state,
                specialities: action.payload.type === 'SPECIALITE' ?
                    state.specialities.filter(elt => elt.id !== action.payload.id) : 
                    state.specialities,
                actesPages: action.payload.type === 'ACTE' ?
                    {...state.actesPages, data: state.actesPages.data?.filter(elt => elt.id !== action.payload.id)} : 
                    state.actesPages,
                lettreCles: action.payload.type === 'LETTRECLE' ?
                    state.lettreCles.filter(elt => elt.id !== action.payload.id) : 
                    state.lettreCles,
                typeActes: action.payload.type === 'TYPEACTE' ?
                    state.typeActes.filter(elt => elt.id !== action.payload.id) : 
                    state.typeActes,
                providerTypes: action.payload.type === 'TYPEPRESTATAIRE' ?
                    state.providerTypes.filter(elt => elt.id !== action.payload.id) : 
                    state.providerTypes,
                tarifs: action.payload.type === 'TARIF' ?
                    state.tarifs.filter(elt => elt.id !== action.payload.id) : 
                    state.tarifs,
                actesRes: action.payload.type === 'ACTE' ?
                    state.actesRes?.filter(elt => elt.id !== action.payload.id) : 
                    state.actesRes,
                familleActes: action.payload.type === 'FAMILLETYPEACTE' ?
                    state.familleActes.filter(elt => elt.id !== action.payload.id) : 
                    state.familleActes,
                medicamentsPages: action.payload.type === 'MEDICAMENT' ?
                    {...state.medicamentsPages, data: state.medicamentsPages.data?.filter(elt => elt.id !== action.payload.id)} : 
                    state.medicamentsPages,
                successMessage: 'Deletion Successfully Completed',
                errorMessage: '',
                showLoading: false,
            };
            
        case DELETE_DATA_FAILED_ACTION:
            return {
                ...state,
                successMessage: '',
                errorMessage: 'Deletion Failed',
                showLoading: false,
            };
            
        case REMOVE_TARIF_CONFIRMED_ACTION:
            return {
                ...state,
                successMessage: 'Removal Successfully Completed',
                errorMessage: '',
                showLoading: false,
            };
            
        case REMOVE_TARIF_FAILED_ACTION:
            return {
                ...state,
                successMessage: '',
                errorMessage: 'Removal Failed',
                showLoading: false,
            };
            
        case UPDATE_PROVIDER_TYPE_CONFIRMED_ACTION:
            return {
                ...state,
                providerTypes: state.providerTypes.map(providerType => {
                    if(providerType.id === action.payload.id){
                        providerType.code = action.payload.code;
                        providerType.nameFr = action.payload.nameFr;
                        providerType.nameEn = action.payload.nameEn;
                    }
                    return providerType;
                }),
                successMessage: 'Update Successfully Completed',
                errorMessage: '',
                showLoading: false,
            };
            
        case UPDATE_PROVIDER_TYPE_FAILED_ACTION:
            return {
                ...state,
                successMessage: '',
                errorMessage: 'Update Failed',
                showLoading: false,
            };
            
        case UPDATE_SPECIALITY_CONFIRMED_ACTION:
            return {
                ...state,
                specialities: state.specialities.map(elt => {
                    if(elt.id === action.payload.id) {
                        elt.nameEn = action.payload.nameEn;
                        elt.nameFr = action.payload.nameFr;
                        elt.code = action.payload.code;
                    }
                    return elt;
                }),
                successMessage: 'Update Successfully Completed',
                errorMessage: '',
                showLoading: false,
            };
            
        case UPDATE_SPECIALITY_FAILED_ACTION:
            return {
                ...state,
                successMessage: '',
                errorMessage: 'Update Failed',
                showLoading: false,
            };
            
        case UPDATE_FAMILLE_ACTES_CONFIRMED_ACTION:
            return {
                ...state,
                familleActes: state.familleActes.map(elt => {
                    if(elt.id === action.payload.id) {
                        elt.nameEn = action.payload.nameEn;
                        elt.nameFr = action.payload.nameFr;
                        elt.code = action.payload.code;
                    }
                    return elt;
                }),
                successMessage: 'Update Successfully Completed',
                errorMessage: '',
                showLoading: false,
            };
            
        case UPDATE_FAMILLE_ACTES_FAILED_ACTION:
            return {
                ...state,
                successMessage: '',
                errorMessage: 'Update Failed',
                showLoading: false,
            };
            
        case UPDATE_TYPE_ACTES_CONFIRMED_ACTION:
            return {
                ...state,
                typeActes: state.typeActes.map(elt => {
                    if(elt.id === action.payload.id){
                        elt = {...elt, ...action.payload};
                    }
                    return elt;
                }),
                successMessage: 'Update Successfully Completed',
                errorMessage: '',
                showLoading: false,
            };
            
        case UPDATE_TYPE_ACTES_FAILED_ACTION:
            return {
                ...state,
                successMessage: '',
                errorMessage: 'Update Failed',
                showLoading: false,
            };
            
        case UPDATE_LETTRE_CLE_CONFIRMED_ACTION:
            return {
                ...state,
                lettreCles: state.lettreCles.map(elt => {
                    if(elt.id === action.payload.id) {
                        elt = {...elt, ...action.payload};
                    }
                    return elt;
                }),
                successMessage: 'Update Successfully Completed',
                errorMessage: '',
                showLoading: false,
            };
            
        case UPDATE_LETTRE_CLE_FAILED_ACTION:
            return {
                ...state,
                successMessage: '',
                errorMessage: 'Update Failed',
                showLoading: false,
            };
            
        case REGISTER_FAMILLE_ACTE_CONFIRMED_ACTION:
            return {
                ...state,
                familleActes: [...state.familleActes, action.payload],
                successMessage: 'Update Successfully Completed',
                errorMessage: '',
                showLoading: false,
            };

        case CREATE_ACTE_CONFIRMED_ACTION:
            return {
                ...state,
                actesPages: {...state.actesPages, data: [...state.actesPages.data, action.payload]},
                actesRes: state.actesRes.length > 0 ? [...state.actesRes, action.payload] : state.actesRes,
                successMessage: 'Create act Successfully Completed',
                errorMessage: '',
                showLoading: false,
            }

        case REGISTER_LETTRE_CLE_CONFIRMED_ACTION:
            return {
                ...state,
                lettreCles: [...state.lettreCles, action.payload],
                successMessage: 'Create lettre clé Successfully Completed',
                errorMessage: '',
                showLoading: false,
            }
            
        case SEARCH_ACTES_CONSULT_CONFIRMED_ACTION:
            return {
                ...state,
                actesConsult: action.payload,
                successMessage: 'Update Successfully Completed',
                errorMessage: '',
                showLoading: false,
            };
            
        case SEARCH_ACTES_CONSULT_FAILED_ACTION:
            return {
                ...state,
                successMessage: '',
                errorMessage: 'get Failed',
                showLoading: false,
            };
            
        case GET_ALL_MONTURE_CONFIRMED_ACTION:
            return {
                ...state,
                montures: action.payload,
                successMessage: 'Update Successfully Completed',
                errorMessage: '',
                showLoading: false,
            };
            
        case GET_ALL_MONTURE_FAILED_ACTION:
            return {
                ...state,
                successMessage: '',
                errorMessage: 'get Failed',
                showLoading: false,
            };
            
        case GET_ALL_OPTIC_PARAMS_CONFIRMED_ACTION:
            return {
                ...state,
                parametresOptique: action.payload,
                successMessage: 'Update Successfully Completed',
                errorMessage: '',
                showLoading: false,
            };
            
        case GET_ALL_OPTIC_PARAMS_FAILED_ACTION:
            return {
                ...state,
                successMessage: '',
                errorMessage: 'get Failed',
                showLoading: false,
            };
            
        case GET_OPTIC_PRIZE_GRID_CONFIRMED_ACTION:
            return {
                ...state,
                opticPrizeGrid: action.payload,
                successMessage: 'Optic grid gotten',
                errorMessage: '',
                showLoading: false,
            };
            
        case GET_TARIFS_PROVIDER_CONFIRMED_ACTION:
            return {
                ...state,
                providerTarifs: action.payload,
                successMessage: 'Tarifs grid gotten',
                errorMessage: '',
                showLoading: false,
            };
            
        case GET_TARIFS_PROVIDER_FAILED_ACTION:
            return {
                ...state,
                successMessage: '',
                errorMessage: 'get tarifs Failed',
                showLoading: false,
            };
            
        case GET_TARIFS_PROVIDER_MED_CONFIRMED_ACTION:
            return {
                ...state,
                providerTarifsMed: action.payload,
                successMessage: 'Tarifs grid gotten',
                errorMessage: '',
                showLoading: false,
            };
            
        case GET_TARIFS_PROVIDER_MED_FAILED_ACTION:
            return {
                ...state,
                successMessage: '',
                errorMessage: 'get tarifs pharmacie Failed',
                showLoading: false,
            };

        case START_LOADER_ACTION:
            return {
                ...state,
                showLoading: true,
            };
        default:
            return state;
    };
};