import axios from 'axios';

const headers = { 'Authorization': 'Bearer '+JSON.parse(localStorage.getItem('userDetails'))?.token, 'Accept-Language': localStorage.getItem('currentLanguage')};

const consultationServiceRequest = axios.create({
  baseURL: process.env.REACT_APP_BASEURL_CONSULTATION_BACKEND,
  headers: headers
});

export const deleteUserNotifications = (userId) => consultationServiceRequest.get('/notification/'+userId);
export const deleteProfilNotifications = (profilId) => consultationServiceRequest.get('/notification/'+profilId);
export const listGroupByCategory = () => consultationServiceRequest.get('/notifications/count');
export const listByCategory = (category) => consultationServiceRequest.get('/notifications/'+category);
