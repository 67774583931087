import axios from 'axios';


const headers = { 'Authorization': 'Bearer '+JSON.parse(localStorage.getItem('userDetails'))?.token, 'Accept-Language': localStorage.getItem('currentLanguage')};
const consultationServiceRequest = axios.create({
  baseURL: process.env.REACT_APP_BASEURL_CONSULTATION_BACKEND,
  headers: headers
});

export const getAllAccordRequest = () => consultationServiceRequest.get('/accords-preables');
export const validateAccord = (id) => consultationServiceRequest.put('/accord-preable/'+id+'/accept');
export const rejectAccord = (id, data) => consultationServiceRequest.put('/accord-preable/'+id+'/refuse', data);
export const sendAccordMessage = (data) => consultationServiceRequest.post('/accord-preable/discussion', data);
export const getAccordDetails = (accordId) => consultationServiceRequest.get('/accords-preable/'+accordId);

// Proforma
//Optique
export const getProformas = () => consultationServiceRequest.get('/Optic/optic-bon');
export const getProforma = (id) => consultationServiceRequest.get('/Optic/'+id+'/detail');
export const validateProforma = (data) => consultationServiceRequest.put('/optic/validate/bon/'+data);
export const rejectProforma = (id, data) => consultationServiceRequest.put('/optic/rejeter/bon/'+id, data);
export const restoreProforma = (data) => consultationServiceRequest.put('/optic/restore/bon/'+data);
export const rejectProformaLine = (id, data) => consultationServiceRequest.put('/optic/rejeter/bon-detail/'+id, data);
export const restoreProformaLine = (data) => consultationServiceRequest.put('/optic/restore/bon-detail/'+data);

//Kine
export const getProformasKine = () => consultationServiceRequest.get('/all-kine-to-validate');
//export const getProformaKine = (id) => consultationServiceRequest.get('/Kine/'+id+'/detail');
export const getProformaKine = (id) => consultationServiceRequest.get('/kine-proformat/'+id);
export const validateProformaKine = (data) => consultationServiceRequest.put('/Kine/validate/bon/'+data);
export const rejectProformaKine = (id, data) => consultationServiceRequest.put('/Kine/rejeter/bon/'+id, data);
export const restoreProformaKine = (data) => consultationServiceRequest.put('/Kine/restore/bon/'+data);
export const rejectProformaKineLine = (id, data) => consultationServiceRequest.put('/Kine/rejeter/bon-detail/'+id, data);
export const restoreProformaKineLine = (data) => consultationServiceRequest.put('/Kine/restore/bon-detail/'+data);