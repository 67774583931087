import React from "react";

import ReactApexChart from "react-apexcharts";

const HorizontalBarChart = (props) => {
    const {series, height, labels} = props;

    const options = {
        chart: {
            type: 'bar',
            height: 350
        },
        plotOptions: {
            bar: {
            borderRadius: 4,
            horizontal: true,
            }
        },
        dataLabels: {
            enabled: false
        },
        xaxis: {
            categories: labels,
        }
    };

    return (
        <div>
            <div id="chart">
            <ReactApexChart options={options} series={series} type="bar" height={height} />
            </div>
            <div id="html-dist"></div>
        </div>
    );
}

export default HorizontalBarChart;