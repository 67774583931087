import { connect, useDispatch } from 'react-redux';
import React, {Fragment, useEffect, useState, useRef} from "react";
import { Link, useParams } from "react-router-dom";
import { Row, Col, Card, Accordion, Button, Spinner, Badge, Modal } from 'react-bootstrap';
import { 
    validateProformaKineLineAction, 
    loadingToggleAction, 
    validateProformaKineAction, 
    getProformaKineAction, 
    restoreProformaKineAction 
} from '../../../../store/actions/AccordPreaActions';
import { getMotifsExclusionAction } from "../../../../store/actions/FacturationAction";
import { currentUser } from "../../../../store/selectors/CurrentUserSelector";
import { getProviderDataAction } from '../../../../store/actions/ProviderActions';
import { useReactToPrint } from 'react-to-print';
import MotifRejetModal from "./MotifRejetModal";
import { STATUSES } from '../../../globals';
import { useTranslation } from "react-i18next";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

const ProformaKineView = (props) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { id, providerId } = useParams();
    const [currentKey, setCurrentKey] = useState("");
    const [opticDatas, setOpticDatas] = useState([]);
    const { proformaData, currentUser, history, errorMessage, successMessage, showLoading, motifExclusion, provider } = props;

    const acteBodyTemplate = (rowData) => {
        return rowData.subData ? 
                <>
                    <span>{rowData.acte}</span><br/>
                    <small className='text-primary'>{rowData.subData}</small>
                </> :
                rowData.acte;
    }

    const actionsBodyTemplate = (rowData) => {
        return  <>
                    {rowData.acte !== "Total" && !rowData.isRejet && <button
                        onClick={e => setCurrentKey(rowData.acte)}
                        className="btn btn-danger p-2 mr-2"
                    >
                        <i className="fa fa-trash"></i>
                    </button>}
                    <Modal
                        className="fade bd-example-modal-lg"
                        show={currentKey === rowData.acte}
                        size="lg"
                    >
                        <MotifRejetModal 
                            showModal={currentKey === rowData.acte}
                            acte={rowData}
                            manageCommentModal={manageCommentModal}
                            history={history}
                            exclusions={motifExclusion}
                            motifRejet={rowData.motifRejet}
                            total={rowData.prixPrestataire}
                            isDetail={true}
                        />
                    </Modal>
                    {rowData.acte !== "Total" && rowData.isRejet && <button
                        onClick={e => this.cancelDeletion(data.id)}
                        className="tooltip-test btn btn-success p-2 mr-2"
                        data-toggle="tooltip"
                        title={rowData.motifRejet}
                    >
                        <i className="la la-trash-restore"></i>
                    </button>}
                </>
            
    };

    const canDo = (menuCode, actionCode) => {
        let theMenu = props.currentUser.menus?.find(menu => menu.code === menuCode);
        if(!theMenu){
            return false;
        }else{
            return theMenu.actionMenus?.filter(action => action.code === actionCode).length > 0;
        }
    }
    
    const hasMenu = (menuCode) => {
        return props.currentUser.menus?.filter(menu => menu.code === menuCode).length > 0;
    }

    useEffect(() => {
        if(hasMenu('MENPROFORMA') && canDo('MENPROFORMA', 'VIEWPROFORMA')){
            dispatch(loadingToggleAction());
            dispatch(getProformaKineAction(id));
            dispatch(getProviderDataAction(providerId));
            dispatch(getMotifsExclusionAction());
        }else{
            props.history.push('/access-denied');
        }
    }, []);

    useEffect(() => {
        dispatch(loadingToggleAction());
        dispatch(getProformaKineAction(id));
    }, [successMessage, errorMessage]);

    useEffect(() => {
        if(Object.keys(proformaData).length > 0) {
            if(proformaData.status === STATUSES.waitingAccordPreable || proformaData.status === STATUSES.confirmed) {
                setOpticDatas([...proformaData.kineProformatDetail?.map(elt => {
                    return {
                        bonDetailId: elt.kinesitherapieBonDetailId,
                        acte: elt.acteName,
                        id: elt.acteId,
                        code: elt.acteCode,
                        subData: elt.description,
                        prixActiva: elt.priceActiva || 0, 
                        prixPrestataire: elt.priceConvention || 0,
                        motifRejet: elt.motifRejet, 
                        montantRejet: elt.amountRejet, 
                        isRejet: elt.amountRejet > 0
                    }
                }), {
                    acte: 'Total',
                    subData: null,
                    prixActiva: proformaData.priceActiva || 0, 
                    prixPrestataire: proformaData.kineProformatDetail?.reduce((sum, elt) => sum + elt.priceConvention, 0) * proformaData.kinesitherapieBon?.nombreSession || 0,
                    motifRejet: proformaData.motifRejet, 
                    montantRejet: proformaData.amountRejet || 0, 
                    isRejet: proformaData.amountRejet > 0
                }])
            }else{
                setOpticDatas([...proformaData.kineProformatDetail?.map(elt => {
                    return {
                        bonDetailId: elt.kinesitherapieBonDetailId,
                        acte: elt.acteName,
                        id: elt.acteId,
                        code: elt.acteCode,
                        subData: elt.description,
                        prixActiva: elt.priceActiva || 0, 
                        prixPrestataire: elt.priceConvention || 0,
                    }
                }), {
                    acte: 'Total',
                    subData: null,
                    prixActiva: proformaData.priceActiva || 0, 
                    prixPrestataire: proformaData.kineProformatDetail?.reduce((sum, elt) => sum + elt.priceConvention, 0) * proformaData.kinesitherapieBon?.nombreSession || 0
                }])
            }
        }
    }, [proformaData]);
    
    const manageCommentModal = () => {
       setCurrentKey(false);
    }

    return (
        <Fragment>
            { props.showLoading && 
                <div id="preloader">
                    <div className="sk-three-bounce">
                        <div className="sk-child sk-bounce1"></div>
                        <div className="sk-child sk-bounce2"></div>
                        <div className="sk-child sk-bounce3"></div>
                    </div>
                </div> 
            }

            { !props.showLoading &&
                <div className="bg-white p-5">
                    {Object.keys(proformaData).length > 0 && 
                        <>
                            <h2 className='text-center mb-5'>{t('common.proforma-kine-title')}</h2>
                            <h4 className='text-center'>
                                <b className='text-warning text-center'>{t('statuses.'+proformaData.status)}</b>
                            </h4>
                            {proformaData.motifRejet && proformaData.motifRejet.length > 0 && <p className='text-center'>
                                <b className='text-danger text-center'>{proformaData.motifRejet}</b>
                            </p>}
                            <div className='row'>
                                <div className='col-6'>
                                    <h4 className='mt-2 text-dark text-start'>
                                        <span style={{textDecoration: 'underline'}}>{t('common.executant')}:</span>
                                        <span className='text-bold text-primary ml-2'>{proformaData.ProviderName}</span>
                                    </h4>
                                    <h4 className='mt-2 text-dark text-start'>
                                        <span style={{textDecoration: 'underline'}}>{t('common.submission-date')}:</span>
                                        <span className='text-bold text-primary ml-2'>{proformaData.createdAt}</span>
                                    </h4>
                                </div>
                                <div className='col-6 text-right'>
                                    <h4 className='mt-2 text-dark text-start'>
                                        <span style={{textDecoration: 'underline'}}>{t('common.patient')}:</span>
                                        <span className='text-bold text-primary ml-2'>{proformaData.beneficiaryName}</span>
                                    </h4>
                                    <h4 className='mt-2 text-dark text-start'>
                                        <span style={{textDecoration: 'underline'}}>{t('common.prescriptor')}:</span>
                                        <span className='text-bold text-primary ml-2'>{proformaData.providerName}</span>
                                    </h4>
                                </div>
                            </div>
                            <div className="border-top my-2"></div>

                            <div className='row'>
                                <div className='form-group col-4'>
                                    <label className='mb-1 '>
                                        <strong>Type: </strong>
                                        <span className='text-primary'>
                                            {proformaData.kinesitherapieBon?.typeOrdonance}
                                        </span>
                                    </label>
                                </div>
                                <div className='form-group col-4'>
                                    <label className='mb-1 '>
                                        <strong>Nombre de séances: </strong>
                                        <span className='text-primary'>
                                            {proformaData.kinesitherapieBon?.nombreSession}
                                        </span>
                                    </label>
                                </div>
                                <div className='form-group col-4'>
                                    <label className='mb-1 '>
                                        <strong>Fréquence: </strong>
                                        <span className='text-primary'>
                                            {proformaData.kinesitherapieBon?.frequence}/{proformaData.kinesitherapieBon?.uniteFrequence}
                                        </span>
                                    </label>
                                </div>
                                <div className='form-group col-4'>
                                    <label className='mb-1 '>
                                        <strong>A domicile: </strong>
                                        <span className='text-primary'>
                                            {proformaData.kinesitherapieBon?.isDomicile ? t('kine.yes') : t('kine.no')}
                                        </span>
                                    </label>
                                </div>
                            </div>
                            <div className="card">
                                <DataTable value={opticDatas} showGridlines tableStyle={{ minWidth: '50rem' }}>
                                    <Column field="acte" header="" style={{ minWidth: '200px' }} body={acteBodyTemplate}></Column>
                                    <Column field="prixActiva" header={t('common.activa-price')} style={{ minWidth: '200px' }}></Column>
                                    <Column field="prixPrestataire" header={t('common.price')} style={{ minWidth: '150px' }}></Column>
                                    <Column field="montantRejet" header={t('common.exl-amount')} style={{ minWidth: '150px' }}></Column>
                                    <Column field="motifRejet" header={t('common.exl-motif')} style={{ minWidth: '150px' }}></Column>
                                    {(proformaData.status !== STATUSES.rejeted && proformaData.status !== STATUSES.accepted) && <Column field="actions" header="Actions" body={actionsBodyTemplate}></Column>}
                                </DataTable>
                            </div>
                            <div className="col-lg-4 col-sm-5 ml-auto">
                                <table className="table table-clear">
                                    <tbody>
                                        <tr>
                                            <td className="left">
                                                <strong>Montant Total</strong>
                                            </td>
                                            <td className="right">{proformaData.priceConvention}</td>
                                        </tr>
                                        <tr>
                                            <td className="left">
                                                <strong>Ticket Modérateur</strong>
                                            </td>
                                            <td className="right">{proformaData.pricePatient}</td>
                                        </tr>
                                        {proformaData.amountRejet > 0 && <tr>
                                            <td className="left">
                                                <strong>Montant Rejeté</strong>
                                            </td>
                                            <td className="right">{proformaData.amountRejet}</td>
                                        </tr>}
                                        <tr>
                                            <td className="left">
                                                <strong>Part assureur</strong>
                                            </td>
                                            <td className="right">{proformaData.priceActiva - proformaData.amountRejet}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            
                            {proformaData.status === STATUSES.waitingAccordPreable && <div className='row justify-content-end my-2'>
                                {hasMenu('MENPROFORMA') && canDo('MENPROFORMA', 'VALIDATEPROFORMA') && <div className='col-2'>
                                    <button onClick={e => dispatch(validateProformaKineAction(proformaData.id))} type='button' className='btn btn-success btn-block'>
                                    {t('common.validate')}
                                    </button>
                                </div>}
                                {proformaData.status !== STATUSES.rejeted && hasMenu('MENPROFORMA') && canDo('MENPROFORMA', 'REJECTPROFORMA') && <div className='col-2'>
                                    <button onClick={e => setCurrentKey("proforma")} type='button' className='btn btn-danger btn-block'>
                                    {t('common.rejected')}
                                    </button>
                                    <Modal
                                        className="fade bd-example-modal-lg"
                                        show={currentKey === "proforma"}
                                        size="lg"
                                    >
                                        <MotifRejetModal 
                                            showModal={currentKey === "proforma"}
                                            acte={proformaData}
                                            manageCommentModal={manageCommentModal}
                                            history={history}
                                            exclusions={motifExclusion}
                                            motifRejet={proformaData.motifExclusion}
                                            isDetail={false}
                                            montantAssureur={proformaData.priceConvention - proformaData.amountRejet}
                                            montantRejete={proformaData.amountRejet}
                                        />
                                    </Modal>
                                </div>}
                            </div>}
                            {(proformaData.status === STATUSES.rejeted) && <div className='row justify-content-end my-2'>
                                <div className='col-2'>
                                    {hasMenu('MENPROFORMA') && canDo('MENPROFORMA', 'RESTOREPROFORMA') && <button onClick={e => {
                                        e.preventDefault();
                                        dispatch(loadingToggleAction());
                                        dispatch(restoreProformaKineAction(proformaData.id))
                                    }} type='button' className='btn btn-success btn-block'>
                                    {t('common.restore')}
                                    </button>}
                                </div>
                            </div>}
                            {proformaData.status === STATUSES.exectuted && <div className='row justify-content-end my-2'>
                                <div className='col-2'>
                                    <b className='text-success'>{t('common.glasses-delivered')} </b>
                                </div>
                            </div>}
                        </>
                    }
                </div>
            }
        </Fragment>
    )
};
const mapStateToProps = (state) => {
    return {
       motifExclusion: state.facturation.motifExclusion,
       proformaData: state.accordPrea.proformaKine,
       successMessage: state.accordPrea.successMessage,
       errorMessage: state.accordPrea.errorMessage,
       showLoading: state.accordPrea.showLoading,
       provider: state.provider.provider,
       currentUser: currentUser(state)
    };
};
export default connect(mapStateToProps)(ProformaKineView);