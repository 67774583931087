import axios from 'axios';

const headers = { 'Authorization': 'Bearer '+JSON.parse(localStorage.getItem('userDetails'))?.token, 'Accept-Language': localStorage.getItem('currentLanguage')};

const parameterServiceRequest = axios.create({
  baseURL: process.env.REACT_APP_BASEURL_ADMIN_BACKEND+"/Parameter",
  headers: headers 
}); 

const parameterServiceRequest_bis = axios.create({
  baseURL: process.env.REACT_APP_BASEURL_ADMIN_BACKEND,
  headers: headers 
});


export const getCountries = () => parameterServiceRequest.get('/Countries');

export const getWorkingFields = () => parameterServiceRequest.get('/WorkingFields');

export const getGlobalActs = () => parameterServiceRequest.get('/acte-standard');

export const mapGlobalAct = (param) => parameterServiceRequest.post('/acte-standard', param);

export const updateGlobalActMapping = (param) => parameterServiceRequest.put('/acte-standard', param);

export const deleteGlobalAct = (id) => parameterServiceRequest_bis.delete('/acte-standard/'+id);

export const getParamIdent = () => parameterServiceRequest.get('/acte-standard');

export const mapParamIdent = (param) => parameterServiceRequest.post('/acte-standard', param);

export const updateParamIdent = (param) => parameterServiceRequest.put('/acte-standard', param);

export const deleteParamIdent = (id) => parameterServiceRequest_bis.delete('/acte-standard/'+id);