import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";

const BasicDatatable = (props) => {
  const [data, setData] = useState(
    document.querySelectorAll("#job_data tbody tr")
  );
  const sort = 5;
  const activePag = useRef(0);
  const [test, settest] = useState(0);
  const tableHeader = props.header.map((key, value) => {
    return (
      <th>{key}</th>
    );
  });

  const tableBody = props.body;

  const tableFooter = props.pagination;

  // use effect
      useEffect(() => {
        setData(document.querySelectorAll("#patient_list tbody tr"));
    }, []);
  // Active data
  const chageData = (frist, sec) => {
     for (var i = 0; i < data.length; ++i) {
        if (i >= frist && i < sec) {
           data[i].classList.remove("d-none");
        } else {
           data[i].classList.add("d-none");
        }
     }
  };

  // Active pagginarion
  activePag.current === 0 && chageData(0, sort);
  // paggination
  let paggination = Array(Math.ceil(data.length / sort))
     .fill()
     .map((_, i) => i + 1);

  // Active paggination & chage data
  const onClick = (i) => {
     activePag.current = i;
     chageData(activePag.current * sort, (activePag.current + 1) * sort);
  };

  return (
    <div className="row">
      <div className="col-xl-12">
        <div className="table-responsive">
          <div
            id="patient_list"
            className="dataTables_wrapper no-footer"
          >
            <table
              id="example5"
              className="table table-striped patient-list mb-4 dataTablesCard fs-14 dataTable no-footer"
              role="grid"
              aria-describedby="example5_info"
            >
              <thead>
                <tr role="row">
                  {tableHeader}
                </tr>
              </thead>
              <tbody>
                {tableBody}
              </tbody>
            </table>
            {tableFooter}
            {!tableFooter && <div className="d-sm-flex text-center justify-content-between align-items-center">
              <div
                className="dataTables_info"
                id="example5_info"
                role="status"
                aria-live="polite"
              >
                Showing {activePag.current * sort + 1} to{" "}
                {data.length > (activePag.current + 1) * sort
                  ? (activePag.current + 1) * sort
                  : data.length}{" "}
                of {data.length} entries
              </div>
              <div className="dataTables_paginate paging_simple_numbers d-flex  justify-content-center align-items-center pb-3">
                <Link
                  to="#"
                  className="paginate_button previous disabled"
                  aria-controls="example5"
                  data-dt-idx={0}
                  tabIndex={0}
                  id="example5_previous"
                  onClick={() =>
                    activePag.current > 0 &&
                    onClick(activePag.current - 1)
                  }
                >
                  Previous
                </Link>
                <span className="d-flex">
                  {paggination.map((number, i) => (
                    <Link
                      key={i}
                      to="#"
                      className={`paginate_button d-flex align-items-center justify-content-center ${activePag.current === i ? "current" : ""
                        } ${i > 0 ? "ml-1" : ""}`}
                      aria-controls="example5"
                      data-dt-idx={1}
                      tabIndex={0}
                      onClick={() => onClick(i)}
                    >
                      {number}
                    </Link>
                  ))}
                </span>

                <Link
                  to="#"
                  className="paginate_button next disabled"
                  aria-controls="example5"
                  data-dt-idx={2}
                  tabIndex={0}
                  id="example5_next"
                  onClick={() =>
                    activePag.current + 1 < paggination.length &&
                    onClick(activePag.current + 1)
                  }
                >
                  Next
                </Link>
              </div>
            </div>}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BasicDatatable;
