import axios from 'axios';

const headers = { 'Authorization': 'Bearer '+JSON.parse(localStorage.getItem('userDetails'))?.token, 'Accept-Language': localStorage.getItem('currentLanguage')};

const userServiceRequest = axios.create({
  baseURL: process.env.REACT_APP_BASEURL_ADMIN_BACKEND+"/Parameter",
  headers: headers 
});

const clientServiceRequest = axios.create({
  baseURL: process.env.REACT_APP_BASEURL_CLIENT_BACKEND,
  headers: headers
});

export const createAction = (data) => userServiceRequest.post('/user/ActionMenu', data);

export const updateAction = (id, data) => userServiceRequest.put('/user/UpdateActionMenu/', {...data, id});

export const createMenu = (data) => userServiceRequest.post('/user/menu', data);

export const updateMenu = (id, data) => userServiceRequest.put('/user/UpdateMenu/', {...data, id});

export const createProfil = (data) => userServiceRequest.post('/user/profil', data);

export const updateProfil = (id, data) => userServiceRequest.put('/user/UpdateProfil/', {...data, id});

export const createRole = (data) => userServiceRequest.post('/user/role', data);

export const updateRole = (id, data) => userServiceRequest.put('/user/UpdateRole/', {...data, id});

export const createUser = (data) => userServiceRequest.post('/user/create', data);

export const updateUser = (id, data) => userServiceRequest.put('/user/Update/'+id, data);

export const getUsers = () => userServiceRequest.get('/user/all');

export const getActions = () => userServiceRequest.get('/user/menu-actions');

export const getMenus = () => userServiceRequest.get('/user/menus');

export const getProfils = () => userServiceRequest.get('/user/profils');

export const getUserParameter = () => userServiceRequest.get('/user/parameters');

export const getRoles = () => userServiceRequest.get('/user/roles');

export const affectUserProfil = () => userServiceRequest.get('/user/userprofil');

export const deleteAction = (data) => userServiceRequest.delete('/user/DeleteAction?id='+data.id);

export const deleteMenu = (data) => userServiceRequest.delete('/user/DeleteMenu?id='+data.id);

export const deleteRole = (data) => userServiceRequest.delete('/user/DeleteRole?id='+data.id);

export const deleteProfil = (data) => userServiceRequest.delete('/user/DeleteProfil?id='+data.id);

export const deleteUser = (data) => userServiceRequest.delete('/user/DeleteUser?id='+data.id);

export const createMenuRoleActions = (data) => userServiceRequest.post('/user/menu-role-actions', data);

export const resetPassword = (id) => userServiceRequest.get('/User/reset-password/'+id);

export const resendParameters = (id) => userServiceRequest.get('/User/ressend-user-info/'+id);

export const listCodesValidation = (assure, telephone, numAssure) => clientServiceRequest.get('/opt/list',{
  params: {
    nomClient: assure,
    phoneClient: telephone,
    id: numAssure
  }
});


