import React, { useState } from "react";
import { useDispatch } from 'react-redux';
import { rejectAccordAction } from "../../../../store/actions/AccordPreaActions";
import { Button, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";

/* --- BEGIN MotifRejetModal
	@param(Object): props
    description: Composant de gestion du motif de rejet d'une demande d'accord préalable
*/
export const MotifRejetModal = (props) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { accordRef, manageSupModal, history } = props;
    const [motif, setMotif] = useState("");
    const [error, setError] = useState(null);

    
    /* --- BEGIN deleteProviderWithMotive
		   @param(): aucun
		   description : Fonction d'enregistrement du refus d'une demande d'accord préalable avec motif
    */
    const deleteProviderWithMotive = () => {
        if(motif.length === 0) {
            setError(t('common.motif-rejet-error'));
            return;
        }

        const data = {motif};
        console.log(accordRef.id);

        dispatch(rejectAccordAction(accordRef.id, data, history));
        manageSupModal(false, true);

    }
    /* --- END MotifRejetModal */

    return (
            <>
                    <Modal.Header>
                        <Modal.Title className="text-center" >
                                Rejet du la demande l'accord pour l'accord{' '}<br/>
                                <b className="text-primary" >{accordRef.acteName}</b>
                        </Modal.Title>
                        <Button
                            variant=""
                            className="close"
                            onClick={() => manageSupModal(false)}
                        >
                            <span>&times;</span>
                        </Button>
                    </Modal.Header>
                    <Modal.Body>
                        <h5>{t('common.reject-label')}</h5>
                        
                        { error && <small className="text-danger">{{error}}</small> }

                        <div className='row justify-content-between align-items-center'>
                            <div className='col-12 form-group'>
                                <textarea
                                className='form-control'
                                rows='4'
                                id='comment'
                                value={motif}
                                onChange={(e) => setMotif(e.target.value)}
                                ></textarea>
                            </div>
                        </div>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant="danger light"
                            onClick={() => manageSupModal(false, false)}
                        >
                           {t('common.cancel')}
                        </Button>
                        <Button
                            variant=""
                            type="button"
                            className="btn btn-primary"
                            disabled={motif.trim().length === 0}
                            onClick={() => deleteProviderWithMotive()}
                        >
                           {t('common.save')}
                        </Button>
                    </Modal.Footer>
            </>
    )
};
/* --- END MotifRejetModal */

export default MotifRejetModal;