import {
    LIST_BY_CATEGORY_CONFIRMED_ACTION,
    LIST_BY_CATEGORY_FAILED_ACTION,
    LIST_CONFIRMED_ACTION,
    LIST_FAILED_ACTION,
    DEL_USER_NOTIFS_CONFIRMED_ACTION,
    DEL_USER_NOTIFS_FAILED_ACTION,
    DEL_PROFIL_NOTIFS_CONFIRMED_ACTION,
    DEL_PROFIL_NOTIFS_FAILED_ACTION,
    LOADING_TOGGLE_ACTION,
    BOX_LOADING_TOGGLE_ACTION
} from '../actions/NotificationAction';

const initialState = {
    notifications: [],
    notificationsGroups: [],
    errorMessage: '',
    successMessage: '',
    showLoading: false,
    boxShowLoading: false,
};

export const NotificationReducer = (state = initialState, action) => {
    switch (action.type) {
        case LIST_BY_CATEGORY_CONFIRMED_ACTION:
            return {
                ...state,
                notifications: action.payload,
                errorMessage: '',
                successMessage: 'Notifications listing Successfully Completed',
                showLoading: false,
            };
        case LIST_BY_CATEGORY_FAILED_ACTION:
            return {
                ...state,
                errorMessage: action.payload,
                successMessage: '',
                showLoading: false,
            };
        case LIST_CONFIRMED_ACTION:
            return {
                ...state,
                notificationsGroups: action.payload,
                errorMessage: '',
                successMessage: 'Notifications listing Successfully Completed',
                boxShowLoading: false,
            };
        case LIST_FAILED_ACTION:
            return {
                ...state,
                errorMessage: action.payload,
                successMessage: '',
                boxShowLoading: false,
            };
        case DEL_USER_NOTIFS_CONFIRMED_ACTION:
            return {
                ...state,
                errorMessage: '',
                successMessage: 'Notification deletion Successfully Completed',
                showLoading: false,
            };
        case DEL_USER_NOTIFS_FAILED_ACTION:
            return {
                ...state,
                errorMessage: action.payload,
                successMessage: '',
                showLoading: false,
            };
        case DEL_PROFIL_NOTIFS_CONFIRMED_ACTION:
            return {
                ...state,
                errorMessage: '',
                successMessage: 'Notification deletion Successfully Completed',
                showLoading: false,
            };
        case DEL_PROFIL_NOTIFS_FAILED_ACTION:
            return {
                ...state,
                errorMessage: action.payload,
                successMessage: '',
                showLoading: false,
            };
        case LOADING_TOGGLE_ACTION:
            return {
                ...state,
                showLoading: true,
            };
        case BOX_LOADING_TOGGLE_ACTION:
            return {
                ...state,
                boxShowLoading: true,
            };
        default:
          return state;
    };
};