import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';
import PieChart from '../chart/PieChart';
import './EtatsGlobaux.css';

const EtatsConsommations = (props) => {
    const { t } = useTranslation();
    const {data, showLoading} = props;

    const [statsDatas, setStatsDatas] = useState([]);
    const [dataSeries, setDataSeries] = useState([]);
    const [dataLabels, setDataLabels] = useState([]);
    const [graph, setGraph] = useState(false);

    const rowLabels = {
        totalPrestations: "Montant total des prestations",
        totalRejete: "Montant total rejeté",
        totalPaye: "Montant total à payer",
        nbrePrestataire: "Nombre total de prestataires",
        nbreVisite: "Nombre total de visites",
    }

    useEffect(() => {
        if(Object.keys(data).length > 0){
            setStatsDatas(Object.keys(data).map(key => {
                return {
                    statName: rowLabels[key],
                    totalMontant: parseFloat(data[key]["total"]["montant"]),
                    totalPourcent: parseFloat(data[key]["total"]["taux"]),
                    ambuMontant: parseFloat(data[key]["ambulatoire"]["montant"]),
                    ambuPourcent: parseFloat(data[key]["ambulatoire"]["taux"]),
                    hospiMontant: parseFloat(data[key]["hospitalier"]["montant"]),
                    hospiPourcent: parseFloat(data[key]["hospitalier"]["taux"]),

                }
            }));
            
            setDataSeries([
                parseFloat(data.totalRejete.ambulatoire.montant),
                parseFloat(data.totalRejete.hospitalier.montant),
                parseFloat(data.totalPaye.ambulatoire.montant), 
                parseFloat(data.totalPaye.hospitalier.montant)
            ]);
            setDataLabels([
                "Montant Rejeté en ambulatoire",
                "Montant Rejeté en hospitalier",
                "Montant Payé en ambulatoire",
                "Montant Payé en hospitalier",
            ]);
        }
    }, [data]);

    const headerGroup = (
        <ColumnGroup>
            <Row>
                <Column header="" rowSpan={2}/>
                <Column header="Total" colSpan={2} />
                <Column header="Ambulatoire" colSpan={2} />
                <Column header="Hospitalier" colSpan={2} />
            </Row>
            <Row>
                <Column header="Montant/Nbre" field="totalMontant" />
                <Column header="Taux en %" field="totalPourcen" />
                <Column header="Montant/Nbre" field="ambuMontant" />
                <Column header="Taux en %" field="ambuPourcen" />
                <Column header="Montant/Nbre" field="hospiMontant" />
                <Column header="Taux en %" field="hospiPourcen" />
            </Row>
        </ColumnGroup>
    );

    return (
        <>
            {Object.keys(data).length === 0 && <span>
                Aucune donnée à afficher, veuiller remplir les filtres afin d'en avoir.
            </span>
            }
            {Object.keys(data).length > 0 && <div className='results-section'>
                <div className='radio-section row justify-content-center my-4'>
                    <div className='form-group mb-0'>
                        <label className='radio-inline mr-5'>
                            <input type='radio' name='graph' value="hideGraph" checked={!graph} onChange={e => {setGraph(!e.target.checked)}}/> Données
                        </label>
                        <label className='radio-inline'>
                            <input type='radio' name='graph' value="showGraph" checked={graph} onChange={e => {setGraph(e.target.checked)}}/> Graphique
                        </label>
                    </div>
                </div>
                {!graph && <DataTable 
                    value={statsDatas} 
                    showGridlines 
                    headerColumnGroup={headerGroup} 
                    tableStyle={{ minWidth: '50rem' }}
                >
                    <Column field="statName"></Column>
                    <Column field="totalMontant"></Column>
                    <Column field="totalPourcent"></Column>
                    <Column field="ambuMontant"></Column>
                    <Column field="ambuPourcent"></Column>
                    <Column field="hospiMontant"></Column>
                    <Column field="hospiPourcent"></Column>
                </DataTable>}
                {graph && <PieChart series={dataSeries} pieWidth="380" labels={dataLabels} legendPosition="bottom" />}
            </div>}
        </>
    )
}
export default EtatsConsommations;