import React, { useState } from "react";
import { useDispatch } from 'react-redux';
import { suspendProviderAction } from '../../../../store/actions/ProviderActions';
import { Button, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";

export const MotifSuppressionModal = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { providerRef, manageSupModal, history } = props;
    const [motif, setMotif] = useState("");
    const [error, setError] = useState(null);

    

    const deleteProviderWithMotive = () => {
        if(motif.length === 0) {
            setError(t('common.motif-rejet-error'));
            return;
        }

        const data = {motif, providerId: providerRef.id};

        dispatch(addProviderSpecialities(data, history));
        manageSupModal(false, true);

    }

    return (
            <>
                    <Modal.Header>
                        <Modal.Title>Désactivation du prestataire {providerRef.providerName}</Modal.Title>
                        <Button
                            variant=""
                            className="close"
                            onClick={() => manageSupModal(false)}
                        >
                            <span>&times;</span>
                        </Button>
                    </Modal.Header>
                    <Modal.Body>
                        <h5>Veuillez fournir le motif de la désactivation du prestataire</h5>
                        
                        { error && <small className="text-danger">{{error}}</small> }

                        <div className='row justify-content-between align-items-center'>
                            <div className='col-12 form-group'>
                                <textarea
                                className='form-control'
                                rows='4'
                                id='comment'
                                value={motif}
                                onChange={(e) => setMotif(e.target.value)}
                                ></textarea>
                            </div>
                        </div>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant="danger light"
                            onClick={() => manageSupModal(false, false)}
                        >
                           {t('common.cancel')}
                        </Button>
                        <Button
                            variant=""
                            type="button"
                            className="btn btn-primary"
                            disabled={motif.trim().length === 0}
                            onClick={() => deleteProviderWithMotive()}
                        >
                           {t('common.save')}
                        </Button>
                    </Modal.Footer>
            </>
    )
};

export default MotifSuppressionModal;