import {
    deleteUserNotifications,
    deleteProfilNotifications,
    listGroupByCategory,
    listByCategory
} from '../../services/NotificationsService';

export const LIST_BY_CATEGORY_CONFIRMED_ACTION = '[listNotifsByCategory action] confirmed listNotifsByCategory';
export const LIST_BY_CATEGORY_FAILED_ACTION = '[listNotifsByCategory action] failed listNotifsByCategory';

export const LIST_CONFIRMED_ACTION = '[listNotifs action] confirmed listNotifs';
export const LIST_FAILED_ACTION = '[listNotifs action] failed listNotifs';


export const DEL_USER_NOTIFS_CONFIRMED_ACTION = '[delUserNotifs action] confirmed delUserNotifs';
export const DEL_USER_NOTIFS_FAILED_ACTION = '[delUserNotifs action] failed delUserNotifs';


export const DEL_PROFIL_NOTIFS_CONFIRMED_ACTION = '[delProfilNotifs action] confirmed delProfilNotifs';
export const DEL_PROFIL_NOTIFS_FAILED_ACTION = '[delProfilNotifs action] failed delProfilNotifs';

export const LOADING_TOGGLE_ACTION = '[Loading action] toggle loading';
export const BOX_LOADING_TOGGLE_ACTION = '[LoadingBox action] toggle LoadingBox';

var I18n = require('react-redux-i18n').I18n;

export function listNotificationsByCategoryAction(category) {
    return (dispatch) => {
        listByCategory(category)
        .then((response) => {
            let data = response.data;
            dispatch(confirmedListByCategoryAction(data));
        })
        .catch((error) => {
            errorToastr(I18n.t('notifications.error')+error.response?.data?.message);
            dispatch(listByCategoryFailedAction(errorMessage));
        });
    };
}

export function listNotificationsGroupByCategoryAction() {
    return (dispatch) => {
        listGroupByCategory()
        .then((response) => {
            let data = response.data;
            dispatch(confirmedListAction(data));
        })
        .catch((error) => {
            errorToastr(I18n.t('notifications.error')+error.response?.data?.message);
            dispatch(listFailedAction(errorMessage));
        });
    };
}

export function MarkUserNotificationsAsReadAction(userId) {
    return (dispatch) => {
        deleteUserNotifications(userId)
        .then((response) => {
            let data = response.data;
            dispatch(confirmedUserNotifDelAction(data));
        })
        .catch((error) => {
            errorToastr(I18n.t('notifications.error_mark_read')+error.response?.data?.message);
            dispatch(UserNotifDelFailedAction(errorMessage));
        });
    };
}

export function MarkProfilNotificationsAsReadAction(profilId) {
    return (dispatch) => {
        deleteProfilNotifications(profilId)
        .then((response) => {
            let data = response.data;
            dispatch(confirmedProfilNotifDelAction(data));
        })
        .catch((error) => {
            errorToastr(I18n.t('notifications.error_mark_read')+error.response?.data?.message);
            dispatch(ProfilNotifDelFailedAction(errorMessage));
        });
    };
}

export function confirmedListByCategoryAction(data) {
    return {
        type: LIST_BY_CATEGORY_CONFIRMED_ACTION,
        payload: data,
    };
}

export function listByCategoryFailedAction(data) {
    return {
        type: LIST_BY_CATEGORY_FAILED_ACTION,
        payload: data,
    };
}


export function confirmedListAction(data) {
    return {
        type: LIST_CONFIRMED_ACTION,
        payload: data,
    };
}

export function listFailedAction(data) {
    return {
        type: LIST_FAILED_ACTION,
        payload: data,
    };
}

export function confirmedUserNotifDelAction(data) {
    return {
        type: DEL_USER_NOTIFS_CONFIRMED_ACTION,
        payload: data,
    };
}

export function UserNotifDelFailedAction(data) {
    return {
        type: DEL_USER_NOTIFS_FAILED_ACTION,
        payload: data,
    };
}

export function confirmedProfilNotifDelAction(data) {
    return {
        type: DEL_PROFIL_NOTIFS_CONFIRMED_ACTION,
        payload: data,
    };
}

export function ProfilNotifDelFailedAction(data) {
    return {
        type: DEL_PROFIL_NOTIFS_FAILED_ACTION,
        payload: data,
    };
}

export function loadingToggleAction(status) {
    return {
        type: LOADING_TOGGLE_ACTION,
        payload: status,
    };
}

export function boxLoadingToggleAction(status) {
    return {
        type: BOX_LOADING_TOGGLE_ACTION,
        payload: status,
    };
}
