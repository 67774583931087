import React, { useState } from "react";
import { connect, useDispatch } from 'react-redux';
import { Button, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
export const CommentaireModal = (props) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { commentaire, manageCommentModal, history, examen, sentive } = props;
    const [error, setError] = useState(null);
    const [comment, setComment] = useState(commentaire ? commentaire : '');
    const [isSensitive, setIsSensitive] = useState(sentive);
    

    

    const addCommentRequest = () => {
        if(comment.trim().length === 0) {
            setError(t('common.commentError'));
            return;
        }

        const data = {
            examenDetailId: examen.id,
            resultDescription: comment,
            isSensitive
        };

        //dispatch(commentExamensAction(data));
        manageCommentModal(false, true);

    }

    return (
            <>
                    <Modal.Header>
                        <Modal.Title className="text-center" >
                                {t('accord.comment-title')}{' '}<br/>
                                <b className="text-primary" >{examen.acteName}</b>
                        </Modal.Title>
                        <Button
                            variant=""
                            className="close"
                            onClick={() => manageCommentModal(false)}
                        >
                            <span>&times;</span>
                        </Button>
                    </Modal.Header>
                    <Modal.Body>
                        <h5>{t('accord.leave-interpretation')}</h5>
                        
                        { error && <small className="text-danger">{{error}}</small> }

                        <form onSubmit={(e) => e.preventDefault()}>
                            <div className='d-flex justify-content-start'>
                                <div className='custom-control custom-checkbox mb-3 checkbox-primary'>
                                    <input
                                    type='checkbox'
                                    checked={isSensitive}
                                    onChange={(e) => setIsSensitive(e.target.checked)}
                                    className='custom-control-input'
                                    disabled
                                    id='customCheckBox2'
                                    />
                                    <label
                                    className='custom-control-label text-primary text-bold'
                                    htmlFor='customCheckBox2'
                                    >
                                    {t('accord.sensitive-results')}
                                    </label>
                                </div>
                            </div>

                            <div className='form-group'>
                                <textarea
                                value={comment}
                                className='form-control'
                                rows='6'
                                id='comment'
                                disabled
                                onChange={(e) => setComment(e.target.value)}
                                ></textarea>
                            </div>
                        </form>

                    </Modal.Body>
                    {/*<Modal.Footer>
                        <Button
                            variant="danger light"
                            onClick={() => manageCommentModal(false, false)}
                        >
                           {t('common.cancel')}
                        </Button>
                        <Button
                            variant=""
                            type="button"
                            className="btn btn-primary"
                            disabled={comment.trim().length === 0}
                            onClick={() => addCommentRequest()}
                        >
                           {t('client.submit')}
                        </Button>
                    </Modal.Footer>*/}
            </>
    )
};

const mapStateToProps = (state) => {
    return {
        drugList: state.consultation.drugs
    };
};

export default connect(mapStateToProps)(CommentaireModal);