import React, { useState, useEffect } from "react";
import { connect, useDispatch } from 'react-redux';
import { saveRejetAction, startLoader } from "../../../store/actions/FacturationAction";
import { Button, Modal } from "react-bootstrap";
import Select from 'react-select';
import { useTranslation } from "react-i18next";

export const MotifRejetModal = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { manageCommentModal, history, acte, exclusions, motifRejet, commentaireRejet } = props;
    const [error, setError] = useState(null);
    const [comment, setComment] = useState(commentaireRejet || '');
    const [motif, setMotif] = useState(motifRejet || '');
    const [amountAvr, setAmountAvr] = useState(parseFloat(acte.montantAssureur) + parseFloat(acte.montantRejete));
    const [amountApr, setAmountApr] = useState(parseFloat(acte.montantAssureur));
    const [amount, setAmount] = useState(parseFloat(acte.montantRejete));

    
    const addCommentRequest = () => {
        if(motif.trim().length === 0) {
            setError(t('common.motif-error'));
            return;
        }

        const data = {
            amount,
            motif,
            commentaire: comment
        };

        dispatch(startLoader());
        dispatch(saveRejetAction(acte.id, data));
        manageCommentModal(data);

    }

    return (
            <>
                    <Modal.Header>
                        <Modal.Title className="text-center" >
                                {t('facturation.rejection-motive')}{' '}<br/>
                                <b className="text-primary" >{acte.libelle}</b>
                        </Modal.Title>
                        <Button
                            variant=""
                            className="close"
                            onClick={() => manageCommentModal(false)}
                        >
                            <span>&times;</span>
                        </Button>
                    </Modal.Header>
                    <Modal.Body>
                        <h5>{t('common.reject-intro')}</h5>
                        
                        { error && <small className="text-danger">{{error}}</small> }

                        <form onSubmit={(e) => e.preventDefault()}>
                            <div className="row mb-2">
                                <div className="col-5">
                                    <b><small>{t('common.before-reject-amount')}:{' '}</small></b>
                                    <b>{amountAvr.toFixed(2)}</b>
                                </div>
                                <div className="col-5">
                                    <b><small>{t('common.after-reject-amount')}:{' '}</small></b>
                                    <b>{amountApr.toFixed(2)}</b>
                                </div>
                                <div className="col-2">
                                    <Button
                                        variant="info light"
                                        onClick={() => {
                                            setAmount(amountAvr);
                                            setAmountApr(0);
                                        }}
                                    >
                                       {t('common.reject-all')}
                                    </Button>
                                </div>
                            </div>
                            <div className='form-group'>
                                <label className='mb-1 '>
                                    <strong>{t('common.amount-rejected')}</strong>
                                </label>
                                <input
                                    type='number'
                                    name='amount'
                                    min='0'
                                    max={amountAvr}
                                    className='form-control'
                                    placeholder='0'
                                    value={amount}
                                    onChange={(e) => {
                                        setAmount(e.target.value);
                                        setAmountApr(amountAvr - e.target.value);
                                    }}
                                />
                            </div>
                            <div className='form-group'>
                                <label className='mb-1 '>
                                    <strong>{t('appointment.reason')}</strong>
                                </label>
                                <Select
                                    className="select-search"
                                    selected={exclusions.filter(exclu => {return exclu.nameFr === motif})[0]?.id}
                                    placeholder="Choisir un motif"
                                    required
                                    onChange={(val) => {setMotif(val.label)}}
                                    options={exclusions.map((opt)=>{return {label:opt.nameFr,value:opt.id}})}
                                    style={{
                                    lineHeight: '40px',
                                    color: '#7e7e7e',
                                    paddingLeft: ' 15px',
                                    }}
                                />
                            </div>
                            <div className='form-group'>
                                <label className='mb-1 '>
                                    <strong>{t('facturation.comment')}</strong>
                                </label>
                                <textarea
                                value={comment}
                                className='form-control'
                                rows='6'
                                id='comment'
                                onChange={(e) => setComment(e.target.value)}
                                ></textarea>
                            </div>
                        </form>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant="danger light"
                            onClick={() => manageCommentModal(false, false)}
                        >
                           {t('common.cancel')}
                        </Button>
                        <Button
                            variant=""
                            type="button"
                            className="btn btn-primary"
                            disabled={motif.trim().length === 0}
                            onClick={() => addCommentRequest()}
                        >
                           {t('client.submit')}
                        </Button>
                    </Modal.Footer>
            </>
    )
};

const mapStateToProps = (state) => {
    return {
        drugList: state.consultation.drugs
    };
};

export default connect(mapStateToProps)(MotifRejetModal);