import React, {useState} from "react";
import { useDispatch } from 'react-redux';
import { Link, withRouter } from "react-router-dom";

/* --- BEGIN AccordListItem
	@param(Object): props
    description: Element de la liste des accords préalables
*/
const AccordListItem = (props) => {
    const { history, accordPrea } = props;

    const dispatch = useDispatch();

    const chackbox = document.querySelectorAll(".patient_checkbox input");
    const motherChackBox = document.querySelector(".patient_strg input");
    // console.log(document.querySelectorAll(".all_spending_strg input")[0].checked);

    /* --- BEGIN chackboxFun
		   @param(string): type
		   description : Action sur les checkbox de la liste
    */
    const chackboxFun = (type) => {
        for (let i = 0; i < chackbox.length; i++) {
            const element = chackbox[i];
            if (type === "all") {
                if (motherChackBox.checked) {
                element.checked = true;
                } else {
                element.checked = false;
                }
            } else {
                if (!element.checked) {
                motherChackBox.checked = false;
                break;
                } else {
                motherChackBox.checked = true;
                }
            }
        }
    };
    /* --- END chackboxFun */
    

    /* --- BEGIN viewProvider
		   @param(): aucun
		   description : Fonction de navigation vers le détail d'un accord préalable
    */
    const viewProvider = () => {
        history.push('/demande-accord/'+accordPrea.id+'/'+accordPrea.dossierConsultation.id);
    }
    /* --- END viewProvider */


    return (
        <tr role="row" className="odd">
            <td className="patient_checkbox">
                <div className="checkbox text-right align-self-center">
                    <div className="custom-control custom-checkbox ">
                    <input
                        type="checkbox"
                        onClick={() => chackboxFun()}
                        className="custom-control-input"
                        id="customCheckBox1"
                        required
                    />
                    <label
                        className="custom-control-label"
                        htmlFor="customCheckBox1"
                    />
                    </div>
                </div>
            </td>
            <td>{accordPrea.acteName}</td>
            <td>{accordPrea.dossierConsultation.consultationBon.beneficiaryName}</td>
            <td>{accordPrea.dossierConsultation.consultationBon.doctorName}</td>
            <td>
                <span className="text-nowrap">
                    <span className={accordPrea.status === "Pending" ? "text-warning" : (accordPrea.status === "ACCEPT" ? "text-success" : "text-danger")}>
                        {accordPrea.status === "Pending" ? "En attente" : (accordPrea.status === "ACCEPT" ? "Validé" : "Rejetté")}
                    </span>
                </span>
            </td>
            <td className="d-flex">
                <button className="btn btn-danger shadow btn-xs sharp mr-2"
                    onClick={viewProvider}
                >
                    <i className="fa fa-eye"></i>
                </button>
            </td>
        </tr>
    );
};
/* --- END MotifRejetModal */

export default withRouter(AccordListItem);