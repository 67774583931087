
const initialData = {
	patient : {},
    authStepOne: false,
    authStepTwo: false,
    codeAttempts: 0,
    errorMessage: '',
    successMessage: '',
    showLoading: false,
}

export function PatientReducer(state = initialData, action) {
    if (action.type === "IDENTITY_CONFIRMED_ACTION") {
        return {
            ...state,
            patient: action.payload,
            errorMessage: '',
            successMessage: 'Identification Successfully Completed',
            showLoading: false,
        };
    }
    if (action.type === "IDENTIFY_FAILED_ACTION") {
        return {
            ...state,
            patient: action.payload,
            errorMessage: 'Patient non identifié',
            successMessage: '',
            showLoading: false,
        };
    }if (action.type === "IDENTITY_ASSU_CONFIRMED_ACTION") {
        return {
            ...state,
            authStepOne: true,
            errorMessage: '',
            successMessage: 'Identification Assuré Successfully Completed',
            showLoading: false,
        };
    }
    if (action.type === "IDENTIFY_ASSU_FAILED_ACTION") {
        return {
            ...state,
            errorMessage: 'Assuré non identifié',
            successMessage: '',
            showLoading: false,
        };
    }
    if (action.type === "IDENTITY_CODE_ASSU_CONFIRMED_ACTION") {
        return {
            ...state,
            authStepTwo: true,
            patient: action.payload,
            errorMessage: '',
            successMessage: 'Identification Code Assuré Successfully Completed',
            showLoading: false,
        };
    }
    if (action.type === "IDENTIFY_CODE_ASSU_FAILED_ACTION") {
        return {
            ...state,
            codeAttempts: state.codeAttempts + 1,
            errorMessage: 'Code Assuré non identifié',
            successMessage: '',
            showLoading: false,
        };
    }
    if (action.type === "RE_INIT_STATE_ACTION") {
        return {
            ...state,
            patient : {},
            authStepOne: false,
            authStepTwo: false,
            errorMessage: '',
            successMessage: '',
            showLoading: false,
        };
    }
    if (action.type === "RESEND_SMS_TRIGGER_ACTION") {
        return {
            ...state,
        };
    }
    if (action.type === "LOADING_TOGGLE_ACTION") {
        let data = {
            ...state,
            showLoading: action.payload,
        };
        console.log(data)
        return data;
    }
    return state;
}

    
