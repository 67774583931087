import React, { useState, useEffect, useRef } from "react";
import { connect, useDispatch } from 'react-redux';
import { listProvidersAction, startLoader } from "../../../store/actions/ProviderActions";
import { getPrestationsAction, createPrestationsAction, closePrestationsAction, deletePrestationsAction, startLoader as startFactureLoader } from "../../../store/actions/FacturationAction";
import { currentUser } from "../../../store/selectors/CurrentUserSelector";
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Tag } from 'primereact/tag';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { TriStateCheckbox } from 'primereact/tristatecheckbox';
import { DataTableBase } from "../DataTables/DataTableBase";
import { withRouter, Link } from 'react-router-dom';
import { Dialog } from 'primereact/dialog';
import { useTranslation } from "react-i18next";
import Select from 'react-select';
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { Button as BButton } from "react-bootstrap";
import DateFnsUtils from "@date-io/date-fns";
import { STATUSES } from "../../globals";
import { close } from "graceful-fs";

export const PretstationList = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [visible, setVisible] = useState(false);
    const [year, setYear] = useState(new Date());
    const [month, setMonth] = useState(new Date());
    const [providerId, setProviderId] = useState("");
    const [providerName, setProviderName] = useState("");
    const [filters, setFilters] = useState(null);
    const [globalFilterValue, setGlobalFilterValue] = useState('');

    const initFilters = () => {
        setFilters({
            global: { value: null, matchMode: FilterMatchMode.CONTAINS },
            provider: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            periode: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            dateOuverture: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
            submitted: { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] }
        });
        setGlobalFilterValue("");
    };

    const renderHeader = () => {
        return (
            <div className="flex justify-content-between">
                <Button type="button" icon="pi pi-filter-slash"  label={t('common.reinit')} outlined onClick={clearFilter} />
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText value={globalFilterValue} onChange={onGlobalFilterChange}  placeholder={t('common.search')} />
                </span>
            </div>
        );
    };

    const clearFilter = () => {
        initFilters();
    };

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };

        _filters['global'].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
    };

    const setColumns = () => {
        return [
            {name: 'provider', headerName: t('dashboard.provider'), filterPlaceholder: t('facturation.bill-number-filter'), style: { minWidth: '12rem', fontSize: '14px' }, filter: true, bodyTemplate: null},
            {name: 'periode', headerName: t('common.period'), filterPlaceholder: t('facturation.bill-number-filter'), style: { minWidth: '12rem', fontSize: '14px' }, filter: true, bodyTemplate: null},
            {name: 'dateOuverture', filterField: 'dateOuverture', dataType: 'date', headerName: t('facturation.create-date'), filterPlaceholder: t('facturation.create-date-filter'), style: { minWidth: '6rem', fontSize: '14px' }, filter: true, bodyTemplate: dateFacturationBodyTemplate, filterElement: dateFilterTemplate},
            {name: 'submitted', headerName: t('common.status'),  filterPlaceholder: t('common.status-filter-text'), style: { minWidth: '12rem', fontSize: '14px' }, filter: true, bodyTemplate: statusBodyTemplate, filterElement: statusFilterTemplate},
            {name: 'actions', headerName: 'Actions', filterPlaceholder: '', style: { minWidth: '6rem', fontSize: '14px'}, filter: false, bodyTemplate: getActionsTemplate},
        ]
    };

    const getActionsTemplate = (rowData) => {
        return <div style={{ width: '100%', display: 'flex', justifyContent: 'center'}}>
                {hasMenu('MENPRESTATION') && canDo('MENPRESTATION', 'VIEWPRESTATION') && <button className="btn btn-info shadow btn-xs sharp mr-2"
                    onClick={(e) => viewFacture(rowData.id)}
                >
                    <i className="fa fa-eye"></i>
                </button>}
                
                {hasMenu('MENPRESTATION') && canDo('MENPRESTATION', 'CLOSEPRESTATION') && rowData.submitted === STATUSES.pending && <button className="btn btn-primary shadow btn-xs sharp mr-2"
                    onClick={e => {
                        e.preventDefault();
                        dispatch(startFactureLoader());
                        dispatch(closePrestationsAction(rowData.id));
                    }}
                >
                    <i className="fa fa-close"></i>
                </button>}

                {hasMenu('MENPRESTATION') && canDo('MENPRESTATION', 'DELETEPRESTATION') && rowData.submitted !== STATUSES.cancel && <button className="btn btn-danger shadow btn-xs sharp mr-2"
                    onClick={(e) => {
                        e.preventDefault();
                        dispatch(startFactureLoader());
                        dispatch(deletePrestationsAction(rowData.id));
                    }}
                >
                    <i className="fa fa-trash"></i>
                </button>}
        </div>
    }

    const viewFacture = (id) => {
        props.history.push('/prestation/'+id);
    }

    const statusBordFilterTemplate = (options) => {
        return (
            <div className="flex align-items-center gap-2">
                <label htmlFor="isClose-filter" className="font-bold">
                    {t('facturation.closed')}
                </label>
                <TriStateCheckbox inputId="isClose-filter" value={options.value} onChange={(e) => options.filterCallback(e.value)} />
            </div>
        );
    };

    const dateFilterTemplate = (options) => {
        return <Calendar value={options.value} onChange={(e) => options.filterCallback(e.value, options.index)} dateFormat="dd/mm/yy" placeholder="dd/mm/yyyy" mask="99/99/9999" />;
    };

    const dateFacturationBodyTemplate = (rowData) => {
        return formatDate(rowData.dateOuverture);
    };

    const statusBodyTemplate = (rowData) => {
        return <Tag value={rowData.submitted ? t('statuses.'+rowData.submitted) : rowData.submitted} severity={getSeverity(rowData.submitted)} />;
    };

    const statusFilterTemplate = (options) => {
        return <Dropdown value={options.value} options={props.prestations.map(prestation => {
            return prestation.submitted === STATUSES.nonPaye ? STATUSES.nonPaye : prestation.submitted
        }).reduce(function (acc, curr) {
            if (!acc.includes(curr))
                acc.push(curr);
            return acc;
        }, [])} onChange={(e) => options.filterCallback(e.value, options.index)} itemTemplate={statusItemTemplate}  placeholder={t('common.choose')} className="p-column-filter" showClear />;
    };

    const statusItemTemplate = (option) => {
        return <Tag value={option} severity={getSeverity(option)} />;
    };


    const getSeverity = (status) => {
        switch (status) {
            case null:
                return 'danger';
            
            case 'non payé':
                return 'danger';

            case 'payé':
                return 'success';

            case true:
                return 'success';

            case false:
                return 'warning';

            case t('facturation.closed'):
                return 'success';

            case 'ouvert':
                return 'warning';

            default:
                return 'warning';
        }
    };

    const formatDate = (value) => {
        return value.toLocaleDateString('fr-FR', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric'
        });
    };

    const footerContent = (
        <div>
            <Button label="Annuler" icon="pi pi-times" onClick={() => setVisible(false)} className="p-button-text" />
            <Button label="Valider" icon="pi pi-check" onClick={() => {
                /*let prestations = localStorage.getItem('prestations') ? JSON.parse(localStorage.getItem('prestations')) : [];
                prestations.push({
                    providerId,
                    providerName,
                    month: month.getMonth() + 1,
                    year: year.getFullYear(),
                    status: false,
                    createdAt: new Date()
                });
                localStorage.setItem('prestations', JSON.stringify(prestations));*/
                let data = {
                    providerId,
                    providerName,
                    mois: t('mois.'+(month.getMonth() + 1)),
                    codeConsultation: "",
                    annee: year.getFullYear()
                };
                dispatch(startFactureLoader());
                dispatch(createPrestationsAction(data, props.history));
                //props.history.push('prestation-form');
                setVisible(false);
            }} autoFocus />
        </div>
    );

    const canDo = (menuCode, actionCode) => {
        let theMenu = props.currentUser.menus?.find(menu => menu.code === menuCode);
        if(!theMenu){
            return false;
        }else{
            return theMenu.actionMenus?.filter(action => action.code === actionCode).length > 0;
        }
    }
    
    const hasMenu = (menuCode) => {
        return props.currentUser.menus?.filter(menu => menu.code === menuCode).length > 0;
    }

    
    useEffect(() => {
        if(hasMenu('MENPRESTATION') && canDo('MENPRESTATION', 'LISTPRESTATION')){
            dispatch(startFactureLoader());
            dispatch(getPrestationsAction());
            dispatch(listProvidersAction());
        }else{
            props.history.push('/access-denied');
        }
    }, []);

    return (
        <>
            
            <div className="form-head d-flex mb-3 mb-md-4 align-items-start">
                <div className="mr-auto d-none d-lg-block">
                    {hasMenu('MENPRESTATION') && canDo('MENPRESTATION', 'CREATEPRESTATION') && <BButton
                    onClick={() => setVisible(true)}
                    className="btn btn-primary btn-rounded"
                    >
                    + {t('common.add-prestation')}
                    </BButton>}
                    <Dialog header="Nouvelle Prestation" position={'top'} visible={visible} style={{ width: '50vw'}} onHide={() => setVisible(false)} footer={footerContent}>
                                    
                        <div className='row justify-content-between align-items-center'>
                            <div className='col-12 form-group'>
                                <label className='mb-1 '>
                                    <strong>{t('dashboard.provider')}</strong>
                                    <span className='text-danger ml-1'>*</span>
                                </label>
                                <Select
                                    className="select-search"
                                    value={props.providers.filter(elt => elt.id === providerId).map((opt)=>{return {label:opt.providerName,value:opt.id}})}
                                    placeholder={t('dashboard.choose-provider')}
                                    required
                                    onChange={(val) => {
                                        setProviderId(val.value);
                                        setProviderName(val.label);
                                    }}
                                    options={props.providers.map((opt)=>{return {label:opt.providerName,value:opt.id}})}
                                    style={{
                                    lineHeight: '40px',
                                    color: '#7e7e7e',
                                    paddingLeft: ' 15px',
                                    }}
                                />
                            </div>
                            <div className='col-6 form-group'>
                                <label className='mb-1 '>
                                    <strong>{t('common.month')}</strong>
                                    <span className='text-danger ml-1'>*</span>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <DatePicker
                                            autoOk
                                            label=""
                                            clearable
                                            format="MMMM"
                                            value={month}
                                            onChange={setMonth}
                                            views={['month']}
                                        />
                                    </MuiPickersUtilsProvider>
                                </label>
                            </div>
                            <div className='col-6 form-group'>
                                <label className='mb-1 '>
                                    <strong>{t('common.annee')}</strong>
                                    <span className='text-danger ml-1'>*</span>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <DatePicker
                                            autoOk
                                            label=""
                                            clearable
                                            format="yyyy"
                                            value={year}
                                            onChange={setYear}
                                            views={['year']}
                                        />
                                    </MuiPickersUtilsProvider>
                                </label>
                            </div>
                        </div>
                    </Dialog>
                </div>
            </div>

            <DataTableBase 
                data={props.prestations.map((elt) => {
                        elt.provider = elt.providerName;
                        elt.periode = elt.mois+' '+elt.annee;
                        elt.dateOuverture = new Date(elt.createdAt);
                        elt.submitted = elt.statut;
                        return elt;
                })}
                emptyMessage={t('common.no-prestation')} 
                filters={filters}
                globalFiltersFields={['provider', 'periode', 'dateOuverture', 'submitted']}
                header={renderHeader()}
                columns={setColumns()}
                rows={10} 
                loading={props.showLoading}
                size={'small'}
                showExport={false}
            />
            
        </>
    )
};

const mapStateToProps = (state) => {
    return {
        providers: state.provider.providers,
        prestations: state.facturation.prestations,
        showLoading: state.facturation.showLoading,
        currentUser: currentUser(state)
    };
};

export default withRouter(connect(mapStateToProps)(PretstationList));