import axios from 'axios';

const headers = { 'Authorization': 'Bearer '+JSON.parse(localStorage.getItem('userDetails'))?.token, 'Accept-Language': localStorage.getItem('currentLanguage')};

const ConsultServiceRequest = axios.create({
    baseURL: process.env.REACT_APP_BASEURL_CONSULTATION_BACKEND,
    headers: headers
});


//Dashboard
export const getdataRapport = (data) => ConsultServiceRequest.get('/prestations/ambulatoire/%20', {params: data});
export const getPrestationAmbu = (data) => ConsultServiceRequest.get('/prestation/ambulatoire', {params: data});
export const getPrestationHospi = () => ConsultServiceRequest.get('/prestation/hospitalisation');
export const getPatologieHospi = () => ConsultServiceRequest.get('/patologie/hospitalisation');
export const getPatologieAmbu = (data) => ConsultServiceRequest.get('/patologie/ambulatoire', {params: data});
export const getTopPharmacie = (data) => ConsultServiceRequest.get('/most/pharmacie', {params: data});
export const getTopDentiste = (data) => ConsultServiceRequest.get('/most/dentiste', {params: data});
export const getTopOptique = (data) => ConsultServiceRequest.get('/most/optique', {params: data});
export const getRapportFinance = (data) => ConsultServiceRequest.get('/rapport-finance', {params: data});
export const getRapportDepenseFamille = (data) => ConsultServiceRequest.get('/facture/prestation?numeroPolice='+data.numeroPolice+'&numeroAssure='+data.numeroAssure+'&dateDebut='+data.dateDebut+'&dateFin='+data.dateFin);


//Client
export const globalClientStats = (data, clientIds) => ConsultServiceRequest.post('/data/consommations/souscripteur', clientIds, {params: data});
export const detailedClientStats = (data) => ConsultServiceRequest.get('/consommations/souscripteur', {params: data});
export const visitsClientStats = (data) => ConsultServiceRequest.get('/visites/client', {params: data});
export const consommationsClientStats = (data) => ConsultServiceRequest.get('', {params: data});
export const topConsommationsClientStats = (data) => ConsultServiceRequest.get('', {params: data});