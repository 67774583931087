import React, { useState, useEffect, useRef } from "react";
import { connect, useDispatch } from 'react-redux';
import { getFacturesAction, startLoader } from "../../../store/actions/FacturationAction";
import { currentUser } from "../../../store/selectors/CurrentUserSelector";
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Tag } from 'primereact/tag';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { TriStateCheckbox } from 'primereact/tristatecheckbox';
import { DataTableBase } from "../DataTables/DataTableBase";
import{withRouter} from 'react-router-dom'
import { STATUSES } from '../../globals';
import { useTranslation } from "react-i18next";

export const FacturationList = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [filters, setFilters] = useState(null);
    const [globalFilterValue, setGlobalFilterValue] = useState('');

    const initFilters = () => {
        setFilters({
            global: { value: null, matchMode: FilterMatchMode.CONTAINS },
            name: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            montantTotal: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
            dateFacturation: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
            statusPaiement: { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
            isClose: { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
            datePaiement: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
        });
        setGlobalFilterValue("");
    };

    const renderHeader = () => {
        return (
            <div className="flex justify-content-between">
                <Button type="button" icon="pi pi-filter-slash"  label={t('common.reinit')} outlined onClick={clearFilter} />
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText value={globalFilterValue} onChange={onGlobalFilterChange}  placeholder={t('common.search')} />
                </span>
            </div>
        );
    };

    const clearFilter = () => {
        initFilters();
    };

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };

        _filters['global'].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
    };

    const setColumns = () => {
        return [
            {name: 'name', headerName: t('common.bill-number'), filterPlaceholder: t('facturation.bill-number-filter'), style: { minWidth: '12rem', fontSize: '14px' }, filter: true, bodyTemplate: null},
            {name: 'montantTotal', dataType: 'numeric', headerName:  t('common.amount'), filterPlaceholder: t('facturation.filter-by-amount'), style: { minWidth: '6rem', fontSize: '14px' }, filter: true, bodyTemplate: null},
            {name: 'prestataireName', headerName: t('dashboard.provider'), filterPlaceholder: t('facturation.provider-filter'), style: { minWidth: '12rem', fontSize: '14px' }, filter: true, bodyTemplate: null},
            {name: 'dateFacturation', filterField: 'dateFacturation', dataType: 'date', headerName: t('facturation.create-date'), filterPlaceholder: t('facturation.create-date-filter'), style: { minWidth: '6rem', fontSize: '14px' }, filter: true, bodyTemplate: dateFacturationBodyTemplate, filterElement: dateFilterTemplate},
            {name: 'statusPaiement', headerName: t('dashboard.payment-status'), filterPlaceholder: t('facturation.payment-status-filter'), style: { minWidth: '12rem', fontSize: '14px' }, filter: true, bodyTemplate: statusBodyTemplate, filterElement: statusFilterTemplate},
            {name: 'isClose', headerName: t('dashboard.invoice-status'), dataType: 'boolean', filterPlaceholder: t('facturation.bord-status-filter'), style: { minWidth: '6rem', fontSize: '14px' }, filter: true, bodyTemplate: statusBordBodyTemplate, filterElement: statusBordFilterTemplate},
            {name: 'datePaiement', filterField: 'dateFacturation', dataType: 'date', headerName: t('facturation.payment-date'), filterPlaceholder: t('facturation.payement-date-filter'), style: { minWidth: '6rem', fontSize: '14px' }, filter: true, bodyTemplate: datePaiementBodyTemplate, filterElement: dateFilterTemplate},
            {name: 'actions', headerName: 'Actions', filterPlaceholder: '', style: { minWidth: '6rem', fontSize: '14px'}, filter: false, bodyTemplate: getActionsTemplate},
        ]
    };

    const getActionsTemplate = (rowData) => {
        return <div style={{ width: '100%', display: 'flex', justifyContent: 'center'}}>
                {hasMenu('MENFACTUREADMIN') && canDo('MENFACTUREADMIN', 'VIEWADMINFACTURE') && <button className="btn btn-danger shadow btn-xs sharp mr-2"
                    onClick={(e) => viewFacture(rowData.id)}
                >
                    <i className="fa fa-eye"></i>
                </button>}
        </div>
    }

    const viewFacture = (id) => {
        props.history.push('/facturation-details/'+id);
    }

    const statusFilterTemplate = (options) => {
        return <Dropdown value={options.value} options={props.factures.map(facture => {
            return facture.statusPaiement === STATUSES.nonPaye ? STATUSES.nonPaye : facture.statusPaiement
        }).reduce(function (acc, curr) {
            if (!acc.includes(curr))
                acc.push(curr);
            return acc;
        }, [])} onChange={(e) => options.filterCallback(e.value, options.index)} itemTemplate={statusItemTemplate}  placeholder={t('common.choose')} className="p-column-filter" showClear />;
    };

    const statusBordFilterTemplate = (options) => {
        return (
            <div className="flex align-items-center gap-2">
                <label htmlFor="isClose-filter" className="font-bold">
                    {t('facturation.closed')}
                </label>
                <TriStateCheckbox inputId="isClose-filter" value={options.value} onChange={(e) => options.filterCallback(e.value)} />
            </div>
        );
    };

    const dateFilterTemplate = (options) => {
        return <Calendar value={options.value} onChange={(e) => options.filterCallback(e.value, options.index)} dateFormat="dd/mm/yy" placeholder="dd/mm/yyyy" mask="99/99/9999" />;
    };

    const datePaiementBodyTemplate = (rowData) => {
        return formatDate(rowData.datePaiement);
    };

    const dateFacturationBodyTemplate = (rowData) => {
        return formatDate(rowData.dateFacturation);
    };

    const statusBodyTemplate = (rowData) => {
        return <Tag value={rowData.statusPaiement ? t('statuses.'+rowData.statusPaiement) : rowData.statusPaiement} severity={getSeverity(rowData.statusPaiement)} />;
    };

    const statusBordBodyTemplate = (rowData) => {
        return <Tag value={rowData.isClose ? t('facturation.closed') :  t('facturation.oppened')} severity={getSeverity(rowData.isClose)} />;
    };

    const statusItemTemplate = (option) => {
        return <Tag value={option} severity={getSeverity(option)} />;
    };

    const getSeverity = (status) => {
        switch (status) {
            case null:
                return 'danger';
            
            case 'non payé':
                return 'danger';

            case 'payé':
                return 'success';

            case true:
                return 'success';

            case false:
                return 'warning';

            case t('facturation.closed'):
                return 'success';

            case 'ouvert':
                return 'warning';

            default:
                return 'warning';
        }
    };

    const formatDate = (value) => {
        return value.toLocaleDateString('fr-FR', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric'
        });
    };

    const canDo = (menuCode, actionCode) => {
        let theMenu = props.currentUser.menus?.find(menu => menu.code === menuCode);
        if(!theMenu){
            return false;
        }else{
            return theMenu.actionMenus?.filter(action => action.code === actionCode).length > 0;
        }
    }
    
    const hasMenu = (menuCode) => {
        return props.currentUser.menus?.filter(menu => menu.code === menuCode).length > 0;
    }

    
    useEffect(() => {
        if(hasMenu('MENFACTUREADMIN') && canDo('MENFACTUREADMIN', 'LISTADMINFACTURE')){
            console.log("About to call all the consultations");
            dispatch(startLoader());
            dispatch(getFacturesAction(props.currentUser.providerId));
        }else{
            props.history.push('/access-denied');
        }
    }, []);

    return (
        <>
            <DataTableBase 
                data={props.factures.map(facture => {
                    facture.dateFacturation = new Date(facture.dateFacturation);
                    facture.datePaiement = new Date(facture.datePaiement);
                    return facture;
                })}
                 emptyMessage={t('facturation.no-bill')} 
                filters={filters}
                globalFiltersFields={['name', 'montantTotal', 'prestataireName', 'dateFacturation', 'statusPaiement', 'datePaiement']}
                header={renderHeader()}
                columns={setColumns()}
                rows={10} 
                loading={props.showLoading}
                size={'small'}
                exportFilename={"decomptes"}
                exportColumns={[
                    {  title: t('common.number'), dataKey: 'name' },
                    { title: t('facturation.amount'), dataKey: 'montantTotal' },
                    {  title: t('dashboard.provider'), dataKey: 'prestataireName' },
                    { title: t('facturation.invoice-date'), dataKey: 'dateFacturation' },
                    { title: t('facturation.payment-status'), dataKey: 'statusPaiement' },
                    { title: t('facturation.payment-date'), dataKey: 'datePaiement' },
                ]}
                showExport={true}
            />
            
        </>
    )
};

const mapStateToProps = (state) => {
    return {
        factures: state.facturation.factures,
        showLoading: state.facturation.showLoading,
        currentUser: currentUser(state)
    };
};

export default withRouter(connect(mapStateToProps)(FacturationList));