import axios from 'axios';

const headers = { 'Authorization': 'Bearer '+JSON.parse(localStorage.getItem('userDetails'))?.token, 'Accept-Language': localStorage.getItem('currentLanguage')};

const consultationServiceRequest = axios.create({
  baseURL: process.env.REACT_APP_BASEURL_CONSULTATION_BACKEND,
  headers: headers
});

export const getBonByCode = (code) => consultationServiceRequest.get('/Pharmacie/bon/'+code);
export const delieverMedicaments = (data) => consultationServiceRequest.post('/Pharmacie/make/delivered', data);
export const requestSubstituteMedicament = (data) => consultationServiceRequest.post('/Pharmacie/subtitution/request/create', data);
export const substituteMedicament = (id) => consultationServiceRequest.put('/Pharmacie/subtitution/request/approuved/'+id);
export const rejectSubstituteMedicament = (id) => consultationServiceRequest.put('/Pharmacie/subtitution/request/declined/'+id);
export const getSubsRequests = () => consultationServiceRequest.get('/Pharmacie/subtitution/request');
