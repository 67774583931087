import React, {useState,useEffect } from 'react'
import { connect, useDispatch } from 'react-redux';
import { Modal, Button as BButton, Spinner } from 'react-bootstrap';
import BasicDatatable from './DataTable';
import { Link } from "react-router-dom";
import {createUserAction, updateUserAction, deleteParamAction, getUsersAction, 
resetSaveDoneAction, resetUserPasswordActionsAction, resendUserParamsActionsAction, startLoader} from '../../../store/actions/UserAction';
import { listProvidersAction } from '../../../store/actions/ProviderActions';
import { getSouscripteursAction, getContractPerClientAction } from '../../../store/actions/ContratActions';
import Select from 'react-select';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import './User.css';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Tag } from 'primereact/tag';
import { Calendar } from 'primereact/calendar';
import { DataTableBase } from '../DataTables/DataTableBase';
import { TabView, TabPanel } from 'primereact/tabview';
import { Tooltip } from 'primereact/tooltip';
import { currentUser } from "../../../store/selectors/CurrentUserSelector";
import { useTranslation } from "react-i18next";

const User = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    let errorsObj = { name: '', username: '' };
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [username, setUsername] = useState('');
    const [phonenumber, setPhonenumber] = useState('');
    const [userType, setUserType] = useState('');
    const [providerId, setProviderId] = useState('');
    const [profils, setProfils] = useState([]);
    const [client, setClient] = useState('');
    const [clientName, setClientName] = useState('')
    const [policies, setPolicies] = useState([]);
    const [errors, setErrors] = useState(errorsObj);
    const [checked, setChecked] = useState([]);
    const [id, setId] = useState('');
    const [updateMode, setUpdateMode] = useState(false);
    //Modal box
    const [addCard, setAddCard] = useState(false);
    const [addProfil, setAddProfil] = useState(false);
    const [adminFilters, setAdminFilters] = useState(null);
    const [globalAdminFilterValue, setGlobalAdminFilterValue] = useState('');
    const [providerFilters, setProviderFilters] = useState(null);
    const [globalProviderFilterValue, setGlobalProviderFilterValue] = useState('');
    const [rhFilters, setRhFilters] = useState(null);
    const [globalRhFilterValue, setGlobalRhFilterValue] = useState('');
    const [clientFilters, setClientFilters] = useState(null);
    const [globalClientFilterValue, setGlobalClientFilterValue] = useState('');
    

    const initFilters = () => {
        setAdminFilters({
            global: { value: null, matchMode: FilterMatchMode.CONTAINS },
            username: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            email: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            phone: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            'userProfil.profil.nameFr': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            createdAt: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
        });
        setGlobalAdminFilterValue("");

        setProviderFilters({
            global: { value: null, matchMode: FilterMatchMode.CONTAINS },
            username: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            email: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            phone: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            'userProfil.profil.nameFr': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            'provider.providerName': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            createdAt: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
        });
        setGlobalProviderFilterValue("");

        setRhFilters({
            global: { value: null, matchMode: FilterMatchMode.CONTAINS },
            username: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            email: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            phone: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            'userProfil.profil.nameFr': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            'rh.clientName': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            'rh.policies': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            createdAt: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
        });
        setGlobalRhFilterValue("");

        setClientFilters({
            global: { value: null, matchMode: FilterMatchMode.CONTAINS },
            username: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            email: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            phone: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            createdAt: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
        });
        setGlobalClientFilterValue("");
    };

    const renderAdminHeader = () => {
        return (
            <div className="flex justify-content-between">
                <Button type="button" icon="pi pi-filter-slash"  label={t('common.reinit')} outlined onClick={clearFilter} />
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText value={globalAdminFilterValue} onChange={(e) => onGlobalFilterChange(e, 'admin')}  placeholder={t('common.search')} />
                </span>
            </div>
        );
    };

    const renderProviderHeader = () => {
        return (
            <div className="flex justify-content-between">
                <Button type="button" icon="pi pi-filter-slash"  label={t('common.reinit')} outlined onClick={clearFilter} />
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText value={globalProviderFilterValue} onChange={(e) => onGlobalFilterChange(e, 'provider')}  placeholder={t('common.search')} />
                </span>
            </div>
        );
    };

    const renderRhHeader = () => {
        return (
            <div className="flex justify-content-between">
                <Button type="button" icon="pi pi-filter-slash"  label={t('common.reinit')} outlined onClick={clearFilter} />
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText value={globalRhFilterValue} onChange={(e) => onGlobalFilterChange(e, 'rh')}  placeholder={t('common.search')} />
                </span>
            </div>
        );
    };

    const renderClientHeader = () => {
        return (
            <div className="flex justify-content-between">
                <Button type="button" icon="pi pi-filter-slash"  label={t('common.reinit')} outlined onClick={clearFilter} />
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText value={globalClientFilterValue} onChange={(e) => onGlobalFilterChange(e, 'client')}  placeholder={t('common.search')} />
                </span>
            </div>
        );
    };

    const clearFilter = () => {
        initFilters();
    };

    const onGlobalFilterChange = (e, type) => {
        const value = e.target.value;
        if(type === 'admin') {
            let _filters = { ...adminFilters };
    
            _filters['global'].value = value;
    
            setAdminFilters(_filters);
            setGlobalAdminFilterValue(value);
        }else if(type === 'provider') {
            let _filters = { ...providerFilters };
    
            _filters['global'].value = value;
    
            setProviderFilters(_filters);
            setGlobalProviderFilterValue(value);
        }else if(type === 'rh') {
            let _filters = { ...rhFilters };
    
            _filters['global'].value = value;
    
            setRhFilters(_filters);
            setGlobalRhFilterValue(value);
        }else{
            let _filters = { ...clientFilters };
    
            _filters['global'].value = value;
    
            setClientFilters(_filters);
            setGlobalClientFilterValue(value);
        }
    };

    const setColumns = (type) => {
        let arr=null;
        if(type === 'admin') {
            arr = [
                {name: 'username', headerName: t('common.name'), filterPlaceholder: t('common.filter-name'), style: { minWidth: '12rem' }, filter: true, bodyTemplate: null},
                {name: 'email', headerName: t('client.email'), filterPlaceholder: t('common.email-filter'), style: { minWidth: '12rem' }, filter: true, bodyTemplate: null},
                {name: 'phone', headerName: t('common.phone'), filterPlaceholder: t('common.phone-filter'), style: { minWidth: '12rem' }, filter: true, bodyTemplate: null},
                {name: 'userProfil.profil.nameFr', headerName: "Profils", filterPlaceholder: 'Filtrer par profils', style: { minWidth: '12rem' }, filter: true, bodyTemplate: profilsBodyTemplate},
                {name: 'createdAt', filterField: 'createdAt', dataType: 'date', headerName: 'Créé le', filterPlaceholder: t('facturation.create-date-filter'), style: { minWidth: '6rem' }, filter: true, bodyTemplate: datePaiementBodyTemplate, filterElement: dateFilterTemplate},
                {name: 'actions', headerName: 'Actions', filterPlaceholder: '', style: { minWidth: '6rem'}, filter: false, bodyTemplate: getActionsTemplate},
            ]
        }else if(type === 'provider') {
            arr = [
                {name: 'username', headerName: t('common.name'), filterPlaceholder: t('common.filter-name'), style: { minWidth: '12rem' }, filter: true, bodyTemplate: null},
                {name: 'email', headerName: t('client.email'), filterPlaceholder: t('common.email-filter'), style: { minWidth: '12rem' }, filter: true, bodyTemplate: null},
                {name: 'phone', headerName: t('common.phone'), filterPlaceholder: t('common.phone-filter'), style: { minWidth: '12rem' }, filter: true, bodyTemplate: null},
                {name: 'userProfil.profil.nameFr', headerName: "Profils", filterPlaceholder: 'Filtrer par profils', style: { minWidth: '12rem' }, filter: true, bodyTemplate: profilsBodyTemplate},
                {name: 'provider.providerName', headerName: t('dashboard.provider'), filterPlaceholder: t('facturation.provider-filter'), style: { minWidth: '12rem' }, filter: true, bodyTemplate: null},
                {name: 'createdAt', filterField: 'createdAt', dataType: 'date', headerName: 'Créé le', filterPlaceholder: t('facturation.create-date-filter'), style: { minWidth: '6rem' }, filter: true, bodyTemplate: datePaiementBodyTemplate, filterElement: dateFilterTemplate},
                {name: 'actions', headerName: 'Actions', filterPlaceholder: '', style: { minWidth: '6rem'}, filter: false, bodyTemplate: getActionsTemplate},
            ]
        }else if(type === 'rh') {
            arr = [
                {name: 'username', headerName: t('common.name'), filterPlaceholder: t('common.filter-name'), style: { minWidth: '12rem' }, filter: true, bodyTemplate: null},
                {name: 'email', headerName: t('client.email'), filterPlaceholder: t('common.email-filter'), style: { minWidth: '12rem' }, filter: true, bodyTemplate: null},
                {name: 'phone', headerName: t('common.phone'), filterPlaceholder: t('common.phone-filter'), style: { minWidth: '12rem' }, filter: true, bodyTemplate: null},
                {name: 'userProfil.profil.nameFr', headerName: "Profils", filterPlaceholder: 'Filtrer par profils', style: { minWidth: '12rem' }, filter: true, bodyTemplate: profilsBodyTemplate},
                {name: 'rh.clientName', headerName: "Client", filterPlaceholder: 'Filtrer par client', style: { minWidth: '12rem' }, filter: true, bodyTemplate: null},
                {name: 'rh.polices', headerName: "Polices", filterPlaceholder: 'Filtrer par polices', style: { minWidth: '12rem' }, filter: true, bodyTemplate: policesBodyTemplate},
                {name: 'createdAt', filterField: 'createdAt', dataType: 'date', headerName: 'Créé le', filterPlaceholder: t('facturation.create-date-filter'), style: { minWidth: '6rem' }, filter: true, bodyTemplate: datePaiementBodyTemplate, filterElement: dateFilterTemplate},
                {name: 'actions', headerName: 'Actions', filterPlaceholder: '', style: { minWidth: '6rem'}, filter: false, bodyTemplate: getActionsTemplate},
            ]
        }else{
            arr = [
                {name: 'username', headerName: t('common.name'), filterPlaceholder: t('common.filter-name'), style: { minWidth: '12rem' }, filter: true, bodyTemplate: null},
                {name: 'email', headerName: t('client.email'), filterPlaceholder: t('common.email-filter'), style: { minWidth: '12rem' }, filter: true, bodyTemplate: null},
                {name: 'phone', headerName: t('common.phone'), filterPlaceholder: t('common.phone-filter'), style: { minWidth: '12rem' }, filter: true, bodyTemplate: null},
                {name: 'createdAt', filterField: 'createdAt', dataType: 'date', headerName: 'Créé le', filterPlaceholder: t('facturation.create-date-filter'), style: { minWidth: '6rem' }, filter: true, bodyTemplate: datePaiementBodyTemplate, filterElement: dateFilterTemplate},
                {name: 'actions', headerName: 'Actions', filterPlaceholder: '', style: { minWidth: '6rem'}, filter: false, bodyTemplate: getActionsTemplate},
            ]
        }
        console.log(arr);
        return arr;
    };

    const getActionsTemplate = (rowData) => {
        return <div style={{ width: '100%', display: 'flex', justifyContent: 'center'}}>
            {hasMenu('MENPARAMUSER') && canDo('MENPARAMUSER', 'UPDATEUSER') && <button className="btn btn-primary shadow btn-xs sharp mr-2"
                data-pr-tooltip="Modifier"
                data-pr-position="bottom"
                onClick={e => updateAction(rowData)}
            >
                <i className="fa fa-pencil"></i>
            </button>}
            {hasMenu('MENPARAMUSER') && canDo('MENPARAMUSER', 'DELETEUSER') && <button className="btn btn-danger shadow btn-xs sharp mr-2"
                data-pr-tooltip="Désactiver"
                data-pr-position="bottom"
                onClick={() =>
                    swal({
                    title: "Supprimer l'utilisateur "+rowData.username+" ?",
                    text:
                        "Une fois l'utilisateur supprimé, il ne pourra plus être accessible",
                    icon: "warning",
                    buttons: true,
                    dangerMode: true,
                    }).then((willDelete) => {
                    if (willDelete) {
                        let data = {
                            id: rowData.id
                        }
                        dispatch(startLoader());
                        dispatch(deleteParamAction('user', data));
                    }
                    })
                }
            >
                <i className="la la-ban"></i>
            </button>}
            <Tooltip target=".btn" />
            {hasMenu('MENPARAMUSER') && canDo('MENPARAMUSER', 'RESETUSERPASSWORD') && <button className="btn btn-warning shadow btn-xs sharp mr-2" 
                data-pr-tooltip="Réinitialiser le mot de passe"
                data-pr-position="bottom"
                onClick={() =>
                    swal({
                    title: "Réinitialisation de mot passe",
                    text:
                        "Vous êtes sur le point de réinitialiser le mot de passe pour l'utilisateur"+
                        rowData.username+". Cette générera un mot de passe aléatoire qu'il recevra par email et sms. Voulez-vous poursuivre?",
                    icon: "warning",
                    buttons: true,
                    dangerMode: true,
                    }).then((willDelete) => {
                    if (willDelete) {
                        dispatch(startLoader());
                        dispatch(resetUserPasswordActionsAction(rowData.id));
                    }
                    })
                }
            >
                <i className="fa fa-key"></i>
            </button>}
            {hasMenu('MENPARAMUSER') && canDo('MENPARAMUSER', 'RESENDUSERPARAMS') && rowData.type === 'Patient' && <button className="btn btn-info shadow btn-xs sharp mr-2" 
                data-pr-tooltip="Renvoyer les paramètres"
                data-pr-position="bottom"
                onClick={() =>
                    swal({
                    title: "Renvoie des paramètres de connexion",
                    text:
                        "Vous êtes sur le point de renvoyer les paramètres de l'utilisateur"+
                        rowData.username+". Cette générera un mot de passe aléatoire qu'il recevra par email et sms, ainsi qu'une adresse de connexion. Voulez-vous poursuivre?",
                    icon: "warning",
                    buttons: true,
                    dangerMode: true,
                    }).then((willDelete) => {
                    if (willDelete) {
                        dispatch(startLoader());
                        dispatch(resendUserParamsActionsAction(rowData.id));
                    }
                    })
                }
            >
                <i className="fa fa-lock"></i>
            </button>}
        </div>
    }

    const dateFilterTemplate = (options) => {
        return <Calendar value={options.value} onChange={(e) => options.filterCallback(e.value, options.index)} dateFormat="dd/mm/yy" placeholder="dd/mm/yyyy" mask="99/99/9999" />;
    };

    const datePaiementBodyTemplate = (rowData) => {
        return formatDate(rowData.createdAt);
    };

    const profilsBodyTemplate = (rowData) => {
        return rowData.userProfil.map(profil => {
            return <Tag value={profil.profil?.nameFr} severity={'secondary'} />;
        })
        
    };

    const policesBodyTemplate = (rowData) => {
        return rowData.rh.polices?.map(police => {
            return <Tag value={police} severity={'info'} />;
        })
        
    };

    const formatDate = (value) => {
        return value.toLocaleDateString('fr-FR', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric'
        });
    };


    const canDo = (menuCode, actionCode) => {
        let theMenu = props.currentUser.menus?.find(menu => menu.code === menuCode);
        if(!theMenu){
            return false;
        }else{
            return theMenu.actionMenus?.filter(action => action.code === actionCode).length > 0;
        }
    }
    
    const hasMenu = (menuCode) => {
        return props.currentUser.menus?.filter(menu => menu.code === menuCode).length > 0;
    }
    
    useEffect(() => {
        dispatch(listProvidersAction());
        dispatch(getSouscripteursAction());
        initFilters();
        //dispatch(getUsersAction());    
    }, []);


    function onUserSave(e) {
        e.preventDefault();
        let error = false;
        const errorObj = { ...errorsObj };
        if (username === '') {
            errorObj.username = 'le champ username est obligatoire';
            error = true;
        }
        if (email === '') {
            errorObj.email = 'le champ email est obligatoire';
            error = true;
        }
        if(!updateMode){
            if (password === '') {
                errorObj.password = 'le mot de passe est obligatoire';
                error = true;
            }
            if (confirmPassword === '' || confirmPassword ==!password ) {
                errorObj.confirmPassword = 'les mots de passe ne correspondent pas';
                error = true;
            }
        }
        setErrors(errorObj);
        if (error) {
            swal('Oops', t('common.check-form-fields'), "infos");
			return ;
		}
        dispatch(startLoader());
        
        let obj = { 
            email, 
            password, 
            username, 
            confirmPassword, 
            phone: phonenumber, 
            profils, 
            providerId, 
            type: userType,
            clientId: client,
            clientName,
            polices: policies 
        };

        if(updateMode) {
            dispatch(updateUserAction(id, obj));
        }else{
            dispatch(createUserAction(obj));
        }
    }

    const updateAction = (data) => {
        console.log(data);
        console.log(data.provider?.id);
        setId(data.id);
        setEmail(data.email);
        setUsername(data.username);
        setPhonenumber(data.phone);
        setProviderId(data.provider?.id);
        setUserType(data.type);
        setProfils(data.userProfil ? data.userProfil.map(res => {return res.profil?.id}) : []);
        setClient(data.clientId);
        setClientName(data.clientName);
        setPolicies(data.polices || []);
        setPolicies([]);
        setUpdateMode(true);
        setAddCard(true);
    }

    useEffect(() => {
        setId("");
        setEmail("");
        setUsername("");
        setPhonenumber("");
        setProviderId("");
        setUserType("");
        setProfils([]);
        setClient("");
        setClientName("");
        setPolicies([]);
        setUpdateMode(false);
        setAddCard(false);
    }, [props.users]);

    const newUserCreate = () => {
        setId("");
        setEmail("");
        setUsername("");
        setPhonenumber("");
        setProviderId("");
        setUserType("");
        setProfils([]);
        setClient("");
        setClientName("");
        setPolicies([]);
        setUpdateMode(false);
        setAddCard(true);
    }

  return (
    <>
        <div className="form-head d-flex mb-3 mb-md-4 align-items-start">
            <div className="mr-auto d-none d-lg-block">
                {hasMenu('MENPARAMUSER') && canDo('MENPARAMUSER', 'CREATEUSER') && <Link
                    to="#"
                    className="btn btn-primary btn-rounded"
                    onClick={() => newUserCreate()}
                >
                    + Nouvel utilisateur
                </Link>}
            </div>
        </div>
        <Modal className="modal fade" show={addCard} onHide={setAddCard} >
            <div className="">
                <div className="card">
                    <div className="card-header">
                        {!updateMode && <h4 className="card-title">Créer un nouvel utilisateur</h4>}
                        {updateMode && <h4 className="card-title">Modifier l'utilisateur {username}</h4>}
                    </div>
                    <div className='card-body'>
                        <div className='basic-form'>
                            <form onSubmit={onUserSave}>
                                <div className='form-group row'>
                                    <label className='col-sm-3 col-form-label'>{t('client.type')}</label>
                                    <div className='col-sm-9'>
                                        <select
                                            defaultValue={userType}
                                            className='form-control form-control-lg'
                                            onChange={(e) => setUserType(e.target.value)}
                                        >
                                            <option value='option'>
                                                choisir...
                                            </option>
                                            {hasMenu('MENPARAMUSER') && canDo('MENPARAMUSER', 'CREATEADMINUSER') && <option value='admin'>Administrateur</option>}
                                            <option value='provider'>{t('dashboard.provider')}</option>
                                            <option value='rh'>{t('dashboard.rh')}</option>
                                        </select>
                                    </div>
                                </div>
                                {userType === 'provider' && <div className='form-group row'>
                                    <label className='col-sm-3 col-form-label'>{t('dashboard.provider')}</label>
                                    <div className='col-sm-9'>
                                        <Select
                                            className="select-search"
                                            value={props.providers.filter(elt => parseInt(elt.id) === parseInt(providerId)).map(res => {return {label: res.providerName, value: res.id}})}
                                            placeholder="Choisir le prestataire"
                                            required={userType === 'provider'}
                                            onChange={(val) => {setProviderId(val.value)}}
                                            options={props.providers?.map((opt)=>{return {label:opt.providerName,value:opt.id}})}
                                            style={{
                                            lineHeight: '40px',
                                            color: '#7e7e7e',
                                            paddingLeft: ' 15px',
                                            }}
                                        />
                                    </div>
                                </div>}
                                {userType === 'rh' && <div className='form-group row'>
                                    <label className='col-sm-3 col-form-label'>{t('patient.suscriber')}</label>
                                    <div className='col-sm-9'>
                                        <Select
                                            className="select-search"
                                            value={props.souscripteurs?.filter(elt => client === elt.codeAssu).map((opt)=>{return {label:opt.nom_Assu,value:opt.codeAssu}})}
                                            placeholder="Choisir le souscripteur"
                                            required={userType === 'rh'}
                                            onChange={(val) => {
                                                setClient(val.value);
                                                setClientName(val.label);
                                                dispatch(getContractPerClientAction(val.value));
                                            }}
                                            options={props.souscripteurs?.map((opt)=>{return {label:opt.nom_Assu,value:opt.codeAssu}})}
                                            style={{
                                            lineHeight: '40px',
                                            color: '#7e7e7e',
                                            paddingLeft: ' 15px',
                                            }}
                                        />
                                    </div>
                                </div>}
                                {userType === 'rh' && <div className='form-group row'>
                                    <label className='col-sm-3 col-form-label'>{t('common.policies')}</label>
                                    <div className='col-sm-9'>
                                        <Select
                                            className="select-search"
                                            value={props.contratPerClient?.filter(elt => policies.includes(elt.numeroPolice)).map((opt)=>{return {label:opt.numeroPolice,value:opt.numeroPolice}})}
                                            placeholder="Choisir les polices"
                                            search
                                            isMulti
                                            required={userType === 'rh'}
                                            onChange={(vals) => {
                                                setPolicies(vals ? vals.map(val => val.value) : []);
                                                return;
                                            }}
                                            options={props.contratPerClient?.map((opt)=>{return {label:opt.numeroPolice,value:opt.numeroPolice}})}
                                            style={{
                                            lineHeight: '40px',
                                            color: '#7e7e7e',
                                            paddingLeft: ' 15px',
                                            }}
                                        />
                                    </div>
                                </div>}
                                <div className='form-group row'>
                                    <label className='col-sm-3 col-form-label'>username</label>
                                    <div className='col-sm-9'>
                                        <input
                                            type='text'
                                            name='username'
                                            className='form-control'
                                            placeholder='MD'
                                            value={username}
                                            onChange={(e) => setUsername(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className='form-group row'>
                                    <label className='col-sm-3 col-form-label'>{t('common.email')}</label>
                                    <div className='col-sm-9'>
                                        <input
                                            type='email'
                                            name='email'
                                            className='form-control'
                                            placeholder='Médecin'
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className='form-group row'>
                                    <label className='col-sm-3 col-form-label'>Téléphone</label>
                                    <div className='col-sm-9'>
                                        {/*<input
                                            type='phone'
                                            name='phone'
                                            className='form-control'
                                            placeholder='23769999999'
                                            value={phonenumber}
                                            onChange={(e) => setPhonenumber(e.target.value)}
                                        />*/}
                                        
                                        <PhoneInput
                                            country={'cm'}
                                            onlyCountries={['cm','ci','cd']}
                                            localization={'fr'}
                                            value={phonenumber}
                                            enableSearch={true}
                                            autoFormat={phonenumber ? !phonenumber.startsWith('225') : true}
                                            onChange={phone => setPhonenumber(phone)}
                                        />
                                    </div>
                                </div>
                                <div className='form-group row'>
                                    <label className='col-sm-3 col-form-label'>Profils</label>
                                    <div className='col-sm-9'>
                                        <Select
                                            className="select-search"
                                            value={props.profilsData?.filter(elt => profils.includes(elt.id)).map(res => {return {label: res.nameFr, value: res.id}})}
                                            disabled={props.showLoading}
                                            placeholder="Choisir les profilsData"
                                            isMulti
                                            required
                                            onChange={(vals) => setProfils(vals ? vals.map(val => { return val.value }) : [])}
                                            options={props.profilsData?.map((opt)=>{return {label:opt.nameFr,value:opt.id}})}
                                            style={{
                                            lineHeight: '40px',
                                            color: '#7e7e7e',
                                            paddingLeft: ' 15px',
                                            }}
                                        />
                                    </div>
                                </div>
                                {!updateMode && <>
                                    <div className='form-group row'>
                                        <label className='col-sm-3 col-form-label'>Password</label>
                                        <div className='col-sm-9'>
                                            <input
                                                type='password'
                                                name='password'
                                                className='form-control'
                                                placeholder='Doctor'
                                                onChange={(e) => setPassword(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                    <div className='form-group row'>
                                        <label className='col-sm-3 col-form-label'>Confirm Password</label>
                                        <div className='col-sm-9'>
                                            <input
                                                type='password'
                                                name='confirmPassword'
                                                className='form-control'
                                                placeholder='Doctor'
                                                onChange={(e) => setConfirmPassword(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                </>}
                                <hr />
                                <div className='form-group align-items-center justify-content-center'>
                                    <div className=''>
                                        { props.showLoading && 
                                            <BButton variant="primary" disabled>
                                                <Spinner
                                                    as="span"
                                                    animation="grow"
                                                    size="sm"
                                                    role="status"
                                                    aria-hidden="true"
                                                />{' '}
                                                <span className="visually-hidden">{t('common.await-text')}</span>
                                            </BButton> }
                                        { !props.showLoading &&
                                            <button type='submit' className='btn btn-primary'>
                                              {t('common.save')}
                                            </button> }
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
        <TabView>
            <TabPanel header="Administrateurs">
                <DataTableBase 
                    data={props.users.filter(user => user.type === 'admin').map(user => {
                        user.createdAt = new Date(user.createdAt);
                        user.profils = user.userProfil.map(prof => prof.profil?.nameFr).toString();
                        return user;
                    })}
                    emptyMessage="Aucun administrateur trouvé" 
                    filters={adminFilters}
                    globalFiltersFields={['username', 'email', 'phone', 'userProfil.profil.nameFr', 'createdAt']}
                    header={renderAdminHeader()}
                    columns={setColumns('admin')}
                    exportColumns={[
                        { title: t('common.name'), dataKey: 'username' },
                        { title: 'Email', dataKey: 'email' },
                        { title: t('common.phone'), dataKey: 'phone' },
                        { title: 'Profils', dataKey: 'profils' },
                        { title: t('common.create-date'), dataKey: 'createdAt' },
                    ]}
                    rows={10} 
                    showExport={true}
                    exportFilename={"administrateurs"}
                    loading={props.showLoading}
                />
            </TabPanel>
            <TabPanel header="Prestataires">
                <DataTableBase 
                    data={props.users.filter(user => user.type === 'provider').map(user => {
                        user.createdAt = new Date(user.createdAt);
                        user.profils = user.userProfil.map(prof => prof.profil?.nameFr).toString();
                        return user;
                    })}
                    emptyMessage="Aucun utilisateur prestataire trouvé" 
                    filters={providerFilters}
                    globalFiltersFields={['username', 'email', 'phone', 'userProfil.profil.nameFr', 'provider.providerName', 'createdAt']}
                    header={renderProviderHeader()}
                    columns={setColumns('provider')}
                    exportColumns={[
                        { title: t('common.name'), dataKey: 'username' },
                        { title: 'Email', dataKey: 'email' },
                        { title: t('common.phone'), dataKey: 'phone' },
                        { title: 'Profils', dataKey: 'profils' },
                        { title: t('common.create-date'), dataKey: 'createdAt' },
                    ]}
                    rows={10} 
                    showExport={true}
                    exportFilename={"utilisateurs_prestataire"}
                    loading={props.showLoading}
                />
            </TabPanel>
            <TabPanel header="Ressources Humaines">
                <DataTableBase 
                    data={props.users.filter(user => user.type === 'rh').map(user => {
                        user.createdAt = new Date(user.createdAt);
                        user.policesString = user.rh.polices.join(', ');
                        user.client = user.rh.clientName;
                        return user;
                    })}
                    emptyMessage="Aucun utilisateur RH trouvé" 
                    filters={providerFilters}
                    globalFiltersFields={['username', 'email', 'phone', 'rh.clientName', 'rh.polices', 'userProfil.profil.nameFr', 'createdAt']}
                    header={renderRhHeader()}
                    columns={setColumns('rh')}
                    exportColumns={[
                        { title: t('common.name'), dataKey: 'username' },
                        { title: 'Email', dataKey: 'email' },
                        { title: t('common.phone'), dataKey: 'phone' },
                        { title: 'Client', dataKey: 'client' },
                        { title: 'Polices', dataKey: 'policesString' },
                        { title: t('common.create-date'), dataKey: 'createdAt' },
                    ]}
                    rows={10} 
                    showExport={true}
                    exportFilename={"utilisateurs_rh"}
                    loading={props.showLoading}
                />
            </TabPanel>
            <TabPanel header="Assurés">
                <DataTableBase 
                    data={props.users.filter(user => user.type === 'Patient').map(user => {
                        user.createdAt = new Date(user.createdAt);
                        return user;
                    })}
                    emptyMessage="Aucun assuré trouvé" 
                    filters={clientFilters}
                    globalFiltersFields={['username', 'email', 'phone', 'createdAt']}
                    header={renderClientHeader()}
                    columns={setColumns('client')}
                    exportColumns={[
                        { title: t('common.name'), dataKey: 'username' },
                        { title: 'Email', dataKey: 'email' },
                        { title: t('common.phone'), dataKey: 'phone' },
                        { title: t('common.create-date'), dataKey: 'createdAt' },
                    ]}
                    rows={10} 
                    showExport={true}
                    exportFilename={"assures"}
                    loading={props.showLoading}
                />
            </TabPanel>
        </TabView>
    </>
  )
};

const mapStateToProps = (state) => {
    return {
        users: state.user.users,
        profilsData: state.user.profils,
        providers: state.provider.providers,
        errorMessage: state.user.errorMessage,
        successMessage: state.user.successMessage,
        showLoading: state.user.showLoading,
        saveDone: state.user.saveDone,
        deleteDone: state.user.deleteDone,
        contratPerClient: state.contrat.contratPerClient,
        souscripteurs: state.contrat.souscripteurs,
        currentUser: currentUser(state)
    };
};

export default connect(mapStateToProps)(User);