import React from 'react';
import { TreeTable } from 'primereact/treetable';
import { Column } from 'primereact/column';

export const TreeTableBase = (props) => {
    const {data, footer, style, header, columns, rowClassName} = props;

    return (
        <TreeTable value={data} header={header} footer={footer} tableStyle={style} rowClassName={rowClassName} scrollable scrollHeight="400px">
            {
                columns.map(column => {
                    return <Column 
                        //key={column.id}
                        field={column.field} 
                        header={column.header} 
                        expander={column.expander}
                        headerClassName={column.headerClassName}
                        style={column.style}
                        body={column.body}
                    ></Column>
                })
            }
        </TreeTable>
    );
}

export default TreeTableBase;