import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';
import HorizontalBarChart from '../chart/HorizontalBarChart';
import './EtatsGlobaux.css';

const EtatsDepenseRubrique = (props) => {
    const { t } = useTranslation();
    const {data, showLoading} = props;

    const [statsDatas, setStatsDatas] = useState([]);
    const [dataAmbuSeries, setDataAmbuSeries] = useState([]);
    const [dataHospiSeries, setDataHospiSeries] = useState([]);
    const [dataAmbuLabels, setDataAmbuLabels] = useState([]);
    const [dataHospiLabels, setDataHospiLabels] = useState([]);
    const [graph, setGraph] = useState(false);

    useEffect(() => {
        if(data.length > 0){
            setStatsDatas(data.map(key => {
                return {
                    statName: key.providerName === 'Hospitalisation' ? 'Chambre' : key.providerName,
                    totalMontant: key["total"]["montant"],
                    totalPourcent: parseFloat(key["total"]["taux"]),
                    ambuMontant: key["ambulatoire"]["montant"],
                    ambuPourcent: parseFloat(key["ambulatoire"]["taux"]),
                    hospiMontant: key["hospitalier"]["montant"],
                    hospiPourcent: parseFloat(key["hospitalier"]["taux"]),

                }
            })) 

            let prestsAmbu = data.filter(elt => elt.ambulatoire.montant > 0).sort((a,b) => b.ambulatoire.montant - a.ambulatoire.montant);
            let prestsHospi = data.filter(elt => elt.hospitalier.montant > 0).sort((a,b) => b.hospitalier.montant - a.hospitalier.montant);
            
            setDataAmbuSeries([{
                data: prestsAmbu.map(key => {
                    return key["ambulatoire"]["montant"]
                })
            }]);
            
            setDataHospiSeries([{
                data: prestsHospi.map(key => {
                    return key["hospitalier"]["montant"]
                })
            }]);
            setDataAmbuLabels(prestsAmbu.map(key => key.providerName));
            setDataHospiLabels(prestsHospi.map(key => key.providerName));
        }
    }, [data]);

    const headerGroup = (
        <ColumnGroup>
            <Row>
                <Column header="Prestataire" rowSpan={2}/>
                <Column header="Total" colSpan={2} />
                <Column header="Ambulatoire" colSpan={2} />
                <Column header="Hospitalier" colSpan={2} />
            </Row>
            <Row>
                <Column header="Montant" field="totalMontant" />
                <Column header="Taux en %" field="totalPourcen" />
                <Column header="Montant" field="ambuMontant" />
                <Column header="Taux en %" field="ambuPourcen" />
                <Column header="Montant" field="hospiMontant" />
                <Column header="Taux en %" field="hospiPourcen" />
            </Row>
        </ColumnGroup>
    );

    return (
        <>
            {Object.keys(data).length === 0 && <span>
                Aucune donnée à afficher, veuiller remplir les filtres afin d'en avoir.
            </span>
            }
            {Object.keys(data).length > 0 && <div className='results-section'>
                <div className='row justify-content-center my-4'>
                    <div className='form-group mb-0'>
                        <label className='radio-inline mr-5'>
                            <input type='radio' name='graph' value="hideGraph" checked={!graph} onChange={e => {setGraph(!e.target.checked)}}/> Données
                        </label>
                        <label className='radio-inline'>
                            <input type='radio' name='graph' value="showGraph" checked={graph} onChange={e => {setGraph(e.target.checked)}}/> Graphique
                        </label>
                    </div>
                </div>
                {!graph && <DataTable 
                    value={statsDatas} 
                    showGridlines 
                    headerColumnGroup={headerGroup} 
                    tableStyle={{ minWidth: '50rem' }}
                >
                    <Column field="statName"></Column>
                    <Column field="totalMontant"></Column>
                    <Column field="totalPourcent"></Column>
                    <Column field="ambuMontant"></Column>
                    <Column field="ambuPourcent"></Column>
                    <Column field="hospiMontant"></Column>
                    <Column field="hospiPourcent"></Column>
                </DataTable>}
                {graph && 
                    <div className='row'>
                        <div className='col-6'>
                            <h5>Rubriques en Ambulatoire</h5>
                            <HorizontalBarChart series={dataAmbuSeries} labels={dataAmbuLabels} height="150" />
                        </div>
                        <div className='col-6'>
                            <h5>Rubriques en Hospitalisation</h5>
                            <HorizontalBarChart series={dataHospiSeries} labels={dataHospiLabels} height="150" />
                        </div>
                    </div>
                }
            </div>}
        </>
    )
}
export default EtatsDepenseRubrique;