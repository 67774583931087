import React, { Fragment, useEffect, useState } from "react";
import { connect, useDispatch } from 'react-redux';
import { useParams, withRouter } from "react-router-dom";
import Select from 'react-select';
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import {
    listClientsAction
} from '../../../../store/actions/ClientAuthActions';
import {getRemboursementAction, updateRemboursementAction, getMotifsExclusionAction, startLoader, calculationRembBaseAction, calculationTMAction} from "../../../../store/actions/FacturationAction";
import { currentUser } from "../../../../store/selectors/CurrentUserSelector";
import { Button, Spinner } from "react-bootstrap";
import { ProgressBar } from 'react-bootstrap';
import {
    getLettreCleAction,
} from '../../../../store/actions/ProviderActions';
import { listProvidersAction } from '../../../../store/actions/ProviderActions';
import { getBeneficiairesAction, getAssuresBeneficiairesAction, getSouscripteursAction, getGarantiesAction } from "../../../../store/actions/ContratActions";
import {
    getActesAction, searchActesAction
} from '../../../../store/actions/ProviderActions';
import { searchDrugsAction, searchDiseasesAction, getActesConsultationAction } from "../../../../store/actions/ConsultationActions";
import TextInput from 'react-autocomplete-input';
import { STATUSES } from '../../../globals';
import { useTranslation } from "react-i18next";

export const RemboursementForm = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const {id} = useParams();
    const { history, showLoading, clients, lettreCles, providers,
         motifExclusion, beneficiaires, souscripteurs, assureBenefs,
        remboursement, garanties, actes, actesConsultation, rembCalculDatas, drugs,
        diseases, ticketModDatas } = props;

    const [souscriptId, setSouscriptId] = useState('');
    const [souscriptName, setSouscriptName] = useState('');
    const [numeroPolice, setNumeroPolice] = useState('');
    const [ligneAssures, setLigneAssures] = useState([
        {   
            assurePrincId: "",
            assurePrincName: "",
            montantReclame: 0,
            benefList: [   
                {
                    assureId: "",
                    assureName: "",
                    montantReclame: 0,
                    remboursements: [
                        {
                            prestatairePrescriptId: "",
                            prestatairePrescriptName: "",
                            prestataireExecId: "",
                            prestataireExecName: "",
                            categorieId: "",
                            categorieName: "",
                            montant: 0,
                            medecin: "",
                            date: new Date(),
                            montantExclu: 0,
                            motifExclu: "",
                            affectionPresum: "",
                            affectionIdent: "",
                            files: [],
                            actes: [],
                            details: [
                                {
                                    prestataireExecId: "",
                                    prestataireExecName: "",
                                    categorieId: "",
                                    categorieName: "",
                                    montant: 0,
                                    montantExclu: 0,
                                    motifExclu: "",
                                    date: new Date(),
                                    actes: [],
                                    files: []
                                }
                            ]
                        }
                    ]
                }
            ]
        }
    ]);

    const requestRemboursement = (e) => {
        e.preventDefault();
        dispatch(startLoader());

        let data = {
            remboursementId: id,
            souscriptId,
            souscriptName,
            numeroPolice,
            ligneAssures
        }

        console.log(data);

        dispatch(updateRemboursementAction(id, data, history));
    }

    const addRembActeLine = (e, i, j, k) => {
        e.preventDefault();
        setLigneAssures(ligneAssures.map((assure, l) => {
            if(l === i) {
                assure.benefList[j].remboursements[k].actes = [...assure.benefList[j].remboursements[k].actes, {
                    acteId: "",
                    acteName: "",
                    motifExclu: "",
                    baseRemb: 0,
                    tm: 0,
                    montantPaye: 0
                }];
            }
            return assure;
        }))
    }

    const addActeLine = (e, i, j, k, m) => {
        e.preventDefault();
        setLigneAssures(ligneAssures.map((assure, l) => {
            if(l === i) {
                assure.benefList[j].remboursements[k].details[m].actes = [...assure.benefList[j].remboursements[k].details[m].actes, {
                    acteId: "",
                    acteName: "",
                    acteAmount: 0,
                    montantFacture: 0,
                    quantite: 1,
                    montantExclu: 0,
                    motifExclu: "",
                    baseRemb: 0,
                    tm: 0,
                    montantPaye: 0
                }];
            }
            return assure;
        }))
    }

    const removeRembActeLine = (e, l, i, p, o) => {
        e.preventDefault();
        setLigneAssures(ligneAssures.map((assure, k) => {
            if(k === l) {
                assure.benefList[i].remboursements[p].actes = assure.benefList[i].remboursements[p].actes.filter((acte, j) => {return j!==o})
            }
            return assure;
        }));
    }

    const removeActeLine = (e, l, i, p, o, q) => {
        e.preventDefault();
        setLigneAssures(ligneAssures.map((assure, k) => {
            if(k === l) {
                assure.benefList[i].remboursements[p].details[o].actes = assure.benefList[i].remboursements[p].details[o].actes.filter((acte, j) => {return j!==q})
            }
            return assure;
        }));
    }

    const calculateAmounts = (acteId, prestaId, patientId, montant, i, j, k, l) => {
        if(prestaId) {
            let data = {
                providerId: prestaId,
                patientId,
                acteId
            }
            dispatch(calculationRembBaseAction(data, i+','+j+','+k+','+l));
        }
    };

    const calculateSubAmounts = (acteId, prestaId, patientId, montant, i, j, k, l, m) => {
        let data = {
            providerId: prestaId,
            patientId,
            acteId
        }
        dispatch(calculationRembBaseAction(data, i+','+j+','+k+','+l+','+m));
    };


    const calculateTM = (garantieId, patientId, i, j, k, l, m) => {
        let data = {
            garantieId,
            patientId
        }
        dispatch(calculationTMAction(data, i+','+j+','+k+','+l+','+m));
    };

    const calculateSums = (l, j) => {
        let assure = ligneAssures[l];
        let benef = ligneAssures[l].benefList[j];
        benef.montantReclame = benef.remboursements.reduce((sum, remb) => {
            return sum + parseInt(remb.montant) + remb.details.reduce((subSum, detail) => subSum + parseInt(detail.montant), 0)
        }, 0);

        assure.montantReclame = assure.benefList.reduce((sumT, ben) => sumT + ben.montantReclame, 0);

        assure.benefList[j] = benef;

        setLigneAssures((line, i) => {
            if(i === l){
                line = assure;
            }
            return line
        })
    }

    const canDo = (menuCode, actionCode) => {
        let theMenu = props.currentUser.menus?.find(menu => menu.code === menuCode);
        if(!theMenu){
            return false;
        }else{
            return theMenu.actionMenus?.filter(action => action.code === actionCode).length > 0;
        }
    }
    
    const hasMenu = (menuCode) => {
        return props.currentUser.menus?.filter(menu => menu.code === menuCode).length > 0;
    }

    useEffect(() => {
        if(hasMenu('MENREMBOURS') && canDo('MENREMBOURS', 'VALIDATEREMBOURS')){
            dispatch(getRemboursementAction(id));
            dispatch(getGarantiesAction());
            dispatch(getActesConsultationAction());
            //dispatch(getActesAction());
            //dispatch(listClientsAction());
            //dispatch(getSouscripteursAction());
            dispatch(listProvidersAction());
            //dispatch(getLettreCleAction());
            dispatch(getMotifsExclusionAction());
        }else{
            props.history.push('/access-denied');
        } 
    }, []);

    useEffect(() => {
        console.log(rembCalculDatas);
        let temp = ligneAssures;
        for(let i=0; i<rembCalculDatas.length; i++){
            let elt = rembCalculDatas[i];
            let indexes = elt.index.split(',');
            indexes = indexes.map(idx => {
                return parseInt(idx);
            })
            if(indexes.length === 4) {
                console.log("In this case");
                
                let montantRec = temp[indexes[0]].benefList[indexes[1]].remboursements[indexes[2]].montant;
                let baseRembours = 0;

                baseRembours = Math.min(montantRec, elt.data.montantPolice);
                
                temp[indexes[0]].benefList[indexes[1]].remboursements[indexes[2]].actes[indexes[3]].tm = elt.data.montantPaye;
                temp[indexes[0]].benefList[indexes[1]].remboursements[indexes[2]].actes[indexes[3]].baseRemb = baseRembours;
                temp[indexes[0]].benefList[indexes[1]].remboursements[indexes[2]].actes[indexes[3]].montantPaye =  (baseRembours - elt.data.montantPaye) > 0 ? baseRembours - elt.data.montantPaye : 0;
                temp[indexes[0]].benefList[indexes[1]].remboursements[indexes[2]].montantExclu = montantRec - ((baseRembours - elt.data.montantPaye) > 0 ? baseRembours - elt.data.montantPaye : 0);
                console.log(temp);
            }else{
                console.log("Inside the second case");

                let montantFact = temp[indexes[0]].benefList[indexes[1]].remboursements[indexes[2]].details[indexes[3]].actes[indexes[4]].montantFacture;

                let baseRembours = 0;
                baseRembours = Math.min(montantFact, elt.data.montantPolice);
                
                temp[indexes[0]].benefList[indexes[1]].remboursements[indexes[2]].details[indexes[3]].actes[indexes[4]].tm = 
                    temp[indexes[0]].benefList[indexes[1]].remboursements[indexes[2]].details[indexes[3]].actes[indexes[4]].quantite * baseRembours - elt.data.montantPaye;

                temp[indexes[0]].benefList[indexes[1]].remboursements[indexes[2]].details[indexes[3]].actes[indexes[4]].baseRemb = 
                    temp[indexes[0]].benefList[indexes[1]].remboursements[indexes[2]].details[indexes[3]].actes[indexes[4]].quantite * baseRembours;

                temp[indexes[0]].benefList[indexes[1]].remboursements[indexes[2]].details[indexes[3]].actes[indexes[4]].montantPaye = 
                temp[indexes[0]].benefList[indexes[1]].remboursements[indexes[2]].details[indexes[3]].actes[indexes[4]].quantite * baseRembours -
                    temp[indexes[0]].benefList[indexes[1]].remboursements[indexes[2]].details[indexes[3]].actes[indexes[4]].quantite * elt.data.montantPaye;

                temp[indexes[0]].benefList[indexes[1]].remboursements[indexes[2]].details[indexes[3]].montantExclu = 
                    temp[indexes[0]].benefList[indexes[1]].remboursements[indexes[2]].details[indexes[3]].montant - elt.data.montantPaye -
                    (temp[indexes[0]].benefList[indexes[1]].remboursements[indexes[2]].details[indexes[3]].actes[indexes[4]].quantite * baseRembours -
                        temp[indexes[0]].benefList[indexes[1]].remboursements[indexes[2]].details[indexes[3]].actes[indexes[4]].quantite * elt.data.montantPaye);
                
                console.log(temp);
            }
        }
        setLigneAssures(temp);
        console.log(ligneAssures);
    }, [rembCalculDatas]);

    useEffect(() => {
        console.log(ticketModDatas);
        let temp = ligneAssures;
        for(let i=0; i<ticketModDatas.length; i++){
            let elt = ticketModDatas[i];
            console.log(elt);
            let indexes = elt.index.split(',');
            indexes = indexes.map(idx => {
                return parseInt(idx);
            })
            console.log("Inside the second case");
            temp[indexes[0]].benefList[indexes[1]].remboursements[indexes[2]].details[indexes[3]].actes[indexes[4]].tm = 
            (temp[indexes[0]].benefList[indexes[1]].remboursements[indexes[2]].details[indexes[3]].actes[indexes[4]].quantite * temp[indexes[0]].benefList[indexes[1]].remboursements[indexes[2]].details[indexes[3]].actes[indexes[4]].montantFacture) * elt.data.tm / 100;
            temp[indexes[0]].benefList[indexes[1]].remboursements[indexes[2]].details[indexes[3]].montantExclu = 
            (temp[indexes[0]].benefList[indexes[1]].remboursements[indexes[2]].details[indexes[3]].actes[indexes[4]].montantFacture * temp[indexes[0]].benefList[indexes[1]].remboursements[indexes[2]].details[indexes[3]].actes[indexes[4]].quantite) - 
            (Math.min(temp[indexes[0]].benefList[indexes[1]].remboursements[indexes[2]].details[indexes[3]].actes[indexes[4]].montantFacture, elt.data.plafond) * temp[indexes[0]].benefList[indexes[1]].remboursements[indexes[2]].details[indexes[3]].actes[indexes[4]].quantite * elt.data.tm / 100)
            console.log(temp);
        }
        setLigneAssures(temp);
        console.log(ligneAssures);
    }, [ticketModDatas]);

    useEffect(() => {
        if(Object.keys(remboursement).length > 0) {
            setSouscriptId(remboursement.souscripteurId);
            setSouscriptName(remboursement.souscripteurName);
            setNumeroPolice(remboursement.numeroPolice);

            let rembDetails = remboursement.factureRemboursementDetails;

            let res = [];

            let visitedIds = [];

            let myRes = rembDetails.map(remb => {
                let obj = {};
                if(!visitedIds.includes(remb.assurerPrincipalId)){
                   obj.assurePrincId = remb.assurerPrincipalId;
                   obj.assurePrincName = remb.assurerPrincipalName;
                   obj.montantReclame = remb.montantReclame;
                   let benefs = rembDetails.filter(elt => {return elt.assurerPrincipalId === remb.assurerPrincipalId});
                   console.log(benefs);
                   let visitedBenefIds = [];
                   obj.benefList = benefs.map(benef => {
                        let res = {};
                        if(!visitedBenefIds.includes(benef.assurerId)){
                            res.assureId = benef.assurerId;
                            res.assureName = benef.assurerName;
                            res.montantReclame = benef.montantReclame;
                            let presta = rembDetails.filter(elt => {return elt.assurerId === benef.assurerId});
                            console.log(presta);
                            res.remboursements  = presta.map(prest => {
                                let resP = {};
                                if(prest.type && prest.type.toLowerCase().includes("consultation")) {
                                    resP.id = prest.id;
                                    resP.consultationCode = prest.consultationCode;
                                    resP.prestatairePrescriptId = prest.prescripteurId || 0;
                                    resP.prestatairePrescriptName = prest.prescripteurName;
                                    resP.prestataireExecId = prest.executeurId || 0;
                                    resP.prestataireExecName = prest.executeurName;
                                    resP.categorieId = prest.categorieId || garanties.find(cat => {return cat.libelle === prest.categorieName})?.id;
                                    resP.categorieName = prest.categorieName;
                                    resP.montant = prest.montantPrestationReclame;
                                    resP.medecin = prest.medecin;
                                    resP.date = new Date(prest.dateExecution);
                                    resP.montantExclu = 0;
                                    resP.motifExclu = prest.motifExclu;
                                    resP.affectionPresum = prest.affectionPresum;
                                    resP.affectionIdent = prest.affectionIdent;
                                    resP.files = [prest.files];
                                    resP.actes = prest.factureRejets;
                                    let detPrest = rembDetails.filter(elt => {return (elt.consultationCode === prest.consultationCode && !elt.type)})
                                    console.log(detPrest);
                                    resP.details = detPrest.map(detP => {
                                        return {
                                            id: detP.id,
                                            consultationCode: detP.consultationCode,
                                            prestataireExecId: detP.executeurId || 0,
                                            prestataireExecName: detP.executeurName,
                                            categorieId: detP.categorieId || garanties.find(cat => {return cat.libelle === detP.categorieName})?.id,
                                            categorieName: detP.categorieName,
                                            montant: detP.montantPrestationReclame,
                                            date: new Date(detP.dateExecution),
                                            montantExclu: 0,
                                            motifExclu: detP.motifExclu,
                                            actes: detP.factureRejets,
                                            files: [detP.files]
                                        }
                                    });
                                }
                                return resP;
                            });
                            res.remboursements = res.remboursements.filter(elt => {return Object.keys(elt).length > 0});
                            visitedBenefIds.push(benef.assurerId);
                        }
                        return res;
                   });
                   obj.benefList = obj.benefList.filter(elt => {return Object.keys(elt).length > 0});
                   visitedIds.push(remb.assurerPrincipalId);
                }
                return obj;
            });

            console.log(myRes.filter(elt => {return Object.keys(elt).length > 0}));

            setLigneAssures(myRes.filter(elt => {return Object.keys(elt).length > 0}));
        }
    }, [remboursement]);


    return (
        <Fragment>
           <div className="row">
              <div className="col-xl-12 col-xxl-12">
                 <div className="card">
                    <div className="card-header">
                       <h4 className="card-title">Demande de remboursement</h4>
                    </div>
                    <div className="card-body">
                        <h5 className='mt-4 text-primary'>Règlement Médical</h5>
                        <div className="border-top mt-2 mb-4"></div>
                        <div className="d-flex justify-content-between">
                            <div>
                                <p><b>{t('dashboard.souscipt-name')}: </b>{souscriptName}</p>
                                <p><b>N° Police: </b>{remboursement.numeroPolice}</p>
                                <p><b>Date d'effet: </b>{remboursement.dateEffet}</p>
                                <p><b>Date d'expiration: </b>{remboursement.dateExpiration}</p>
                            </div>
                            <div>
                                <p><b>Date de demande: </b>{remboursement.dateFacturation}</p>
                                <p><b>Montant total du remboursement: </b>{remboursement.montantTotal}</p>
                                <p><b>{t('common.status')}: </b>{remboursement.statusPaiement ? t('statuses.'+remboursement.statusPaiement) : remboursement.statusPaiement}</p>
                            </div>
                        </div>
                        <div className="border-top mt-2 mb-4"></div>
                        <form
                            onSubmit={(e) => requestRemboursement(e)}
                        >
                                <section>
                                    {ligneAssures.map((ligne, i) => {
                                        return <>
                                            <div className="row">
                                                <div className="col-lg-5 mb-2">
                                                    <div className="form-group">
                                                        <label className='mb-1 '>
                                                            <strong>Assuré principal</strong>
                                                        </label>
                                                        <input type='text' className='form-control'
                                                            value={ligne.assurePrincName}
                                                            required
                                                            disabled
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-lg-5 mb-2">
                                                    <div className="form-group">
                                                        <label className='mb-1 '>
                                                            <strong>Montant total</strong>
                                                        </label>
                                                        <input type='number' className='form-control'
                                                            value={ligne.montantReclame}
                                                            required
                                                            disabled
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            {ligne.benefList.map((line, j) => {
                                                return <>
                                                <div className="row ml-2" key={j}>
                                                    <div className="col-lg-5 mb-2">
                                                        <div className="form-group">
                                                            <label className='mb-1 '>
                                                                <strong>Bénéficiaire</strong>
                                                            </label>
                                                            <input type='text' className='form-control'
                                                                value={line.assureName}
                                                                required
                                                                disabled
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-5 mb-2">
                                                        <div className="form-group">
                                                            <label className='mb-1 '>
                                                                <strong>Montant total</strong>
                                                            </label>
                                                            <input type='number' className='form-control'
                                                                value={line.montantReclame}
                                                                required
                                                                disabled
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                
                                                {line.remboursements.map((remboursement, k) => {
                                                    return <>
                                                    <h6 className="ml-4 text-secondary">REMBOURSEMENT N°{" "}{i+1}{j+1}{k+1}</h6>
                                                    <div className="border-top border-secondary my-2 ml-4"></div>
                                                    <div className="row mb-2 ml-2" key={k}>
                                                        <div className="col-lg-2">
                                                            <div className="form-group">
                                                                <label className='mb-1 '>
                                                                    <strong>Categorie</strong>
                                                                </label>
                                                                <input type='text' className='form-control'
                                                                    value={ligne.benefList[j].remboursements[k].categorieName}
                                                                    required
                                                                    disabled
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-2">
                                                            <div className="form-group">
                                                                <label className='mb-1 '>
                                                                    <strong>Centre prescripteur</strong>
                                                                </label>
                                                                <TextInput
                                                                    className='form-control custom-text-input border'
                                                                    Component="input"
                                                                    required
                                                                    trigger={""}
                                                                    value={remboursement.prestatairePrescriptName}
                                                                    placeholder="Entrer un prestataire"
                                                                    onChange={val => {
                                                                        setLigneAssures(ligneAssures.map((linA, l) => {
                                                                            if(i === l) {
                                                                                linA.benefList[j].remboursements[k].prestatairePrescriptName = val;
                                                                                let provider = providers.filter(prov => {return prov.providerName.trim() === val.trim()})[0];
                                                                                linA.benefList[j].remboursements[k].prestatairePrescriptId = provider ? provider.id : null;
                                                                            }
                                                                            return linA;
                                                                        }))
                                                                    }}
                                                                    options={providers.map(prov => {return prov.providerName})}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-2">
                                                            <div className="form-group">
                                                                <label className='mb-1 '>
                                                                    <strong>{t('common.executant')}</strong>
                                                                </label>
                                                                <TextInput
                                                                    className='form-control custom-text-input border'
                                                                    Component="input"
                                                                    required
                                                                    trigger={""}
                                                                    value={remboursement.prestataireExecName}
                                                                    placeholder="Entrer un prestataire"
                                                                    onChange={val => {
                                                                        setLigneAssures(ligneAssures.map((linA, l) => {
                                                                            if(i === l) {
                                                                                linA.benefList[j].remboursements[k].prestataireExecName = val;
                                                                                let provider = providers.filter(prov => {return prov.providerName.trim() === val.trim()})[0];
                                                                                linA.benefList[j].remboursements[k].prestataireExecId = provider ? provider.id : null;
                                                                            }
                                                                            return linA;
                                                                        }))
                                                                    }}
                                                                    options={providers.map(prov => {return prov.providerName})}
                                                                />
                                                            </div>
                                                        </div>
                                                        
                                                        <div className="col-lg-2">
                                                            <div className="form-group">
                                                                <label className='mb-1 '>
                                                                    <strong>{t('common.doctor')}</strong>
                                                                </label>
                                                                <input type='text' className='form-control'
                                                                    value={remboursement.medecin}
                                                                    required
                                                                    onChange={(e) => {
                                                                        setLigneAssures(ligneAssures.map((linA, l) => {
                                                                            if(i === l) {
                                                                                linA.benefList[j].remboursements[k].medecin = e.target.value;
                                                                            }
                                                                            return linA;
                                                                        }))
                                                                    }}
                                                                    />
                                                            </div>
                                                        </div>

                                                        <div className="col-lg-2">
                                                            <div className="form-group">
                                                                <label className='mb-1 '>
                                                                    <strong>Affection présumée</strong>
                                                                </label>
                                                                <Select
                                                                    className="select-search"
                                                                    value={{label: remboursement.affectionPresum, value: k}}
                                                                    options={diseases.map(elt => {return {label: elt.name, value: elt.id}})}
                                                                     placeholder={t('common.choose')}
                                                                    search
                                                                    isRequired
                                                                    name="affectionPresumée"
                                                                    onInputChange={(val) => {
                                                                        if(val.length >= 3){
                                                                            dispatch(searchDiseasesAction(val))
                                                                        }
                                                                    }}
                                                                    onChange={(val) => {
                                                                        setLigneAssures(ligneAssures.map((linA, l) => {
                                                                            if(i === l) {
                                                                                linA.benefList[j].remboursements[k].affectionPresum = val.label;
                                                                            }
                                                                            return linA;
                                                                        }))
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>

                                                        <div className="col-lg-2">
                                                            <div className="form-group">
                                                                <label className='mb-1 '>
                                                                    <strong>Affection identifiée</strong>
                                                                </label>
                                                                <Select
                                                                    className="select-search"
                                                                    name="affectionIdentifiée"
                                                                    value={{label: remboursement.affectionIdent, value: k}}
                                                                    options={diseases.map(elt => {return {label: elt.name, value: elt.id}})}
                                                                     placeholder={t('common.choose')}
                                                                    search
                                                                    isRequired
                                                                    onInputChange={(val) => {
                                                                        if(val.length >= 3){
                                                                            dispatch(searchDiseasesAction(val))
                                                                        }
                                                                    }}
                                                                    onChange={(val) => {
                                                                        setLigneAssures(ligneAssures.map((linA, l) => {
                                                                            if(i === l) {
                                                                                linA.benefList[j].remboursements[k].affectionIdent = val.label;
                                                                            }
                                                                            return linA;
                                                                        }))
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                        
                                                        <div className="col-lg-2">
                                                            <div className="form-group">
                                                                <label className='mb-1 '>
                                                                    <strong>Montant réclamé</strong>
                                                                </label>
                                                                <input type='number' className='form-control'
                                                                    value={remboursement.montant}
                                                                    required
                                                                    onChange={(e) => {
                                                                        setLigneAssures(ligneAssures.map((linA, l) => {
                                                                            if(i === l) {
                                                                                linA.benefList[j].remboursements[k].montant = e.target.value;
                                                                            }
                                                                            return linA;
                                                                        }))
                                                                    }}
                                                                    />
                                                            </div>
                                                        </div>

                                                        <div className="col-lg-2">
                                                            <div className="form-group">
                                                                <label className='mb-1 '>
                                                                    <strong>Montant exclu</strong>
                                                                </label>
                                                                <input type='number' className='form-control'
                                                                    value={remboursement.montantExclu}
                                                                    required
                                                                    onChange={(e) => {
                                                                        setLigneAssures(ligneAssures.map((linA, l) => {
                                                                            if(i === l) {
                                                                                linA.benefList[j].remboursements[k].montantExclu = e.target.value;
                                                                            }
                                                                            return linA;
                                                                        }))
                                                                        if(e.target.value > 0 && remboursement.actes.length === 0) {
                                                                            addRembActeLine(e, i, j, k)
                                                                        }
                                                                    }}
                                                                    />
                                                                {remboursement.montantExclu > 0 && <small className="text-warning">
                                                                    veuillez préciser l'acte
                                                                </small>}
                                                            </div>
                                                        </div>

                                                        <div className="col-lg-2">
                                                            <div className="form-group">
                                                                <label className='mb-1 '>
                                                                    <strong>{t('appointment.reason')} Exclusion</strong>
                                                                </label>
                                                                <Select
                                                                    className="select-search"
                                                                    value={motifExclusion.filter(cat => {return cat.nameFr === remboursement.motifExclu})
                                                                    .map(elt => {return {label:elt.nameFr,value:elt.id}})}
                                                                    options={motifExclusion.map(opt => {return {label:opt.nameFr,value:opt.id}})}
                                                                     placeholder={t('common.choose')}
                                                                    search
                                                                    isRequired
                                                                    onChange={(val) => {
                                                                        setLigneAssures(ligneAssures.map((linA, l) => {
                                                                            console.log("Setting the default value");
                                                                            if(i === l) {
                                                                                linA.benefList[j].remboursements[k].motifExclu = val.label;
                                                                            }
                                                                            return linA;
                                                                        }))
                                                                    }}
                                                                />
                                                                {remboursement.montantExclu > 0 && !remboursement.motifExclu && <small className="text-warning">
                                                                    veuillez préciser le motif d'exclusion
                                                                </small>}
                                                            </div>
                                                        </div>
                                                        

                                                        <div className="col-lg-2">
                                                            <div className="form-group">
                                                                <label className='mb-1 '>
                                                                    <strong>Date de soin</strong>
                                                                </label>
                                                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                                    <DateTimePicker
                                                                        autoOk
                                                                        label=""
                                                                        clearable
                                                                        format="dd/MM/yyyy hh:mm"
                                                                        value={remboursement.date}
                                                                        disableFuture
                                                                        required
                                                                        onChange={(val) => {
                                                                            setLigneAssures(ligneAssures.map((linA, l) => {
                                                                                if(i === l) {
                                                                                    linA.benefList[j].remboursements[k].date = val;
                                                                                }
                                                                                return linA;
                                                                            }))
                                                                        }}
                                                                    />
                                                                </MuiPickersUtilsProvider>
                                                            </div>
                                                        </div>

                                                        

                                                        {remboursement.files.length > 0 && remboursement.files[0] && <div className="col-lg-2">
                                                            <Button
                                                                as='a'
                                                                variant='success light'
                                                                href={remboursement.files[0]}
                                                                target="_blank"
                                                                className='btn-card mt-3 mr-3'
                                                                >
                                                                <i className="la la-file-download"></i>
                                                                facture
                                                            </Button>
                                                        </div>}

                                                </div>

                                                    {remboursement.actes.map((acte, r) => {
                                                        return <div className="row mb-2 ml-5" key={r}>
                                                            <div className="col-lg-2">
                                                                <div className="form-group">
                                                                    <label className='mb-1 '>
                                                                        <strong>{t('provider.acte')}</strong>
                                                                    </label>
                                                                    <Select
                                                                        className="select-search"
                                                                        value={actesConsultation.filter(cat => {return cat.id === acte.acteId})
                                                                        .map(elt => {return {label: elt.nameFr, value: elt.id}})}
                                                                        options={actesConsultation.map(elt => {return {label: elt.nameFr, value: elt.id}})}
                                                                         placeholder={t('common.choose')}
                                                                        search
                                                                        isRequired
                                                                        onChange={(val) => {
                                                                            setLigneAssures(ligneAssures.map((linA, l) => {
                                                                                if(i === l) {
                                                                                    linA.benefList[j].remboursements[k].actes[r].acteId = val.value;
                                                                                    linA.benefList[j].remboursements[k].actes[r].acteName = val.label;

                                                                                    //calculateAmounts(val.value, remboursement.prestataireExecId, line.assureId, remboursement.montant, i, j, k, r);
                                                                                    if(remboursement.prestataireExecId) {
                                                                                        let data = {
                                                                                            providerId: remboursement.prestataireExecId,
                                                                                            patientId: line.assureId,
                                                                                            acteId: val.value
                                                                                        }
                                                                                        dispatch(calculationRembBaseAction(data, i+','+j+','+k+','+r));
                                                                                    }
                                                                                }
                                                                                return linA;
                                                                            }))
                                                                        }}
                                                                    />
                                                                    {!remboursement.prestataireExecId && <small className="text-warning">
                                                                        prestaire non conventionné, calcul impossible
                                                                    </small>}
                                                                </div>
                                                            </div>

                                                            <div className="col-lg-2">
                                                                <div className="form-group">
                                                                    <label className='mb-1 '>
                                                                        <strong>Base de remb.</strong>
                                                                    </label>
                                                                    <input type='number' className='form-control'
                                                                        value={acte.baseRemb}
                                                                        required
                                                                        disabled={!remboursement.prestataireExecId}
                                                                        onChange={(e) => {
                                                                            setLigneAssures(ligneAssures.map((linA, l) => {
                                                                                if(i === l) {
                                                                                    linA.benefList[j].remboursements[k].actes[r].baseRemb = e.target.value;
                                                                                }
                                                                                return linA;
                                                                            }))
                                                                        }}
                                                                        />
                                                                </div>
                                                            </div>

                                                            <div className="col-lg-2">
                                                                <div className="form-group">
                                                                    <label className='mb-1 '>
                                                                        <strong>{t('common.tm')}</strong>
                                                                    </label>
                                                                    <input type='number' className='form-control'
                                                                        value={acte.tm}
                                                                        required
                                                                        disabled={!remboursement.prestataireExecId}
                                                                        onChange={(e) => {
                                                                            setLigneAssures(ligneAssures.map((linA, l) => {
                                                                                if(i === l) {
                                                                                    linA.benefList[j].remboursements[k].actes[r].tm = e.target.value;
                                                                                }
                                                                                return linA;
                                                                            }))
                                                                        }}
                                                                        />
                                                                </div>
                                                            </div>

                                                            <div className="col-lg-2">
                                                                <div className="form-group">
                                                                    <label className='mb-1 '>
                                                                        <strong>Montant à payer</strong>
                                                                    </label>
                                                                    <input type='number' className='form-control'
                                                                        value={acte.montantPaye}
                                                                        required
                                                                        disabled={!remboursement.prestataireExecId}
                                                                        onChange={(e) => {
                                                                            setLigneAssures(ligneAssures.map((linA, l) => {
                                                                                if(i === l) {
                                                                                    linA.benefList[j].remboursements[k].actes[r].montantPaye = e.target.value;
                                                                                }
                                                                                return linA;
                                                                            }))
                                                                        }}
                                                                        />
                                                                </div>
                                                            </div>

                                                            <div className="col-lg-2">
                                                                <div className="form-group">
                                                                    <label className='mb-1 '>
                                                                        <strong>{t('appointment.reason')} Exclusion</strong>
                                                                    </label>
                                                                    <Select
                                                                        className="select-search"
                                                                        value={motifExclusion.filter(cat => {return cat.nameFr === acte.motifExclu})
                                                                        .map(elt => {return {label:elt.nameFr,value:elt.id}})}
                                                                        options={motifExclusion.map(opt => {return {label:opt.nameFr,value:opt.id}})}
                                                                         placeholder={t('common.choose')}
                                                                        search
                                                                        isRequired
                                                                        onChange={(val) => {
                                                                            setLigneAssures(ligneAssures.map((linA, l) => {
                                                                                console.log("Setting the default value");
                                                                                if(i === l) {
                                                                                    linA.benefList[j].remboursements[k].actes[r].motifExclu = val.label;
                                                                                }
                                                                                return linA;
                                                                            }))
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>

                                                            <div className="col-lg-1">
                                                                <button type="button" className="btn btn-danger shadow btn-xs sharp mx-1"
                                                                    onClick={(e) => removeRembActeLine(e, i, j, k, r)}
                                                                >
                                                                    <i className="fa fa-close"></i>
                                                                </button>
                                                            </div>
                                                    </div>

                                                    })}
                                                    
                                                    {remboursement.actes.length === 0 && <div className="d-flex justify-content-end">
                                                        <Button variant="danger" onClick={e => addRembActeLine(e, i, j, k)}>
                                                            Ajouter un acte de consultation
                                                        </Button>
                                                    </div>}
                                                    <div className="border-top mt-2 mb-4"></div>
                                                
                                                {remboursement.details.map((detail, m) => {
                                                    return <>
                                                    <div className="row mb-2 ml-4" key={m}>
                                                        <div className="col-lg-2">
                                                            <div className="form-group">
                                                                <label className='mb-1 '>
                                                                    <strong>Categorie</strong>
                                                                </label>
                                                                <Select
                                                                    className="select-search"
                                                                    value={garanties.filter(cat => {return cat.libelle === detail.categorieName})
                                                                    .map(elt => {return {label: elt.libelle, value: elt.id}})}
                                                                    options={garanties.filter(cat => {return cat.id !== remboursement.categorieId})
                                                                    .map(elt => {return {label: elt.libelle, value: elt.id}})}
                                                                     placeholder={t('common.choose')}
                                                                    search
                                                                    isRequired
                                                                    onChange={(val) => {
                                                                        setLigneAssures(ligneAssures.map((linA, l) => {
                                                                            if(i === l) {
                                                                                linA.benefList[j].remboursements[k].details[m].categorieId = val.value;
                                                                                linA.benefList[j].remboursements[k].details[m].categorieName = val.label;
                                                                            }
                                                                            return linA;
                                                                        }))
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-2">
                                                            <div className="form-group">
                                                                <label className='mb-1 '>
                                                                    <strong>{t('common.executant')}</strong>
                                                                </label>
                                                                <TextInput
                                                                    className='form-control custom-text-input border'
                                                                    Component="input"
                                                                    required
                                                                    trigger={""}
                                                                    value={detail.prestataireExecName}
                                                                    placeholder="Entrer un prestataire"
                                                                    onChange={val => {
                                                                        setLigneAssures(ligneAssures.map((linA, l) => {
                                                                            if(i === l) {
                                                                                linA.benefList[j].remboursements[k].details[m].prestataireExecName = val;
                                                                                let provider = providers.filter(prov => {return prov.providerName.trim() === val.trim()})[0];
                                                                                linA.benefList[j].remboursements[k].details[m].prestataireExecId = provider ? provider.id : null;
                                                                            }
                                                                            return linA;
                                                                        }))
                                                                    }}
                                                                    options={providers.map(prov => {return prov.providerName})}
                                                                />
                                                            </div>
                                                        </div>
                                                        
                                                        <div className="col-lg-2">
                                                            <div className="form-group">
                                                                <label className='mb-1 '>
                                                                    <strong>Montant réclamé</strong>
                                                                </label>
                                                                <input type='number' className='form-control'
                                                                    value={detail.montant}
                                                                    required
                                                                    onChange={(e) => {
                                                                        setLigneAssures(ligneAssures.map((linA, l) => {
                                                                            if(i === l) {
                                                                                linA.benefList[j].remboursements[k].details[m].montant = e.target.value;
                                                                                calculateSums(l, j);
                                                                            }
                                                                            return linA;
                                                                        }))
                                                                    }}
                                                                    />
                                                            </div>
                                                        </div>

                                                        <div className="col-lg-2">
                                                            <div className="form-group">
                                                                <label className='mb-1 '>
                                                                    <strong>Montant exclu</strong>
                                                                </label>
                                                                <input type='number' className='form-control'
                                                                    value={detail.montantExclu}
                                                                    required
                                                                    onChange={(e) => {
                                                                        setLigneAssures(ligneAssures.map((linA, l) => {
                                                                            if(i === l) {
                                                                                linA.benefList[j].remboursements[k].details[m].montantExclu = e.target.value;
                                                                            }
                                                                            return linA;
                                                                        }))
                                                                        if(e.target.value > 0 && detail.actes.length === 0) {
                                                                            addActeLine(e, i, j, k, m)
                                                                        }
                                                                    }}
                                                                    />
                                                                {detail.montantExclu > 0 && <small className="text-warning">
                                                                    veuillez préciser au moins 1 acte
                                                                </small>}
                                                            </div>
                                                        </div>

                                                        <div className="col-lg-2">
                                                            <div className="form-group">
                                                                <label className='mb-1 '>
                                                                    <strong>{t('appointment.reason')} Exclusion</strong>
                                                                </label>
                                                                <Select
                                                                    className="select-search"
                                                                    value={motifExclusion.filter(cat => {return cat.nameFr === detail.motifExclu})
                                                                    .map(elt => {return {label:elt.nameFr,value:elt.id}})}
                                                                    options={motifExclusion.map(opt => {return {label:opt.nameFr,value:opt.id}})}
                                                                     placeholder={t('common.choose')}
                                                                    search
                                                                    isRequired
                                                                    onChange={(val) => {
                                                                        setLigneAssures(ligneAssures.map((linA, l) => {
                                                                            console.log("Setting the default value");
                                                                            if(i === l) {
                                                                                linA.benefList[j].remboursements[k].details[m].motifExclu = val.label;
                                                                            }
                                                                            return linA;
                                                                        }))
                                                                    }}
                                                                />
                                                                {detail.montantExclu > 0 && !detail.motifExclu && <small className="text-danger">
                                                                    veuillez préciser le motif
                                                                </small>}
                                                            </div>
                                                        </div>

                                                        <div className="col-lg-2">
                                                            <div className="form-group">
                                                                <label className='mb-1 '>
                                                                    <strong>Date de soin</strong>
                                                                </label>
                                                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                                    <DateTimePicker
                                                                        autoOk
                                                                        label=""
                                                                        clearable
                                                                        format="dd/MM/yyyy hh:mm"
                                                                        value={detail.date}
                                                                        disableFuture
                                                                        required
                                                                        onChange={(val) => {
                                                                            setLigneAssures(ligneAssures.map((linA, l) => {
                                                                                if(i === l) {
                                                                                    linA.benefList[j].remboursements[k].details[m].date = val;
                                                                                }
                                                                                return linA;
                                                                            }))
                                                                        }}
                                                                    />
                                                                </MuiPickersUtilsProvider>
                                                            </div>
                                                        </div>

                                                        {detail.files.length > 0 && remboursement.files[0] && <div className="col-lg-2">
                                                            <Button
                                                                as='a'
                                                                variant='success light'
                                                                href={detail.files[0]}
                                                                target="_blank"
                                                                className='btn-card mt-3 mr-3'
                                                                >
                                                                <i className="la la-file-download"></i>
                                                                facture
                                                            </Button>
                                                        </div>}
                                                </div>
                                                                        
                                                    {detail.actes.map((acte, n) => {
                                                        return <div className="row mb-2 ml-5" key={n}>
                                                            <div className="col-lg-2">
                                                                <div className="form-group">
                                                                    <label className='mb-1 '>
                                                                        {!detail.categorieName.toLocaleLowerCase().includes("pharmacie") &&
                                                                            <strong>{t('provider.acte')}</strong>
                                                                        }
                                                                        {detail.categorieName.toLocaleLowerCase().includes("pharmacie") &&
                                                                            <strong>Medicament</strong>
                                                                        }
                                                                    </label>
                                                                    {!detail.categorieName.toLocaleLowerCase().includes("pharmacie") && <Select
                                                                        className="select-search"
                                                                        value={{label: acte.acteName, value: acte.acteId}}
                                                                        options={actes.map(elt => {return {label: elt.nameFr, value: elt.id}})}
                                                                         placeholder={t('common.choose')}
                                                                        search
                                                                        isRequired
                                                                        onInputChange={(val) => {
                                                                            console.log(val);
                                                                            if(val.length >= 3){
                                                                                dispatch(searchActesAction(val));
                                                                            }
                                                                        }}
                                                                        onChange={(val) => {
                                                                            console.log("Inside the wrong event listener");
                                                                            setLigneAssures(ligneAssures.map((linA, l) => {
                                                                                if(i === l) {
                                                                                    linA.benefList[j].remboursements[k].details[m].actes[n].acteId = val.value;
                                                                                    linA.benefList[j].remboursements[k].details[m].actes[n].acteName = val.label;

                                                                                    //calculateSubAmounts(val.value, detail.prestataireExecId, line.assureId, acte.montantFacture, i, j, k, m, n);
                                                                                    let data = {
                                                                                        providerId: detail.prestataireExecId,
                                                                                        patientId: line.assureId,
                                                                                        acteId: val.value
                                                                                    }
                                                                                    dispatch(calculationRembBaseAction(data, i+','+j+','+k+','+m+','+n));
                                                                                }
                                                                                return linA;
                                                                            }))
                                                                        }}
                                                                    />}
                                                                    {detail.categorieName.toLocaleLowerCase().includes("pharmacie") && <Select
                                                                        className="select-search"
                                                                        value={{label: acte.acteName, value: acte.acteId, amount: acte.amount}}
                                                                        options={drugs.map(elt => {return {label: elt.name, value: elt.id, amount: elt.amount}})}
                                                                         placeholder={t('common.choose')}
                                                                        search
                                                                        isRequired
                                                                        onInputChange={(val) => {
                                                                            console.log(val);
                                                                            if(val.length >= 3){
                                                                                dispatch(searchDrugsAction(val));
                                                                            }
                                                                        }}
                                                                        onChange={(val) => {
                                                                            console.log("Inside the correct event listener");
                                                                            setLigneAssures(ligneAssures.map((linA, l) => {
                                                                                if(i === l) {
                                                                                    linA.benefList[j].remboursements[k].details[m].actes[n].acteId = val.value;
                                                                                    linA.benefList[j].remboursements[k].details[m].actes[n].acteName = val.label;
                                                                                    linA.benefList[j].remboursements[k].details[m].actes[n].acteAmount = val.amount;

                                                                                    //calculateTM(detail.categorieId, line.assureId, i, j, k, m, n);
                                                                                    let data = {
                                                                                        patientId: line.assureId
                                                                                    }
                                                                                    dispatch(calculationTMAction(data, i+','+j+','+k+','+m+','+n));
                                                                                }
                                                                                return linA;
                                                                            }))
                                                                        }}
                                                                    />}
                                                                    {!detail.categorieName.toLocaleLowerCase().includes("pharmacie") && !detail.prestataireExecId && <small className="text-warning">
                                                                        prestaire non conventionné, calcul impossible
                                                                    </small>}
                                                                </div>
                                                            </div>

                                                            <div className="col-lg-2">
                                                                <div className="form-group">
                                                                    <label className='mb-1 '>
                                                                        <strong>Montant Facture</strong>
                                                                    </label>
                                                                    {!detail.categorieName.toLocaleLowerCase().includes("pharmacie") && <input type='number' className='form-control'
                                                                        value={acte.montantFacture}
                                                                        required
                                                                        onChange={(e) => {
                                                                            setLigneAssures(ligneAssures.map((linA, l) => {
                                                                                if(i === l) {
                                                                                    linA.benefList[j].remboursements[k].details[m].actes[n].montantFacture = e.target.value;

                                                                                    /*if(e.target.value.length > 0){
                                                                                        console.log("this field has a value");
                                                                                        calculateSubAmounts(acte.acteId, detail.prestataireExecId, line.assureId, e.target.value, i, j, k, m, n);
                                                                                    }*/
                                                                                }
                                                                                return linA;
                                                                            }))
                                                                        }}
                                                                        />}
                                                                    {detail.categorieName.toLocaleLowerCase().includes("pharmacie") && <input type='number' className='form-control'
                                                                        value={acte.montantFacture}
                                                                        required
                                                                        onChange={(e) => {
                                                                            setLigneAssures(ligneAssures.map((linA, l) => {
                                                                                if(i === l) {
                                                                                    linA.benefList[j].remboursements[k].details[m].actes[n].montantFacture = e.target.value;
                                                                                    linA.benefList[j].remboursements[k].details[m].actes[n].baseRemb = 
                                                                                        Math.min((e.target.value * acte.quantite), (acte.acteAmount * acte.quantite));
                                                                                    linA.benefList[j].remboursements[k].details[m].actes[n].montantPaye = 
                                                                                        Math.min((e.target.value * acte.quantite), (acte.acteAmount * acte.quantite)) - acte.tm;
                                                                                }
                                                                                return linA;
                                                                            }))
                                                                        }}
                                                                        />}
                                                                </div>
                                                            </div>
                                                            
                                                            <div className="col-lg-2">
                                                                <div className="form-group">
                                                                    <label className='mb-1 '>
                                                                        <strong>Quantité</strong>
                                                                    </label>
                                                                    {!detail.categorieName.toLocaleLowerCase().includes("pharmacie") &&<input type='number' className='form-control'
                                                                        value={acte.quantite}
                                                                        required
                                                                        onChange={(e) => {
                                                                            setLigneAssures(ligneAssures.map((linA, l) => {
                                                                                if(i === l) {
                                                                                    linA.benefList[j].remboursements[k].details[m].actes[n].quantite = e.target.value;
                                                                                    //calculateSubAmounts(acte.acteId, detail.prestataireExecId, line.assureId, e.target.value, i, j, k, m, n);
                                                                                }
                                                                                return linA;
                                                                            }))
                                                                        }}
                                                                        />}
                                                                    {detail.categorieName.toLocaleLowerCase().includes("pharmacie") &&<input type='number' className='form-control'
                                                                        value={acte.quantite}
                                                                        required
                                                                        onChange={(e) => {
                                                                            setLigneAssures(ligneAssures.map((linA, l) => {
                                                                                if(i === l) {
                                                                                    linA.benefList[j].remboursements[k].details[m].actes[n].quantite = e.target.value;
                                                                                    linA.benefList[j].remboursements[k].details[m].actes[n].baseRemb = 
                                                                                        Math.min((e.target.value * acte.quantite), (acte.acteAmount * acte.quantite));
                                                                                    linA.benefList[j].remboursements[k].details[m].actes[n].montantPaye = 
                                                                                        Math.min((e.target.value * acte.quantite), (acte.acteAmount * acte.quantite)) - acte.tm;
                                                                                }
                                                                                return linA;
                                                                            }))
                                                                        }}
                                                                        />}
                                                                </div>
                                                            </div>

                                                            <div className="col-lg-2">
                                                                <div className="form-group">
                                                                    <label className='mb-1 '>
                                                                        <strong>Base de remb.</strong>
                                                                    </label>
                                                                    <input type='number' className='form-control'
                                                                        value={acte.baseRemb}
                                                                        required
                                                                        disabled={!detail.categorieName.toLocaleLowerCase().includes("pharmacie") && !detail.prestataireExecId}
                                                                        onChange={(e) => {
                                                                            setLigneAssures(ligneAssures.map((linA, l) => {
                                                                                if(i === l) {
                                                                                    linA.benefList[j].remboursements[k].details[m].actes[n].baseRemb = e.target.value;
                                                                                }
                                                                                return linA;
                                                                            }))
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>

                                                            <div className="col-lg-2">
                                                                <div className="form-group">
                                                                    <label className='mb-1 '>
                                                                        <strong>{t('common.tm')}</strong>
                                                                    </label>
                                                                    <input type='number' className='form-control'
                                                                        value={acte.tm}
                                                                        required
                                                                        disabled={!detail.categorieName.toLocaleLowerCase().includes("pharmacie") && !detail.prestataireExecId}
                                                                        onChange={(e) => {
                                                                            setLigneAssures(ligneAssures.map((linA, l) => {
                                                                                if(i === l) {
                                                                                    linA.benefList[j].remboursements[k].details[m].actes[n].tm = e.target.value;
                                                                                }
                                                                                return linA;
                                                                            }))
                                                                        }}
                                                                        />
                                                                </div>
                                                            </div>

                                                            <div className="col-lg-2">
                                                                <div className="form-group">
                                                                    <label className='mb-1 '>
                                                                        <strong>Montant à payer</strong>
                                                                    </label>
                                                                    <input type='number' className='form-control'
                                                                        value={acte.montantPaye}
                                                                        required
                                                                        disabled={!detail.categorieName.toLocaleLowerCase().includes("pharmacie") && !detail.prestataireExecId}
                                                                        onChange={(e) => {
                                                                            setLigneAssures(ligneAssures.map((linA, l) => {
                                                                                if(i === l) {
                                                                                    linA.benefList[j].remboursements[k].details[m].actes[n].montantPaye = e.target.value;
                                                                                }
                                                                                return linA;
                                                                            }))
                                                                        }}
                                                                        />
                                                                </div>
                                                            </div>

                                                            <div className="col-lg-2">
                                                                <div className="form-group">
                                                                    <label className='mb-1 '>
                                                                        <strong>{t('appointment.reason')} Exclusion</strong>
                                                                    </label>
                                                                    <Select
                                                                        className="select-search"
                                                                        value={motifExclusion.filter(cat => {return cat.nameFr === acte.motifExclu})
                                                                        .map(elt => {return {label:elt.nameFr,value:elt.id}})}
                                                                        options={motifExclusion.map(opt => {return {label:opt.nameFr,value:opt.id}})}
                                                                         placeholder={t('common.choose')}
                                                                        search
                                                                        isRequired
                                                                        onChange={(val) => {
                                                                            setLigneAssures(ligneAssures.map((linA, l) => {
                                                                                console.log("Setting the default value");
                                                                                if(i === l) {
                                                                                    linA.benefList[j].remboursements[k].details[m].motifExclu = val.label;
                                                                                }
                                                                                return linA;
                                                                            }))
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>

                                                            <div className="col-lg-1">
                                                                <button type="button" className="btn btn-danger shadow btn-xs sharp mx-1"
                                                                    onClick={(e) => removeActeLine(e, i, j, k, m, n)}
                                                                >
                                                                    <i className="fa fa-close"></i>
                                                                </button>
                                                            </div>
                                                    </div>

                                                    })}
                                                    
                                                    <div className="d-flex justify-content-end">
                                                        <Button variant="danger" onClick={e => addActeLine(e, i, j, k, m)}>
                                                            Ajouter un acte
                                                        </Button>
                                                    </div>
                                                    <div className="border-top mt-2 mb-4"></div>
                                                
                                                </>
                                                })}
                                                <div className="border-top mt-2 mb-4"></div>
                                                </>
                                                })
                                            }
                                            <div className="border-top mt-2 mb-4"></div>
                                            </>
                                            })}
                                            <div className="border-top border-primary mt-2 mb-4"></div>
                                        </>
                                    })}
                                </section>
                                
                                <div className='row justify-content-center mt-4'>
                                    { showLoading && 
                                        <Button variant="primary" disabled>
                                            <Spinner
                                                as="span"
                                                animation="grow"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                            />{' '}
                                            <span className="visually-hidden">{t('common.await-text')}</span>
                                        </Button> }
                                    { !showLoading &&
                                        <div className='col-4'>
                                            <input type='submit' className='btn btn-primary btn-block'/>
                                        </div> }
                                </div>
                        </form>
                    </div>
                 </div>
              </div>
           </div>
        </Fragment>
    )
};

const mapStateToProps = (state) => {
    return {
        beneficiaires: state.contrat.beneficiaires,
        motifExclusion: state.facturation.motifExclusion,
        rembCalculDatas: state.facturation.rembCalculDatas,
        lettreCles: state.provider.lettreCles,
        remboursement: state.facturation.remboursement,
        providers: state.provider.providers,
        beneficiaires: state.contrat.beneficiaires,
        souscripteurs: state.contrat.souscripteurs,
        assureBenefs: state.contrat.assureBenefs,
        actes: state.provider.actesRes,
        actesConsultation: state.consultation.actesConsultation,
        showLoading: state.facturation.showLoading,
        garanties: state.contrat.garanties,
        drugs: state.consultation.drugs,
        diseases: state.consultation.diseases,
        ticketModDatas: state.facturation.ticketModDatas,
        currentUser: currentUser(state)
    };
};

export default withRouter(connect(mapStateToProps)(RemboursementForm));