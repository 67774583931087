import React, {useState} from "react";
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Tag } from 'primereact/tag';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { DataTableBase } from '../../DataTables/DataTableBase';
import { Button as ButtonBootstrap, Modal } from 'react-bootstrap';
import CommentaireModal from './CommentaireModal';
import { STATUSES } from '../../../globals';
import { useTranslation } from "react-i18next";
        

export const ExamenBonsTable = (props) => {
    
    const { t } = useTranslation();
    const [filters, setFilters] = useState(null);
    const [globalFilterValue, setGlobalFilterValue] = useState('');
    const [showCommentModal, setShowCommentModal] = useState(false);
    const [targetExam, setTargetExam] = useState(null);

    const {data, history} = props;

    const initFilters = () => {
        setFilters({
            global: { value: null, matchMode: FilterMatchMode.CONTAINS },
            acteName: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            executedBy: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            executeDate: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
            status: { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
        });
        setGlobalFilterValue("");
    };

    const renderHeader = () => {
        return (
            <div className="flex justify-content-between">
                <Button type="button" icon="pi pi-filter-slash" label={t('common.reinit')} outlined onClick={clearFilter} />
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder={t('common.search')} />
                </span>
            </div>
        );
    };

    const clearFilter = () => {
        initFilters();
    };

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };

        _filters['global'].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
    };

    const setColumns = () => {
        return [
            {name: 'acteName', headerName: t('common.exam'), filterPlaceholder: t('common.filter-exam'), style: { minWidth: '12rem' }, filter: true, bodyTemplate: null},
            {name: 'executedBy', headerName: t('common.executed-center'), filterPlaceholder: t('common.filter-center'), style: { minWidth: '12rem' }, filter: true, bodyTemplate: null},
            {name: 'executeDate', filterField: 'date', dataType: 'date', headerName: t('common.executed-on'), headerName: t('common.dateFilterText'), style: { minWidth: '6rem' }, filter: true, bodyTemplate: dateBodyTemplate, filterElement: dateFilterTemplate},
            {name: 'status', headerName: t('common.status'), filterPlaceholder: t('common.filter-status'), style: { minWidth: '12rem' }, filter: true, bodyTemplate: statusBodyTemplate, filterElement: statusFilterTemplate},
            {name: 'examenResult[0].resultUrl', headerName: t('common.results'), filterPlaceholder: '', style: { minWidth: '12rem' }, filter: false, bodyTemplate: resultsBodyTemplate},
            {name: 'actions', headerName: 'Actions', filterPlaceholder: '', style: { minWidth: '6rem'}, filter: false, bodyTemplate: getActionsTemplate},
        ]
    };

    const getActionsTemplate = (rowData) => {
        return <div style={{ width: '100%', display: 'flex', justifyContent: 'center'}}>
            {rowData.status === STATUSES.exectuted &&
                <ButtonBootstrap
                    as='a'
                    variant='info light'
                    href='#'
                    className='btn-card mt-3'
                    onClick={(e) => {
                        e.preventDefault();
                        setShowCommentModal(true);
                        setTargetExam(rowData);
                    }}
                >
                    {t('accord.comment')}
                </ButtonBootstrap>
            }
        </div>
    };

    const statusFilterTemplate = (options) => {
        return <Dropdown value={options.value} options={data.map(exam => {
            return exam.status
        }).reduce(function (acc, curr) {
            if (!acc.includes(curr))
                acc.push(curr);
            return acc;
        }, [])} onChange={(e) => options.filterCallback(e.value, options.index)} itemTemplate={statusItemTemplate}  placeholder={t('common.choose')} className="p-column-filter" showClear />;
    };

    const dateFilterTemplate = (options) => {
        return <Calendar value={options.value} onChange={(e) => options.filterCallback(e.value, options.index)} dateFormat="dd/mm/yy" placeholder="dd/mm/yyyy" mask="99/99/9999" />;
    };

    const dateBodyTemplate = (rowData) => {
        return formatDate(rowData.date);
    };

    const statusBodyTemplate = (rowData) => {
        return <Tag value={t('statuses.'+rowData.status)} severity={getSeverity(rowData.status)} />;
    };

    const resultsBodyTemplate = (rowData) => {
        return <ButtonBootstrap
            as='a'
            variant='success light'
            href={rowData.examenResult[0]?.resultUrl}
            target="_blank"
            className='btn-card mt-3 mr-3'
        >
            <i className="la la-file-download"></i>
        </ButtonBootstrap>;
    };

    const statusItemTemplate = (option) => {
        return <Tag value={option} severity={getSeverity(option)} />;
    };

    const getSeverity = (status) => {
        switch (status) {
            case STATUSES.rejeted:
                return 'danger';
            
            case STATUSES.pending:
                return 'warning';

            default:
                return 'success';
        }
    };

    const formatDate = (value) => {
        return value ? value.toLocaleDateString('fr-FR', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric'
        }) : '';
    };

    const manageCommentModal = () => {
        setShowCommentModal(false);
    }

    return (
        <>
            <DataTableBase 
                data={data.map(bon => {
                    bon.executeDate = new Date(bon.executeDate);
                    return bon;
                })}
                size="small"
                emptyMessage={t('common.noExam')} 
                filters={filters}
                globalFiltersFields={['acteName', 'status', 'executeDate', 'executedBy']}
                header={renderHeader()}
                columns={setColumns()}
                rows={10} 
                loading={props.showLoading}
            />
            <Modal
                className="fade bd-example-modal-lg"
                show={showCommentModal}
                size="lg"
            >
                <CommentaireModal 
                    showModal={showCommentModal}
                    commentaire={targetExam?.examenResult[0]?.resultDescription}
                    sentive={targetExam?.examenResult[0]?.isSensitive} 
                    examen={targetExam}
                    manageCommentModal={manageCommentModal}
                    history={history}
                />
            </Modal>
        </>
    )
};

export default ExamenBonsTable;