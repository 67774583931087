import {
    LIST_CONFIRMED_ACTION,
    LIST_FAILED_ACTION,
    VALIDATE_ACCORD_CONFIRMED_ACTION,
    VALIDATE_ACCORD_FAILED_ACTION,
    REJECT_ACCORD_CONFIRMED_ACTION,
    REJECT_ACCORD_FAILED_ACTION,
    SEND_MESSAGE_CONFIRMED_ACTION,
    SEND_MESSAGE_NOTIFS_FAILED_ACTION,
    GET_ACCORD_CONFIRMED_ACTION,
    GET_ACCORD_NOTIFS_FAILED_ACTION,
    LOADING_TOGGLE_ACTION,
    GET_PROFORMAS_CONFIRMED_ACTION,
    GET_PROFORMAS_FAILED_ACTION,
    GET_PROFORMA_CONFIRMED_ACTION,
    GET_PROFORMA_FAILED_ACTION,
    VALIDATE_PROFORMA_CONFIRMED_ACTION,
    VALIDATE_PROFORMA_FAILED_ACTION,
    REJECT_PROFORMA_CONFIRMED_ACTION,
    REJECT_PROFORMA_FAILED_ACTION,
    VALIDATE_PROFORMA_LINE_CONFIRMED_ACTION,
    VALIDATE_PROFORMA_LINE_FAILED_ACTION,
    REJECT_PROFORMA_LINE_CONFIRMED_ACTION,
    REJECT_PROFORMA_LINE_FAILED_ACTION,
    RESTORE_PROFORMA_CONFIRMED_ACTION,
    RESTORE_PROFORMA_FAILED_ACTION,
    GET_PROFORMAS_KINE_CONFIRMED_ACTION,
    GET_PROFORMAS_KINE_FAILED_ACTION,
    GET_PROFORMA_KINE_CONFIRMED_ACTION,
    GET_PROFORMA_KINE_FAILED_ACTION,
    VALIDATE_PROFORMA_KINE_CONFIRMED_ACTION,
    VALIDATE_PROFORMA_KINE_FAILED_ACTION,
    REJECT_PROFORMA_KINE_CONFIRMED_ACTION,
    REJECT_PROFORMA_KINE_FAILED_ACTION,
    VALIDATE_PROFORMA_KINE_LINE_CONFIRMED_ACTION,
    VALIDATE_PROFORMA_KINE_LINE_FAILED_ACTION,
    REJECT_PROFORMA_KINE_LINE_CONFIRMED_ACTION,
    REJECT_PROFORMA_KINE_LINE_FAILED_ACTION,
    RESTORE_PROFORMA_KINE_CONFIRMED_ACTION,
    RESTORE_PROFORMA_KINE_FAILED_ACTION
} from '../actions/AccordPreaActions';

const initialState = {
    accordPreas: [],
    accordPrea: {},
    proformas: [],
    proforma: {},
    proformasKine: [],
    proformaKine: {},
    errorMessage: '',
    successMessage: '',
    showLoading: false,
};

export const AccordPreaReducer = (state = initialState, action) => {
    switch (action.type) {
        case LIST_CONFIRMED_ACTION:
            return {
                ...state,
                accordPreas: action.payload,
                errorMessage: '',
                successMessage: 'AccordPrea listing Successfully Completed',
                showLoading: false,
            };
        case LIST_FAILED_ACTION:
            return {
                ...state,
                errorMessage: action.payload,
                successMessage: '',
                showLoading: false,
            };
        case VALIDATE_ACCORD_CONFIRMED_ACTION:
            return {
                ...state,
                errorMessage: '',
                successMessage: 'Accord Prea validation Successfully Completed',
                showLoading: false,
            };
        case VALIDATE_ACCORD_FAILED_ACTION:
            return {
                ...state,
                errorMessage: action.payload,
                successMessage: '',
                showLoading: false,
            };
        case REJECT_ACCORD_CONFIRMED_ACTION:
            return {
                ...state,
                errorMessage: '',
                successMessage: 'Accord Prea rejection Successfully Completed',
                showLoading: false,
            };
        case REJECT_ACCORD_FAILED_ACTION:
            return {
                ...state,
                errorMessage: action.payload,
                successMessage: '',
                showLoading: false,
            };
        case SEND_MESSAGE_CONFIRMED_ACTION:
            return {
                ...state,
                accordPrea: {...state.accordPrea, accordPreableDisccusions: [...state.accordPrea.accordPreableDisccusions, action.payload]},
                errorMessage: '',
                successMessage: 'Message sending Successfully Completed',
                showLoading: false,
            };
        case SEND_MESSAGE_NOTIFS_FAILED_ACTION:
            return {
                ...state,
                errorMessage: action.payload,
                successMessage: '',
                showLoading: false,
            };
        case GET_ACCORD_CONFIRMED_ACTION:
            return {
                ...state,
                accordPrea: action.payload,
                errorMessage: '',
                successMessage: 'Get AccordPrea Successfully Completed',
                showLoading: false,
            };
        case GET_ACCORD_NOTIFS_FAILED_ACTION:
            return {
                ...state,
                errorMessage: action.payload,
                successMessage: '',
                showLoading: false,
            };
        case GET_PROFORMAS_CONFIRMED_ACTION:
            return {
                ...state,
                proformas: action.payload,
                errorMessage: '',
                successMessage: 'Get AccordPrea Successfully Completed',
                showLoading: false,
            };
        case GET_PROFORMAS_FAILED_ACTION:
            return {
                ...state,
                errorMessage: action.payload,
                successMessage: '',
                showLoading: false,
            };
        case GET_PROFORMA_CONFIRMED_ACTION:
            return {
                ...state,
                proforma: action.payload,
                errorMessage: '',
                successMessage: 'Get AccordPrea Successfully Completed',
                showLoading: false,
            };
        case GET_PROFORMA_FAILED_ACTION:
            return {
                ...state,
                errorMessage: action.payload,
                successMessage: '',
                showLoading: false,
            };
        case VALIDATE_PROFORMA_CONFIRMED_ACTION:
            return {
                ...state,
                errorMessage: '',
                successMessage: 'Proforma validation Successfully Completed',
                showLoading: false,
            };
        case VALIDATE_PROFORMA_FAILED_ACTION:
            return {
                ...state,
                errorMessage: action.payload,
                successMessage: '',
                showLoading: false,
            };
        case REJECT_PROFORMA_CONFIRMED_ACTION:
            return {
                ...state,
                errorMessage: '',
                successMessage: 'Proforma rejection Successfully Completed',
                showLoading: false,
            };
        case REJECT_PROFORMA_FAILED_ACTION:
            return {
                ...state,
                errorMessage: action.payload,
                successMessage: '',
                showLoading: false,
            };
        case VALIDATE_PROFORMA_LINE_CONFIRMED_ACTION:
            return {
                ...state,
                errorMessage: '',
                successMessage: 'Proforma line validation Successfully Completed',
                showLoading: false,
            };
        case VALIDATE_PROFORMA_LINE_FAILED_ACTION:
            return {
                ...state,
                errorMessage: action.payload,
                successMessage: '',
                showLoading: false,
            };
        case REJECT_PROFORMA_LINE_CONFIRMED_ACTION:
            return {
                ...state,
                errorMessage: '',
                successMessage: 'Proforma line rejection Successfully Completed',
                showLoading: false,
            };
        case REJECT_PROFORMA_LINE_FAILED_ACTION:
            return {
                ...state,
                errorMessage: action.payload,
                successMessage: '',
                showLoading: false,
            };
        case RESTORE_PROFORMA_CONFIRMED_ACTION:
            return {
                ...state,
                errorMessage: '',
                successMessage: 'Proforma restoration Successfully Completed',
                showLoading: false,
            };
        case RESTORE_PROFORMA_FAILED_ACTION:
            return {
                ...state,
                errorMessage: action.payload,
                successMessage: '',
                showLoading: false,
            };



        case GET_PROFORMAS_KINE_CONFIRMED_ACTION:
            return {
                ...state,
                proformasKine: action.payload,
                errorMessage: '',
                successMessage: 'Get AccordPrea Successfully Completed',
                showLoading: false,
            };
        case GET_PROFORMAS_KINE_FAILED_ACTION:
            return {
                ...state,
                errorMessage: action.payload,
                successMessage: '',
                showLoading: false,
            };
        case GET_PROFORMA_KINE_CONFIRMED_ACTION:
            return {
                ...state,
                proformaKine: action.payload,
                errorMessage: '',
                successMessage: 'Get AccordPrea Successfully Completed',
                showLoading: false,
            };
        case GET_PROFORMA_KINE_FAILED_ACTION:
            return {
                ...state,
                errorMessage: action.payload,
                successMessage: '',
                showLoading: false,
            };
        case VALIDATE_PROFORMA_KINE_CONFIRMED_ACTION:
            return {
                ...state,
                errorMessage: '',
                successMessage: 'Proforma validation Successfully Completed',
                showLoading: false,
            };
        case VALIDATE_PROFORMA_KINE_FAILED_ACTION:
            return {
                ...state,
                errorMessage: action.payload,
                successMessage: '',
                showLoading: false,
            };
        case REJECT_PROFORMA_KINE_CONFIRMED_ACTION:
            return {
                ...state,
                errorMessage: '',
                successMessage: 'Proforma rejection Successfully Completed',
                showLoading: false,
            };
        case REJECT_PROFORMA_KINE_FAILED_ACTION:
            return {
                ...state,
                errorMessage: action.payload,
                successMessage: '',
                showLoading: false,
            };
        case VALIDATE_PROFORMA_KINE_LINE_CONFIRMED_ACTION:
            return {
                ...state,
                errorMessage: '',
                successMessage: 'Proforma line validation Successfully Completed',
                showLoading: false,
            };
        case VALIDATE_PROFORMA_KINE_LINE_FAILED_ACTION:
            return {
                ...state,
                errorMessage: action.payload,
                successMessage: '',
                showLoading: false,
            };
        case REJECT_PROFORMA_KINE_LINE_CONFIRMED_ACTION:
            return {
                ...state,
                errorMessage: '',
                successMessage: 'Proforma line rejection Successfully Completed',
                showLoading: false,
            };
        case REJECT_PROFORMA_KINE_LINE_FAILED_ACTION:
            return {
                ...state,
                errorMessage: action.payload,
                successMessage: '',
                showLoading: false,
            };
        case RESTORE_PROFORMA_KINE_CONFIRMED_ACTION:
            return {
                ...state,
                errorMessage: '',
                successMessage: 'Proforma restoration Successfully Completed',
                showLoading: false,
            };
        case RESTORE_PROFORMA_KINE_FAILED_ACTION:
            return {
                ...state,
                errorMessage: action.payload,
                successMessage: '',
                showLoading: false,
            };


        case LOADING_TOGGLE_ACTION:
            return {
                ...state,
                showLoading: true,
            };
        default:
          return state;
    };
};