import React, { useState, useEffect, useRef } from 'react';
import {Spinner, Button} from 'react-bootstrap';
import { useParams, withRouter } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import { Steps } from 'primereact/steps';


const PrestationDetails = (props) => {

};

const mapStateToProps = (state) => {
    return {
        errorMessage: state.clientAuth.errorMessage,
        successMessage: state.clientAuth.successMessage,
        showLoading: state.provider.showLoading,
        currentUser: currentUser(state)
    };
};

export default withRouter(connect(mapStateToProps)(PrestationDetails));