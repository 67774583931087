import React, { Fragment,useEffect } from 'react'
import { connect, useDispatch } from 'react-redux';
import { Row, Col, Card, Tab, Nav } from 'react-bootstrap'
import Menu from './Menu';
import Role from './Role';
import Profil from './Profil';
import User from './User';
import Action from './Action';
import MenuRoleAction from './MenuRoleAction';
//import '../../../../css/SelectStyle.css'
import { useTranslation } from "react-i18next";
import {getUsersAction,getUserParameterAction, getActionsAction, getRolesAction, getMenusAction} from '../../../store/actions/UserAction';
import { currentUser } from "../../../store/selectors/CurrentUserSelector";

const UserProfil = (props) => {
    const { t } = useTranslation();
    let errorsObj = { name: '', code: '' };
    const dispatch = useDispatch();
    const header =["Nom","code","Date de création","Action"]

    useEffect(() => {
        dispatch(getUsersAction());    
        dispatch(getUserParameterAction());  
        dispatch(getActionsAction());    
        dispatch(getRolesAction());    
        dispatch(getMenusAction());  
    }, []);

    const canDo = (menuCode, actionCode) => {
        let theMenu = props.currentUser.menus?.find(menu => menu.code === menuCode);
        if(!theMenu){
            return false;
        }else{
            return theMenu.actionMenus?.filter(action => action.code === actionCode).length > 0;
        }
    }
    
    const hasMenu = (menuCode) => {
        return props.currentUser.menus?.filter(menu => menu.code === menuCode).length > 0;
    }

    const tabData = [
        {
            label: t("User"),
            name: 'user',
            icon: 'person',
            content: <User/>
        },
        {
            label: t("Profil"),
            name: 'profil',
            icon: 'user',
            content: <Profil/>
        },
        {
            label: t("Role"),
            name: 'role',
            icon: 'role',
            content: <Role/>
        },
        {
            label: t("Menu"),
            name: 'menu',
            icon: 'menu',
            content: <Menu/>
        },
        {
            label: t("Action"),
            name: 'action',
            icon: 'action',
            content: <Action/>
        },
        /*{
            name: t("MenuRoleAction"),
            icon: 'action',
            content: <MenuRoleAction/>
        },*/
    ]

    const hasAccess = (type) => {
        switch(type){
            case 'user':
                return hasMenu('MENPARAMUSER') && canDo('MENPARAMUSER', 'LISTUSER')
            case 'profil':
                return hasMenu('MENPARAMUSER') && canDo('MENPARAMUSER', 'LISTPROFIL')
            case 'role':
                return hasMenu('MENPARAMUSER') && canDo('MENPARAMUSER', 'LISTROLE')
            case 'menu':
                return hasMenu('MENPARAMUSER') && canDo('MENPARAMUSER', 'LISTMENU')
            case 'action':
                return hasMenu('MENPARAMUSER') && canDo('MENPARAMUSER', 'LISTACTION')
            default:
                return false
            
        }
    }

    return (
        <Fragment>
            <Row>
                <Col xl={12}>
                    <Card>
                        <Card.Header>
                            <Card.Title>{t('user.parameter')}</Card.Title>
                        </Card.Header>
                        <Card.Body>
                            {/* <!-- Nav tabs --> */}
                            <div className='custom-tab-1'>
                                <Tab.Container defaultActiveKey={tabData[0].name.toLowerCase()}>
                                    <Nav as='ul' className='nav-tabs'>
                                        {tabData.map((data, i) => (
                                            <Nav.Item as='li' key={i}>
                                                <Nav.Link disabled={!hasAccess(data.name.toLowerCase())} eventKey={data.name.toLowerCase()}>
                                                    <i className={`la la-${data.icon} mr-2`} />
                                                    {data.label}
                                                </Nav.Link>
                                            </Nav.Item>
                                        ))}
                                    </Nav>
                                    <Tab.Content className='pt-4'>
                                        {tabData.map((data, i) => (
                                            <Tab.Pane eventKey={data.name.toLowerCase()} key={i}>
                                                <div>{data.content}</div>
                                            </Tab.Pane>
                                        ))}
                                    </Tab.Content>
                                </Tab.Container>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Fragment>
    )
}
const mapStateToProps = (state) => {
    return {
        errorMessage: state.user.errorMessage,
        successMessage: state.user.successMessage,
        showLoading: state.user.showLoading,
        currentUser: currentUser(state) 
        //admin: state.admin.administration,
    };
};
export default connect(mapStateToProps)(UserProfil);