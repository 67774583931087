import React, {useState,useEffect } from 'react'
import { connect, useDispatch } from 'react-redux';
import { Spinner, Button, Modal, ListGroup } from "react-bootstrap";
import BasicDatatable from '../DataTable';
import { Link } from "react-router-dom";
import {
    getActesAction,
    getActePagesAction,
    startLoader,
    createActeAction,
    importActeFileUploadAction,
    //getGarantiesAction,
    updateActeAction,
    getProvidersTypesAction,
    deleteDataAction
} from '../../../../store/actions/ProviderActions';
import { getGarantiesAction, getActesProdAction } from '../../../../store/actions/ContratActions';
import { searchActesAction } from '../../../../store/actions/ProviderActions';
import { currentUser } from "../../../../store/selectors/CurrentUserSelector";
import Select from 'react-select';
import swal from 'sweetalert';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { InputText } from 'primereact/inputtext';
import { Button as PButton } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { DataTableBase } from '../../DataTables/DataTableBase';
import { useTranslation } from "react-i18next";
import './Actes.css';

const Acte = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    let errorsObj = { name: '', code: '' };
    const [nameFr, setNameFr] = useState('');
    const [nameEn, setNameEn] = useState('');
    const [file, setFile] = useState(null);
    const [code, setCode] = useState('');
    const [coefLettre, setCoefLettre] = useState(0);
    const [acteId, setActeId] = useState('');
    const [typeActeId, setTypeActeId] = useState('');
    const [lettreCleId, setLettreCleId] = useState('');
    const [garantieId, setGarantieId] = useState('');
    const [codeGarantie, setCodeGarantie] = useState('');
    const [garantieName, setLibelleGarantie] = useState('');
    const [acteProdCode, setActeProdCode] = useState('');
    const [acteProdName, setActeProdName] = useState('');
    const [providerTypeId, setProviderTypeId] = useState([]);
    const [isExclu, setIsExclu] = useState(false);
    const [isAccord, setIsAccord] = useState(false);
    const [isAmbulatoire, setIsAmbulatoire] = useState(true);
    const [isHospitalisation, setIsHospitalisation] = useState(true);
    const [isMultiple, setIsMultiple] = useState(false);
    const [type, setType] = useState("");
    const [errors, setErrors] = useState(errorsObj);
    const [search, setSearch] = useState('');
    //Modal box
    const [addCard, setAddCard] = useState(false);
    const [updateMode, setUpdateMode] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);

    const [filters, setFilters] = useState(null);
    const [globalFilterValue, setGlobalFilterValue] = useState('');
    const [lazyState, setlazyState] = useState({
        first: 0,
        rows: 10,
        page: 1,
        sortField: null,
        sortOrder: null,
        filters: {
            global: { value: null, matchMode: FilterMatchMode.CONTAINS },
            code: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            nameFr: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            familyText: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            garantieName: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            acteProdName: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            createdAt: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
        }
    });

    const initFilters = () => {
        setFilters({
            global: { value: null, matchMode: FilterMatchMode.CONTAINS },
            code: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            nameFr: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            familyText: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            garantieName: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            acteProdName: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            createdAt: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
        });
        setGlobalFilterValue("");
    };

    const renderHeader = () => {
        return (
            <div className="flex justify-content-between">
                <PButton type="button" icon="pi pi-filter-slash"  label={t('common.reinit')} outlined onClick={clearFilter} />
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText value={globalFilterValue} onChange={(e) => onGlobalFilterChange(e)}  placeholder={t('common.search')} />
                </span>
            </div>
        );
    };

    const clearFilter = () => {
        initFilters();
    };

    const setColumns = () => {
        return [
            {name: 'code', headerName: "Code", filterPlaceholder: 'Filtrer par code', style: { minWidth: '12rem' }, filter: true, bodyTemplate: null},
            {name: 'nameFr', headerName: t('common.name'), filterPlaceholder: t('common.filter-name'), style: { minWidth: '12rem' }, filter: true, bodyTemplate: null},
            {name: 'familyText', headerName: t('common.family'), filterPlaceholder: 'Filtrer par famille', style: { minWidth: '12rem' }, filter: true, bodyTemplate: null},
            {name: 'garantieName', headerName: t('common.garanty'), filterPlaceholder: 'Filtrer par garantie', style: { minWidth: '12rem' }, filter: true, bodyTemplate: null},
            {name: 'acteProdName', headerName: t('provider.acte-production'), filterPlaceholder: 'Filtrer par acte de production', style: { minWidth: '12rem' }, filter: true, bodyTemplate: null},
            {name: 'formule', headerName: "Formule", style: { minWidth: '12rem' }, filter: false, bodyTemplate: null},
            {name: 'createdAt', filterField: 'createdAt', dataType: 'date', headerName: 'Créé le', filterPlaceholder: t('facturation.create-date-filter'), style: { minWidth: '6rem' }, filter: true, bodyTemplate: datePaiementBodyTemplate, filterElement: dateFilterTemplate},
            {name: 'actions', headerName: 'Actions', filterPlaceholder: '', style: { minWidth: '6rem'}, filter: false, bodyTemplate: getActionsTemplate},
        ]
    };

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };

        _filters['global'].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);

        if(value.trim().length > 0) {
            dispatch(startLoader());
            dispatch(searchActesAction(value)); 
        }else{
            dispatch(searchActesAction("@@@"))
        }
    };

    const getActionsTemplate = (rowData) => {
        return <div style={{ width: '100%', display: 'flex', justifyContent: 'center'}}>
            {hasMenu('MENPARAMACTES') && canDo('MENPARAMACTES', 'UPDATEACTE') && <Link href="#" to="#" onClick={e => updateAct(e, rowData)} className="mr-4" title="Edit">
                <i className="fa fa-pencil color-muted"></i>{" "}
            </Link>}
            {hasMenu('MENPARAMACTES') && canDo('MENPARAMACTES', 'DELETEACTE') && <Link href="#" to="#" title="Close"
                onClick={() =>
                    swal({
                    title: "Supprimer l'acte "+rowData.nameFr+" ?",
                    text:
                        "Une fois l'acte supprimé, il ne pourra plus être accessible",
                    icon: "warning",
                    buttons: true,
                    dangerMode: true,
                    }).then((willDelete) => {
                    if (willDelete) {
                        let data = {
                            type: "ACTE",
                            id: rowData.id
                        }
                        dispatch(startLoader());
                        dispatch(deleteDataAction(data));
                    }
                    })
                }
            >
                <i className="fa fa-close color-danger"></i>
            </Link>}
        </div>
    }

    const dateFilterTemplate = (options) => {
        return <Calendar value={options.value} onChange={(e) => options.filterCallback(e.value, options.index)} dateFormat="dd/mm/yy" placeholder="dd/mm/yyyy" mask="99/99/9999" />;
    };

    const datePaiementBodyTemplate = (rowData) => {
        return formatDate(rowData.createdAt);
    };

    const formatDate = (value) => {
        return value.toLocaleDateString('fr-FR', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric'
        });
    };


    const actTypes = [
        {label: "Chambre", value: "Chambre"},
        {label: "Examen", value: "Examen"},
        {label: "Acte spécialisé", value: "Acte spécialisé"},
        {label: "Frais de déplacement", value: "Frais de déplacement"},
        { label: t('layout.optics'), value: "Optique"},
        {label: "Dentaire", value: "Dentaire"},
        {label: "Consultation", value: "Consultation"},
        {label: "Accessoires verres", value: "Accessoires verres"},
        {label: t('layout.pharmacy'), value: "Pharmacie"},
    ]

    const canDo = (menuCode, actionCode) => {
        let theMenu = props.currentUser.menus?.find(menu => menu.code === menuCode);
        if(!theMenu){
            return false;
        }else{
            return theMenu.actionMenus?.filter(action => action.code === actionCode).length > 0;
        }
    }
    
    const hasMenu = (menuCode) => {
        return props.currentUser.menus?.filter(menu => menu.code === menuCode).length > 0;
    }

    useEffect(() => {
        initFilters();   
        //dispatch(getActesAction());
        dispatch(getActePagesAction(currentPage, 10));  
        dispatch(getGarantiesAction());  
        dispatch(getProvidersTypesAction());
    }, []);


    useEffect(() => {
        if(search.trim().length > 0) {
            dispatch(searchActesAction(search)); 
        }else{
            dispatch(searchActesAction("@@@"))
        }
    }, [search]);

    function onProviderTypeSave(e) {
        e.preventDefault();
        let error = false;
        const errorObj = { ...errorsObj };
        if(file){
            dispatch(startLoader());
            console.log(file)
            dispatch(importActeFileUploadAction(file));
            return ;
        }
        if (code === '') {
            errorObj.code = 'Code est réquis';
            error = true;
        }
        if (nameFr === '') {
            errorObj.name = 'le nom est réquis';
            error = true;
        }
        if (nameEn === '') {
            errorObj.name = 'le nom est réquis';
            error = true;
        }
        setErrors(errorObj);
        if (error) {
            swal('Oops', t('common.check-form-fields'), "infos");
			return ;
		}
        dispatch(startLoader());

        if(updateMode) {
            let obj = { typeActeId, lettreCleId, garantieId, garantieName, nameFr, nameEn, code, isExclu, isAccord, isAmbulatoire, isHospitalisation, isMultiple, providerTypeId, coefLettre, acteProdCode, acteProdName, type };
            dispatch(updateActeAction(acteId, obj));
        }else{
            let obj = { typeActeId, lettreCleId, garantieId, garantieName, nameFr, nameEn, code, isExclu, isAccord, isAmbulatoire, isHospitalisation, isMultiple, providerTypeId, coefLettre, acteProdCode, acteProdName, type };
            dispatch(createActeAction(obj));
        }
    }

    function selectFile(event) {
        setFile(event.target.files[0])
    }

    const onFilterData = (rowData) => {
        console.log(rowData);
    }

    const onGoToPage = (event) => {
        console.log(event);
        setlazyState(event);
    }

    const onSortData = (rowData) => {
        console.log(rowData);
    }

    useEffect(() => {
        if(lazyState.page !== props.actesPages?.pageNumber) {
            dispatch(startLoader());
            dispatch(getActePagesAction(lazyState.page + 1, lazyState.rows)); 
        }
    }, [lazyState]);

    useEffect(() => {
        setUpdateMode(false);
        setActeId("");
        setNameEn("");
        setNameFr("");
        setCode("");
        setCoefLettre(0);
        setIsExclu(false);
        setIsAccord(false);
        setIsAmbulatoire(false);
        setIsHospitalisation(false);
        setIsMultiple(false);
        setGarantieId("");
        setTypeActeId("");
        setLettreCleId("");
        setProviderTypeId([]);
        setType("");
        setCodeGarantie("");
        setLibelleGarantie("");
        setAddCard(false);
        //dispatch(getActesAction());
        //dispatch(getActePagesAction(currentPage, 10)); 
        //dispatch(getGarantiesAction());
        //dispatch(getActesProdAction());
    }, [props.actesPages, props.actesRes]);

    const updateAct = (e, act) => {
        e.preventDefault();
        setUpdateMode(true);
        setActeId(act.id);
        setNameEn(act.nameEn);
        setNameFr(act.nameFr);
        setCode(act.code);
        setCoefLettre(act.coefLettre);
        setIsExclu(act.isExclu);
        setIsAccord(act.isAccord);
        setIsAmbulatoire(act.isAmbulatoire);
        setIsHospitalisation(act.isHospitalisation);
        setIsMultiple(act.isMultiple);
        setGarantieId(act.garantieId);
        setTypeActeId(act.typeActeId);
        setLettreCleId(act.lettreCleId);
        setProviderTypeId(act.acteParTypePrestataire ? act.acteParTypePrestataire.map(elt => {return elt.providerTypeId}) : []);
        setAddCard(true);
        setType(act.type);
        setCodeGarantie(act.codeGarantie);
        setLibelleGarantie(act.garantieName);
    }


    return (
        <>

            <div className="form-head d-flex mb-3 mb-md-4 align-items-start">
                <div className="mr-auto d-none d-lg-block">
                    {hasMenu('MENPARAMACTES') && canDo('MENPARAMACTES', 'CREATEACTE') && <Link
                        to="#"
                        className="btn btn-primary btn-rounded"
                        onClick={() => setAddCard(true)}
                    >
                        + Nouvel Acte
                    </Link>}
                </div>
            </div>
            <Modal className="modal fade" size="lg" show={addCard} onHide={setAddCard} >
            <div className="">
                <div className="card">
                    <div className="card-header">
                        {!updateMode && <h4 className="card-title">Créer un nouvel acte</h4>}
                        {updateMode && <h4 className="card-title">Modifier l'acte {nameFr}</h4>}
                    </div>
                    <div className='card-body'>
                        <div className='basic-form'>
                            <form onSubmit={onProviderTypeSave}>
                                <div className='row'>
                                    <div className='form-group col-6'>
                                        <label className='mb-1'>{t('common.code')}</label>
                                        <input
                                            type='text'
                                            name='code'
                                            className='form-control'
                                            placeholder='PH'
                                            value={code}
                                            disabled={props.showLoading || file}
                                            required={props.showLoading || file}
                                            onChange={(e) => setCode(e.target.value)}
                                        />
                                    </div>
                                    
                                    <div className='form-group col-6'>
                                        <label className='mb-1'>{t('client.type')} Acte</label>
                                        <Select
                                            className="select-search"
                                            value={actTypes.filter(elt => elt.value === type)}
                                            placeholder="Choisir un type"
                                            onChange={(val) => setType(val.value)}
                                            disabled={props.showLoading || file}
                                            required={props.showLoading || file}
                                            options={actTypes}
                                            style={{
                                                lineHeight: '40px',
                                                color: '#7e7e7e',
                                                paddingLeft: ' 15px',
                                            }}
                                        />
                                    </div>
                                    
                                    <div className='form-group col-6'>
                                        <label className='mb-1'>Famille Acte</label>
                                        <Select
                                            className="select-search"
                                            value={props.typeActes?.filter((opt)=>{return parseInt(opt.id) === parseInt(typeActeId)}).map((val)=>{return {label:val.nameFr,value:val.id}})[0]}
                                            placeholder="Choisir une famille"
                                            onChange={(val) => setTypeActeId(val.value)}
                                            disabled={props.showLoading || file}
                                            required={props.showLoading || file}
                                            options={props.typeActes?.map((opt)=>{return {label:opt.nameFr,value:opt.id}})}
                                            style={{
                                            lineHeight: '40px',
                                            color: '#7e7e7e',
                                            paddingLeft: ' 15px',
                                            }}
                                        />
                                    </div>

                                    <div className='form-group col-6'>
                                        <label className='mb-1'>{t('client.type')} {t('dashboard.provider')}</label>
                                        <Select
                                            className="select-search"
                                            value={props.providerTypes?.filter((opt)=>{return providerTypeId.includes(opt.id)}).map((val)=>{return {label:val.nameFr,value:val.id}})}
                                            placeholder="Choisir un type"
                                            onChange={(vals) => setProviderTypeId(vals? vals.map(val => { return val.value }) : [])}
                                            disabled={props.showLoading || file}
                                            required={props.showLoading || file}
                                            options={props.providerTypes?.map((opt)=>{return {label:opt.nameFr,value:opt.id}})}
                                            isMulti
                                            style={{
                                            lineHeight: '40px',
                                            color: '#7e7e7e',
                                            paddingLeft: ' 15px',
                                            }}
                                        />
                                    </div>

                                    <div className='form-group col-6'>
                                        <label className='mb-1'>Lettre Clé</label>
                                        <Select
                                            className="select-search"
                                            value={props.lettreCles?.filter((opt)=>{return opt.id === lettreCleId}).map((val)=>{return {label:val.code,value:val.id}})[0]}
                                            placeholder="Choisir un type"
                                            onChange={(val) => setLettreCleId(val.value)}
                                            disabled={props.showLoading || file}
                                            required={props.showLoading || file}
                                            options={props.lettreCles?.map((opt)=>{return {label:opt.code,value:opt.id}})}
                                            style={{
                                            lineHeight: '40px',
                                            color: '#7e7e7e',
                                            paddingLeft: ' 15px',
                                            }}
                                        />
                                    </div>

                                    <div className='form-group col-6'>
                                        <label className='mb-1'>{t('provider.acte-production')}</label>
                                        <Select
                                            className="select-search"
                                            value={props.actesProd?.filter((opt)=>{return opt.code === acteProdCode}).map((val)=>{return {label:val.name,value:val.id, code:val.code, garantieId: val.garantieId}})[0]}
                                            placeholder="Choisir un type"
                                            onChange={(val) => {
                                                setActeProdCode(val.code);
                                                setActeProdName(val.label);
                                                let res = props.garanties?.filter(elt => parseInt(elt.id) === val.garantieId);
                                                if(res && res.length > 0) {
                                                    setGarantieId(res[0].code);
                                                    setLibelleGarantie(res[0].libelle);
                                                }
                                            }}
                                            disabled={props.showLoading || file}
                                            required={props.showLoading || file}
                                            options={props.actesProd?.map((opt)=>{return {label:opt.name,value:opt.id, code: opt.code, garantieId: opt.garantieId}})}
                                            style={{
                                            lineHeight: '40px',
                                            color: '#7e7e7e',
                                            paddingLeft: ' 15px',
                                            }}
                                        />
                                    </div>

                                    <div className='form-group col-6'>
                                        <label className='mb-1'>Garantie</label>
                                        <Select
                                            className="select-search"
                                            value={props.garanties?.filter((opt)=>{return opt.code === garantieId}).map((val)=>{return {label:val.libelle,value:val.code}})[0]}
                                            placeholder="Choisir un type"
                                            onChange={
                                                (val) => {
                                                    setGarantieId(val.value);
                                                    setLibelleGarantie(val.label);
                                                    //setCodeGarantie(val.code);
                                                }
                                            }
                                            isDisabled={true}
                                            required={props.showLoading || file}
                                            options={props.garanties?.map((opt)=>{return {label:opt.libelle,value:opt.code}})}
                                            style={{
                                            lineHeight: '40px',
                                            color: '#7e7e7e',
                                            paddingLeft: ' 15px',
                                            }}
                                        />
                                    </div>
                                    <div className='form-group col-6'>
                                        <label className='mb-1'>Coeficient</label>
                                        <input
                                            type='number'
                                            name='coefLettre'
                                            placeholder='0'
                                            className='form-control'
                                            disabled={props.showLoading || file}
                                            required={props.showLoading || file}
                                            value={coefLettre}
                                            onChange={(e) => setCoefLettre(e.target.value)}
                                        />
                                    </div>
                                    
                                    <div className='form-group col-12'>
                                        <label className='mb-1'>{t('common.label')}</label>
                                        <input
                                            type='text'
                                            name='nameFr'
                                            className='form-control'
                                            disabled={props.showLoading || file}
                                            required={props.showLoading || file}
                                             placeholder={t('layout.pharmacy')}
                                            value={nameFr}
                                            onChange={(e) => {
                                                setNameFr(e.target.value);
                                                setNameEn(e.target.value);
                                            }}
                                        />
                                    </div>
                                    {/*<div className='form-group col-6'>
                                        <label className='mb-1'>{t('common.label')} En</label>
                                        <input
                                            type='text'
                                            name='nameEn'
                                            className='form-control'
                                             placeholder={t('layout.pharmacy')}
                                            value={nameEn}
                                            onChange={(e) => setNameEn(e.target.value)}
                                        />
                                    </div>*/}
                                    
                                    <div className='col-3 custom-control custom-checkbox mb-3 checkbox-primary'>
                                        <input
                                        type='checkbox'
                                        checked={isExclu}
                                        disabled={props.showLoading || file}
                                        onChange={(e) => setIsExclu(e.target.checked)}
                                        className='custom-control-input'
                                        id='customCheckBox2'
                                        />
                                        <label
                                        className='custom-control-label text-primary text-bold'
                                        htmlFor='customCheckBox2'
                                        >
                                            Exclu
                                        </label>
                                    </div>
                                    <div className='col-3 custom-control custom-checkbox mb-3 checkbox-primary'>
                                        <input
                                        type='checkbox'
                                        checked={isAccord}
                                        disabled={props.showLoading || file}
                                        onChange={(e) => setIsAccord(e.target.checked)}
                                        className='custom-control-input'
                                        id='customCheckBox3'
                                        />
                                        <label
                                        className='custom-control-label text-primary text-bold'
                                        htmlFor='customCheckBox3'
                                        >
                                            Accord préalable
                                        </label>
                                    </div>
                                    
                                    <div className='col-3 custom-control custom-checkbox mb-3 checkbox-primary'>
                                        <input
                                        type='checkbox'
                                        checked={isAmbulatoire}
                                        disabled={props.showLoading || file}
                                        onChange={(e) => setIsAmbulatoire(e.target.checked)}
                                        className='custom-control-input'
                                        id='customCheckBox4'
                                        />
                                        <label
                                        className='custom-control-label text-primary text-bold'
                                        htmlFor='customCheckBox4'
                                        >
                                            Ambulatoire
                                        </label>
                                    </div>
                                    <div className='col-3 custom-control custom-checkbox mb-3 checkbox-primary'>
                                        <input
                                        type='checkbox'
                                        checked={isHospitalisation}
                                        disabled={props.showLoading || file}
                                        onChange={(e) => setIsHospitalisation(e.target.checked)}
                                        className='custom-control-input'
                                        id='customCheckBox5'
                                        />
                                        <label
                                        className='custom-control-label text-primary text-bold'
                                        htmlFor='customCheckBox5'
                                        >
                                            Hospitalier
                                        </label>
                                    </div>
                                    <div className='col-3 custom-control custom-checkbox mb-3 checkbox-primary'>
                                        <input
                                        type='checkbox'
                                        checked={isMultiple}
                                        disabled={props.showLoading || file}
                                        onChange={(e) => setIsMultiple(e.target.checked)}
                                        className='custom-control-input'
                                        id='customCheckBox6'
                                        />
                                        <label
                                        className='custom-control-label text-primary text-bold'
                                        htmlFor='customCheckBox6'
                                        >
                                            Ajout multiple au tarif
                                        </label>
                                    </div>
                                </div>
                                <hr />
                                <div className='row text-center'>
                                    <h4 className='text-center'>Ou Importer un fichier csv des Actes</h4>
                                </div>
                                <div className='input-group mb-3'>
                                    <div className='input-group-prepend'>
                                        <span className='input-group-text'>CSV</span>
                                    </div>
                                    <div className='custom-file'>
                                        <input type='file' className='custom-file-input' onChange={selectFile} />
                                        <label className='custom-file-label'>{file ? file.name : t('common.choose-file')}</label>
                                    </div>
                                </div>
                                <div className='form-group align-items-center justify-content-center'>
                                    <div className=''>
                                        { props.showLoading && 
                                            <Button variant="primary" disabled>
                                                <Spinner
                                                    as="span"
                                                    animation="grow"
                                                    size="sm"
                                                    role="status"
                                                    aria-hidden="true"
                                                />{' '}
                                                <span className="visually-hidden">{t('common.await-text')}</span>
                                            </Button> }
                                        { !props.showLoading &&
                                            <button type='submit' className='btn btn-primary'>
                                              {t('common.save')}
                                            </button> }
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            </Modal>
            
            <DataTableBase 
                data={ 
                    globalFilterValue.length > 0 && props.actesRes.length > 0 ? 
                    props.actesRes.map(acte => {
                        acte.createdAt = new Date(acte.createdAt);
                        acte.formule = props.lettreCles?.filter((opt)=>{return opt.id === acte.lettreCleId})[0]?.code+' * '+acte.coefLettre
                        acte.familyText = props.typeActes?.filter((opt)=>{return opt.id === acte.typeActeId})[0]?.nameFr;
                        return acte;
                    }) :
                    (props.actesPages.data && props.actesPages?.data.length > 0 ? props.actesPages.data.map(acte => {
                        acte.createdAt = new Date(acte.createdAt);
                        acte.formule = props.lettreCles?.filter((opt)=>{return opt.id === acte.lettreCleId})[0]?.code+' * '+acte.coefLettre
                        acte.familyText = props.typeActes?.filter((opt)=>{return opt.id === acte.typeActeId})[0]?.nameFr;
                        return acte;
                    }) : [])
                }
                emptyMessage="Aucun acte trouvé" 
                filters={filters}
                globalFiltersFields={['code', 'nameFr', 'familyText', 'garantieName', 'acteProdName', 'formule', 'createdAt']}
                header={renderHeader()}
                columns={setColumns()}
                exportColumns={[
                    { title: 'Code', dataKey: 'code' },
                    { title: t('common.name'), dataKey: 'nameFr' },
                    { title: t('common.formula'), dataKey: 'formule' },
                    { title: t('common.garanty'), dataKey: 'garantieName' },
                    { title: 'Acte Production', dataKey: 'acteProdName' },
                    { title: t('client.type'), dataKey: 'type' },
                    { title: t('common.family'), dataKey: 'FamilyText' },
                    { title: 'Exclu', dataKey: 'isExclu' },
                    { title: 'Accord préalable', dataKey: 'isAccord' },
                    { title: 'Ambulatoire', dataKey: 'isAmbulatoire' },
                    { title: 'Hospitalier', dataKey: 'isHospitalisation' },
                    { title: t('common.create-date'), dataKey: 'createdAt' },
                ]}
                rows={10} 
                showExport={true}
                exportFilename={"actes"}
                loading={props.showLoading}
                lazy={globalFilterValue.length === 0}
                dataSet={lazyState}
                totalRecords={props.actesPages?.totalPages}
                onGoToPage={onGoToPage}
                onSortData={onSortData}
                onFilterData={onFilterData}
            />

        </>
    );
};

const mapStateToProps = (state) => {
    return {
        actes: state.provider.actes,
        garanties: state.contrat.garanties,
        actesProd: state.contrat.actes,
        typeActes: state.provider.typeActes,
        actesPages: state.provider.actesPages,
        lettreCles: state.provider.lettreCles,
        actesRes: state.provider.actesRes,
        providerTypes: state.provider.providerTypes,
        errorMessage: state.clientAuth.errorMessage,
        successMessage: state.clientAuth.successMessage,
        showLoading: state.provider.showLoading,
        successMessage: state.provider.successMessage,
        currentUser: currentUser(state)
    };
};

export default connect(mapStateToProps)(Acte);