import React, { Fragment, useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useParams } from "react-router-dom";
import { Tab, Nav } from "react-bootstrap";
import { currentUser } from '../../../../store/selectors/CurrentUserSelector';
import Traitements from './TraitementsTable/Traitements';
import Dashboard from './Dashboard';
import HistoriqueMedical from './HistoriqueMedical';
import Parametres from './Parametres';
import DossierAnalyses from './DossierAnalysesTable/DossierAnalyses';
import PiecesJointes from './PiecesJointesTable/PiecesJointes';
import Antecedents from './Antecedents';
import { useTranslation } from "react-i18next";

/* --- BEGIN VoletMedicalPane
	@param(Object): props
    description: Composant du volet médical du dossier du patient
*/
const VoletMedicalPane = (props) => {
  const { t } = useTranslation();
    const {dossierDatas} = props;
    const { id } = useParams();
    const dispatch = useDispatch();
    
    const tabData = [
        {
          name: 'Dashbord',
          icon: 'chalkboard',
          component: Dashboard,
        },
        {
          name: t('dashboard.consults'),
          icon: 'notes-medical',
          component: HistoriqueMedical,
        },
        {
          name: t('client.health-params'),
          icon: 'heartbeat',
          component: Parametres,
        },
        {
           name: t('consultation.treatments'),
          icon: 'pills',
          component: Traitements,
        },
        {
          name: t('client.antecedents'),
          icon: 'diagnoses',
          component: Antecedents,
        },
        {
           name: t('common.anal-files'),
          icon: 'paperclip',
          component: PiecesJointes,
        },
        {
          name: t('common.anal-files'),
          icon: 'procedures',
          component: DossierAnalyses,
        },
        /*{
          name: 'Vaccins',
          icon: 'syringe',
          component: Traitements,
        },*/
      ];

      console.log(dossierDatas);


    return (
        <Fragment>
            <div className='custom-tab-1'>
                <Tab.Container defaultActiveKey={tabData[0].name.toLowerCase()}>
                    <Nav as='ul' className='nav-tabs'>
                    {tabData.map((data, i) => (
                        <Nav.Item as='li' key={i}>
                        <Nav.Link eventKey={data.name.toLowerCase()}>
                            <i className={`la la-${data.icon} mr-2`} />
                            {data.name}
                        </Nav.Link>
                        </Nav.Item>
                    ))}
                    </Nav>
                    <Tab.Content className='pt-4'>
                    {tabData.map((data, i) => (
                        <Tab.Pane eventKey={data.name.toLowerCase()} key={i}>
                            <data.component datas={dossierDatas} />
                        </Tab.Pane>
                    ))}
                    </Tab.Content>
                </Tab.Container>
            </div>
        </Fragment>
    )
}
/* --- END VoletMedicalPane */

const mapStateToProps = (state) => {
    return {
        provider: state.provider.provider,
        currentUser: currentUser(state)
    };
};

export default connect(mapStateToProps)(VoletMedicalPane);