import {
    getdataRapport,getPatologieAmbu,getPatologieHospi,getPrestationAmbu,getTopDentiste,
    getTopOptique,getTopPharmacie,getPrestationHospi,getRapportFinance,getRapportDepenseFamille,
    globalClientStats, detailedClientStats, consommationsClientStats, visitsClientStats,
    topConsommationsClientStats
} from '../../services/RapportService';
import { errorToastr, successToastr } from '../../Utils/ToastrMessage';


export const GET_RAPPORT_CONFIRMED_ACTION = '[getdataRapport action] confirmed getdataRapport';
export const GET_PATOLOGIE_AMBU_CONFIRMED_ACTION = '[getPatologieAmbuAction action] confirmed getPatologieAmbu';
export const GET_PATOLOGIE_HOSPI_CONFIRMED_ACTION = '[getPatologieHospiAction action] confirmed getPatologieHospi';
export const GET_PRESTATION_AMBU_CONFIRMED_ACTION = '[getPrestationAmbuAction action] confirmed getPrestationAmbu';
export const GET_PRESTATION_HOSPI_CONFIRMED_ACTION = '[getPrestationHospiAction action] confirmed getPrestationHospi';
export const GET_TOP_PHARMACIE_CONFIRMED_ACTION = '[getTopPharmacieAction action] confirmed getTopPharmacie';
export const GET_TOP_OPTIQUE_CONFIRMED_ACTION = '[getTopOptiqueAction action] confirmed getTopOptique';
export const GET_TOP_DENTISTE_CONFIRMED_ACTION = '[getTopDentisteAction action] confirmed getTopDentiste';
export const GET_RAPPORT_FINANCE_CONFIRMED_ACTION = '[getRapportFinanceAction action] confirmed getRapportFinance';
export const GET_RAPPORT_DEPENSE_FAMILLE_CONFIRMED_ACTION = '[getRapportDepenseFamilleAction action] confirmed getRapportDepenseFamille';
export const LOADING_TOGGLE_ACTION = '[Loading action] toggle loading';
export const GET_GLOBAL_CLIENT_STATS_CONFIRMED_ACTION = '[globalClientStats action] confirmed globalClientStats';
export const GET_DETAILDED_CLIENT_STATS_CONFIRMED_ACTION = '[detailedClientStats action] confirmed detailedClientStats';
export const GET_VISITS_CLIENT_STATS_CONFIRMED_ACTION = '[visitsClientStats action] confirmed visitsClientStats';
export const GET_CONSOMMATIONS_CLIENT_STATS_CONFIRMED_ACTION = '[consommationsClientStats action] confirmed consommationsClientStats';
export const GET_TOP_CONSOMMATIONS_CLIENT_STATS_CONFIRMED_ACTION = '[topConsommationsClientStats action] confirmed topConsommationsClientStats';
export const GET_RAPPORT_FAILED_ACTION = '[getdataRapport action] failed getdataRapport';

var I18n = require('react-redux-i18n').I18n;

export function getDataRapportAction(data, history) {
    return (dispatch) => {
        getdataRapport(data)
        .then((response) => {
            let data = response.data;
            dispatch(confirmedGetRapportDataAction(data));
           // alert()
        })
        .catch((error) => {
            errorToastr(I18n.t('rapports.error')+error.response?.data?.message);
            dispatch(getRapportDataFailedAction(error.response?.data?.message));
            //history.push('/client-search/');
        });
    };
}
export function getPatologieAmbuAction(data, history) {
    return (dispatch) => {
        getPatologieAmbu(data)
        .then((response) => {
            let data = response.data;
            dispatch(confirmedPatologieAmbuAction(data));
           // alert()
        })
        .catch((error) => {
            errorToastr(I18n.t('rapports.error')+error.response?.data?.message);
            dispatch(getRapportDataFailedAction(error.response?.data?.message));
            //history.push('/client-search/');
        });
    };
}
export function getPatologieHospiAction(history) {
    return (dispatch) => {
        getPatologieHospi()
        .then((response) => {
            let data = response.data;
            dispatch(confirmedPatologieHospiAction(data));
           // alert()
        })
        .catch((error) => {
            errorToastr(I18n.t('rapports.error')+error.response?.data?.message);
            dispatch(getRapportDataFailedAction(error.response?.data?.message));
            //history.push('/client-search/');
        });
    };
}
export function getPrestationAmbuAction(data, history) {
    return (dispatch) => {
        getPrestationAmbu(data)
        .then((response) => {
            let data = response.data;
            dispatch(confirmedPrestationAmbuAction(data));
           // alert()
        })
        .catch((error) => {
            errorToastr(I18n.t('rapports.error')+error.response?.data?.message);
            dispatch(getRapportDataFailedAction(error.response?.data?.message));
            //history.push('/client-search/');
        });
    };
}
export function getPrestationHospiAction(history) {
    return (dispatch) => {
        getPrestationHospi()
        .then((response) => {
            let data = response.data;
            dispatch(confirmedPrestationHospiAction(data));
           // alert()
        })
        .catch((error) => {
            errorToastr(I18n.t('rapports.error')+error.response?.data?.message);
            dispatch(getRapportDataFailedAction(error.response?.data?.message));
            //history.push('/client-search/');
        });
    };
}
export function getTopPharmacieAction(data, history) {
    return (dispatch) => {
        getTopPharmacie(data)
        .then((response) => {
            let data = response.data;
            dispatch(confirmedGetTopPharmacieAction(data));
           // alert()
        })
        .catch((error) => {
            errorToastr(I18n.t('rapports.error')+error.response?.data?.message);
            dispatch(getRapportDataFailedAction(error.response?.data?.message));
            //history.push('/client-search/');
        });
    };
}
export function getTopOptiqueAction(data, history) {
    return (dispatch) => {
        getTopOptique(data)
        .then((response) => {
            let data = response.data;
            dispatch(confirmedGetTopOptiqueAction(data));
           // alert()
        })
        .catch((error) => {
            errorToastr(I18n.t('rapports.error')+error.response?.data?.message);
            dispatch(getRapportDataFailedAction(error.response?.data?.message));
            //history.push('/client-search/');
        });
    };
}
export function getTopDentisteAction(data, history) {
    return (dispatch) => {
        getTopDentiste(data)
        .then((response) => {
            let data = response.data;
            dispatch(confirmedGetTopDentisteAction(data));
           // alert()
        })
        .catch((error) => {
            errorToastr(I18n.t('rapports.error')+error.response?.data?.message);
            dispatch(getRapportDataFailedAction(error.response?.data?.message));
            //history.push('/client-search/');
        });
    };
}
export function getRapportFinanceAction(data, history) {
    return (dispatch) => {
        getRapportFinance(data)
        .then((response) => {
            let data = response.data;
            dispatch(confirmedGetRapportFinanceAction(data));
           // alert()
        })
        .catch((error) => {
            errorToastr(I18n.t('rapports.error')+error.response?.data?.message);
            dispatch(getRapportDataFailedAction(error.response?.data?.message));
            //history.push('/client-search/');
        });
    };
}
export function getRapportDepenseParFamilleAction(police) {
    return (dispatch) => {
        getRapportDepenseFamille(police)
        .then((response) => {
            let data = response.data;
            dispatch(confirmedGetRapportDepenseParFamilleAction(data));
           // alert()
        })
        .catch((error) => {
            errorToastr(I18n.t('rapports.error')+error.response?.data?.message);
            dispatch(getRapportDataFailedAction(error.response?.data?.message));
            //history.push('/client-search/');
        });
    };
}

export function globalClientStatsAction(data, clientIds) {
    return (dispatch) => {
        globalClientStats(data, clientIds)
        .then((response) => {
            let data = response.data;
            dispatch(confirmedGetGlobalClientStatsAction(data));
           // alert()
        })
        .catch((error) => {
            errorToastr("Erreur lors de la récupération des données: "+error.response?.data?.message);
            dispatch(getRapportDataFailedAction(error.response?.data?.message));
            //history.push('/client-search/');
        });
    };
}

export function detailedClientStatsAction(data) {
    return (dispatch) => {
        detailedClientStats(data)
        .then((response) => {
            let data = response.data;
            dispatch(confirmedGetDetailedClientStatsAction(data));
           // alert()
        })
        .catch((error) => {
            errorToastr("Erreur lors de la récupération des données: "+error.response?.data?.message);
            dispatch(getRapportDataFailedAction(error.response?.data?.message));
            //history.push('/client-search/');
        });
    };
}

export function consommationsClientStatsAction(data) {
    return (dispatch) => {
        consommationsClientStats(data)
        .then((response) => {
            let data = response.data;
            dispatch(confirmedGetConsommationsClientStatsAction(data));
           // alert()
        })
        .catch((error) => {
            errorToastr("Erreur lors de la récupération des données: "+error.response?.data?.message);
            dispatch(getRapportDataFailedAction(error.response?.data?.message));
            //history.push('/client-search/');
        });
    };
}

export function visitsClientStatsAction(data) {
    return (dispatch) => {
        visitsClientStats(data)
        .then((response) => {
            let data = response.data;
            dispatch(confirmedGetVisitsClientStatsAction(data));
           // alert()
        })
        .catch((error) => {
            errorToastr("Erreur lors de la récupération des données: "+error.response?.data?.message);
            dispatch(getRapportDataFailedAction(error.response?.data?.message));
            //history.push('/client-search/');
        });
    };
}

export function topConsommationsClientStatsAction(data) {
    return (dispatch) => {
        topConsommationsClientStats(data)
        .then((response) => {
            let data = response.data;
            dispatch(confirmedGetTopConsommationsClientStatsAction(data));
           // alert()
        })
        .catch((error) => {
            errorToastr("Erreur lors de la récupération des données: "+error.response?.data?.message);
            dispatch(getRapportDataFailedAction(error.response?.data?.message));
            //history.push('/client-search/');
        });
    };
}

export function confirmedGetTopConsommationsClientStatsAction(data) {
    return {
        type: GET_TOP_CONSOMMATIONS_CLIENT_STATS_CONFIRMED_ACTION,
        payload: data,
    };
}

export function confirmedGetVisitsClientStatsAction(data) {
    return {
        type: GET_VISITS_CLIENT_STATS_CONFIRMED_ACTION,
        payload: data,
    };
}

export function confirmedGetConsommationsClientStatsAction(data) {
    return {
        type: GET_CONSOMMATIONS_CLIENT_STATS_CONFIRMED_ACTION,
        payload: data,
    };
}

export function confirmedGetDetailedClientStatsAction(data) {
    return {
        type: GET_DETAILDED_CLIENT_STATS_CONFIRMED_ACTION,
        payload: data,
    };
}

export function confirmedGetGlobalClientStatsAction(data) {
    return {
        type: GET_GLOBAL_CLIENT_STATS_CONFIRMED_ACTION,
        payload: data,
    };
}



export function confirmedGetRapportFinanceAction(data) {
    return {
        type: GET_RAPPORT_FINANCE_CONFIRMED_ACTION,
        payload: data,
    };
}
export function confirmedGetRapportDepenseParFamilleAction(data) {
    return {
        type: GET_RAPPORT_DEPENSE_FAMILLE_CONFIRMED_ACTION,
        payload: data,
    };
}
export function confirmedGetRapportDataAction(data) {
    return {
        type: GET_RAPPORT_CONFIRMED_ACTION,
        payload: data,
    };
}
export function confirmedGetTopPharmacieAction(data) {
    return {
        type: GET_TOP_PHARMACIE_CONFIRMED_ACTION,
        payload: data,
    };
}
export function confirmedGetTopDentisteAction(data) {
    return {
        type: GET_TOP_DENTISTE_CONFIRMED_ACTION,
        payload: data,
    };
}
export function confirmedGetTopOptiqueAction(data) {
    return {
        type: GET_TOP_OPTIQUE_CONFIRMED_ACTION,
        payload: data,
    };
}
export function confirmedPatologieAmbuAction(data) {
    return {
        type: GET_PATOLOGIE_AMBU_CONFIRMED_ACTION,
        payload: data,
    };
}
export function confirmedPatologieHospiAction(data) {
    return {
        type: GET_PATOLOGIE_HOSPI_CONFIRMED_ACTION,
        payload: data,
    };
}
export function confirmedPrestationAmbuAction(data) {
    return {
        type: GET_PRESTATION_AMBU_CONFIRMED_ACTION,
        payload: data,
    };
}
export function confirmedPrestationHospiAction(data) {
    return {
        type: GET_PRESTATION_HOSPI_CONFIRMED_ACTION,
        payload: data,
    };
}
export function getRapportDataFailedAction(data) {
    return {
        type: GET_RAPPORT_FAILED_ACTION,
        payload: data,
    };
}
export function loadingToggleAction(status) {
    return {
        type: LOADING_TOGGLE_ACTION,
        payload: status,
    };
}