import React, {useState,useEffect } from 'react'
import { connect, useDispatch } from 'react-redux';
import { Modal, Button as BButton, Spinner, ListGroup } from 'react-bootstrap';
import BasicDatatable from './DataTable';
import { Link } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import {createRolection, updateRoleAction, deleteParamAction, getRolesAction, resetSaveDoneAction, startLoader} from '../../../store/actions/UserAction';
import Select from 'react-select';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Tag } from 'primereact/tag';
import { Calendar } from 'primereact/calendar';
import { DataTableBase } from '../DataTables/DataTableBase';
import { currentUser } from "../../../store/selectors/CurrentUserSelector";
import { useTranslation } from "react-i18next";


const Role = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    let errorsObj = { name: '', code: '' };
    const [nameFr, setNameFr] = useState('');
    const [nameEn, setNameEn] = useState('');
    const [code, setCode] = useState('');
    const [menus, setMenus] = useState([]);
    const [errors, setErrors] = useState(errorsObj);
    const [id, setId] = useState('');
    const [updateMode, setUpdateMode] = useState(false);
    //Modal box
    const [addCard, setAddCard] = useState(false);

    const [filters, setFilters] = useState(null);
    const [globalFilterValue, setGlobalFilterValue] = useState('');
    

    const initFilters = () => {
        setFilters({
            global: { value: null, matchMode: FilterMatchMode.CONTAINS },
            roleCode: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            roleNameFr: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            'menus.menuNameFr': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            createdAt: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
        });
        setGlobalFilterValue("");
    };

    const renderHeader = () => {
        return (
            <div className="flex justify-content-between">
                <Button type="button" icon="pi pi-filter-slash"  label={t('common.reinit')} outlined onClick={clearFilter} />
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText value={globalFilterValue} onChange={(e) => onGlobalFilterChange(e)}  placeholder={t('common.search')} />
                </span>
            </div>
        );
    };

    const clearFilter = () => {
        initFilters();
    };

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };

        _filters['global'].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
    };

    const setColumns = () => {
        return [
            {name: 'roleCode', headerName: "COde", filterPlaceholder: 'Filtrer par code', style: { minWidth: '12rem' }, filter: true, bodyTemplate: null},
            {name: 'roleNameFr', headerName: t('common.name'), filterPlaceholder: t('common.filter-name'), style: { minWidth: '12rem' }, filter: true, bodyTemplate: null},
            {name: 'menus.menuNameFr', headerName: "Menus", filterPlaceholder: 'Filtrer par menus', style: { minWidth: '12rem' }, filter: true, bodyTemplate: rolesBodyTemplate},
            {name: 'createdAt', filterField: 'createdAt', dataType: 'date', headerName: 'Créé le', filterPlaceholder: t('facturation.create-date-filter'), style: { minWidth: '6rem' }, filter: true, bodyTemplate: datePaiementBodyTemplate, filterElement: dateFilterTemplate},
            {name: 'actions', headerName: 'Actions', filterPlaceholder: '', style: { minWidth: '6rem'}, filter: false, bodyTemplate: getActionsTemplate},
        ]
    };

    const getActionsTemplate = (rowData) => {
        return <div style={{ width: '100%', display: 'flex', justifyContent: 'center'}}>
            {hasMenu('MENPARAMUSER') && canDo('MENPARAMUSER', 'UPDATEROLE') && <button className="btn btn-primary shadow btn-xs sharp mr-2"
                onClick={e => updateAction(rowData)}
            >
                <i className="fa fa-pencil"></i>
            </button>}
            {hasMenu('MENPARAMUSER') && canDo('MENPARAMUSER', 'DELETEROLE') && <button className="btn btn-danger shadow btn-xs sharp mr-2"
                onClick={() =>
                    swal({
                    title: "Supprimer le role "+rowData.nameFr+" ?",
                    text:
                        "Une fois le role supprimé, il ne pourra plus être accessible",
                    icon: "warning",
                    buttons: true,
                    dangerMode: true,
                    }).then((willDelete) => {
                    if (willDelete) {
                        let data = {
                            id: rowData.roleId
                        }
                        dispatch(startLoader());
                        dispatch(deleteParamAction('role', data));
                    }
                    })
                }
            >
                <i className="fa fa-trash"></i>
            </button>}
        </div>
    }

    const dateFilterTemplate = (options) => {
        return <Calendar value={options.value} onChange={(e) => options.filterCallback(e.value, options.index)} dateFormat="dd/mm/yy" placeholder="dd/mm/yyyy" mask="99/99/9999" />;
    };

    const datePaiementBodyTemplate = (rowData) => {
        return formatDate(rowData.createdAt);
    };

    const rolesBodyTemplate = (rowData) => {
        console.log(rowData);
        return rowData.menus?.map(menu => {
            return <Tag value={menu.menuNameFr} severity={'secondary'} />;
        })
        
    };

    const focusOnMenu = (index, e) => {
        e.preventDefault();
        console.log(e);
        if(e.target.id === "sup-menu"){
            if(index < menus.length - 1) {
                setMenus(menus.map((menu, i) => {
                    menu.active = i === index + 1;
                    return menu;
                }))
            }
            setMenus(menus.filter((elt, i) => i !== index));
        }else{
            setMenus(menus.map((menu, i) => {
                if(i === index) {
                    console.log(menu);
                }
                menu.active = i === index;
                return menu;
            }))
        };
    }

    const formatDate = (value) => {
        return value.toLocaleDateString('fr-FR', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric'
        });
    };

    const canDo = (menuCode, actionCode) => {
        let theMenu = props.currentUser.menus?.find(menu => menu.code === menuCode);
        if(!theMenu){
            return false;
        }else{
            return theMenu.actionMenus?.filter(action => action.code === actionCode).length > 0;
        }
    }
    
    const hasMenu = (menuCode) => {
        return props.currentUser.menus?.filter(menu => menu.code === menuCode).length > 0;
    }


    useEffect(() => {
        initFilters();    
    }, []);

    function onProviderActeurSave(e) {
        e.preventDefault();
        let error = false;
        const errorObj = { ...errorsObj };
        if (code === '') {
            errorObj.code =t('common.code-required');
            error = true;
        }
        if (nameFr === '') {
            errorObj.name =t('common.name-required');
            error = true;
        }
        /*if (nameEn === '') {
            errorObj.name =t('common.name-required');
            error = true;
        }*/
        setErrors(errorObj);
        if (error) {
            swal('Oops', t('common.check-form-fields'), "infos");
			return ;
		}
        dispatch(startLoader());
        
        let obj = { nameFr: nameFr,nameEn: nameFr, code: code, menus: menus.map(menu => {
            return {
                menuId: menu.id,
                actions: menu.actionMenus?.map(action => {
                    return action.choosen ? action.id : null;
                }).filter(elt => elt)
            }
        }) };

        if(updateMode) {
            dispatch(updateRoleAction(id, obj));
        }else{
            dispatch(createRolection(obj));
        }
    }

    const updateAction = (data) => {
        setId(data.roleId);
        setNameFr(data.roleNameFr);
        setNameEn(data.roleNameEn);
        setCode(data.roleCode);
        /*setMenus(data.menuRole ? data.menuRole.map(menRole => {
                return menRole.menus.id
            }) : []);*/
        setMenus(data.menus ? 
            props.menusData?.filter(elt => data.menus?.map(menRole => menRole.menuId).includes(elt.id)).map((menu) => {
                let menDActions = data.menus.find(elt => elt.menuId === menu.id).actions;
                menu.actionMenus = menu.actionMenus.map(action => {
                    action.choosen = menDActions.includes(action.id);
                    return action
                });
                return menu; 
            }) :
        []);
        setUpdateMode(true);
        setAddCard(true);
    }

    useEffect(() => {
        setId("");
        setNameFr("");
        setNameEn("");
        setCode("");
        setMenus([]);
        setUpdateMode(false);
        setAddCard(false);
    }, [props.roles]);

    const newUserCreate = () => {
        setId("");
        setNameFr("");
        setNameEn("");
        setCode("");
        setMenus([]);
        setUpdateMode(false);
        setAddCard(true);
    }

    return (
        <>
            <div className="form-head d-flex mb-3 mb-md-4 align-items-start">
                <div className="mr-auto d-none d-lg-block">
                    {hasMenu('MENPARAMUSER') && canDo('MENPARAMUSER', 'CREATEROLE') && <Link
                        to="#"
                        className="btn btn-primary btn-rounded"
                        onClick={() => newUserCreate()}
                    >
                        + Nouveau Role
                    </Link>}
                </div>
            </div>
            <Modal className="modal fade" size="lg" show={addCard} onHide={setAddCard} >
                <div className="">
                    <div className="card">
                        <div className="card-header">
                            {!updateMode && <h4 className="card-title">Créer un nouveau role</h4>}
                            {updateMode && <h4 className="card-title">Modifier le role {nameFr}</h4>}
                        </div>
                        <div className='card-body'>
                            <div className='basic-form'>
                                <form onSubmit={onProviderActeurSave}>
                                    <div className='row justify-content-between align-items-center'>
                                        <div className='form-group col-4'>
                                            <label className='mb-1 '>{t('common.code')} du rôle</label>
                                            <input
                                                type='text'
                                                name='code'
                                                className='form-control'
                                                placeholder='ROL'
                                                disabled={updateMode}
                                                value={code}
                                                onChange={(e) => setCode(e.target.value)}
                                            />
                                        </div>
                                        <div className='form-group col-8'>
                                            <label className='mb-1 '>{t('common.label')}</label>
                                            <input
                                                type='text'
                                                name='nameFr'
                                                className='form-control'
                                                placeholder='Médecin'
                                                value={nameFr}
                                                onChange={(e) => setNameFr(e.target.value)}
                                            />
                                        </div>
                                        {/*<div className='form-group col-4'>
                                            <label className='mb-1 '>{t('common.label')} En</label>
                                            <input
                                                type='text'
                                                name='nameEn'
                                                className='form-control'
                                                placeholder='Doctor'
                                                value={nameEn}
                                                onChange={(e) => setNameEn(e.target.value)}
                                            />
                                        </div>*/}
                                        <div className='form-group col-12'>
                                            <label className='mb-1 '>Menus</label>
                                            <Select
                                                className="select-search"
                                                value={props.menusData?.filter(elt => menus.map(menu => menu.id).includes(elt.id)).map(res => {return {...res, label: res.nameFr, value: res.id}})}
                                                placeholder="Choisir les menus"
                                                onChange={(vals) => {
                                                    if(vals) {
                                                        let currentMenusIds = menus.map(elt => {return elt.id});
                                                        setMenus(vals.map((val, i) => {
                                                            val.active = i === (vals.length - 1);
                                                            val.actionMenus = currentMenusIds.includes(val.id) ? 
                                                                menus.find(elt => elt.id === val.id)?.actionMenus : 
                                                                val.actionMenus.map(action => {
                                                                    action.choosen = false;
                                                                    return action;
                                                                });
                                                            return val;
                                                        }))
                                                    }else{
                                                        setMenus([]);
                                                    }
                                                }}
                                                disabled={props.showLoading}
                                                required
                                                isMulti
                                                options={props.menusData?.map(res => {return {...res, label: res.nameFr, value: res.id}})}
                                                style={{
                                                    lineHeight: '40px',
                                                    color: '#7e7e7e',
                                                    paddingLeft: ' 15px',
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-6'>
                                            <PerfectScrollbar
                                                id="DZ_W_Todo2"
                                                className="widget-media dz-scroll ps ps--active-y height300 text-center"
                                            >
                                                {menus.length === 0 && <span className='text-light'>Ajouter des menus...</span>}
                                                {menus.length > 0 && 
                                                    <div className="basic-list-group">
                                                        <ListGroup>
                                                            {menus.map((menu, j) => {
                                                                console.log(menu);
                                                                return <ListGroup.Item
                                                                    action
                                                                    active={menu.active}
                                                                    key={j}
                                                                    className="mb-1 d-flex justify-content-between align-items-center"
                                                                    style={{padding: "10px 20px", zIndex: "unset"}}
                                                                    onClick={(e) => focusOnMenu(j, e)}
                                                                    >
                                                                    <h6 className={menu.active ? "text-white m-0" : "text-dark m-0"}>
                                                                        {menu.nameFr}
                                                                    </h6>
                                                                    <i className={'fa fa-close '+(menu.active ? 'text-white' : 'text-primary')} id="sup-menu"></i>
                                                                </ListGroup.Item>
                                                                })
                                                            }
                                                        </ListGroup>
                                                    </div>
                                                }
                                            </PerfectScrollbar>
                                        </div>
                                        <div className='col-6'>
                                            <PerfectScrollbar
                                                id="DZ_W_Todo2"
                                                className="widget-media dz-scroll ps ps--active-y height300 text-center"
                                            >
                                                {menus.filter(elt => elt.active).length === 0 && <span className='text-light'>Sélectionner un menu</span>}
                                                {menus.filter(elt => elt.active).length > 0 && 
                                                    <div className="basic-list-group">
                                                        <ListGroup>
                                                            {menus.find(elt => elt.active)?.actionMenus?.map((actionM, j) => {
                                                                return <ListGroup.Item
                                                                    key={j}
                                                                        className="mb-1 d-flex flex-column align-items-start"
                                                                        style={{padding: "10px 20px", zIndex: "unset"}}
                                                                    >
                                                                        <div 
                                                                            className='d-flex custom-control custom-checkbox checkbox-primary'
                                                                            style={{zIndex: "unset"}}
                                                                        >
                                                                            <input
                                                                                type='checkbox'
                                                                                checked={actionM.choosen}
                                                                                onChange={(e) => {
                                                                                    if(e.target.checked){
                                                                                        setMenus(menus.map(menu => {
                                                                                            if(menu.id === actionM.menuId) {
                                                                                                menu.actionMenus[j].choosen = true;
                                                                                            }
                                                                                            return menu;
                                                                                        }))
                                                                                    }else{
                                                                                        setMenus(menus.map(menu => {
                                                                                            if(menu.id === actionM.menuId) {
                                                                                                menu.actionMenus[j].choosen = false;
                                                                                            }
                                                                                            return menu;
                                                                                        }))
                                                                                    }
                                                                                }}
                                                                                name={'customCheckBox'+actionM.code+j}
                                                                                className='custom-control-input'
                                                                                id={'customCheckBox'+actionM.code+j}
                                                                            />
                                                                            <label
                                                                                className='custom-control-label text-primary text-bold'
                                                                                htmlFor={'customCheckBox'+actionM.code+j}
                                                                            >
                                                                                {actionM.nameFr}
                                                                            </label>
                                                                        </div>
                                                                </ListGroup.Item>
                                                                })
                                                            }
                                                        </ListGroup>
                                                    </div>
                                                }
                                            </PerfectScrollbar>
                                        </div>
                                    </div>
                                    <hr />
                                    <div className='form-group align-items-center justify-content-center'>
                                        <div className=''>
                                            { props.showLoading && 
                                                <BButton variant="primary" disabled>
                                                    <Spinner
                                                        as="span"
                                                        animation="grow"
                                                        size="sm"
                                                        role="status"
                                                        aria-hidden="true"
                                                    />{' '}
                                                    <span className="visually-hidden">{t('common.await-text')}</span>
                                                </BButton> }
                                            { !props.showLoading &&
                                                <button type='submit' className='btn btn-primary'>
                                               {t('common.save')}
                                                </button> }
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
            <DataTableBase 
                data={props.roles?.map(role => {
                    role.createdAt = new Date(role.createdAt);
                    //role.menus = role.menuRole?.map(prof => prof.menus?.nameFr).toString();
                    return role;
                })}
                emptyMessage="Aucun role trouvé" 
                filters={filters}
                globalFiltersFields={['roleCode', 'roleNameFr', 'menuRole.menus.nameFr', 'createdAt']}
                header={renderHeader()}
                columns={setColumns()}
                exportColumns={[
                    { title: 'Code', dataKey: 'roleCode' },
                    { title: t('common.name'), dataKey: 'roleNameFr' },
                    { title: 'Menus', dataKey: 'menus' },
                    { title: t('common.create-date'), dataKey: 'createdAt' },
                ]}
                rows={10} 
                showExport={true}
                exportFilename={"roles"}
                loading={props.showLoading}
            />
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        roles: state.user.roleDatas,
        menusData: state.user.menusDatas,
        errorMessage: state.user.errorMessage,
        successMessage: state.user.successMessage,
        showLoading: state.user.showLoading,
        saveDone: state.user.saveDone,
        deleteDone: state.user.deleteDone,
        currentUser: currentUser(state)
    };
};

export default connect(mapStateToProps)(Role);