import {ParametreMicroserviceApi, ConsultationMicroserviceApi} from './AxiosInstance';
import { errorToastr, successToastr } from '../Utils/ToastrMessage';

export async function  addCountry(data)  {
    return ParametreMicroserviceApi.post(
        `country`,
        data,
    );
}
export async function  updateCountry(id, data)  {
    return ParametreMicroserviceApi.put(
        `country/`+id,
        data,
    );
}
export async function  deleteCountry(id)  {
    return ParametreMicroserviceApi.delete(
        `country/`+id
    );
}
export async function  addCity(data)  {
    return ParametreMicroserviceApi.post(
        `city`,
        data,
    );
}
export async function  updateCity(id, data)  {
    return ParametreMicroserviceApi.put(
        `city/`+id,
        data,
    );
}
export async function  deleteCity(id)  {
    return ParametreMicroserviceApi.delete(
        `city/`+id
    );
}
export async function  addExclusion(data)  {
    return ParametreMicroserviceApi.post(
        `exclusions`,
        data,
    );
}
export async function  updateExclusion(id, data)  {
    return ParametreMicroserviceApi.put(
        `exclusion/`+id,
        data,
    );
}
export async function  deleteExclusion(id, data)  {
    return ParametreMicroserviceApi.delete(
        `exclusion/`+id
    );
}
export async function  addProfession(data)  {
    return ParametreMicroserviceApi.post(
        `profession`,
        data,
    );
}
export async function  updateProfession(id, data)  {
    return ParametreMicroserviceApi.put(
        `profession/`+id,
        data,
    );
}
export async function  deleteProfession(id, data)  {
    return ParametreMicroserviceApi.delete(
        `profession/`+id
    );
}
export async function  addActivite(data)  {
    return ParametreMicroserviceApi.post(
        `activity-area`,
        data,
    );
}
export async function  updateActivite(id, data)  {
    return ParametreMicroserviceApi.put(
        `activity-area/`+id,
        data,
    );
}
export async function  deleteActivite(id, data)  {
    return ParametreMicroserviceApi.delete(
        `activity-area/`+id
    );
}
export async function  addTypeExclusion(data)  {
    return ParametreMicroserviceApi.post(
        `dictionaire`,
        data,
    );
}
export async function  addProvince(data)  {
    return ParametreMicroserviceApi.post(
        `dictionaire`,
        data,
    );
}
export async function  updateDictionaire(id, data)  {
    return ParametreMicroserviceApi.put(
        `dictionnaire/`+id,
        data,
    );
}
export async function  deleteDictionaire(id)  {
    return ParametreMicroserviceApi.delete(
        `dictionaire/`+id
    );
}
export async function  getParameter(data)  {
    return ParametreMicroserviceApi.get(
        `all`,
        data,
    );
}
export async function  getDictionnaireData(type)  {
    return ParametreMicroserviceApi.get(
        `dictionaires/${type}`,
    );
}
export function formatPatient() {

}

export function formatError(error) {
    errorToastr("Erreur lors de l'opération: "+error?.response?.data?.message);
}
export function getDateError() {
    errorToastr("Une erreur est survenue");
}
export function saveSuccess() {
    successToastr("Opération Reussie !");
}